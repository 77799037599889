import React from 'react'
import ReactDOM from  'reactDOM'
import $ from 'jquery'
import jQuery from 'jquery'
var moment = require('moment')
import Loader from '../shared/PulseLoader'
window.$ = $
window.jQuery = jQuery

import helpers from "../../utils/helpers"
//import timezoneHelper from "../../utils/timezoneHelper"

import Translate from '../shared/i18n/Translate'

var ClientsPresentation =  class extends React.Component {
  constructor(props){
    super(props)
    this.getRemainingClients=this.getRemainingClients.bind(this)
    this.searchInputEnterListener=this.searchInputEnterListener.bind(this)
    this.handleSetSearchFilter=this.handleSetSearchFilter.bind(this)
  }
  componentDidMount(){
    this.props.refreshClients()
  }
  getRemainingClients(){
    var cantClients = this.props.clients.length
    var start = this.props.page*50 + (cantClients>0? 1:0)
    var end = this.props.page*50 + this.props.clients.length
    return start + "/" + end
  }
  searchInputEnterListener(e){
    var input=e.target
    var keyCode = e.charCode;
    if (keyCode == '13'){// Enter pressed
      this.handleSetSearchFilter(input.value)
    }
  }
  handleSetSearchFilter(query){
    if(!this.props.loading)
      this.props.setSearchFilter(query)
  }
  render() {
    const {loading,searchView,setSearchView,setSearchFilter,status,fetchClients, goToClientsManage,goToHome,
        clients,refreshClients,nextPage,backPage,setActiveStatus,setInactiveStatus,strings} = this.props

    var ClientsControls =
    (<div className="mailbox-controls">
      {/* <!-- Check all button
      <button type="button" className="btn btn-default btn-sm checkbox-toggle"><i className="fa fa-square-o"></i>
      </button>
      <div className="btn-group">
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-trash-o"></i></button>
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-reply"></i></button>
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-share"></i></button>
      </div> --> */}
      {/* <!-- /.btn-group --> */}

      <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap",alignItems:"center", margin:"0px 5px"}}>
        <div style={{display:"flex"}}>
          <button type="button" className="btn btn-default btn-sm" onClick={refreshClients}>
            <i className="fa fa-refresh"></i>
          </button>
        </div>
        <div style={!loading? {display:"none"} : {}}>
          <Loader color="#3c8dbc" size="9px" margin="4px"/>
        </div>
        <div className="pull-right" style={{margin:"5px 0px"}}>
          {this.getRemainingClients()}
          <div className="btn-group">
            <button type="button" className="btn btn-default btn-sm" onClick={backPage}><i className="fa fa-chevron-left"></i></button>
            <button type="button" className="btn btn-default btn-sm" onClick={nextPage}><i className="fa fa-chevron-right"></i></button>
          </div>
          {/* <!-- /.btn-group --> */}
        </div>
      </div>
      {/* <!-- /.pull-right --> */}
    </div>
  )


  var Breadcrumb = ({strings}) => (
    <ol className="breadcrumb">
      <li><div onClick={goToHome} style={{cursor:"pointer"}}><i className="fa fa-dashboard"></i> {strings.home}</div></li>
      <li className="active">{status? strings.activeClients : strings.inactiveClients}</li>
    </ol>
  )
  Breadcrumb = Translate(Breadcrumb,"Breadcrumb")

    return (
            <div>
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                  <h1>
                    {strings.clientManagement}
                    <small></small>
                  </h1>
                  <Breadcrumb strings={strings} />
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="btn btn-primary btn-block margin-bottom" style={{marginBottom:"15px"}} onClick={()=>goToClientsManage()}>
                        {strings.newClient}
                      </div>

                      <div className="box box-solid">
                        <div className="box-header with-border">
                          <h3 className="box-title">{strings.status}</h3>

                          {/* <div className="box-tools">
                            <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i>
                            </button>
                          </div> */}

                        </div>
                        <div className="box-body no-padding">
                          <ul className="nav nav-pills nav-stacked" style={{cursor:"pointer"}}>
                            <li className={status? strings.active : ""}
                                onClick={setActiveStatus}>
                              <a>
                                <i className="fa fa-inbox"></i> {strings.active}
                                {/* <span className="label label-primary pull-right">{1}</span> */}
                              </a>
                            </li>
                            <li className={status? "" : strings.active}
                                onClick={setInactiveStatus}>
                              <a>
                                <i className="fa fa-filter"></i> {strings.inactive}
                                {/* <span className="label label-warning pull-right">{0}</span> */}
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/* <!-- /.box-body --> */}
                      </div>

                    </div>
                    {/* <!-- /.col --> */}
                    <div className="col-md-9">
                      <div className="box box-primary">
                        <div className="box-header with-border">
                          <h3 className="box-title">{status? strings.activeClients : strings.inactiveClients }</h3>
                            <div className="box-tools pull-right">
                              <div className="has-feedback">
                                <input type="text"
                                       value={searchView}
                                       onChange={(e)=>setSearchView(e.target.value)}
                                       className="form-control input-sm" placeholder={strings.search}
                                       onKeyPress={this.searchInputEnterListener} />
                                <span className="glyphicon glyphicon-search form-control-feedback"
                                      style={{pointerEvents:"auto",cursor:"pointer"}}
                                      onClick={()=>this.handleSetSearchFilter(searchView)}></span>
                            </div>
                          </div>
                          {/* <!-- /.box-tools --> */}
                        </div>
                        {/* <!-- /.box-header --> */}
                        <div className="box-body no-padding">
                          {ClientsControls}
                          <div className="table-responsive mailbox-messages">
                            <table className="table table-hover table-striped">
                              <tbody>
                                {clients.map((op,key)=>(
                                  <tr key={key} onClick={()=>{goToClientsManage({id:op.id.toString()})}} style={{cursor:"pointer"}}>
                                    <td className="mailbox-pic" style={{verticalAlign:"middle"}}><img className="img-circle" src="dist/img/company.png" alt="Company Avatar" style={{width:"35px"}}/></td>
                                    <td className="mailbox-name" style={{verticalAlign:"middle"}}>{op.company}</td>
                                    <td className="mailbox-subject" style={{verticalAlign:"middle"}}>{op.email}</td>
                                    <td className="mailbox-subject" style={{verticalAlign:"middle"}}>{op.name}</td>
                                    <td className="mailbox-date" style={{verticalAlign:"middle"}}>{op.avatar}</td>
                                    <td className="" style={{verticalAlign:"middle"}}><div className="fa fa-edit" style={{fontSize:"15px"}}></div></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {/* <!-- /.table --> */}
                          </div>
                          {/* <!-- /.mail-box-messages --> */}
                        </div>
                        {/* <!-- /.box-body --> */}
                        <div className="box-footer no-padding">
                          {ClientsControls}
                        </div>
                      </div>
                      {/* <!-- /. box --> */}
                    </div>
                    {/* <!-- /.col --> */}
                  </div>
                  {/* <!-- /.row --> */}
                </section>
                {/* <!-- /.content --> */}
            </div>
    )
  }
}

export default Translate(ClientsPresentation,"Clients")
