import { connect } from 'react-redux'
import NavigationMenu from './NavigationMenu'

const mapStateToProps = function(state) {
  return {
    indicators:state.indicators,
    loggedUser:state.loggedUser
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationMenu)
