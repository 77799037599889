import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import TableList from "./components/table";
import { Modal, Button } from "antd";

export class Maps extends Component {
  constructor(props) {
    super(props);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.state = {
      titleModal: "",
      visibleModal: false,
    };
    this.hideModal = this.hideModal.bind(this);
  }
  componentDidMount() {
    this.props.onLoadTasks(this.props.filter, true);
  }

  onMarkerClick(props, marker, e) {
    let f = this.props.filter;
    f = {
      ...f,
      location_id: marker.id,
    };
    this.setState({
      titleModal: `${this.props.strings.location}: ${marker.title}`,
      visibleModal: true,
    });
    this.props.onLoadTasks(f);
  }

  hideModal() {
    this.setState({
      visibleModal: false,
    });
  }

  onApply() {
    this.props.onSetView("list");
  }

  render() {
    const { strings, listMarkers } = this.props;

    return (
      <div>
        <section className='content'>
          <div className='row'>
            <div
              className='col-md-12'
              style={{ height: "calc(100vh - 200px)" }}
            >
              <Modal
                title={this.state.titleModal}
                visible={this.state.visibleModal}
                okText={strings.yes}
                cancelText={strings.cancel}
                width='800px'
                onOk={() => this.onApply()}
                onCancel={this.hideModal}
                footer={[
                  <Button key='back' onClick={this.hideModal}>
                    {strings.close}
                  </Button>,
                  <Button
                    key='submit'
                    className='btn-primary'
                    onClick={() => this.onApply()}
                  >
                    {strings.more}
                  </Button>,
                ]}
              >
                <div className='zd-modal-list'>
                  <TableList {...this.props} reduced={true} />
                </div>
              </Modal>
              <Map
                google={this.props.google}
                zoom={10}
                scrollwheel={true}
                initialCenter={{
                  lat: 40.701993,
                  lng: -74.062682,
                }}
              >
                {listMarkers.length > 0 &&
                  listMarkers.map((m, i) => {
                    return (
                      <Marker
                        key={`mk${m.key}`}
                        onClick={this.onMarkerClick}
                        position={{ lat: m.location_lat, lng: m.location_lon }}
                        label={`${m.tasks_qty}`}
                        title={`${m.location_decr}`}
                        id={m.location_id}
                      />
                    );
                  })}
              </Map>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDNLaTMiUnl2Eh_tSG9NMO_HPzJqTArP5M",
})(Maps);
