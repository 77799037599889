import React from 'react'
import { connect } from 'react-redux'
import routes from "../../utils/routes"
import { push } from 'react-router-redux'

const Redirect404 = class extends React.Component {
  constructor(props){
    super(props)
  }
  componentDidMount(){
    localStorage.setItem("ADASDASDASS","DA")
    this.props.goTo404()
  }
  render(){
    return null
  }
}

const mapStateToProps = function(state) {return {}}

const mapDispatchToProps = function(dispatch)  {
  return {
    goTo404:()=>dispatch(push(routes.route404()))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Redirect404)
