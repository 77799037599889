import {handleActions} from "redux-actions"
import {SET_EMAIL,SET_PASSWORD,SET_AUTH_MESSAGE,SET_LOGIN_LOADING} from "../actionTypes/loginActions"
var update = require('immutability-helper');

const initialState={
  email:"",
  password:"",
  authMessage:"",
  loading:false
}

const reducer=handleActions({
  [SET_EMAIL]:(state,action)=>update(state,{email:{$set:action.payload}}),
  [SET_PASSWORD]:(state,action)=>update(state,{password:{$set:action.payload}}),
  [SET_AUTH_MESSAGE]:(state,action)=>update(state,{authMessage:{$set:action.payload}}),
  [SET_LOGIN_LOADING]:(state,action)=>update(state,{loading:{$set:action.payload}})
},initialState)

export default reducer
