import React, { useState } from "react";
import Chart from "./Chart";

const MessagesGraph = (props) => {
  return (
    <section className='content'>
      <Chart {...props} />
    </section>
  );
};

export default MessagesGraph;
