import React from 'react'

export default ({percentage,theme}) => {
  var stylePercentage = {
      width: percentage + '%'
  }
  return (
      <li>
          <a href="#">
              <h3>
                  {content}
                  <small className="pull-right">{percentage+'%'}</small>
              </h3>
              <div className="progress xs">
                  <div className={"progress-bar "+ theme} style={stylePercentage}>
                      <span className="sr-only">{percentage + '% Complete'} </span>
                  </div>
              </div>
          </a>
      </li>
  )
}
