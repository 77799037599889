import {handleActions} from "redux-actions"
import {SET_CLIENTS} from "../actionTypes/clientsListActions"
var update = require('immutability-helper')

/*
const initialState=[{
  id:"ac136d5e-5af6-19ab-815a-f61ce8cb0000",
  name:"Sergio Martinez",
  email:"smartinez@gmail.com",
  password:"martinez",
  rol:"ADMIN",
  timezone:"(GMT-03:00) Buenos Aires",
  active:true
},
{
  name:"Jorge Lopez",
  email:"jlopez@gmail.com",
  rol:"Operador",
  timezone:"(GMT-03:00) Buenos Aires",
  active:true
},
{
  name:"Agustin Perez",
  email:"aperez@gmail.com",
  rol:"Operador",
  timezone:"(GMT-03:00) Buenos Aires",
  active:false
},]

*/
const initialState=[]

const reducer=handleActions({
  [SET_CLIENTS]:(state,action)=>update(state,{$set:action.payload})
},initialState)

export default reducer
