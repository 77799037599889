import React from 'react'
import ReactDOM from  'reactDOM'
import helpers from "../../utils/helpers"
import api from "../../utils/api"
//import timezoneHelper from "../../utils/timezoneHelper"
import Translate from '../shared/i18n/Translate'
import $ from 'jquery';

var LocationsManagePresentation = class extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      validation:true,
      inputIdValid:true,
      inputLatValid:true,
      inputLonValid:true,
    }
    this.handleNewLocationClick=this.handleNewLocationClick.bind(this)
    this.getErrorMessage=this.getErrorMessage.bind(this)
  }
  componentDidMount(){
    console.log("mount")
    // Default values
    this.inputId.value=""
    this.inputLat.value=""
    this.inputLon.value=""
    this.inputDescr.value=""

    // Location values (editing)
    if(this.props.isEditing){
      var {id,lat,lon,decr} = this.props.location
      this.inputId.value=id
      this.inputLat.value=lat
      this.inputLon.value=lon
      this.inputDescr.value=decr
    }
  }
  handleNewLocationClick(e){
    e.preventDefault()
    var inputId=ReactDOM.findDOMNode(this.inputId)
    var inputLat=ReactDOM.findDOMNode(this.inputLat)
    var inputLon=ReactDOM.findDOMNode(this.inputLon)
    var inputDescr=ReactDOM.findDOMNode(this.inputDescr)

    var valid=true

    if(!helpers.isEmptyString(inputLat.value)){
      this.setState({inputLatValid:true})
    }else{valid=false; this.setState({inputLatValid:false})}

    if(!helpers.isEmptyString(inputLon.value)){
      this.setState({inputLonValid:true})
    }else{valid=false; this.setState({inputLonValid:false})}


    if(valid){
      this.setState({validation:true})
      var location={
        id: this.inputId.value,
        lat: this.inputLat.value,
        lon: this.inputLon.value,
        decr: this.inputDescr.value,
      }


      if(this.props.isEditing){
        this.props.editLocation(Object.assign({},location,{id:this.props.locationId}))
      }else{
        this.props.setLocation(location)
      }

    }else{this.setState({validation:false})}
  }
  getErrorMessage(){
    if(this.state.validation)
      return null

    var element
    if(!this.state.inputIdValid){
      element=this.props.strings.id
    }else if(!this.state.inputLatValid){
      element=this.props.strings.lat
    }else if(!this.state.inputLonValid){
      element=this.props.strings.lon
    }

    return <span style={{color:"#a94442"}}>{this.props.strings.badDataMsg + element + this.props.strings.badDataMsgValid }</span>
  }
    render(){
      const {goToHome,goToLocations,isEditing,location,strings} =this.props

      var Breadcrumb = ({strings}) => (
        <ol className="breadcrumb">
          <li><div onClick={goToHome} style={{"cursor":"pointer"}}><i className="fa fa-dashboard"></i> {strings.home}</div></li>
          <li onClick={goToLocations} style={{"cursor":"pointer"}}>{strings.locations}</li>
          <li style={{marginBottom:"20px"}} className="active">{isEditing? location.id : strings.newLocation}</li>
        </ol>
      )
      Breadcrumb = Translate(Breadcrumb,"Breadcrumb")

      console.log("PORT: ",location)
      return (
        <div>
          <section className="content-header">
            <h1 style={{marginBottom:"10px"}}>
              {isEditing? strings.editLocation : strings.newLocation}
              <small></small>
            </h1>
            <Breadcrumb />
          </section>
            <div className="col-md-12">
              <div className="box box-info">

                <form autoComplete="off" className="form-horizontal">
                  <div className="box-body" style={{marginTop:"57px"}}>
                    <div className="form-group" style={(this.props.isEditing? {}:{display:"none"})}>
                      <label className="col-sm-2 control-label">{strings.id}</label>
                      <div className={"col-sm-10 "+ (!this.state.inputIdValid? "has-error":"")}>
                        <input style={{maxWidth:"650px"}} ref={(i)=>this.inputId=i} type="text" className="form-control" placeholder={strings.id} required disabled/>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.description}</label>
                      <div className={"col-sm-10"}>
                        <input style={{maxWidth:"650px"}} ref={(i)=>this.inputDescr=i} type="text" className="form-control" placeholder={strings.description} />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.lat}</label>
                      <div className={"col-sm-10 "+ (!this.state.inputLatValid? "has-error":"")}>
                        <input style={{maxWidth:"650px"}} ref={(i)=>this.inputLat=i} type="text" className="form-control" placeholder={strings.lat} required/>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.lon}</label>
                      <div className={"col-sm-10 "+ (!this.state.inputLonValid? "has-error":"")}>
                        <input style={{maxWidth:"650px"}} ref={(i)=>this.inputLon=i} type="text" className="form-control" placeholder={strings.lon} required/>
                      </div>
                    </div>



                    <div className="col-sm-1"/>


                  </div>

                  <div className="box-footer" style={{justifyContent:"center",display:"flex"}}>
                    <button type="submit" onClick={this.handleNewLocationClick}
                            className="btn btn-info pull-right" style={{marginRight:"100px",padding:"6px 25px"}}>{strings.save}</button>
                    <button className="btn btn-default pull-left" style={{padding:"6px 25px"}}
                      onClick={(e)=>{e.preventDefault();goToLocations()}} style={{"cursor":"pointer"}}>{strings.cancel}</button>

                  </div>
                  <div style={{width:"100%",textAlign:"center"}}>
                    {this.getErrorMessage()}
                  </div>

                </form>
              </div>
            </div>
        </div>
      )
    }
  }

  export default Translate(LocationsManagePresentation,"LocationsManage")
