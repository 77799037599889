import {handleActions} from "redux-actions"
import {SET_STATUS,NEXT_PAGE,BACK_PAGE,RESET_PAGE,
        CLIENTS_LOADING,SET_SEARCH,SET_SEARCH_VIEW } from "../actionTypes/clientsActions"
var update = require('immutability-helper')

const initialState={
  filters:{
    search:"",
    searchView:""
  },
  status:true,
  page:0,
  loading:false
}

const reducer=handleActions({
  [SET_STATUS]:(state,action)=>update(state,{status:{$set:action.payload}}),
  [NEXT_PAGE]:(state,action)=>update(state,{page:{$set:state.page+1}}),
  [BACK_PAGE]:(state,action)=>update(state,{page:{$set:state.page-1}}),
  [RESET_PAGE]:(state,action)=>update(state,{page:{$set:initialState.page}}),
  [CLIENTS_LOADING]:(state,action)=>update(state,{loading:{$set:action.payload}}),
  [SET_SEARCH]:(state,action)=>update(state,{filters:{search:{$set:action.payload}}}),
  [SET_SEARCH_VIEW]:(state,action)=>update(state,{filters:{searchView:{$set:action.payload}}}),
},initialState)

export default reducer
