import React, { useState } from "react";
import api from "../../utils/api";
import { Input, Modal } from "antd";
import DatePicker from "react-datepicker";
import "antd/lib/modal/style/css";
import "antd/lib/input/style/css";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const CustomDatePickerInput = ({ onClick, value }) => {
  return (
    <div role='button' onClick={onClick}>
      <div className='react-datepicker-wrapper'>
        <div className='react-datepicker__input-container'>
          <input
            type='text'
            style={{ width: "400px" }}
            placeholder='Measurement Date'
            className='form-control'
            value={value}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};

const ManualRead = ({
  open,
  asset,
  loggedUser,
  getAsset,
  onClose,
  assetId,
  onGetMetrics,
  days,
}) => {
  const [formValues, setFormValues] = useState({
    dypstick_measurement: "",
    measurement_date: null,
  });

  const handleSetQuickEvent = () => {
    if (
      formValues.dypstick_measurement.trim() === "" ||
      !formValues.measurement_date
    ) {
      return;
    }
    const data = {
      id: asset.id,
      liters_measurement: 0,
      dypstick_measurement: formValues.dypstick_measurement,
      measurement_date: moment(formValues.measurement_date).valueOf(),
    };

    api
      .setManualReading(loggedUser.clientId, asset.id, data)
      .then(() => {
        getAsset(assetId);
        onGetMetrics(loggedUser.clientId, assetId, days);
      })
      .finally(() => {
        setFormValues({ dypstick_measurement: "", measurement_date: null });
        onClose();
      });
  };

  const onChange = (field, value) => {
    setFormValues((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Modal
      title='Add Manual Reading'
      visible={open}
      onOk={() => handleSetQuickEvent()}
      onCancel={onClose}
      okText='Add'
      cancelText='Cancel'
    >
      <div className='box box-info'>
        <div className='box-body'>
          <form action='#' method='post'>
            <div className='form-group'>
              <Input
                addonAfter='cm'
                placeholder='Dipstick Measurement'
                name='dypstick_measurement'
                onChange={(evt) => onChange(evt.target.name, evt.target.value)}
              />
            </div>
            <div className='form-group'>
              <DatePicker
                customInput={<CustomDatePickerInput />}
                showTimeSelect
                selected={
                  formValues.measurement_date
                    ? moment(formValues.measurement_date)
                    : null
                }
                onChange={(dateTime) => {
                  onChange("measurement_date", dateTime);
                }}
                dateFormat='MMMM D, yyyy h:mm a'
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ManualRead;
