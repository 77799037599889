import React from 'react';
import ReactDOM from  'reactDOM';
import $ from 'jquery';
import jQuery from 'jquery';
var moment = require('moment');
import Loader from '../shared/PulseLoader'

window.$ = $;
window.jQuery = jQuery;

import helpers from "../../utils/helpers"
//import NewChatModal from "../modals/NewChatModal"
import Timer from "../shared/Timer"

import Translate from '../shared/i18n/Translate'

const InboxPresentation = class extends React.Component {
  constructor(props){
    super(props)
    this.getRemainingConversations=this.getRemainingConversations.bind(this)
    this.searchInputEnterListener=this.searchInputEnterListener.bind(this)
    this.handleSetSearchFilter=this.handleSetSearchFilter.bind(this)
  }
  componentDidMount(){
    this.props.refreshConversations()
  }
  getRemainingConversations(){
    var cantConvers = this.props.conversations.length
    var start = this.props.page*50 + (cantConvers>0? 1:0)
    var end = this.props.page*50 + this.props.conversations.length
    return start + "/" + end
  }
  searchInputEnterListener(e){
    var input=e.target
    var keyCode = e.charCode;
    if (keyCode == '13'){// Enter pressed
      this.handleSetSearchFilter(input.value)
    }
  }
  handleSetSearchFilter(query){
    if(!this.props.loading) // !helpers.isEmptyString(query)
      this.props.setSearchFilter(query)
  }
  render() {
    const {loading,unreaded,searchView,setSearchView,setSearchFilter,setUnreadedFilter,
        conversations,refreshConversations,nextPage,backPage,goToConversation,indicators,
        setInboxFolder,setJunkFolder,folder,fetchConversations,goToHome,strings} = this.props

    var InboxControls =
    (<div className="mailbox-controls">
      {/* <!-- Check all button
      <button type="button" className="btn btn-default btn-sm checkbox-toggle"><i className="fa fa-square-o"></i>
      </button>
      <div className="btn-group">
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-trash-o"></i></button>
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-reply"></i></button>
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-share"></i></button>
      </div> --> */}
      {/* <!-- /.btn-group --> */}

      <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap",alignItems:"center", margin:"0px 5px"}}>
        <div style={{display:"flex"}}>
          <button type="button" className="btn btn-default btn-sm" onClick={refreshConversations}>
            <i className="fa fa-refresh"></i>
          </button>
          <button type="button"
                  className="btn btn-default btn-sm checkbox-toggle"
                  style={{margin:"0px 6px"}}
                  onClick={setUnreadedFilter}>
              <i className={"fa fa-"+(unreaded? "check-":"")+"square-o"} style={{marginRight:"6px"}}></i>
              {strings.unreadFilter}
          </button>
        </div>
        <div style={!loading? {display:"none"} : {}}>
          <Loader color="#3c8dbc" size="9px" margin="4px"/>
        </div>
        <div className="pull-right" style={{margin:"5px 0px"}}>
          {this.getRemainingConversations()}
          <div className="btn-group">
            <button type="button" className="btn btn-default btn-sm" onClick={backPage}><i className="fa fa-chevron-left"></i></button>
            <button type="button" className="btn btn-default btn-sm" onClick={nextPage}><i className="fa fa-chevron-right"></i></button>
          </div>
          {/* <!-- /.btn-group --> */}
        </div>
      </div>
      {/* <!-- /.pull-right --> */}
    </div>
  )


  var Breadcrumb = ({strings}) => (
      <ol className="breadcrumb">
        <li><div onClick={goToHome} style={{"cursor":"pointer"}}><i className="fa fa-dashboard"></i> {strings.home}</div></li>
        <li className="active">{folder=="inbox"? strings.inbox : strings.junk}</li>
      </ol>
    )
  Breadcrumb = Translate(Breadcrumb,"Breadcrumb")

    return (
            <div>
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                  <h1>
                    SMS Inbox
                    <small>{""} {strings.newMessages}</small>
                  </h1>
                  <Breadcrumb />
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                  <div className="row">
                    <div className="col-md-3">
                      <a className="btn btn-primary btn-block margin-bottom" data-toggle="modal" data-target="#NewChatModal">
                        {strings.newSms}
                      </a>

                      <div className="box box-solid">
                        <div className="box-header with-border">
                          <h3 className="box-title">{strings.folders}</h3>

                          {/* <div className="box-tools">
                            <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"></i>
                            </button>
                          </div> */}

                        </div>
                        <div className="box-body no-padding">
                          <ul className="nav nav-pills nav-stacked" style={{cursor:"pointer"}}>
                            <li className={folder=="inbox"? "active" : ""}
                                onClick={setInboxFolder}>
                              <a>
                                <i className="fa fa-inbox"></i> Inbox
                                <span className="label label-primary pull-right">{""}</span>
                              </a>
                            </li>
                            <li className={folder=="junk"? "active" : ""}
                                onClick={setJunkFolder}>
                              <a>
                                <i className="fa fa-filter"></i> {strings.junk}
                                <span className="label label-warning pull-right">{""}</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/* <!-- /.box-body --> */}
                      </div>

                    </div>
                    {/* <!-- /.col --> */}
                    <div className="col-md-9">
                      <div className="box box-primary">
                        <div className="box-header with-border">
                          <h3 className="box-title">{folder=="inbox"? strings.inbox : strings.junk}</h3>
                            <div className="box-tools pull-right">
                              <div className="has-feedback">
                                <input type="text"
                                       value={searchView}
                                       onChange={(e)=>setSearchView(e.target.value)}
                                       className="form-control input-sm" placeholder={strings.search}
                                       onKeyPress={this.searchInputEnterListener} />
                                <span className="glyphicon glyphicon-search form-control-feedback"
                                      style={{pointerEvents:"auto",cursor:"pointer"}}
                                      onClick={()=>this.handleSetSearchFilter(searchView)}></span>
                            </div>
                          </div>
                          {/* <!-- /.box-tools --> */}
                        </div>
                        {/* <!-- /.box-header --> */}
                        <div className="box-body no-padding">
                          {InboxControls}
                          <div className="table-responsive mailbox-messages">
                            <table className="table table-hover table-striped">
                              <tbody>
                                {conversations.map((conv,key)=>(
                                  <tr key={key} onClick={()=>goToConversation(conv)} style={{cursor:"pointer"}}>
                                    <td className="mailbox-name" >{conv.dinamicTittle}</td>
                                    <td className="mailbox-subject">{conv.last_readed? conv.last_message:<b>{conv.last_message}</b>}
                                    </td>
                                    <td>
                                    {
                                      (conv.tags? conv.tags.map((tag,key)=>
                                          (<span key={key}><span className="label label-danger">{tag}</span><span>{" "}</span></span>)) : null)
                                    }
                                    </td>
                                    <td className="mailbox-date">{moment(conv.last_time).fromNow()}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {/* <!-- /.table --> */}
                          </div>
                          {/* <!-- /.mail-box-messages --> */}
                        </div>
                        {/* <!-- /.box-body --> */}
                        <div className="box-footer no-padding">
                          {InboxControls}
                        </div>
                      </div>
                      {/* <!-- /. box --> */}
                    </div>
                    {/* <!-- /.col --> */}
                  </div>
                  {/* <!-- /.row --> */}
                </section>
                {/* <!-- /.content --> */}
                {/*<NewChatModal />*/}
                {/* <Timer name="TimerRefreshInbox" behavior={()=>this.props.page==0? fetchConversations("0",50) : ""} /> */}
            </div>
    )
  }
}

export default Translate(InboxPresentation,"Inbox")
