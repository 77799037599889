import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import api from "../../utils/api";
import Translate from "../shared/i18n/Translate";
import Select from "react-select";
import PieChart from "../shared/PieChart";
import ColumnChart from "../shared/ColumnChart";

const Dashboard = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { locations: [], locationSelected: {}, dashboard: {} };
    this.onLocationSelectChange = this.onLocationSelectChange.bind(this);
    this.getSelectOptions = this.getSelectOptions.bind(this);
    this.updateDashboard = this.updateDashboard.bind(this);
    this.updateAll = this.updateAll.bind(this);
  }
  onLocationSelectChange(option) {
    if (option.value == "All") return this.updateAll();

    const locSelected = this.state.locations.filter(
      (l) => l.id == option.value
    )[0];
    if (locSelected) {
      this.updateDashboard(locSelected.id);
      this.setState({ locationSelected: locSelected });
    }
  }
  componentDidMount() {
    this.updateAll();
  }
  updateAll() {
    const { loggedUser } = this.props;

    Promise.all([
      api.getLocations(loggedUser.clientId),
      api.getDashboard(),
    ]).then((values) => {
      this.setState({ locations: values[0], dashboard: values[1] });
    });
  }
  updateDashboard(locationId = false) {
    api
      .getDashboard(locationId)
      .then((values) => this.setState({ dashboard: values }));
  }
  getSelectOptions(locations) {
    return [{ value: "All", label: this.props.strings.all }].concat(
      locations.map((l) => ({ value: l.id, label: l.decr }))
    );
  }
  render() {
    const { strings } = this.props;
    const { locations, dashboard, locationSelected } = this.state;
    return (
      <div>
        <section className='content-header'>
          <h1>{strings.dashboard}</h1>
          <div style={{ minWidth: "240px" }}>
            <Select
              className='iot-select2'
              options={this.getSelectOptions(locations)}
              onChange={this.onLocationSelectChange}
              placeholder={strings.select}
            />
          </div>
        </section>
        <section className='content'>
          <div className='row'>
            <div className='col-md-6'>
              <ColumnChart
                data={dashboard.assetsByLocation}
                originalName
                title={strings.assetsByLocation}
              />
            </div>
            <div className='col-md-6'>
              <div className='info-box bg-yellow'>
                <span className='info-box-icon'>
                  <i className='ion ion-ios-location-outline' />
                </span>
                <div className='info-box-content'>
                  <span
                    className='info-box-text'
                    style={{ fontSize: "19px", marginTop: "7px" }}
                  >
                    {strings.locations}
                  </span>
                  <span
                    className='info-box-number'
                    style={{ fontSize: "19px" }}
                  >
                    {(dashboard &&
                      dashboard.qty &&
                      dashboard.qty.locations_qty) ||
                      "-"}
                  </span>
                </div>
              </div>
              <div className='info-box bg-green'>
                <span className='info-box-icon'>
                  <i className='ion ion-ios-box-outline' />
                </span>
                <div className='info-box-content'>
                  <span
                    className='info-box-text'
                    style={{ fontSize: "19px", marginTop: "1px" }}
                  >
                    {strings.assets}
                  </span>
                  <span
                    className='info-box-number'
                    style={{ fontSize: "19px", marginBottom: "5px" }}
                  >
                    {(dashboard && dashboard.qty && dashboard.qty.assets_qty) ||
                      "-"}
                  </span>

                  <span
                    className='progress-description'
                    style={{ borderTop: "solid 1px #c3ffcf" }}
                  >
                    {dashboard &&
                      dashboard.qty &&
                      dashboard.qty.assets_actives + " " + strings.actives}
                  </span>
                </div>
              </div>
              <div className='info-box bg-red'>
                <span className='info-box-icon'>
                  <i className='ion ion-ios-box-outline' />
                </span>
                <div className='info-box-content'>
                  <span
                    className='info-box-text'
                    style={{ fontSize: "19px", marginTop: "7px" }}
                  >
                    {strings.assetsNot}
                  </span>
                  <span
                    className='info-box-number'
                    style={{ fontSize: "19px" }}
                  >
                    {(dashboard &&
                      dashboard.qty &&
                      dashboard.qty &&
                      dashboard.qty.assets_actives_notreporting_qty) ||
                      "-"}
                  </span>
                </div>
              </div>
              <div className='info-box bg-aqua'>
                <span className='info-box-icon'>
                  <i className='fa fa-bookmark-o' />
                </span>
                <div className='info-box-content'>
                  <span
                    className='info-box-text'
                    style={{ fontSize: "19px", marginTop: "7px" }}
                  >
                    {strings.openTasks}
                  </span>
                  <span
                    className='info-box-number'
                    style={{ fontSize: "19px" }}
                  >
                    {(dashboard && dashboard.qty && dashboard.qty.tasks_qty) ||
                      "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <ColumnChart
                data={dashboard.assetsByContent}
                originalName
                title={strings.assetsByContent}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <ColumnChart
                data={dashboard.assetsByContentType}
                originalName
                title={strings.assetsByContentType}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <PieChart data={dashboard.assetsByType} title={strings.type} />
            </div>
            <div className='col-md-6'>
              <PieChart
                data={dashboard.assetsByStatus}
                title={strings.status}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <PieChart
                data={dashboard.assetsByManufacter}
                title={strings.manufacter}
              />
            </div>
            <div className='col-md-6'>
              <PieChart data={dashboard.assetsByMode} title={strings.mode} />
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <ColumnChart
                data={dashboard.activeTasksByLocation}
                title={strings.locations_tasks}
              />
            </div>
          </div>
        </section>
      </div>
    );
  }
};

const mapStateToProps = function (state) {
  return {
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {};
};

const DashboardComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);

export default Translate(DashboardComponent, "Dashboard");
