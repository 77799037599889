import { connect } from 'react-redux'
import LoginPresentation from './LoginPresentation'
import * as loginActionCreators from "../../redux/actionCreators/loginActionCreators"

const mapStateToProps = function(state) {
  return {
    email: state.login.email,
    password: state.login.password,
    authMessage: state.login.authMessage,
    loading: state.login.loading
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    setEmail:(e)=>dispatch(loginActionCreators.setEmail(e)),
    setPassword:(p)=>dispatch(loginActionCreators.setPassword(p)),
    login:()=>dispatch(loginActionCreators.login())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPresentation)
