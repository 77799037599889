import React from 'react';
import $ from 'jquery';
import jQuery from 'jquery';
import HeaderMessagesContainer from './headerMessages/HeaderMessagesContainer';
import HeaderNotificationsContainer from './headerNotifications/HeaderNotificationsContainer'
import HeaderTasks from './headerTasks/HeaderTasks';
import * as loginActions from "../../../redux/actionCreators/loginActionCreators"
import * as loggedUserActions from "../../../redux/actionCreators/loggedUserActionCreators"
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link } from 'react-router-dom'
import routes from '../../../utils/routes'
import roles from "../../../utils/roles"
import api from "../../../utils/api"

import * as languageActions from "../../../redux/actionCreators/languageActionCreators"
import languages from "../../shared/i18n/languages"

import Translate from '../../shared/i18n/Translate'


// export for others scripts to use
window.$ = $;
window.jQuery = jQuery;

const HeaderBar = class extends React.Component {
    constructor(props){
      super(props)
      this.pushMenu=this.pushMenu.bind(this)
      this.pushControlMenu=this.pushControlMenu.bind(this)
    }
    pushMenu() {
      var body = document.body;
      if(body.clientWidth > 768){
          if(body.className.indexOf('sidebar-collapse') === -1){
              body.className += ' sidebar-collapse';
          }else {
              body.className = body.className.replace(' sidebar-collapse', '');
          }
      }else{
          if (body.className.indexOf('sidebar-open') === -1) {
              body.className += ' sidebar-open';
          }else{
              body.className = body.className.replace(' sidebar-open','');
          }
      }
    }
    pushControlMenu() {
      var body = document.body;
      if (body.className.indexOf('control-sidebar-open') === -1) {
          body.className += ' control-sidebar-open';
      }else{
          body.className = body.className.replace(' control-sidebar-open','');
      }

    }
    render() {
        const {logout,loggedUser,routeDevices,setCurrentAccount,setLanguage,routing,strings} = this.props

        var that = this;
        return (
            <header className="main-header">
                {/* Logo */}
                <Link to="/">
                  <div className="logo">
                      {/* mini logo for sidebar mini 50x50 pixels */}
                      <span className="logo-mini"><b>I</b>Adm</span>
                      {/* logo for regular state and mobile devices */}
                      <span className="logo-lg"><b>{roles.getCurrentAccount(loggedUser).account_name}</b></span>
                  </div>
                </Link>
                {/* Header Navbar: style can be found in header.less */}
                <nav className="navbar navbar-static-top" role="navigation">
                    {/* Sidebar toggle button*/}
                    <a className="sidebar-toggle" data-toggle="offcanvas" role="button" onClick={that.pushMenu}>
                        <span className="sr-only">Toggle navigation</span>
                    </a>
                    <div className="navbar-custom-menu">
                        <ul className="nav navbar-nav">
                            {/* Messages: style can be found in dropdown.less*/}
                            <HeaderMessagesContainer />
                            {/* Notifications: style can be found in dropdown.less 
                            <HeaderNotificationsContainer /> */}
                            {/* Tasks: style can be found in dropdown.less */}
                            {/* <HeaderTasks /> */}
                            {/* User Account: style can be found in dropdown.less */}
                            <li ref={i=>this.list=i} className="dropdown user user-menu">
                                <a className="dropdown-toggle" onClick={()=>{
                                  if(this.list.classList.contains("open"))
                                    return this.list.classList.remove("open")
                                  this.list.classList.add("open")
                                }}>
                                    <img src="/dist/img/user-gcba.jpeg" className="user-image" alt="User Image" />
                                    <span className="hidden-xs">{loggedUser.name}</span>
                                </a>
                                <ul className="dropdown-menu"  onMouseUp={(e)=>{e.stopPropagation(); e.preventDefault(); console.log("CLICK")}} onClick={(e)=>{e.stopPropagation(); e.preventDefault(); console.log("CLICK")}}>
                                    {/* User image */}
                                    <li className="user-header">
                                        <img src="/dist/img/user-gcba.jpeg" className="img-circle" alt="User Image" />
                                        <p>
                                            {loggedUser.name + "-" + loggedUser.role}
                                            <small>{loggedUser.email}</small>
                                        </p>
                                    </li>
                                    {/* Menu Body */}

                                    {/* Menu Footer */}
                                    <li className="user-footer" >
                                      <div>
                                        <div style={{display:"flex",alignItems:"center"}}>
                                          <div style={{marginRight:"20px"}}>{"Language "}</div>
                                          <select ref={(i)=>this.inputLang=i} onChange={()=>{
                                            this.list.classList.remove("open")
                                            setLanguage(this.inputLang.value)}
                                          }>
                                            {Object.keys(languages).map(key=><option key={key}>{key}</option>)}
                                          </select>
                                        </div>
                                        <div style={{display:"flex",alignItems:"center",margin:"6px 0px"}}>
                                          <div style={{marginRight:"30px"}}>{"Account "}</div>
                                          <select defaultValue={roles.getCurrentAccount(loggedUser).account} ref={(i)=>this.inputAccount=i}
                                                  onChange={(e)=>{
                                                    this.list.classList.remove("open")

                                                    const value=e.target.value
                                                    api.changeCurrentAccount(roles.getCurrentAccount(loggedUser).account,e.target.value)
                                                      .finally(e=>{
                                                        setCurrentAccount(value)
                                                        localStorage.setItem('loggedUser',JSON.stringify(Object.assign(loggedUser,{currentAccount:value})))
                                                        routeDevices()
                                                      })

                                                    }}>
                                            {roles.getAccounts(loggedUser).map(account=><option key={account.account} value={account.account}>{account.account_name}</option>)}
                                          </select>
                                        </div>
                                      </div>
                                        <div className="pull-right">
                                            <div className="btn btn-default btn-flat"
                                              onClick={logout}>{strings.logOut}</div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            { /* Control Sidebar Toggle Button */}
                            {false && routing && routing.location && (routing.location.pathname==routes.routeOperationsConsole())? // only show button in operations console view
                              <li>
                                <a data-toggle="control-sidebar" style={{padding:"initial"}}>
                                  <i className="fa fa-gears" onClick={that.pushControlMenu} style={{padding:"18px 15px"}}></i>
                                </a>
                            </li>
                            :null
                          }
                        </ul>
                    </div>
                </nav>
            </header>
        )
    }
}


const mapStateToProps = function(state) {
  return {
    loggedUser:state.loggedUser,
    routing:state.routing
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    logout:()=>dispatch(loginActions.logout()),
    setLanguage:lan=>dispatch(languageActions.setLanguage(lan)),
    routeDevices:()=>dispatch(push(routes.routeDevices())),
    setCurrentAccount:n=>dispatch(loggedUserActions.setCurrentAccount(n))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Translate(HeaderBar,"Header"))
