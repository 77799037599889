import { createAction } from 'redux-actions';
import {SET_CONVERSATIONS} from "../actionTypes/conversationsActions.js"
import api from "../../utils/api"
import {transformConversations} from "../../utils/transformers"


export const setConversations=createAction(SET_CONVERSATIONS)

export const fetchConversations = (offset=null,limit=null) => {
  return (dispatch, getState) => {
    var loggedUser=getState().loggedUser
    var inbox=getState().inbox

    console.log("fetchConversations: offset:" + offset + " limit:" + limit +
                "folder: " + inbox.folder + " filters:", inbox.filters)
return;// TODO OLD
    return  _getChats(inbox.filters,inbox.folder,offset,limit)
              .then(data=>transformConversations(data,loggedUser.timezone))
              .then(data=>dispatch(setConversations(data)))
  }
}


const _getChats=({unreaded,search,tags},folder,offset,limit)=>{

  if(search)
    return api.getChatsSearch(search,folder,offset,limit)

  if(unreaded)
    return api.getChatsUnreaded(folder,offset,limit)

  if(tags.length!=0)
    return api.getChatsTags(tags,folder,offset,limit)

  return api.getChats(folder,offset,limit)

}

export const beginTyping=createAction("BEGIN_TYPING")
export const sendBeginTyping = (chatId, message) => {
  return (dispatch, getState) => {
    var loggedUser = getState().loggedUser

    dispatch(beginTyping({chat_id:chatId, message:message, user:loggedUser.id}))
  }
}

export const endTyping=createAction("END_TYPING")
export const sendEndTyping = (chatId, message) => {
  return (dispatch, getState) => {
    var loggedUser = getState().loggedUser

    dispatch(endTyping({chat_id:chatId, message:message, user:loggedUser.id}))
  }
}
