import { createAction } from 'redux-actions'
import {SET_LOCATIONS} from "../actionTypes/locationsListActions"
import api from "../../utils/api"
import routes from "../../utils/routes"
import { push } from 'react-router-redux'

export const setLocations=createAction(SET_LOCATIONS)

export const fetchLocations = (offset=null,limit=null) => {
  return (dispatch, getState) => {
    var locations=getState().locations

    console.log("fetchLocations: offset:" + offset + " limit:" + limit )

    return  _getLocations(offset,limit)
              .then(data=>dispatch(setLocations(data)))

  }
}


const _getLocations=(offset,limit)=>{
  return api.getLocations("1",offset,limit)
}

export const setLocation = (op) => {
  return (dispatch, getState) => {
    return  api.setLocation(1,op)
                .then(data=>dispatch(push(routes.routeLocations())))
  }
}

export const editLocation = (op) => {
  return (dispatch, getState) => {
    return  api.editLocation(1,op)
                .then(data=>dispatch(push(routes.routeLocations())))
  }
}
