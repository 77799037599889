import React from 'react'
import ReactDOM from  'reactDOM'
import { connect } from 'react-redux'

import Modal from "./Modal"

import Translate from '../shared/i18n/Translate'

class DeleteModal extends React.Component {
  constructor(props){
    super(props)
    this.handleYes=this.handleYes.bind(this)
  }
  handleYes(){
    this.props.onDelete()
  }
  render(){
    const { strings, itemText, id } = this.props

    return (
      <Modal id={id} >
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 className="modal-title" id="myModalLabel" style={{height:"15px"}}></h4>
          </div>
          <div className="modal-body" style={{fontSize:"20px"}}>
            {strings.areYouSure + " " + itemText + " ?"}
          </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-default" data-dismiss="modal" style={{width:"90px"}}>{strings.no}</button>
          <button onClick={this.handleYes} style={{width:"90px"}} type="button" data-dismiss="modal" className="btn btn-success">
            {strings.yes}
          </button>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps=state=>{
  return {
  }
}

const mapDispatchToProps=dispatch=>{
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Translate(DeleteModal,"Common"))
