import {
  connect
} from 'react-redux';
import {
  push
} from 'react-router-redux';
import list from './list';
import * as tasks from '../../redux/actionCreators/tasks';
import { fetchLocations } from "../../redux/actionCreators/locationsListActionCreators"
import { fetchOperators } from "../../redux/actionCreators/operatorsListActionCreators"
import routes from '../../utils/routes';

const mapStateToProps = function (state) {
  return {
    listTasks: state.tasks.listTasks,
    loading: state.tasks.loading,
    loggedUser: state.loggedUser,
    listLocations: state.locationsList,
    listOperators: state.operatorsList,
    listStatus: state.tasks.listStatus,
    listOrdersTask: state.tasks.listOrdersTask,
    listMarkers: state.tasks.listMarkers,
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    fetchTasks: (filter) => dispatch(tasks.fetchTasks(filter)),
    fetchLocations: (offset = 0, limit = 50) => dispatch(fetchLocations(offset, limit)),
    fetchOperators: (offset = 0, limit = 50) => dispatch(fetchOperators(offset, limit)),
    fetchStatus: () => dispatch(tasks.fetchStatus()),
    fetchOrdersTask: () => dispatch(tasks.fetchOrdersTask()),
    fetchMapMarkers: (filter) => dispatch(tasks.fetchMapMarkers(filter)),
    postAssignMeTask: (taskId) => dispatch(tasks.postAssignMeTask(taskId)),
      goToEdit: (task) => dispatch(push(routes.routeTask(task))),
      goToHome: () => dispatch(push(routes.routeHome())),
  }
}

const ListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(list)

export default ListContainer
