import React from "react";

const RefillsTable = ({ data }) => {
  return (
    <section className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="box box-primary">
            <div className="box-body no-padding">
              <div className="table-responsive mailbox-messages">
                <table className="table table-hover table-striped zd-table">
                  <thead>
                    <tr>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Initial Volume (L)</th>
                      <th>End Volume (L)</th>
                      <th>Fill Liters (L)</th>
                      <th>Duration (Hours)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.length > 0 ? (
                      data.map((dataset, index) =>
                        dataset.data.map((item, subIndex) => (
                          <tr key={`${index}-${subIndex}`}>
                            <td>{item.startTime}</td>
                            <td>{item.endTime}</td>
                            <td>{Number(item.initial_volume).toFixed(2)}</td>
                            <td>{Number(item.end_volume).toFixed(2)}</td>
                            <td>{Number(item.y).toFixed(2)}</td>
                            <td>{Number(item.duration).toFixed(2)}</td>
                          </tr>
                        ))
                      )
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RefillsTable;
