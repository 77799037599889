import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import api from "../../../utils/api"
import routes from "../../../utils/routes"

import * as deviceSelectedActions from "../../../redux/actionCreators/deviceSelectedActionCreators"
import * as assetSelectedActions from "../../../redux/actionCreators/assetSelectedActionCreators"

const ControlTabPresentation  = class extends React.Component {
  constructor(props){
    super(props)
    this.state={toggleLoading:false}
    this.toggleActive=this.toggleActive.bind(this)
  }
  toggleActive(){
    const {device,mergeDevice}=this.props

    this.setState({toggleLoading:true})
    if(device.authorized){
      api.unauthorizeDevice(device.deviceId)
        .then(()=>mergeDevice({authorized:false}))
        .then(()=>this.setState({toggleLoading:false}))
    }else{
      api.authorizeDevice(device.deviceId)
        .then(()=>mergeDevice({authorized:true}))
        .then(()=>this.setState({toggleLoading:false}))
    }
    return ;
  }
  render(){
    const {
      isRouteDevice,
      goToAssets,
      goToAssetsManage,
      isRouteAsset,
      asset,
      device,
      strings,
      goToDeviceManage,
      setDeviceView,
      setAssetView,
      updateAsset,
      loggedUser
    } = this.props

    if(isRouteDevice && device)
      return (
      <div>
        <button type="button" className="btn btn-block btn-primary" onClick={()=>goToDeviceManage({id:device.deviceId})}>{strings.edit}</button>
        <button type="button" className="btn btn-block btn-info" style={(this.state.toggleLoading? {backgroundColor:"#757575",color:"#757575",border:"none"}:{})}
           onClick={(this.state.toggleLoading? null:this.toggleActive)}>{device.authorized? strings.disable:strings.activate}
        </button>

         <h4 className="control-sidebar-heading" style={{marginTop:"20px",padding:"3px",cursor:"pointer"}} onClick={()=>setDeviceView("details")}>{strings.detail}</h4>
         <h4 className="control-sidebar-heading" style={{padding:"3px",cursor:"pointer"}} onClick={()=>setDeviceView("messages")}>{strings.messages}</h4>
       {/* <h4 className="control-sidebar-heading" style={{padding:"3px",cursor:"pointer"}} onClick={()=>setDeviceView("reading")}>{strings.reading}</h4>
       <h4 className="control-sidebar-heading" style={{padding:"3px",cursor:"pointer"}} onClick={()=>setDeviceView("information")}>{strings.information}</h4>
         <h4 className="control-sidebar-heading" style={{padding:"3px",cursor:"pointer"}} onClick={()=>setDeviceView("debug")}>{strings.debug}</h4>
         <h4 className="control-sidebar-heading" style={{padding:"3px",cursor:"pointer"}} onClick={()=>setDeviceView("engineering")}>{strings.engineering}</h4> */}
         <h4 className="control-sidebar-heading" style={{padding:"3px",cursor:"pointer"}} onClick={()=>setDeviceView("timeline")}>{"Timeline"}</h4>
         <div className="deviceTags">
           {device.tags && device.tags.tags && device.tags.tags.map((t,i)=><div key={i} className="deviceTag">{t}</div>)}
         </div>

         <div className="form-group">
             <label className="control-sidebar-subheading">
                {strings.hardwareVersion +": "+device.bom_ver}
              </label>
              <label className="control-sidebar-subheading">
                 {strings.firmwareVersion +": "+device.fw_ver}
               </label>
         </div>

         <button type="button" className="btn btn-block btn-primary" >{strings.updateFirmware}</button>
         <button type="button"  onClick={()=>setDeviceView("assignDevice")} className="btn btn-block btn-primary" >{strings.assignDevice}</button>



         <ul className="list-unstyled clearfix">
         </ul>
      </div>
    )


    if(isRouteAsset && asset)
      return <div>
          <button type="button" className="btn btn-block btn-primary" onClick={()=>goToAssetsManage({id:asset.id})}>
            {strings.edit}
          </button>
          <button type="button" className={`btn btn-block btn-${asset.active ? 'danger':'success'}`} onClick={() => {updateAsset(loggedUser.currentAccount, asset.id, asset.active ? 'disable' : 'activate')}}>
            {asset.active ? strings.disable : strings.activate}
            </button>
          <h4 className="control-sidebar-heading" style={{padding:"3px",cursor:"pointer"}} onClick={()=>setAssetView("details")}>
            {strings.details}
          </h4>
          <h4 className="control-sidebar-heading" style={{padding:"3px",cursor:"pointer"}} onClick={()=>setAssetView("timeline")}>
            {strings.timeline}
          </h4>
          <h4 className="control-sidebar-heading" style={{padding:"3px",cursor:"pointer"}} onClick={()=>setAssetView("analytics")}>
            {strings.analytics}
          </h4>
          <h4 className="control-sidebar-heading" onClick={goToAssets} style={{marginTop:"20px",padding:"3px",cursor:"pointer"}}>
            {strings.assets}
          </h4>
          {asset.mode === 'automated' && <button type="button" className="btn btn-block btn-primary" onClick={()=>setAssetView("dipstick")}>{strings.dipstickCalibration}</button>}
      </div>


    return null
  }
}

const mapStateToProps = function (state, ownProps) {
  return {
    device: state.deviceSelected.deviceSelected,
    isRouteDevice: state.routing.location && state.routing.location.pathname.indexOf("/device/") !== -1,
    isRouteAsset: state.routing.location && state.routing.location.pathname.indexOf("/asset/") !== -1,
    asset: state.assetSelected.assetSelected,
    loggedUser: state.loggedUser
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //setConsoleOption:(opt,bool)=>dispatch(consoleActions.setConsoleOption(opt,bool))
    mergeDevice: (obj) => dispatch(deviceSelectedActions.mergeDevice(obj)),
    goToDeviceManage: (id) => dispatch(push(routes.routeDeviceManage(id))),
    goToAssets: () => dispatch(push(routes.routeAssets())),
    goToAssetsManage: (asset) => dispatch(push(routes.routeAssetsManage(asset))),
    setDeviceView: (dv) => dispatch(deviceSelectedActions.setDeviceView(dv)),
    setAssetView: (view) => dispatch(assetSelectedActions.setAssetView(view)),
    updateAsset: (clientId, assetId, status) => dispatch(assetSelectedActions.updateAsset(clientId, assetId, status)),
  }
}

const ControlTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlTabPresentation)

export default ControlTab
