import { handleActions } from 'redux-actions';
import { GET_TASK_TEMPLATES, POST_TASK_TEMPLATES } from '../../actionTypes/tasktemplates';

const initialState = {
  loading: true,
  listTasks: []
}

const tasksTemplates = handleActions({
  [GET_TASK_TEMPLATES](state,action){
    return {
      ...state,
      listTasks: action.payload,
      loading: false
    }
  },
}, initialState);

export default tasksTemplates
