import React, { Component } from 'react'
import helpers from '../../../utils/helpers';
import { string } from 'prop-types';

class Timeline extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { timeline, strings } = this.props;
    const { timezone } = this.props.loggedUser.preferences
    const tl = timeline.timeline.reverse();
    return <div className="box box-primary">
        <div className="box-header with-border">
          <i className="fa fa-bell-o"></i>
          <h3 className="box-title">{strings.timeline}</h3>
        </div>
        <div className="box-body">
          <ul className="timeline">
            {tl.length > 0 && tl.map((tl, i) => {
              return <li key={`ti${i}`}>
                    <i className="fa fa-user bg-aqua" />
                    <div className="timeline-item">
                      <span className="time zd-time">
                        <i className="fa fa-clock-o" />
                        {helpers._transformUtcToTimezone(tl.aud_time, timezone, true)}
                      </span>
                      <h3 className="timeline-header no-border">
                        <a href="#">{tl.ident}</a>
                        {/*<div>{tl.timelineType}</div> */}
                      </h3>
                      <div className="timeline-body">
                        {tl.decr}
                      </div>
                    </div>
                  </li>
            })}
            <li>
              <i className="fa fa-clock-o bg-gray" />
            </li>
          </ul>
        </div>
      </div>
  }
}

module.exports = Timeline;