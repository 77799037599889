import { handleActions } from 'redux-actions';
import * as types from '../actionTypes/dataSourceActions';

export default handleActions({
    [types.GET_DATASOURCES](state, action) {
        return {
            ...state,
            listDataSources: action.payload
        }
    },
    [types.GET_DATASOURCE](state, action) {
        return {
            ...state,
            dataSource: action.payload
        }
    },
    [types.POST_DATASOURCE](state, action) {
        return {
            ...state,
            dataSource: action.payload
        }
    },
    [types.GET_TAGS](state, action) {
        return {
            ...state,
            tags: action.payload
        }
    }
}, {
    listDataSources: [],
    dataSource: {},
    tags: []
});