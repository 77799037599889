import { createAction } from 'redux-actions'
import {NEXT_PAGE,BACK_PAGE,RESET_PAGE,LOCATIONS_LOADING} from "../actionTypes/locationsActions"
import * as locationsListActions from "./locationsListActionCreators"
import api from "../../utils/api"

export const setNextPage=createAction(NEXT_PAGE)
export const setBackPage=createAction(BACK_PAGE)
export const setLocationsLoading=createAction(LOCATIONS_LOADING)
export const setResetPage=createAction(RESET_PAGE)

export const refreshLocations=()=>{
  return (dispatch, getState) => {
    const locations=getState().locations
    if(locations.loading) return;

    dispatch(setLocationsLoading(true))

    return dispatch(locationsListActions.fetchLocations(0,50))
              .then(()=>dispatch(setResetPage()))
              .then(()=>dispatch(setLocationsLoading(false)))
              .catch(err=>dispatch(setLocationsLoading(false)))
  }
}

export const nextPage=()=>{
  return (dispatch, getState) => {
    const locationsList=getState().locationsList
    const locations=getState().locations
    if(locationsList.length < 50 || locations.loading) return;

    dispatch(setLocationsLoading(true))

    return dispatch(locationsListActions.fetchLocations(locationsList.length*(locations.page+1),50))
              .then(()=>dispatch(setNextPage()))
              .then(()=>dispatch(setLocationsLoading(false)))
  }
}

export const backPage=()=>{
  return (dispatch, getState) => {
    const locationsList=getState().locationsList
    const locations=getState().locations
    if(locations.page==0 || locations.loading) return;

    dispatch(setLocationsLoading(true))

    return dispatch(locationsListActions.fetchLocations(locationsList.length*(locations.page-1),50))
              .then(()=>dispatch(setBackPage()))
              .then(()=>dispatch(setLocationsLoading(false)))
  }
}
