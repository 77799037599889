import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import OperatorsPresentation from './OperatorsPresentation.js'
import * as operatorsList from "../../redux/actionCreators/operatorsListActionCreators"
import * as operatorsActions from "../../redux/actionCreators/operatorsActionCreators"
import routes from "../../utils/routes"
import roles from "../../utils/roles"
import {APP_ADMIN,ROLE_USER} from "../../utils/roles"

const mapStateToProps = function(state) {
  var operatorsList=state.operatorsList
  const loggedUser=state.loggedUser
  if(loggedUser){
    const hasRoleUser=roles.hasAppRole(loggedUser,APP_ADMIN,ROLE_USER,roles.getCurrentAccount(loggedUser))
    if(hasRoleUser)
      operatorsList=operatorsList.filter(op=>op.email==loggedUser.email)
  }

  return {
    operators: operatorsList,
    page: state.operators.page,
    status: state.operators.status,
    loading:state.operators.loading,
    searchView:state.operators.filters.searchView,
    loggedUser:state.loggedUser,
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    nextPage:()=>dispatch(operatorsActions.nextPage()),
    backPage:()=>dispatch(operatorsActions.backPage()),
    setActiveStatus:()=>dispatch(operatorsActions.setActiveStatus()),
    setInactiveStatus:()=>dispatch(operatorsActions.setInactiveStatus()),
    refreshOperators:()=>dispatch(operatorsActions.refreshOperators()),
    setSearchView:(query)=>dispatch(operatorsActions.setSearchView(query)),
    setSearchFilter:(query)=>dispatch(operatorsActions.setSearchFilter(query)),
    fetchOperators:()=>dispatch(operatorsList.fetchOperators()),
    goToOperatorsManage:(operator)=>dispatch(push(routes.routeOperatorsManage(operator))),
    goToHome:()=>dispatch(push(routes.routeHome())),
  }
}

const OperatorsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OperatorsPresentation)

export default OperatorsContainer
