import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";

export class Maps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    };
    this.onMarkerClick = this.onMarkerClick.bind(this);
  }

  onMarkerClick(props, marker, e) {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  }

  _renderMap(list, onlyMap) {
    let coords = {
      lat: -34.56904895542438,
      lng: -58.46522746658031,
    };
    if (onlyMap) {
      const single = list[0];
      coords = {
        lat: single.lat,
        lng: single.lon,
      };
    }
    return (
      <Map
        google={this.props.google}
        zoom={onlyMap ? 5 : 3}
        scrollwheel={true}
        initialCenter={coords}
        containerStyle={onlyMap ? {} : { width: "calc(100vw - 260px)" }}
      >
        {list.length > 0 &&
          list.map((m, i) => {
            return (
              <Marker
                key={`mk${m.id}`}
                onClick={this.onMarkerClick}
                position={{ lat: m.lat, lng: m.lon }}
                label={onlyMap ? "" : String(m.id)}
                ident={m.ident}
                title={String(m.decr)}
                fill={`${
                  m.values && m.values.values
                    ? m.values.values.fill.toFixed(0)
                    : ""
                } %`}
                id={m.id}
              />
            );
          })}
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
        >
          <div>
            <div>
              <strong>Ident: </strong>
              {this.state.selectedPlace.ident}
            </div>
            <div>
              <strong>Name: </strong>
              {this.state.selectedPlace.title}
            </div>
            <div>
              <strong>Level: </strong>
              {this.state.selectedPlace.fill}
            </div>
            <a
              style={{ marginTop: "5px", textDecoration: "underline" }}
              href={`/asset/${this.state.selectedPlace.id}`}
            >
              More details
            </a>
          </div>
        </InfoWindow>
      </Map>
    );
  }

  render() {
    const { listMarkers, onlyMap } = this.props;
    if (onlyMap) {
      return (
        <div style={{ height: "200px", position: "relative" }}>
          {this._renderMap(listMarkers, onlyMap)}
        </div>
      );
    }
    return (
      <div>
        <section className='content'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='box box-primary'>
                <div
                  className='box-body no-padding'
                  style={{ height: "calc(100vh - 150px)" }}
                >
                  {this._renderMap(listMarkers, false)}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAix1bB8qIpfGS-hJC8Mlhs73CGg8hyPtc",
})(Maps);
