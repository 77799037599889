import { createAction } from 'redux-actions'
import * as types from '../actionTypes/dataSourceActions';
import api from "../../utils/api"
import routes from "../../utils/routes"
import { push } from 'react-router-redux'


export const datasources = createAction(types.GET_DATASOURCES);
export const datasource = createAction(types.GET_DATASOURCE);
export const saveDataSource = createAction(types.POST_DATASOURCE);
export const getTags = createAction(types.GET_TAGS);


export const getDataSources = (offset, limit) => {
    return (dispatch) => {
        return _getDataSources(offset, limit)
          .then(response => {
              dispatch(datasources(response))
          })
          .catch(err => {
              console.log(err);
          })
    }
}

export const getDataSource = (datasourceId) => {
  return (dispatch) => {
    return _getDataSource(datasourceId)
      .then(response => {
          dispatch(datasource(response))
      })
      .catch(err => {
          console.log(err);
      })
  }
}

export const postDataSource = (data) => {
  return (dispatch) => {
    return _postDataSource(data)
      .then(response => {
          dispatch(push(routes.routeDataSources()));
      })
      .catch(err => {
        console.log('err in post');
      })
  }
}

export const getListTags = () => {
  return (dispatch) => {
      return _getTags()
        .then(response => {
            dispatch(getTags(_parseTags(response)))
        })
        .catch(err => {
            console.log(err);
        })
  }
}


const _getDataSources = (offset, limit)=>{
  return api.getDataSources(offset, limit);
}
const _getDataSource = (resourceId)=>{
  return api.getDataSource(resourceId);
}

const _postDataSource = (data)=>{
  return api.postDataSource(data);
}

const _getTags = ()=>{
  return api.getTags();
}

const _parseTags = (tags) => {
  if (tags.length > 0) {
    const tagss = [];
    tags.map((tag) => {
      tagss.push({value: tag.descr, label: tag.descr})
    })
    return tagss;
  }
  return null;
}