import React from 'react';
import ReduxDevTools from "../../redux/store/devTools"

// Por ahora solo cargo las devtools de redux
var DevTools=<ReduxDevTools />

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'testing') {
  DevTools=null
}

export default ()=>DevTools
