import {handleActions} from "redux-actions"
import {SET_CONVERSATIONS,ADD_MESSAGE,REMOVE_CONVERSATION} from "../actionTypes/consoleConversationsActions"
var update = require('immutability-helper')

const initialState=[]

const reducer=handleActions({
  [SET_CONVERSATIONS]:(state,action)=> update(state,{$set:action.payload}),
  [REMOVE_CONVERSATION]:(state,action)=>state.filter(c=>c.id!==action.payload),
  ["NEW_MESSAGE"]:(state,action)=>{
    const {chat_id,id} = action.payload

    var index=0
    const conversation = state.filter(conv=>conv.id==chat_id)[0]
    if(conversation){
      const messageExists = conversation.lastMessages.filter(msg=>msg.id==id)[0]
      if(!messageExists){
        return update(state,{[_.findIndex(state, ['id', chat_id])]:{lastMessages:{$push:[action.payload]},cant:{$set:conversation.cant+1}}})
      }
    }

    return state
  },
  ["NEW_CONVERSATION"]:(state,action)=>{
    const {chat_id, id} = action.payload

    var index=0
    const conversation = state.filter(conv=>conv.id==chat_id)[0]
    if(!conversation){
      return update(state, {$unshift:[action.payload]})
    }

    return state
  },
  [ADD_MESSAGE]: (state, action) =>{
    const conversation = state.filter(conv=>conv.id==action.payload.id)[0]
    if(conversation)
      return update(state,{[_.findIndex(state, ['id', action.payload.id])]:{lastMessages:{$push:[action.payload.message]},cant:{$set:conversation.cant+1}}})

    return state
},
},initialState)

export default reducer
