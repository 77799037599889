import { createAction } from 'redux-actions';
import {CHAT_LOADING} from "../actionTypes/chatActions.js"
import api from "../../utils/api"
import { push } from 'react-router-redux'
import routes from "../../utils/routes"

import * as conversationSelectedActions from "./conversationSelectedActionCreators"

export const setChatLoading=createAction(CHAT_LOADING)

export const goToConversation=(conv)=>{
  return (dispatch,getState)=>{

    dispatch(push(routes.routeOperations(conv.id)))
    dispatch(setChatLoading(true))

    api.msgRead(conv.last_id)

    return dispatch(conversationSelectedActions.fetchConversation(conv.id))
      .then(()=>dispatch(setChatLoading(false)))
  }
}

export const sendMessage= (message,oentity,saveLocal=false)=>{
  return (dispatch,getState)=>{

    var loggedUser=getState().loggedUser
    var dentity=loggedUser.id
    var ddesc=loggedUser.name

    api.sendMsg(oentity,message,dentity,ddesc)

    if(saveLocal){
      dispatch(conversationSelectedActions.localMessage(message,oentity))
    }

    return;
  }
}
