import React from "react";
import $ from "jquery";
import jQuery from "jquery";
import { Link } from "react-router-dom";
import {
  APP_ADMIN,
  APP_ASSET,
  APP_DEEP,
  APP_PARKING,
  APP_WASTE,
  APP_IOT,
  APP_BIKES,
  ROLE_USER,
  ROLE_MONITOR,
} from "../../../utils/roles";
import routes from "../../../utils/routes";
import roles from "../../../utils/roles";
window.$ = $;
window.jQuery = jQuery;

import Authorization from "../../shared/Authorization";

import Translate from "../../shared/i18n/Translate";
var AdminButtons = ({ strings, loggedUser }) => {
  var clientsRoute = routes.routeClients();
  var operatorsRoute = routes.routeOperators();
  const hasRoleMonitor = roles.hasAppRole(loggedUser, APP_ADMIN, ROLE_MONITOR);
  const hasRoleUser = roles.hasAppRole(loggedUser, APP_ADMIN, ROLE_USER);
  if (hasRoleUser) {
    clientsRoute = routes.routeClientsManage({
      id: roles.getCurrentAccount(loggedUser).account,
    });
    operatorsRoute = routes.routeOperatorsManage({ id: loggedUser.email });
  }

  return (
    <div
      className={
        "sidebar-menu " + (roles.hasApp(loggedUser, APP_ADMIN) ? "" : "hidden")
      }
    >
      <li className='header'>{strings.admin}</li>
      {hasRoleMonitor ? null : (
        <li>
          <Link to={clientsRoute}>
            <i className='fa  fa-user '></i> <span>{strings.accounts}</span>
          </Link>
        </li>
      )}
      {hasRoleMonitor ? null : (
        <li>
          <Link to={operatorsRoute}>
            <i className='fa  fa-user '></i> <span>{strings.operators}</span>
          </Link>
        </li>
      )}
      {hasRoleMonitor ? null : (
        <li>
          <Link to={routes.routeTags()}>
            {" "}
            <i className='fa  fa-tag '></i> <span>{strings.tags}</span>
          </Link>
        </li>
      )}
      {/*    <li><Link to={routes.routeAppStatus()} > <i className="fa  fa-circle "></i> <span>{strings.status}</span></Link></li>
    <li><Link to={routes.routeOperators()} > <i className="fa  fa-circle "></i> <span>{strings.tags}</span></Link></li>
    <li><Link to={routes.routeOperators()} > <i className="fa  fa-circle "></i> <span>{strings.devops}</span></Link></li>*/}
    </div>
  );
};

var NavigationMenu = ({ indicators, loggedUser, strings }) => (
  <aside className='main-sidebar'>
    {/* sidebar: style can be found in sidebar.less */}
    <section className='sidebar'>
      {/* Sidebar user panel */}
      <div className='user-panel'>
        <div className='pull-left image'>
          <img
            src='/dist/img/user-gcba.jpeg'
            className='img-circle'
            alt='User Image'
          />
        </div>
        <div className='pull-left info'>
          <p>{loggedUser.name}</p>
          <a>
            <i className='fa fa-circle text-success'></i> {strings.online}
          </a>
        </div>
      </div>
      {/* sidebar menu: : style can be found in sidebar.less */}

      <ul className='sidebar-menu'>
        {/* <li className="active treeview">
                  <a >
                      <i className="fa fa-dashboard"></i>
                      <span>
                          Dashboard
                      </span>
                      <i className="fa fa-angle-left pull-right"></i>
                  </a>
              </li>
              */}

        <div
          className={
            "sidebar-menu " +
            (roles.hasApp(loggedUser, APP_ASSET) ? "" : "hidden")
          }
        >
          <li className='header'>{strings.assets}</li>
          <li>
            <Link to={routes.routeDashboard()}>
              {" "}
              <i className='fa  fa-line-chart '></i>{" "}
              <span>{strings.dashboard}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.routeAssets()}>
              {" "}
              <i className='fa  fa-user '></i> <span>{strings.assets}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.routeAssetTypes()}>
              {" "}
              <i className='fa  fa-key '></i> <span>{strings.assetTypes}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.routeTasks()}>
              {" "}
              <i className='fa  fa-tasks '></i> <span>{strings.tasks}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.routeLocations()}>
              {" "}
              <i className='fa  fa-circle '></i>{" "}
              <span>{strings.locations}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.routeTaskTemplates()}>
              {" "}
              <i className='fa  fa-tasks'></i>{" "}
              <span>{strings.taskTemplates}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.routeERPIntegration()}>
              {" "}
              <i className='fa  fa-cube '></i>{" "}
              <span>{strings.erpIntegration}</span>
            </Link>
          </li>
        </div>

        <div
          className={
            "sidebar-menu " +
            (roles.hasApp(loggedUser, APP_DEEP) ? "" : "hidden")
          }
        >
          <li className='header'>{strings.deep}</li>
          <li>
            <Link to={routes.routePorts()}>
              {" "}
              <i className='fa  fa-circle '></i> <span>{strings.ports}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.route404()}>
              {" "}
              <i className='fa  fa-user '></i> <span>{strings.users}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.route404()}>
              {" "}
              <i className='fa  fa-key '></i> <span>{strings.keys}</span>
            </Link>
          </li>
        </div>

        <div
          className={
            "sidebar-menu " +
            (roles.hasApp(loggedUser, APP_PARKING) ? "" : "hidden")
          }
        >
          <li className='header'>{strings.parking}</li>
          <li>
            <Link to={routes.route404()}>
              {" "}
              <i className='fa  fa-circle '></i> <span>{strings.slots}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.route404()}>
              {" "}
              <i className='fa  fa-user '></i> <span>{strings.users}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.route404()}>
              {" "}
              <i className='fa  fa-key '></i> <span>{strings.keys}</span>
            </Link>
          </li>
        </div>

        <div
          className={
            "sidebar-menu " +
            (roles.hasApp(loggedUser, APP_BIKES) ? "" : "hidden")
          }
        >
          <li className='header'>{strings.bikesTitle}</li>
          <li>
            <Link to={routes.route404()}>
              {" "}
              <i className='fa  fa-circle '></i> <span>{strings.slots}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.route404()}>
              {" "}
              <i className='fa  fa-bicycle '></i> <span>{strings.bikes}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.route404()}>
              {" "}
              <i className='fa  fa-key '></i> <span>{strings.keys}</span>
            </Link>
          </li>
        </div>

        <div
          className={
            "sidebar-menu " +
            (roles.hasApp(loggedUser, APP_WASTE) ? "" : "hidden")
          }
        >
          <li className='header'>{strings.waste}</li>
          <li>
            <Link to={routes.route404()}>
              {" "}
              <i className='fa  fa-truck '></i> <span>{strings.trucks}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.route404()}>
              {" "}
              <i className='fa  fa-circle '></i> <span>{strings.bins}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.route404()}>
              {" "}
              <i className='fa  fa-user '></i> <span>{strings.users}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.route404()}>
              {" "}
              <i className='fa  fa-key '></i> <span>{strings.keys}</span>
            </Link>
          </li>
        </div>

        <div
          className={
            "sidebar-menu " +
            (roles.hasApp(loggedUser, APP_IOT) ? "" : "hidden")
          }
        >
          <li className='header'>{strings.iotdevices}</li>
          <li>
            <Link to={routes.routeDevices()}>
              {" "}
              <i className='fa  fa-cube '></i> <span>{strings.devices}</span>
            </Link>
          </li>
          <li>
            <Link to={routes.routeDataSources()}>
              {" "}
              <i className='fa  fa-database '></i>{" "}
              <span>{strings.datasources}</span>
            </Link>
          </li>
          {/*<li><Link to={routes.route404()} > <i className="fa  fa-cube "></i> <span>{strings.gateways}</span></Link></li>
                <li><Link to={routes.route404()} > <i className="fa  fa-wrench "></i> <span>{strings.mobileSupport}</span></Link></li>
                <li><Link to={routes.route404()} > <i className="fa  fa-wrench "></i> <span>{strings.supportSessions}</span></Link></li>*/}
        </div>

        <AdminButtons strings={strings} loggedUser={loggedUser} />
      </ul>
    </section>
    {/* /.sidebar */}
  </aside>
);

export default Translate(NavigationMenu, "NavigationMenu");
