import React,{Fragment} from 'react'
import helpers from "../../utils/helpers"
import JSONPretty from 'react-json-pretty'
import api from "../../utils/api"
import moment from "moment"
import timezoneHelper from "../../utils/timezoneHelper"

var TimelineView = class extends React.Component {
  constructor(props){
    super(props)
    this.state={timeline:[],showAddEventForm:false,typeValue:""}
    this.handleCancelEvent=this.handleCancelEvent.bind(this)
    this.handleSetEvent=this.handleSetEvent.bind(this)
    this.getTimeline=this.getTimeline.bind(this)
    this.onDeleteEvent=this.onDeleteEvent.bind(this)
  }
  componentDidMount(){
    this.getTimeline()
  }
  getTimeline(){
    const {loggedUser,deviceId}=this.props
    api.getDeviceTimeline(loggedUser.currentAccount,deviceId).then(data=>{
      this.setState({timeline:data})
    })
  }
  handleCancelEvent(){
    this.setState({showAddEventForm:false})
  }
  handleSetEvent(){
    const {device,loggedUser}=this.props

    const data={
      "accountid": loggedUser.currentAccount,
      "deviceid": device.deviceId,
      "userid": loggedUser.email,
      "username":  loggedUser.name,
      "event_time":(new Date).getTime(),
      "event_type": JSON.parse(this.type.value).type,
     "event_descr" : JSON.parse(this.type.value).descr,
      "event_text" : this.description.value
    }


    api.setTimelineEvent(data)
      .then(()=>{
        this.getTimeline()
        this.setState({showAddEventForm:false})
        this.description.value=""
      })
      .catch(()=>{
        this.getTimeline()
        this.setState({showAddEventForm:false})
        this.description.value=""
      })

  }
  onDeleteEvent(event){
    api.deleteTimelineEvent(event.deviceid,event.event_time)
      .then(()=>{
        this.getTimeline()
      })
  }
  render(){
    const {strings,device,deviceId,content,loggedUser}=this.props
    const {timeline,showAddEventForm}=this.state

    return (
      <div className="box-body" style={{padding:"0"}}>
        <section className="content-header" style={{display:"flex",justifyContent:"space-between"}}>
          <h1 style={{marginBottom:"15px"}}>
            {content.title}
            <small></small>
          </h1>
          <button type="button" className={"btn btn-block btn-info " + (showAddEventForm? "hidden":"")} onClick={()=>this.setState({showAddEventForm:true})}
            style={{height:"max-content",backgroundColor:"#00c0ef",color:"white",width:"max-content",border:"none"}}
            >{strings.addEvent}</button>
        </section>



        <div className="col-md-12">
          <div className={(showAddEventForm? "":"hidden")}>

            <div className="box box-info">
              <div className="box-header ui-sortable-handle" style={{cursor: 'move'}}>
                <i className="fa fa-envelope" />
                <h3 className="box-title">Add Event</h3>
                {/* tools box */}
                <div className="pull-right box-tools">

                </div>
                {/* /. tools */}
              </div>
              <div className="box-body">
                <form action="#" method="post">
                  <div className="form-group">
                    <select ref={(i)=>this.type=i} className="form-control" placeholder="Event Type" onChange={(e) => this.setState({ typeValue: e.target.value })}>
                      <option value={'{"type":"2","descr":"Note"}'}>{"Note"}</option>
                      <option value={'{"type":"7","descr":"Firmware Upgrade"}'}>{"Firmware Upgrade"}</option>
                      <option value={'{"type":"9","descr":"Device Maintenance"}'}>{"Device Maintenance"}</option>
                      <option value={'{"type":"12","descr":"Battery Change"}'}>{"Battery Change"}</option>
                    </select>
                  </div>
                  <div>
                    <textarea ref={(i)=>this.description=i} className="textarea" style={{width: '100%', height: 125, fontSize: 14, border: '1px solid rgb(221, 221, 221)'}} placeholder="" defaultValue={""} />
                  </div>
                </form>
              </div>
              <div className="box-footer clearfix" style={{display:"flex"}}>
                <button type="button" className="btn btn-block btn-info"
                  style={{backgroundColor:"#00c0ef",color:"white",border:"none",width:"auto",marginRight:"20px"}}
                   id="sendEmail" onClick={this.handleSetEvent}>Add Event
                </button>
                <button type="button" className="pull-right btn btn-default"  id="sendEmail" onClick={this.handleCancelEvent}>Cancel
                  </button>


              </div>
            </div>

          </div>
        <ul className="timeline">


        {timeline.map((tl,i)=>{
          return <Fragment key={i}>

          <li  className="time-label">
            <span className="bg-red">
              {helpers.transformUtcToTimezone(tl.event_time).format("MMM D, h:mm A")}
            </span>
          </li>
          <li>
            <i className="fa fa-envelope bg-blue" />
            <div className="timeline-item">
              {/*<span className="time"><i className="fa fa-clock-o" /> 12:05</span>*/}
              <h3 className="timeline-header"><a href="#" style={{cursor:"initial"}}>{tl.username}</a>{"  "+tl.event_descr}
                {tl.userid==loggedUser.email? <i className="fa fa-trash" onClick={()=>this.onDeleteEvent(tl)} style={{padding:"0px 10px",fontSize:"19px",cursor:"pointer"}}/>:null}
              </h3>
              <div className="timeline-body">
                {tl.event_text}
              </div>
              {/*<div className="timeline-footer">
                <a className="btn btn-danger btn-xs">Delete</a>
              </div>*/}
            </div>
          </li>
        </Fragment>

        })}
        </ul>
      </div>


    </div>
  )
}
}

export default TimelineView
