import React from 'react'
import ReactDOM from  'reactDOM'
import helpers from "../../utils/helpers"
import api from "../../utils/api"
//import timezoneHelper from "../../utils/timezoneHelper"
import Translate from '../shared/i18n/Translate'
import $ from 'jquery';

var PortsManagePresentation = class extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      autocompleteSerialList:[],
      suggestions:[],


      validation:true,
      inputNameValid:true,
      inputLatValid:true,
      inputLonValid:true,
      sensors:[],
      sensorsOpen:false,
    }
    this.handleNewPortClick=this.handleNewPortClick.bind(this)
    this.getErrorMessage=this.getErrorMessage.bind(this)
    this.removeSensor=this.removeSensor.bind(this)
    this.addSensor=this.addSensor.bind(this)
    this.setListOfSerialNumbersAutocomplete=this.setListOfSerialNumbersAutocomplete.bind(this)
    this.handleSensorChange=this.handleSensorChange.bind(this)
  }
  componentDidMount(){
    // Default values
    this.inputName.value=""
    this.inputLat.value=""
    this.inputLon.value=""
    this.inputDepth.value=""
    this.inputMapId.value=""
    this.setState({sensors:[]})

    // Port values (editing)
    if(this.props.isEditing){
      var {id,name,lat,lon,depth_formula_type,mapid,depthConfig} = this.props.port
      this.locateMap(parseFloat(lat),parseFloat(lon))
      this.inputName.value=name
      this.inputLat.value=lat
      this.inputLon.value=lon
      this.inputDepth.value=depth_formula_type
      this.inputMapId.value=mapid
      this.setState({sensors:depthConfig.sensors})
    }


    this.setListOfSerialNumbersAutocomplete()
  }
  setListOfSerialNumbersAutocomplete(){
    api.getDevices(null,0,100).then(devices=>{
      this.setState({autocompleteSerialList:devices.map(dev=>dev.deviceId).filter(function(item, pos, self) {
        return self.indexOf(item) == pos;
      })
    })
    })
  }
  handleSensorChange(){
    console.log("sensor change",this.inputSensor.value)
    if(!this.inputSensor.value)
      this.setState({suggestions:[]})

    if(this.inputSensor.value)
      this.setState({suggestions:this.state.autocompleteSerialList.filter(s=>s.indexOf(this.inputSensor.value)>-1)})
  }
  handleNewPortClick(e){
    e.preventDefault()
    var inputName=ReactDOM.findDOMNode(this.inputName)
    var inputLat=ReactDOM.findDOMNode(this.inputLat)
    var inputLon=ReactDOM.findDOMNode(this.inputLon)
    var inputDepth=ReactDOM.findDOMNode(this.inputDepth)
    var inputMapId=ReactDOM.findDOMNode(this.inputMapId)
    //var inputName=ReactDOM.findDOMNode(this.inputName) depthConfig

    var valid=true
    if(!helpers.isEmptyString(inputName.value)){
      this.setState({inputNameValid:true})
    }else{valid=false; this.setState({inputNameValid:false})}

    if(!helpers.isEmptyString(inputLat.value)){
      this.setState({inputLatValid:true})
    }else{valid=false; this.setState({inputLatValid:false})}

    if(!helpers.isEmptyString(inputLon.value)){
      this.setState({inputLonValid:true})
    }else{valid=false; this.setState({inputLonValid:false})}


    if(valid){
      this.setState({validation:true})
      var port={
        name: this.inputName.value,
        lat: this.inputLat.value,
        lon: this.inputLon.value,
        depth_formula_type: this.inputDepth.value || "0",
        mapid: this.inputMapId.value || "0",
        depthConfig: {sensors:this.state.sensors,tidePlace:"SF"},
      }


      if(this.props.isEditing){
        this.props.editPort(Object.assign({},port,{id:this.props.portId}))
      }else{
        this.props.setPort(port)
      }

    }else{this.setState({validation:false})}
  }
  getErrorMessage(){
    if(this.state.validation)
      return null

    var element
    if(!this.state.inputNameValid){
      element=this.props.strings.name
    }else if(!this.state.inputLatValid){
      element=this.props.strings.lat
    }else if(!this.state.inputLonValid){
      element=this.props.strings.lon
    }

    return <span style={{color:"#a94442"}}>{this.props.strings.badDataMsg + element + this.props.strings.badDataMsgValid }</span>
  }
  locateMap(lat,lon){
    var latLng={lat:lat,lng:lon}
    //var latLng = {lat: -25.363, lng: 131.044}

    var map = new google.maps.Map(this.mapPorts, {
      zoom: 13,
      center: latLng,
      disableDefaultUI: true,
      gestureHandling: 'greedy'
    })

    var marker = new google.maps.Marker({
      position: latLng,
      map: map,
      title: ''
    })

  }
  removeSensor(id){
    console.log(id,this.state.sensors)
    const sensors=this.state.sensors.filter(s=>s.sensorId!==id)
    console.log(sensors)
    this.setState({sensors:sensors})
  }
  addSensor(e){
    e.preventDefault()
    var inputSensor=ReactDOM.findDOMNode(this.inputSensor)
    var inputPriority=ReactDOM.findDOMNode(this.inputPriority)
    var inputOffset=ReactDOM.findDOMNode(this.inputOffset)

    if(!inputSensor.value || !inputPriority.value || !inputOffset.value)
      return;

    const sensor={
      sensorId:inputSensor.value,
      depthOffset:inputOffset.value,
      priority:inputPriority.value
    }
    this.inputSensor.value=""
    this.inputPriority.value=""
    this.inputOffset.value=""
    console.log(sensor)
    this.setState({sensors:this.state.sensors.concat([sensor])})
  }
    render(){
      const {goToHome,goToPorts,isEditing,port,strings} =this.props

      var Breadcrumb = ({strings}) => (
        <ol className="breadcrumb">
          <li><div onClick={goToHome} style={{"cursor":"pointer"}}><i className="fa fa-dashboard"></i> {strings.home}</div></li>
          <li onClick={goToPorts} style={{"cursor":"pointer"}}>{strings.ports}</li>
          <li style={{marginBottom:"20px"}} className="active">{isEditing? port.name : strings.newPort}</li>
        </ol>
      )
      Breadcrumb = Translate(Breadcrumb,"Breadcrumb")

      console.log("PORT: ",port,this.state.suggestions)
      return (
        <div>
          <section className="content-header">
            <h1 style={{marginBottom:"10px"}}>
              {isEditing? strings.editPort : strings.newPort}
              <small></small>
            </h1>
            <Breadcrumb />
          </section>
            <div className="col-md-12">
              <div className="box box-info">

                {this.props.isEditing? <div className="box box-widget widget-user" style={{marginBottom:"36px"}}>
                  <div className="widget-user-header" style={{borderRadius:"0",background:"white",height:"130px",padding:"0px",borderBottom:"solid 1px rgb(179, 179, 179)"}}>
                    <div ref={i=>this.mapPorts=i} style={{height:"100%"}}></div>
                  </div>
                  <div className="widget-user-image" style={{top:"67px",marginLeft:"-64px"}}>
                    <img className="img-circle" src="/dist/img/iotlogo.png" alt="IoT" style={{border:"solid 1px #b3b3b3",width:"125px"}}/>
                  </div>
                </div>:
                <div className="box box-widget widget-user" style={{marginBottom:"36px"}}>
                  <div className="widget-user-header" style={{borderRadius:"0",background:"lightgray",height:"100px"}}>
                  </div>
                  <div className="widget-user-image" style={{top:"27px",marginLeft:"-64px"}}>
                    <img className="img-circle" src="/dist/img/iotlogo.png" alt="IoT" style={{border:"solid 1px #b3b3b3",width:"125px"}}/>
                  </div>
                </div>}



                <form autoComplete="off" className="form-horizontal">
                  <div className="box-body" style={{marginTop:"57px"}}>
                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.name}</label>
                      <div className={"col-sm-10 "+ (!this.state.inputNameValid? "has-error":"")}>
                        <input style={{maxWidth:"650px"}} ref={(i)=>this.inputName=i} type="text" className="form-control" placeholder={strings.name} required/>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.lat}</label>
                      <div className={"col-sm-10 "+ (!this.state.inputLatValid? "has-error":"")}>
                        <input style={{maxWidth:"650px"}} ref={(i)=>this.inputLat=i} type="text" className="form-control" placeholder={strings.lat} required/>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.lon}</label>
                      <div className={"col-sm-10 "+ (!this.state.inputLonValid? "has-error":"")}>
                        <input style={{maxWidth:"650px"}} ref={(i)=>this.inputLon=i} type="text" className="form-control" placeholder={strings.lon} required/>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.depth_formula_type}</label>
                      <div className={"col-sm-10"}>
                        <input style={{maxWidth:"650px"}} ref={(i)=>this.inputDepth=i} type="text" className="form-control" placeholder={strings.depth_formula_type} />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.mapid}</label>
                      <div className={"col-sm-10 "}>
                        <input style={{maxWidth:"650px"}} ref={(i)=>this.inputMapId=i} type="text" className="form-control" placeholder={strings.mapid}/>
                      </div>
                    </div>


                    <div className="col-sm-1"/>
                    <div className="col-sm-11" style={{maxWidth:"775px"}}>
                      <div className="box box-warning"  style={{cursor:"pointer"}} >
                        <div className="box-header with-border" onClick={()=>this.setState({sensorsOpen:!this.state.sensorsOpen})  }>
                          <h3 className="box-title">{strings.sensors}</h3>
                          <div className="box-tools pull-right">
                            <button type="button" className="btn btn-box-tool" ><i className={"fa fa-" + (this.state.sensorsOpen? "minus":"plus")} />
                            </button>
                          </div>
                          {/* /.box-tools */}
                        </div>
                        {/* /.box-header */}
                        <div className="box-body" style={this.state.sensorsOpen? {cursor:"initial"}:{display:"none"}}>


                          <div style={{display:"flex",alignItems:"flex-end",marginBottom:"20px"}}>

                            <div style={{display:"flex",flexDirection:"column"}}>
                              <label className="col-sm-2 control-label">{strings.sensor}</label>
                              <div className={"col-sm-10"} style={{width:"105%"}}>
                                <input style={{maxWidth:"650px"}} onFocus={this.handleSensorChange} onBlur={()=>setTimeout(()=>this.setState({suggestions:[]}),500)}  onChange={this.handleSensorChange} ref={(i)=>this.inputSensor=i} type="text" className="form-control" placeholder={strings.sensor} />
                              </div>
                              {this.state.suggestions.length>0? <div className="sensor-autocomplete">
                                {this.state.suggestions.map((input,i)=><div key={i} style={{padding:"3px 0px",fontWeight:"600"}} onClick={()=>this.inputSensor.value=input}>{input}</div>)}
                              </div>:null}
                            </div>

                            <div style={{display:"flex",flexDirection:"column"}}>
                              <label className="col-sm-2 control-label">{strings.priority}</label>
                              <div className={"col-sm-10"} style={{width:"105%"}}>
                                <input style={{maxWidth:"650px"}} ref={(i)=>this.inputPriority=i} type="text" className="form-control" placeholder={strings.priority} />
                              </div>
                            </div>

                            <div style={{display:"flex",flexDirection:"column"}}>
                              <label className="col-sm-2 control-label">{strings.offset}</label>
                              <div className={"col-sm-10"} style={{width:"105%"}}>
                                <input style={{maxWidth:"650px"}} ref={(i)=>this.inputOffset=i} type="text" className="form-control" placeholder={strings.offset} />
                              </div>
                            </div>

                            <button onClick={(e)=>this.addSensor(e)} className="btn btn-info pull-right" style={{height:"max-content",padding:"7px 35px"}}>{strings.add}</button>

                          </div>

                          {/*<div style={{display:"flex",fontWeight:"600",marginBottom:"10px"}}>
                            <div style={{width:"30%"}}>{strings.sensor}</div>
                            <div style={{width:"30%"}}>{strings.priority}</div>
                            <div style={{width:"30%"}}>{strings.offset}</div>
                          </div>*/}
                          {this.state.sensors && this.state.sensors.length? this.state.sensors.map((sensor,i)=>{
                            return <div key={i} style={{display:"flex",borderBottom:"solid 1px #f9f9f9",borderTop:"solid 1px #f9f9f9",padding:"5px 0px"}}>
                              <div style={{width:"30%"}}>{sensor.sensorId}</div>
                              <div style={{width:"30%"}}>{sensor.priority}</div>
                              <div style={{width:"30%"}}>{sensor.depthOffset}</div>
                              <div style={{width:"10%",color:"gray",fontWeight:"600",cursor:"pointer"}} onClick={()=>this.removeSensor(sensor.sensorId)}>{strings.remove}</div>
                            </div>
                          }):null}


                        </div>
                        {/* /.box-body */}
                      </div>
                      {/* /.box */}
                    </div>

                  </div>

                  <div className="box-footer" style={{justifyContent:"center",display:"flex"}}>
                    <button type="submit" onClick={this.handleNewPortClick}
                            className="btn btn-info pull-right" style={{marginRight:"100px",padding:"6px 25px"}}>{strings.save}</button>
                    <button className="btn btn-default pull-left" style={{padding:"6px 25px"}}
                      onClick={(e)=>{e.preventDefault();goToPorts()}} style={{"cursor":"pointer"}}>{strings.cancel}</button>

                  </div>
                  <div style={{width:"100%",textAlign:"center"}}>
                    {this.getErrorMessage()}
                  </div>

                </form>
              </div>
            </div>
        </div>
      )
    }
  }

  export default Translate(PortsManagePresentation,"PortsManage")
