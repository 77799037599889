import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import addNoDataModule from "highcharts/modules/no-data-to-display";
import moment from "moment";
import "moment-timezone";
import timezoneHelper from "../../utils/timezoneHelper";
import helpers from "../../utils/helpers";
window.moment = moment;
addNoDataModule(Highcharts);

const Chart = (props) => {
  const { strings } = props;
  const chartComponent = useRef({});
  const [config, setConfig] = useState({
    firstBoxOpen: false,
    secondBoxOpen: true,
    thirdBoxOpen: false,
    chart: {
      chart: {
        zoomType: "xy",
      },
      title: {
        text: strings.chart.lastValues,
      },
      subtitle: {
        text:
          document.ontouchstart === undefined
            ? strings.chart.clickAndDrag
            : strings.chart.pinchToZoom,
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          day: "%e %b %y",
        },
        crosshair: true,
      },
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: "{value} °C",
            style: {
              color: Highcharts.getOptions().colors[2],
            },
          },
          title: {
            text: strings.chart.temperature,
            style: {
              color: Highcharts.getOptions().colors[2],
            },
          },
          opposite: true,
          min: 0,
        },
        {
          // Secondary yAxis
          gridLineWidth: 0,
          title: {
            text: strings.chart.values,
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
          labels: {
            style: {
              color: Highcharts.getOptions().colors[0],
            },
          },
        },
        {
          // Tertiary yAxis
          gridLineWidth: 0,
          title: {
            text: strings.chart.battery,
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          labels: {
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          opposite: true,
        },
      ],
      legend: {
        backgroundColor:
          (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
          "rgba(255,255,255,0.25)",
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        area: {
          fillOpacity: 0.3,
        },
        series: {
          marker: {
            lineWidth: 1,
            lineColor: null,
          },
        },
      },
      //colors: ['#7EB26D', '#EAB839', '#6ED0E0'],
      series: [],
      time: {
        timezone:
          timezoneHelper.timezones[props.loggedUser.preferences.timezone] ||
          "America/Argentina/Buenos_Aires",
      },
    },
    loadingChart: true,
    countDays: 2,
  });

  useEffect(() => {
    var body = document.body;
    body.className = body.className.replace(" control-sidebar-open", "");
    props.getMetricsDevice(props.deviceId, 2);
  }, []);

  useEffect(() => {
    const chart = chartComponent.current.chart;
    if (chart) {
      chart.reflow(false);
    }
  }, [config.chart.series]);

  useEffect(() => {
    let datas = props.listMetricsDevice;

    if (datas.length > 0) {
      datas[0].type = "spline";
      (datas[0].yAxis = 1),
        (datas[0].tooltip = {
          valueSuffix: "",
        });
    }
    if (datas.length > 1) {
      datas[1].type = "spline";
      datas[1].yAxis = 2;
      datas[1].marker = {
        enabled: false,
      };
      (datas[1].dashStyle = "shortdot"),
        (datas[1].tooltip = {
          valueSuffix: " V",
        });
    }
    if (datas.length > 1) {
      datas[2].type = "spline";
      datas[2].tooltip = {
        valueSuffix: " °C",
      };
      props.getHelthMetrics(props.deviceId, config.countDays);
      props.getReadQualityMetrics(props.deviceId, config.countDays);
    }

    if (datas.length > 0) {
      if (datas[7]) {
        datas[7].type = "spline";
      }
      if (datas[6]) {
        datas[6].type = "spline";
      }
      if (datas[5]) {
        datas[5].type = "spline";
      }
      if (datas[4]) {
        datas[4].type = "spline";
      }
    }

    setConfig((prev) => ({
      ...prev,
      chart: {
        ...prev.chart,
        series: datas,
      },
      loadingChart: false,
    }));
  }, [props.listMetricsDevice]);

  useEffect(() => {
    if (
      config.chart.series.length > 0 &&
      props.listHelthMetrics.data &&
      props.listHelthMetrics.data.length > 0
    ) {
      setConfig((prev) => ({
        ...prev,
        chart: {
          ...prev.chart,
          series: [...config.chart.series, props.listHelthMetrics],
        },
      }));
    }
  }, [props.listHelthMetrics]);

  useEffect(() => {
    if (
      config.chart.series.length > 0 &&
      props.listReadQualityMetrics.data &&
      props.listReadQualityMetrics.data.length > 0
    ) {
      setConfig((prev) => ({
        ...prev,
        chart: {
          ...prev.chart,
          series: [...config.chart.series, props.listReadQualityMetrics],
        },
      }));
    }
  }, [props.listReadQualityMetrics]);

  const onChangeHandler = (evt) => {
    const countDays = evt.target.value;
    setConfig((prev) => ({
      ...prev,
      loadingChart: true,
      countDays,
    }));
    props.getMetricsDevice(props.deviceId, countDays);
  };

  return (
    <div>
      <form className='form-horizontal'>
        <div className='box-body'>
          <div className='form-group'>
            <label
              for='inputPassword3'
              className='col-sm-4 col-xs-4 control-label'
            >
              {strings.showLast}
            </label>
            <div className='col-sm-2 col-xs-4'>
              <select
                name='change'
                value={config.countDays}
                onChange={onChangeHandler}
                className='form-control'
              >
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='4'>4</option>
                <option value='7'>7</option>
                <option value='10'>10</option>
                <option value='30'>30</option>
                <option value='60'>60</option>
                <option value='90'>90</option>
              </select>
            </div>
            <div className='pull-left col-sm-1 col-xs-4'>
              {strings.days}
            </div>
          </div>
        </div>
      </form>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        options={
          config.chart.series.length > 0 ? config.chart : helpers.noData()
        }
      />
    </div>
  );
};

export default Chart;
