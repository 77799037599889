import React from 'react'
import MessageItem from './MessageItem'

export default class extends React.Component {
  constructor(props){
    super(props)
    //this.state = {
    //    messages: []
    //}
    this.handleClick=this.handleClick.bind(this)
  }
  componentDidMount(){
    /*
    var messages = [{
        title: 'Sara Bullrich',
        displayPicture: 'dist/img/user3-128x128.jpg',
        content: '@Alerta de posible inundacion en balvanera',
        time: '5 mins',
    }]

    this.setState({
        messages: messages
    })
    */
  }
  handleClick(){
    this.props.setFolder("inbox")
    this.props.setUnreadedFilter(true)
    this.props.goToInbox()
  }
  render() {
      //const {cantUnreadedToday} = this.props.indicators
      /*
      var that = this;

      var messageList = this.state.messages.map(function (messageDetails, iterator) {
          return (
              <MessageItem
                  key={iterator}
                  title={messageDetails.title}
                  displayPicture={messageDetails.displayPicture}
                  time={messageDetails.time}
                  content={messageDetails.content} />
          )
      });
      */

      return (
          <li className="dropdown messages-menu">
              <a className="dropdown-toggle" data-toggle="dropdown" onClick={this.handleClick}>
                  <i className="fa fa-support"></i>
                  <span className="label label-success">{""}</span>
              </a>
              { /* <ul className="dropdown-menu">
                  <li className="header">You have {this.state.messages.length} messages</li>
                  <li>
                       inner menu: contains the actual data
                      <div className="slimScrollDiv" >

                          <ul className="menu" >
                              {messageList}
                          </ul>

                          <div className="slimScrollBar"></div>
                          <div className="slimScrollRail"></div>
                      </div>
                  </li>
                  <li className="footer"><a href="#">See All Messages</a></li>
              </ul> */ }
          </li>

      )
  }
}
