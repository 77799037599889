import { createAction } from 'redux-actions'
import {SET_STATUS,NEXT_PAGE,BACK_PAGE,RESET_PAGE,
        DEVICES_LOADING,SET_SEARCH,SET_SEARCH_VIEW,SET_TAGS,SET_AVAILABLE_TAGS_VIEW } from "../actionTypes/devicesActions"
import * as devicesListActions from "./devicesListActionCreators"
import api from "../../utils/api"
import {transformTags} from "../../utils/transformers"

// ---------- Folders

export const setStatus=createAction(SET_STATUS)
export const devicesStatus=(status)=>{
  return (dispatch, getState) => {
    const devices=getState().devices
    if(devices.loading) return;

    dispatch(setStatus(status))

    return dispatch(refreshDevices())
  }
}
export const setActiveStatus=()=>dispatch=>dispatch(devicesStatus(true))
export const setInactiveStatus=()=>dispatch=>dispatch(devicesStatus(false))



// --------- Paginacion

export const setNextPage=createAction(NEXT_PAGE)
export const setBackPage=createAction(BACK_PAGE)
export const setDevicesLoading=createAction(DEVICES_LOADING)
export const setResetPage=createAction(RESET_PAGE)

export const refreshDevices=()=>{
  return (dispatch, getState) => {
    const devices=getState().devices
    if(devices.loading) return;

    dispatch(setDevicesLoading(true))

    return dispatch(devicesListActions.fetchDevices(0,50))
              .then(()=>dispatch(setResetPage()))
              .then(()=>dispatch(setDevicesLoading(false)))
              .catch(err=>dispatch(setDevicesLoading(false)))
  }
}

export const nextPage=()=>{
  return (dispatch, getState) => {
    const devicesList=getState().devicesList
    const devices=getState().devices
    if(devicesList.length < 50 || devices.loading) return;

    dispatch(setDevicesLoading(true))

    return dispatch(devicesListActions.fetchDevices(devicesList.length*(devices.page+1),50))
              .then(()=>dispatch(setNextPage()))
              .then(()=>dispatch(setDevicesLoading(false)))
  }
}

export const backPage=()=>{
  return (dispatch, getState) => {
    const devicesList=getState().devicesList
    const devices=getState().devices
    if(devices.page==0 || devices.loading) return;

    dispatch(setDevicesLoading(true))

    return dispatch(devicesListActions.fetchDevices(devicesList.length*(devices.page-1),50))
              .then(()=>dispatch(setBackPage()))
              .then(()=>dispatch(setDevicesLoading(false)))
  }
}



// ----------- Filters

export const setSearch=createAction(SET_SEARCH)
export const setSearchView=createAction(SET_SEARCH_VIEW)
export const setTags=createAction(SET_TAGS)
export const setAvailableTagsView=createAction(SET_AVAILABLE_TAGS_VIEW)

export const setTagsFilter=(tags)=>{
  return (dispatch, getState) => {
    const inbox=getState().inbox
    if(inbox.loading) return;

    dispatch(setTags(tags))

    return dispatch(refreshDevices())
  }
}
export const setSearchFilter=(query)=>{
  return (dispatch, getState) => {
    const devices=getState().devices
    if(devices.loading) return;

    dispatch(setSearch(query))

    return dispatch(refreshDevices())
  }
}

export const fetchTags=()=>{
  return (dispatch, getState) => {

    return api.getTags()
            .then(tags=>transformTags(tags))
            .then(tags=>dispatch(setAvailableTagsView(tags)))
  }
}
