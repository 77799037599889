import {handleActions} from "redux-actions"
import {SET_CITIZENS} from "../actionTypes/citizensListActions"
var update = require('immutability-helper')

const initialState=[]

const reducer=handleActions({
  [SET_CITIZENS]:(state,action)=>update(state,{$set:action.payload})
},initialState)

export default reducer
