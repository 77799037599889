import { createAction } from 'redux-actions'
import {SET_STATUS,NEXT_PAGE,BACK_PAGE,RESET_PAGE,
        OPERATORS_LOADING,SET_SEARCH,SET_SEARCH_VIEW } from "../actionTypes/operatorsActions"
import * as operatorsListActions from "./operatorsListActionCreators"
import api from "../../utils/api"


// ---------- Folders

export const setStatus=createAction(SET_STATUS)
export const operatorsStatus=(status)=>{
  return (dispatch, getState) => {
    const operators=getState().operators
    if(operators.loading) return;

    dispatch(setStatus(status))

    return dispatch(refreshOperators())
  }
}
export const setActiveStatus=()=>dispatch=>dispatch(operatorsStatus(true))
export const setInactiveStatus=()=>dispatch=>dispatch(operatorsStatus(false))



// --------- Paginacion

export const setNextPage=createAction(NEXT_PAGE)
export const setBackPage=createAction(BACK_PAGE)
export const setOperatorsLoading=createAction(OPERATORS_LOADING)
export const setResetPage=createAction(RESET_PAGE)

export const refreshOperators=()=>{
  return (dispatch, getState) => {
    const operators=getState().operators
    if(operators.loading) return;

    dispatch(setOperatorsLoading(true))

    return dispatch(operatorsListActions.fetchOperators(0,50))
              .then(()=>dispatch(setResetPage()))
              .then(()=>dispatch(setOperatorsLoading(false)))
              .catch(err=>dispatch(setOperatorsLoading(false)))
  }
}

export const nextPage=()=>{
  return (dispatch, getState) => {
    const operatorsList=getState().operatorsList
    const operators=getState().operators
    if(operatorsList.length < 50 || operators.loading) return;

    dispatch(setOperatorsLoading(true))

    return dispatch(operatorsListActions.fetchOperators(operatorsList.length*(operators.page+1),50))
              .then(()=>dispatch(setNextPage()))
              .then(()=>dispatch(setOperatorsLoading(false)))
  }
}

export const backPage=()=>{
  return (dispatch, getState) => {
    const operatorsList=getState().operatorsList
    const operators=getState().operators
    if(operators.page==0 || operators.loading) return;

    dispatch(setOperatorsLoading(true))

    return dispatch(operatorsListActions.fetchOperators(operatorsList.length*(operators.page-1),50))
              .then(()=>dispatch(setBackPage()))
              .then(()=>dispatch(setOperatorsLoading(false)))
  }
}



// ----------- Filters

export const setSearch=createAction(SET_SEARCH)
export const setSearchView=createAction(SET_SEARCH_VIEW)

export const setSearchFilter=(query)=>{
  return (dispatch, getState) => {
    const operators=getState().operators
    if(operators.loading) return;

    dispatch(setSearch(query))

    return dispatch(refreshOperators())
  }
}
