import React, { Component } from "react";
import Translate from "../shared/i18n/Translate";
import Title from "../common/title";
import Filter from "./filter";
import { Modal, Form } from "antd";
import "antd/lib/table/style/css";
import "antd/lib/modal/style/css";
import Controls from "../common/controls";
import Maps from "./maps";
import TableList from "./components/table";

const DEFAULT_FILTER = {
  status: null,
  assigned_to: null,
  closed: 0,
  location_id: null,
  priority: -1,
  order: "entry_date",

  planned_date: 0,
  entry_date: 0,
};
class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paging: {
        offset: 0,
        page: 1,
        limit: 50,
        total: 0,
      },
      filter: DEFAULT_FILTER,
      view: "list",
    };
    this.onRefresh = this.onRefresh.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onLoadTasks = this.onLoadTasks.bind(this);
    this.onResetLoad = this.onResetLoad.bind(this);
    this.onSetView = this.onSetView.bind(this);
  }
  componentDidMount() {
    this.props.fetchLocations();
    this.onLoadTasks();
    this.props.fetchOperators();
    this.props.fetchStatus();
    this.props.fetchOrdersTask();
  }

  onLoadTasks(filter = null, map = null) {
    if (filter != null) {
      this.setState(
        {
          filter,
        },
        () => {
          if (map != null) {
            this.props.fetchMapMarkers(filter);
          } else {
            this.props.fetchTasks(filter);
          }
        }
      );
    } else {
      this.props.fetchTasks(this.state.filter);
      this.setState({ view: "list" });
    }
  }

  onResetLoad() {
    this.setState(
      {
        filter: DEFAULT_FILTER,
      },
      () => {
        this.props.fetchTasks(this.state.filter);
      }
    );
  }

  onRefresh() {
    this.setState(
      {
        paging: {
          ...this.state.paging,
          offset: 0,
          page: 1,
        },
      },
      () => {
        this.props.fetchTasks(
          this.state.paging.offset,
          this.state.paging.limit
        );
      }
    );
  }

  onNext(offset) {
    this.setState({
      paging: {
        ...this.state.paging,
        offset: offset,
        page: this.state.paging.page + 1,
      },
    });
    this.props.fetchTasks(offset, this.state.paging.limit);
  }

  onPrev(offset) {
    this.setState({
      paging: {
        ...this.state.paging,
        page: this.state.paging.page - 1,
        offset: offset,
      },
    });
    this.props.fetchTasks(offset, this.state.paging.limit);
  }

  onSetView(view) {
    this.setState({
      view,
    });
  }

  render() {
    const { strings, goToEdit, listTasks } = this.props;
    const WrappedHorizontalLoginForm = Form.create({
      name: "horizontal_login",
    })(Filter);
    return (
      <div>
        <Title
          title={strings.tasks}
          newActionLabel={strings.newTask}
          goTo={goToEdit}
        >
          <div className='zd-select-views'>
            <button
              type='button'
              className={`btn btn-default ${
                this.state.view == "list" ? "btn-active" : ""
              }`}
              onClick={() => this.onSetView("list")}
            >
              <i className='fa fa-bars'></i>
            </button>
            <button
              type='button'
              className={`btn btn-default ${
                this.state.view == "map" ? "btn-active" : ""
              }`}
              onClick={() => this.onSetView("map")}
            >
              <i className='fa fa-globe'></i>
            </button>
          </div>
        </Title>
        <Modal
          title={strings.confirm}
          visible={this.state.visible}
          onOk={() => this.onDelete()}
          onCancel={this.hideModal}
          okText={strings.yes}
          cancelText={strings.cancel}
        >
          <p>
            {strings.areYouSure} {strings.taskTemplates} {this.state.taskId}
          </p>
        </Modal>
        <div className='col-md-12'>
          <div className='box box-warning zd-box-pointer'>
            <div
              className='box-header with-border'
              onClick={() =>
                this.setState({ secondBoxOpen: !this.state.secondBoxOpen })
              }
            >
              <h3 className='box-title'>Filter Tasks</h3>
              <div className='box-tools pull-right'>
                <button
                  type='button'
                  className='btn btn-box-tool'
                  onClick={() =>
                    this.setState({ secondBoxOpen: !this.state.secondBoxOpen })
                  }
                >
                  <i
                    className={
                      "fa fa-" + (this.state.secondBoxOpen ? "minus" : "plus")
                    }
                  />
                </button>
              </div>
            </div>
            <div
              className='box-body'
              style={this.state.secondBoxOpen ? {} : { display: "none" }}
            >
              <WrappedHorizontalLoginForm
                {...this.props}
                filter={this.state.filter}
                onLoadTasks={this.onLoadTasks}
                onResetLoad={this.onResetLoad}
              />
            </div>
          </div>
        </div>

        <section className='content'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='box box-primary'>
                <div className='box-body no-padding'>
                  {this.state.view == "list" && (
                    <React.Fragment>
                      <Controls
                        onRefresh={this.onRefresh}
                        paging={this.state.paging}
                        onNext={this.onNext}
                        onPrev={this.onPrev}
                      />
                      <TableList {...this.props} reduced={false} />
                    </React.Fragment>
                  )}
                  {this.state.view == "map" && (
                    <Maps
                      {...this.props}
                      onLoadTasks={this.onLoadTasks}
                      filter={this.state.filter}
                      onSetView={this.onSetView}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Translate(List, "Tasks");
