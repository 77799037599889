import { connect } from "react-redux";
import AssetsRefillPresentation from "./AssetsRefillPresentation";

const mapStateToProps = function (state, ownProps) {
  return {
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {};
};

const AssetsRefillContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetsRefillPresentation);

export default AssetsRefillContainer;
