import { createAction } from 'redux-actions';
import {SET_CONVERSATION,ADD_LOCAL_MESSAGE} from "../actionTypes/conversationSelectedActions.js"
import api from "../../utils/api"
import helpers from "../../utils/helpers"
import {transformConversation} from "../../utils/transformers"

export const setConversation=createAction(SET_CONVERSATION)
export const addLocalMessage=createAction(ADD_LOCAL_MESSAGE)


export const fetchConversation=(id)=>{
  return (dispatch, getState) => {
    var loggedUser=getState().loggedUser

    console.log("fetchConversation: id:" + id)

    return api.getChat(id)
              .then(data=>transformConversation(data,loggedUser.timezone))
              .then(data => dispatch(setConversation(data)))
  }
}

export const localMessage=(message,oentity)=>{
  return (dispatch, getState) => {
    var loggedUser=getState().loggedUser

    var localMessage = {
      localMessage:true,
      oentity:oentity,
      dentity:loggedUser.id,
      ddesc:loggedUser.name,
      message:message,
      direction:1, // 1 = del CUUCO al ciudadano
      msg_time:helpers.getCurrentTime()
    }

    dispatch(addLocalMessage(localMessage))
  }
}
