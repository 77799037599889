import {handleActions} from "redux-actions"
import {SET_DEVICES} from "../actionTypes/devicesListActions"
var update = require('immutability-helper')

const initialState=[]

const reducer=handleActions({
  [SET_DEVICES]:(state,action)=>update(state,{$set:action.payload})
},initialState)

export default reducer
