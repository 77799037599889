import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import MessagesView from "./MessagesView";
import MessagesGraph from "./MessagesGraph";
const { TabPane } = Tabs;

const Messages = (props) => {
  const [view, setView] = useState("graph");
  const onChange = (key) => {
    setView(key);
  };

  return (
    <section className='content'>
      <Tabs defaultActiveKey={view} onChange={onChange}>
        <TabPane tab='Graph' key='graph'>
          <MessagesGraph
            {...props}
            getMetricsDevice={props.getMetricsDeviceV2}
          />
        </TabPane>
        <TabPane tab='Messages' key='messages'>
          <MessagesView {...props} />
        </TabPane>
      </Tabs>
    </section>
  );
};

export default Messages;
