import React, { Component } from 'react';
import Translate from '../shared/i18n/Translate';
import Title from '../common/title';
import { Table, Modal } from 'antd';
import 'antd/lib/table/style/css';
import 'antd/lib/modal/style/css';
import Controls from '../common/controls';

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      taskId: 0,
      paging: {
        offset: 0,
        page: 1,
        limit: 50,
        total: 0
      }
    }
    this.showConfirm = this.showConfirm.bind(this);
    this.showSelection = this.showSelection.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onNext = this.onNext.bind(this);
  }
  componentDidMount() {
    this.props.fetchTasks(this.state.paging.offset, this.state.paging.limit);
  }
  showConfirm(task) {
    this.setState({
      visible: true,
      taskId: task.id
    })
  }
  hideModal() {
    this.setState({
      visible: false
    });
  };

  showSelection(task, rowIndex) {
    return {
      onClick: event => {
        this.props.goToEdit({id: task.id})
      }
    }
  }

  onDelete() {
    this.props.onDelete(this.state.taskId);
    this.setState({
      visible: false,
      taskId: 0
    })
  }

  onRefresh() {
    this.setState({
      paging: {
        ...this.state.paging,
        offset: 0,
        page: 1
      }
    }, () => {
      this.props.fetchTasks(this.state.paging.offset, this.state.paging.limit);
    })
  }

  onNext(offset) {
    this.setState({
      paging: {
        ...this.state.paging,
        offset: offset,
        page: this.state.paging.page + 1
      }
    })
    this.props.fetchTasks(offset, this.state.paging.limit);
  }

  onPrev(offset) {
    this.setState({
      paging: {
        ...this.state.paging,
        page: this.state.paging.page - 1,
        offset: offset
      }
    })
    this.props.fetchTasks(offset, this.state.paging.limit);
  }

  render() {
    const { strings, goToEdit, listTasks } = this.props;
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: 'Description',
        dataIndex: 'descr',
        key: 'descr',
      },
      {
        title: 'Action',
        key: 'action',
        width: 120,
        render: (text, record) => (
          <a href="javascript:;" className="btn btn-default" onClick={ (e) => {e.preventDefault();e.stopPropagation(); this.showConfirm(record);}}>
            <i className="fa fa-trash"></i>
          </a>
        ),
      }
    ];
    return <div>
        <Title title={strings.taskTemplates} newActionLabel={strings.newTask} goTo={goToEdit} />
        <Modal
          title={strings.confirm}
          visible={this.state.visible}
          onOk={() => this.onDelete()}
          onCancel={this.hideModal}
          okText={strings.yes}
          cancelText={strings.cancel}
        >
          <p>{strings.areYouSure} {strings.taskTemplates} {this.state.taskId}</p>
        </Modal>
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-body no-padding">
                  <Controls onRefresh={this.onRefresh} paging={this.state.paging} onNext={this.onNext} onPrev={this.onPrev}/>
                  {listTasks.length > 0 && <Table pagination={false} onRow={this.showSelection} columns={columns} dataSource={listTasks} />}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
  }
}

export default Translate(List, 'TasksTemplates');