/*
import React from 'react'

export default ({theme,content})=>(
  <li key={"header-notification-item"}>
    <a href="#">
      <i className={theme}></i> {content}
    </a>
  </li>
)
*/
