/*
import React from 'react'

export default ({displayPicture,title,time,content})=>(
  <li>
      <a href="#">
          <div className="pull-left">
              <img src={displayPicture} className="img-circle" alt="User Image" />
          </div>
          <h4>
              {title}
              <small><i className="fa fa-clock-o"></i> {time}</small>
          </h4>
          <p>{content}</p>
      </a>
  </li>
)
*/
