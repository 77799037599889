

import React from 'react';
const Card = props => {
  const { children } = props
  return <section className="content">
          <div className="row">
            <div className="col-sm-12 col-lg-12 col-xs-12 col-md-12">
              <div className="box box-primary">
                <div className="box-body no-padding">
                  <div className="table-responsive mailbox-messages">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
  </section>
};

module.exports = Card