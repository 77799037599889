import React from 'react'
import config from "../../../../../CONFIG"
import Translate from '../../shared/i18n/Translate'

var Footer = ({strings}) => (
  <footer className="main-footer">
    <div className="pull-right hidden-xs">
        <b>{strings.version}</b> {config.app_version}
    </div>
    <strong>Backed by <a href="http://www.blink-srl.com"></a>Blink</strong>
  </footer>
)

export default Translate(Footer,"Footer")
