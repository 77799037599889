import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import edit from './edit'
import * as taskTemplates from "../../redux/actionCreators/tasktemplates"
import * as Actions from "../../redux/actionCreators/tasktemplates"
import routes from "../../utils/routes"
import helpers from "../../utils/helpers"

const mapStateToProps = function (state, ownProps) {
  const taskId = helpers.queryStringToJSON(ownProps.location.search).id;
  let task = null;
    if (taskId) {
      task = state.tasksTemplates.listTasks.filter(p => p.id == taskId)[0];
    }
  return {
    taskId: taskId,
    task: task,
    isEditing: taskId ? true : false,
    loading: state.loading,
    loggedUser: state.loggedUser,
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    fetchTasks: () => dispatch(taskTemplates.fetchTasks()),
    saveTasks: (data) => dispatch(taskTemplates.saveTasks(data)),
      goToHome: () => dispatch(push(routes.routeHome())),
      goToList: () => dispatch(push(routes.routeTaskTemplates())),
  }
}

const EditContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(edit)

export default EditContainer
