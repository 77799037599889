import React from 'react'
import ReactDOM from  'reactDOM'
import $ from 'jquery'
import jQuery from 'jquery'
var moment = require('moment')
import Loader from '../shared/PulseLoader'
window.$ = $
window.jQuery = jQuery
import api from "../../utils/api"
import PortDeleteModal from "../modals/PortDeleteModal"

import helpers from "../../utils/helpers"

import Translate from '../shared/i18n/Translate'

import PortsMap from "./PortsMap.js"

var PortsPresentation =  class extends React.Component {
  constructor(props){
    super(props)
    this.state={portIdToDelete:null}
    this.getRemainingPorts=this.getRemainingPorts.bind(this)
    this.onDelete=this.onDelete.bind(this)
    this.setPortToDelete=this.setPortToDelete.bind(this)
  }
  componentDidMount(){
    this.props.refreshPorts()
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  getRemainingPorts(){
    var cantPorts = this.props.ports.length
    var start = this.props.page*50 + (cantPorts>0? 1:0)
    var end = this.props.page*50 + this.props.ports.length
    return start + "/" + end
  }
  setPortToDelete(port){
    this.setState({portIdToDelete:port.id})
  }
  onDelete(){
    console.log("deleting ")
    setTimeout(()=>this.props.refreshPorts(),500)
    api.deletePort(this.state.portIdToDelete).catch(err=>console.log(err))
    console.log("mmm")
  }
  render() {
    const {loading,setView,view,goToPortsManage,goToHome,ports,refreshPorts,nextPage,backPage,strings} = this.props

    var PortsControls =
    (<div className="mailbox-controls">
      {/* <!-- Check all button
      <button type="button" className="btn btn-default btn-sm checkbox-toggle"><i className="fa fa-square-o"></i>
      </button>
      <div className="btn-group">
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-trash-o"></i></button>
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-reply"></i></button>
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-share"></i></button>
      </div> --> */}
      {/* <!-- /.btn-group --> */}

      <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap",alignItems:"center", margin:"0px 5px"}}>
        <div style={{display:"flex"}}>
          <button type="button" className="btn btn-default btn-sm" onClick={refreshPorts}>
            <i className="fa fa-refresh"></i>
          </button>
        </div>
        <div style={(!loading? {display:"none"} : {})}>
          <Loader color="#3c8dbc" size="9px" margin="4px"/>
        </div>
        <div className="pull-right" style={{margin:"5px 0px"}}>
          {this.getRemainingPorts()}
          <div className="btn-group" style={{marginLeft:"15px"}}>
            <button type="button" className="btn btn-default btn-sm" onClick={backPage}><i className="fa fa-chevron-left"></i></button>
            <button type="button" className="btn btn-default btn-sm" onClick={nextPage}><i className="fa fa-chevron-right"></i></button>
          </div>
          {/* <!-- /.btn-group --> */}
        </div>
      </div>
      {/* <!-- /.pull-right --> */}
    </div>
  )


/*
  var Breadcrumb = ({strings}) => (
    <ol className="breadcrumb">
      <li><div onClick={goToHome} style={{cursor:"pointer"}}><i className="fa fa-dashboard"></i> {strings.home}</div></li>
      <li className="active">{"ports"}</li>
    </ol>
  )
  Breadcrumb = Translate(Breadcrumb,"Breadcrumb")
*/
    return (
            <div>
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header" style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                  <h1>
                    {strings.portManagement}
                    <small></small>
                  </h1>
                  <div style={{display:"flex"}}>
                    <div>
                      <button onClick={()=>setView("list")} type="button" className="btn btn-default btn-sm" style={{background:(view=="list"? "#efefef":"white"),padding:"2px 18px",marginRight:"15px",fontSize:"18px"}}><i className="fa fa-bars"></i></button>
                      <button onClick={()=>setView("map")} type="button" className="btn btn-default btn-sm" style={{background:(view=="map"? "#efefef":"white"),padding:"2px 18px",marginRight:"30px",fontSize:"18px"}}><i className="fa fa-globe"></i></button>
                    </div>
                    <div className="btn btn-primary btn-block margin-bottom" style={{marginBottom:"0",width:"120px"}} onClick={()=>goToPortsManage()}>
                      {strings.newPort}
                    </div>
                  </div>
                  {/*<Breadcrumb strings={strings} />*/}
                </section>


                {view=="map"? <PortsMap goToPortsManage={goToPortsManage} className="mapContent" ports={ports} lat={-34.440305} lng={-58.546842} zoom={12} />:null}
                {/* <!-- Main content --> */}
                <section className={"content " + (view=="map"? "hidden":"")}>
                  <div className="row">
                    {/* <!-- /.col --> */}
                    <div className="col-md-12">
                      <div className="box box-primary">
                        {/* <!-- /.box-header --> */}
                        <div className="box-body no-padding">
                          {PortsControls}
                          <div className="table-responsive mailbox-messages">
                            <table className="table table-hover table-striped">
                              <tbody>
                                <tr>
                                  <th style={{paddingLeft:"15px"}}>{strings.id}</th>
                                  <th>{strings.name}</th>
                                  <th>{strings.delete}</th>
                                </tr>
                                {ports.map((op,key)=>(
                                  <tr key={key} onClick={()=>{goToPortsManage({id:op.id})}} style={{cursor:"pointer"}}>
                                                                      <td className="mailbox-date" style={{verticalAlign:"middle",paddingLeft:"15px"}}>{op.id}</td>
                                    <td className="mailbox-name" style={{verticalAlign:"middle"}}>{op.name}</td>
                                    <td className="" style={{verticalAlign:"middle",width:"50px"}}> <a data-toggle="modal" data-target="#PortDeleteModal" style={{cursor:"pointer"}} onClick={e=>{this.setPortToDelete(op);e.stopPropagation();}}>
                                                          <button type="button" className="btn btn-default btn-sm" style={{padding:"1px 17px",marginRight:"0",background:"white",fontSize:"17px"}}><i className="fa fa-trash"></i></button>
                                                        </a></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {/* <!-- /.table --> */}
                          </div>
                          {/* <!-- /.mail-box-messages --> */}
                        </div>
                        {/* <!-- /.box-body --> */}
                        <div className="box-footer no-padding">
                          {PortsControls}
                        </div>
                      </div>
                      {/* <!-- /. box --> */}
                    </div>
                    {/* <!-- /.col --> */}
                  </div>
                  {/* <!-- /.row --> */}
                </section>
                {/* <!-- /.content --> */}
                <PortDeleteModal onDelete={this.onDelete} portId={this.state.portIdToDelete}/>
            </div>
    )
  }
}

export default Translate(PortsPresentation,"Ports")
