"use strict";
import React from "react";
import ReactDOM from "reactDOM";

// ------ Require de .CSS globales
// Puedo hacerlos en cada componente para optimizar
// Si o si ponerlos para que me los compile en styles.bundle.css
require("../css/main.css");
require("../css/devices.css");
require("../css/components.css");
require("../../public/bootstrap/css/bootstrap.min.css"); // require debe ir antes de adminLTE
require("../../public/adminLTE/css/AdminLTE.min.css");
require("../../public/adminLTE/skins/_all-skins.min.css");
require("../../public/toastr/toastr.min.css");
require("../../public/ionicons/css/ionicons.min.css");
require("../../public/fontAwesome/css/font-awesome.min.css");
// ------

import {
  ConnectedRouter,
  routerReducer,
  routerMiddleware,
  push,
} from "react-router-redux";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
} from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";

import App from "./App";
import Inbox from "./components/inbox/InboxContainer";
import Devices from "./components/devices/DevicesContainer";
import Device from "./components/device/DeviceContainer";
import DeviceManage from "./components/devicemanage/DeviceManageContainer";
import RegisterSingleDevice from "./components/device/RegisterSingleDevice";
import Operators from "./components/operators/OperatorsContainer";
import OperatorsManage from "./components/operatorsmanage/OperatorsManageContainer";
import Clients from "./components/clients/ClientsContainer";
import ClientsManage from "./components/clientsmanage/ClientsManageContainer";
import AssetTypes from "./components/assettypes/AssetTypesContainer";
import AssetTypesManage from "./components/assettypesmanage/AssetTypesManageContainer";
import Locations from "./components/locations/LocationsContainer";
import LocationsManage from "./components/locationsmanage/LocationsManageContainer";
import Ports from "./components/ports/PortsContainer";
import PortsManage from "./components/portsmanage/PortsManageContainer";
import Assets from "./components/assets/AssetsContainer";
import Asset from "./components/asset/AssetContainer";
import AssetsManage from "./components/assetsmanage/AssetsManageContainer";
import AssetsRefill from "./components/assets-refill/AssetsRefillContainer";
import ERPINtegrarion from "./components/erpintegration/ERPIntegration";
import Todo from "./components/404/todo";
import AppStatus from "./components/status/StatusPresentation";
import Dashboard from "./components/dashboard/Dashboard";
import Datasources from "./components/datasources";
import DatasourcesEdit from "./components/datasources/editContainer";
import Tags from "./components/tags";
import Tag from "./components/tags/edit";
import Authorization from "./components/shared/Authorization";
import Authentication from "./components/shared/Authentication";
import { List, Edit } from "./components/tasktemplates";
import * as tasks from "./components/tasks";

if (module.hot) {
  module.hot.accept();
}

import vendorsLocales from "./components/shared/i18n/vendorsLocales";
vendorsLocales.initialiceMomentLocales();

// ------ Polyfills ----------------------------------------

// Fetch polyfill
import "whatwg-fetch";
// Promise polyfill
import Promise from "promise-polyfill";
if (!window.Promise) {
  window.Promise = Promise;
}
require("../../public/polyfills/object-assign-auto.min");
//----------------------------------------------------------

// Lodash library
var findindex = require("lodash.findindex");
var debounce = require("lodash.debounce");
window._ = {};
window._.debounce = debounce;
window._.findIndex = findindex;
//window.ReactPerf = require('react-addons-perf') // Uncomment for testing react performance

import { Provider } from "react-redux";
import configureStore from "./redux/store/configureStore";
import * as loginActionCreators from "./redux/actionCreators/loginActionCreators";

const history = createBrowserHistory();

const store = configureStore(history);

const Routes = (
  <div>
    <Switch>
      <Route exact path='/' component={Devices} />
      <Route path='/inbox' component={Todo} />
      <Route path='/devices' component={Devices} />
      <Route path='/dashboard' component={Dashboard} />
      <Route path='/device/:id' component={Device} />
      <Route path='/devicemanage' component={DeviceManage} />
      <Route path='/registerSingleDevice' component={RegisterSingleDevice} />
      <Route path='/operators' component={Operators} />
      <Route path='/operatorsmanage' component={OperatorsManage} />
      <Route path='/clients' component={Clients} />
      <Route path='/clientsmanage' component={ClientsManage} />
      <Route path='/ports' component={Ports} />
      <Route path='/portsmanage' component={PortsManage} />
      <Route path='/locations' component={Locations} />
      <Route path='/task-te' component={Locations} />
      <Route path='/locationsmanage' component={LocationsManage} />
      <Route path='/assettypes' component={AssetTypes} />
      <Route path='/assettypesmanage' component={AssetTypesManage} />
      <Route path='/assets' component={Assets} />
      <Route path='/asset/:id' component={Asset} />
      <Route path='/assetsmanage' component={AssetsManage} />
      <Route path='/assets-refill' component={AssetsRefill} />
      <Route path='/status' component={AppStatus} />
      <Route path='/datasources' component={Datasources} />
      <Route path='/datasource/:id' component={DatasourcesEdit} />
      <Route path='/tag' component={Tag} />
      <Route path='/tags' component={Tags} />
      <Route path='/ERPIntegration' component={ERPINtegrarion} />
      <Route path='/task-templates' component={List} />
      <Route path='/task-template' component={Edit} />
      <Route path='/tasks' component={tasks.List} />
      <Route path='/task' component={tasks.Edit} />
      <Route component={Todo} />
    </Switch>
  </div>
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <Route
          component={({ ...props }) => <App {...props} Routes={Routes} />}
        />
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("app")
);
