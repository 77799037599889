import React, { Component } from "react";
import { DatePicker } from "antd";
import "antd/lib/date-picker/style/css";
import helpers from "../../../utils/helpers";

class Form extends Component {
  constructor(props) {
    super(props);
    const { task } = this.props;
    this.state = {
      form: {
        id: task ? task.id : null,
        status: task ? task.status : "",
        priority: task ? task.priority : "",
        descr: task ? task.descr : "",
        planned_date: task ? task.planned_date : "",
        resolved_by: task ? task.resolved_by : "",
        resolved_by_descr: task ? task.resolved_by_descr : "",
        assigned_to: task ? task.assigned_to : "",
      },
    };
    this.onHandlerChange = this.onHandlerChange.bind(this);
    this.onHandlerUserChange = this.onHandlerUserChange.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onSave = this.onSave.bind(this);
    this._renderDate = this._renderDate.bind(this);
  }

  onChangeDate(date, dateString) {
    this.setState({
      form: {
        ...this.state.form,
        planned_date: date.format("X"),
      },
    });
  }
  onHandlerChange(event) {
    const target = event.target;
    const value = target.value;
    this.state.form[target.name] = value;
    this.setState({ form: this.state.form });
  }
  onHandlerUserChange(event) {
    const target = event.target;
    const value = target.value;
    const index = event.nativeEvent.target.selectedIndex;
    const text = event.nativeEvent.target[index].text;
    this.state.form[target.id] = text;
    this.state.form[target.name] = value;
    this.setState({ form: this.state.form });
  }

  onSave(event) {
    event.preventDefault();
    this.props.updateTask(this.state.form);
  }

  _renderDate(date) {
    const { timezone } = this.props.loggedUser.preferences;
    if (date != 0) {
      return helpers._transformUtcToTimezone(date, timezone, true);
    }
    return "";
  }
  render() {
    const { task, strings, listStatus, listOperators, goToList } = this.props;

    let defaultPlannedDate = null;
    if (task && task.planned_date > 0) {
      defaultPlannedDate = moment(
        moment.unix(task.planned_date).format("DD/MM/YYYY"),
        "DD/MM/YYYY"
      );
    }

    return (
      <div className='box box-primary'>
        <div className='box-header with-border'>
          <i className='fa fa-edit'></i>
          <h3 className='box-title'>{strings.edit}</h3>
        </div>
        <div className='box-body'>
          <form role='form' className='form-horizontal'>
            <div className='box-body'>
              <div className='form-group'>
                <label htmlFor='' className='col-sm-12'>
                  {strings.descr}
                </label>
                <div className='col-sm-12'>
                  <textarea
                    className='form-control'
                    name='descr'
                    onChange={this.onHandlerChange}
                    defaultValue={task ? task.descr : ""}
                  ></textarea>
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='' className='col-sm-2 control-label'>
                  {strings.status}
                </label>
                <div className='col-sm-5'>
                  <select
                    className='form-control'
                    onChange={this.onHandlerChange}
                    defaultValue={this.state.form.status}
                    name='status'
                  >
                    <option>
                      - {strings.select} {strings.status} -
                    </option>
                    {listStatus.length > 0 &&
                      listStatus.map((ls, i) => {
                        return (
                          <option key={`st${i}`} value={ls.taskStatus}>
                            {ls.taskStatus}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='' className='col-sm-2 control-label'>
                  {strings.assignedto}
                </label>
                <div className='col-sm-3'>
                  <select
                    className='form-control'
                    defaultValue={this.state.form.assigned_to}
                    onChange={this.onHandlerUserChange}
                    name='assigned_to'
                    id='assigned_to_descr'
                  >
                    <option>
                      - {strings.select} {strings.assignedto} -
                    </option>
                    {listOperators.length > 0 &&
                      listOperators.map((lo, i) => {
                        return (
                          <option key={`at${i}`} value={lo.email}>
                            {lo.name} - {lo.email}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <label htmlFor='' className='col-sm-1 control-label'>
                  {strings.on}
                </label>
                <div className='col-sm-3'>
                  <span className='form-control'>
                    {this._renderDate(task ? task.assigned_to_date : "")}
                  </span>
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='' className='col-sm-2 control-label'>
                  {strings.plannedto}
                </label>
                <div className='col-sm-5'>
                  <DatePicker
                    defaultValue={defaultPlannedDate}
                    format='DD/MM/YYYY'
                    onChange={this.onChangeDate}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='' className='col-sm-2 control-label'>
                  {strings.resolved}
                </label>
                <div className='col-sm-3'>
                  <select
                    className='form-control'
                    onChange={this.onHandlerUserChange}
                    defaultValue={this.state.form.resolved_by}
                    name='resolved_by'
                    id='resolved_by_descr'
                  >
                    <option>
                      - {strings.select} {strings.resolved} -
                    </option>
                    {listOperators.length > 0 &&
                      listOperators.map((lo, i) => {
                        return (
                          <option key={`at${i}`} value={lo.email}>
                            {lo.name} - {lo.email}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <label htmlFor='' className='col-sm-1 control-label'>
                  {strings.on}
                </label>
                <div className='col-sm-3'>
                  <span className='form-control'>
                    {this._renderDate(task ? task.resolved_by_date : "")}
                  </span>
                </div>
              </div>
              <div className='form-group'>
                <label htmlFor='' className='col-sm-2 control-label'>
                  {strings.priority}
                </label>
                <div className='col-sm-3'>
                  <select
                    className='form-control'
                    onChange={this.onHandlerChange}
                    defaultValue={this.state.form.priority}
                    name='priority'
                  >
                    <option>
                      - {strings.select} {strings.priority} -
                    </option>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((k, i) => {
                      return (
                        <option key={`p${i}`} value={k}>
                          {k}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className='box-footer zd-box-footer'>
              <button
                type='submit'
                className='btn btn-info pull-left'
                onClick={(e) => {
                  this.onSave(e);
                }}
              >
                {strings.save}
              </button>
              <button
                className='btn btn-default pull-right'
                onClick={(e) => {
                  e.preventDefault();
                  goToList();
                }}
              >
                {strings.cancel}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

module.exports = Form;
