import {handleActions} from "redux-actions"
import {SET_LOGGED_USER,SET_CURRENT_ACCOUNT} from "../actionTypes/loggedUserActions"
var update = require('immutability-helper');

const loggedUser=localStorage.getItem('loggedUser')
const initialState=loggedUser? JSON.parse(loggedUser):null
/*
const initialState={
  id: "ac132a4a-5a5c-1f9b-815a-5cc98c430439",
  name: "Sergio Martinez",
  rol: "ADMIN",
  email: "smartinez@gmail.com",
  password: "martinez",
  timezone: "(GMT-03:00) Buenos Aires",
  active: true,
  authenticated: true,
  auth_message: "Welcome back."
}
*/


/* object to test with custom rolesList */
var ob=JSON.parse('{"roles":[{"account":"1","account_name":"IoT Americas","roles":[{"role":"IOT_ADMIN","restricted":"none","entities":[]},{"role":"ADMIN_ADMIN","restricted":"none","entities":[]},{"role":"PLATFORM_ADMIN","restricted":"none","entities":[]},{"role":"ASSET_ADMIN","restricted":"none","entities":[]},{"role":"DEEP_ADMIN","restricted":"none","entities":[]},{"role":"BIKES_ADMIN","restricted":"none","entities":[]},{"role":"PARKING_ADMIN","restricted":"none","entities":[]}]},{"account":"2","account_name":"Blink IoT","roles":[{"role":"IOT_ADMIN","restricted":"none","entities":[]},{"role":"ADMIN_MONITOR","restricted":"none","entities":[]},{"role":"PLATFORM_ADMIN","restricted":"none","entities":[]},{"role":"ASSET_ADMIN","restricted":"none","entities":[]},{"role":"DEEP_ADMIN","restricted":"none","entities":[]},{"role":"BIKES_ADMIN","restricted":"none","entities":[]},{"role":"PARKING_ADMIN","restricted":"none","entities":[]}]}]}')

const reducer=handleActions({
  [SET_LOGGED_USER]:(state,action)=>update(state,{$set:Object.assign(action.payload,{clientId:1})}), // {rolesList:ob}
  [SET_CURRENT_ACCOUNT]:(state,action)=>update(state,{currentAccount:{$set:action.payload}})
},initialState)

export default reducer
