import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import AssetTypesPresentation from './AssetTypesPresentation.js'
import * as assetTypesList from "../../redux/actionCreators/assetTypesListActionCreators"
import * as assetTypesActions from "../../redux/actionCreators/assetTypesActionCreators"
import routes from "../../utils/routes"

const mapStateToProps = function (state) {
  return {
    assetTypes: state.assetTypesList.listAssets,
    page: state.assetTypes.page,
    loading: state.assetTypes.loading,
    loggedUser: state.loggedUser
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    nextPage: () => dispatch(assetTypesActions.nextPage()),
    backPage: () => dispatch(assetTypesActions.backPage()),
    setView: (v) => dispatch(assetTypesActions.setView(v)),
    refreshAssetTypes: () => dispatch(assetTypesActions.refreshAssetTypes()),
    getAssetTypes: (clientId, offset, limit) => dispatch(assetTypesList.getAssetTypes(clientId, offset, limit)),
    goToAssetTypesManage: (assetType) => dispatch(push(routes.routeAssetTypesManage(assetType))),
    goToHome: () => dispatch(push(routes.routeHome())),
  }
}

const AssetTypesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetTypesPresentation)

export default AssetTypesContainer
