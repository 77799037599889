import { createAction } from 'redux-actions';
import {SET_EMAIL,SET_PASSWORD,SET_AUTH_MESSAGE,SET_LOGIN_LOADING,USER_LOGOUT} from "../actionTypes/loginActions"
import api from "../../utils/api"
import { push } from 'react-router-redux'
import routes from "../../utils/routes"
import Authentication from "../../components/shared/Authentication"

import * as loggedUserActions from "./loggedUserActionCreators"

export const setEmail=createAction(SET_EMAIL)
export const setPassword=createAction(SET_PASSWORD)
export const setAuthMessage=createAction(SET_AUTH_MESSAGE)
export const setLoginLoading=createAction(SET_LOGIN_LOADING)
export const userLogout=createAction(USER_LOGOUT)


export const login=()=>{
  return (dispatch,getState)=>{
    const login=getState().login

    dispatch(setLoginLoading(true))

    return api.login(login.email,login.password)
      .then(data=>{
        if(data){
          Authentication.authUser({token:data.auth.authToken,refreshToken:data.auth.refreshToken})
          dispatch(loggedUserActions.setLoggedUser(data.operator))
          localStorage.setItem('loggedUser',JSON.stringify(data.operator))
        } else {
          dispatch(setAuthMessage(data.auth_message))
        }
      })
      .catch((status)=>{
        console.log("Login Catch",status)
        if(status==422)
          dispatch(setAuthMessage("Email o password incorrecto. Intentelo nuevamente."))
      })
      .finally(()=>dispatch(setLoginLoading(false)))
  }
}

export const logout=()=>{
  return (dispatch,getState)=>{

    dispatch(push(routes.routeHome()))
    dispatch(userLogout())
    Authentication.deAuthUser()

  }
}
