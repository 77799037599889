import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import View from './viewEdit';
import * as tags from '../../redux/actionCreators/tagsActionCreators'
import routes from "../../utils/routes"

const mapStateToProps = function(state) {
  return {
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    goHome: ()=>dispatch(push(routes.routeHome())),
    goList: ()=>dispatch(push(routes.routeTags())),
    saveTag: (data) => dispatch(tags.postTag(data)),
  }
}

const TagContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(View)

export default TagContainer
