import React from 'react';
import PropTypes from 'prop-types';

class Alert extends React.Component {
  constructor(props) {
      super(props);
  }

  render() {
    const { title, icon, type, desc, closable, className } = this.props;
    return <div className={`${className} alert alert-${type} ${closable ? 'alert-dismissible': ''}`}>
      {closable &&
        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
      }
      {icon !== '' && title !== '' &&
        <h4><i className={`icon fa fa-${type}`}></i> {title}</h4>
      }
      {icon == '' && title !== '' &&
        <h4>{title}</h4>
      }
      <div dangerouslySetInnerHTML={{__html: desc}}></div>
    </div>
  }
}

Alert.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  desc: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  closable: PropTypes.bool
};

Alert.defaultProps = {
  title: "",
  icon: "",
  type: "success",
  closable: false,
  className: ""
};

module.exports = Alert;