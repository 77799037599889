import { createAction } from 'redux-actions'
import {SET_DEVICES} from "../actionTypes/devicesListActions"
import api from "../../utils/api"
import routes from "../../utils/routes"
import { push } from 'react-router-redux'

export const setDevices=createAction(SET_DEVICES)

export const fetchDevices = (offset=null,limit=null) => {
  return (dispatch, getState) => {
    var devices=getState().devices

    console.log("fetchDevices: offset:" + offset + " limit:" + limit +
                " status: " + devices.status + "filters:", devices.filters)

    return  _getDevices(devices.filters,devices.status,offset,limit)
              .then(data=>dispatch(setDevices(data)))

  }
}


const _getDevices=({search,tags},status,offset,limit)=>{

  //if(search)
  //  return api.getDevicesSearch(search,status,offset,limit)

  api.getActiveTags()
  if(tags && tags.length!=0)
    return api.getDevicesTags(tags.join("@"),status,offset,limit)

  return api.getDevices(status,offset,limit)

}
