import {handleActions} from "redux-actions"
import {CHAT_LOADING} from "../actionTypes/chatActions.js"
var update = require('immutability-helper');

const initialState={
  loading:false
}

const reducer=handleActions({
  [CHAT_LOADING]:(state,action)=>update(state,{loading:{$set:action.payload}}),
},initialState)

export default reducer
