'use strict';

var React = require('react');
var assign = require('domkit/appendVendorPrefix');
var insertKeyframesRule = require('domkit/insertKeyframesRule');
import PropTypes from 'prop-types'
/**
 * @type {Object}
 */
var keyframes = {
    '0%': {
        transform: 'scale(1)',
        opacity: 1
    },
    '45%': {
        transform: 'scale(0.1)',
        opacity: 0.7
    },
    '80%': {
        transform: 'scale(1)',
        opacity: 1
    }
};

/**
 * @type {String}
 */
var animationName = insertKeyframesRule(keyframes);

var propT = {
    loading: PropTypes.bool,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

var ptKeys = Object.keys(propT);

var PulseLoader = class extends React.Component {
  constructor(props){
    super(props)
    this.getBallStyle=this.getBallStyle.bind(this)
    this.getAnimationStyle=this.getAnimationStyle.bind(this)
    this.getStyle=this.getStyle.bind(this)
    this.renderLoader=this.renderLoader.bind(this)
    this.displayName=this.displayName.bind(this)
  }
  displayName(){
    return 'PulseLoader'
  }
  getBallStyle() {
      return {
          backgroundColor: this.props.color,
          width: this.props.size,
          height: this.props.size,
          margin: this.props.margin,
          borderRadius: '100%',
          verticalAlign: this.props.verticalAlign
      }
  }

  /**
   * @param  {Number} i
   * @return {Object}
   */
  getAnimationStyle(i) {
      var animation = [animationName, '0.75s', i * 0.12 + 's', 'infinite', 'cubic-bezier(.2,.68,.18,1.08)'].join(' ');
      var animationFillMode = 'both';

      return {
          animation: animation,
          animationFillMode: animationFillMode
      };
  }

  /**
   * @param  {Number} i
   * @return {Object}
   */
  getStyle(i) {
      return assign(this.getBallStyle(i), this.getAnimationStyle(i), {
          display: 'inline-block',
          border: '0px solid transparent' // fix firefox/chrome/opera rendering
      });
  }

  /**
   * @param  {Boolean} loading
   * @return {ReactComponent || null}
   */
  renderLoader(loading) {
      if (loading) {
          var props = Object.assign({}, this.props);

          if (propT && ptKeys) {
              var klen = ptKeys.length;
              for (var i = 0; i < klen; i++) {
                  delete props[ptKeys[i]];
              }
          }

          return React.createElement(
              'div',
              props,
              React.createElement('div', { style: this.getStyle(1) }),
              React.createElement('div', { style: this.getStyle(2) }),
              React.createElement('div', { style: this.getStyle(3) })
          );
      }

      return null;
  }

  render() {
      return this.renderLoader(this.props.loading);
  }
}

PulseLoader.defaultProps={
    loading: true,
    color: '#ffffff',
    size: '15px',
    margin: '2px'
}
PulseLoader.propTypes=propT

export default PulseLoader
