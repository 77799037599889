import {
  createAction
} from 'redux-actions'
import {
  GET_TASKS, GET_STATUS, GET_ORDERS_TASK, GET_MAP_MARKERS, POST_ASSIGN_ME_TASK, GET_ASSETS_TASKS, POST_ASSETS_TASK
} from '../../actionTypes/tasks';
import api from '../../../utils/api';
import routes from '../../../utils/routes';
import {
  push
} from 'react-router-redux';

export const getTasks = createAction(GET_TASKS);
export const getStatus = createAction(GET_STATUS);
export const getOrdersTask = createAction(GET_ORDERS_TASK);
export const getMapMarkers = createAction(GET_MAP_MARKERS);
export const postAssignMe = createAction(POST_ASSIGN_ME_TASK);
export const getAssetsTasks = createAction(GET_ASSETS_TASKS);
export const postAssetsTasks = createAction(POST_ASSETS_TASK);

export const fetchTasks = (filter) => {
  return (dispatch) => {
    return _getTasks(filter)
      .then(data => {
        return data.map(task => {
          return {
            ...task,
            key: task.id
          }
        })
      })
      .then(data => dispatch(getTasks(data)))
  }
}

export const fetchStatus = () => {
  return (dispatch) => {
    return _getStatus()
      .then(data => {
        return data.map(status => {
          return {
            ...status,
            key: status.id
          }
        })
      })
      .then(data => dispatch(getStatus(data)))
  }
}

export const fetchOrdersTask = () => {
  return (dispatch) => {
    return _getOrdersTask()
      .then(data => dispatch(getOrdersTask(data)));
  }
}

export const updateTask = (data) => {
  return (dispatch) => {
    return _postUpdateTask(data)
      .then(() => dispatch(push(routes.routeTasks())))

  }
}

export const saveQuickEvent = (data) => {
  return (dispatch) => {
    return _postQuickEvent(data)
      .then((data) => { console.log('save', data) });
  }
}

export const fetchMapMarkers = (filter) => {
  return (dispatch) => {
    return _getMapMarkers(filter)
      .then(data => {
        return data.map(task => {
          return {
            ...task,
            key: task.id
          }
        })
      })
      .then(data => dispatch(getMapMarkers(data)));
  }
}
export const postAssignMeTask = (taskId) => {
  return (dispatch) => {
    return _postAssignMe(taskId)
      .then(data => dispatch(postAssignMe(data)));
  }
}

export const fetchAssetsTasks = (assetId) => {
  return (dispatch) => {
    return _getAssetsTasks(assetId)
      .then(data => dispatch(getAssetsTasks(data)));
  }
}

export const postAssetsTask = (data) => {
  return (dispatch) => {
    return _postAssetsTasks(data)
      .then(data => dispatch(postAssetsTasks(data)));
  }
}

const _getTasks = (filter) => {
  return api.getTasks(filter);
}
const _getStatus = () => {
  return api.getStatus();
}
const _getOrdersTask = () => {
  return api.getOrdersTask();
}
const _postUpdateTask = (data) => {
  return api.updateTask(data);
}
const _postQuickEvent = (data) => {
  return api.postQuickEvent(data);
}
const _getMapMarkers = (filter) => {
  return api.getMapMarkers(filter);
}
const _postAssignMe = (taskId) => {
  return api.postAssignMe(taskId);
}
const _getAssetsTasks = (assetId) => {
  return api.getAssetsTasks(assetId);
}

const _postAssetsTasks = (data) => {
  return api.postAssetsTask(data);
}