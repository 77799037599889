

import React from 'react';
import PropTypes from 'prop-types';

class Breadcrumb extends React.Component {
  constructor(props) {
      super(props);
  }

  goToLink(event, toLink) {
    event.preventDefault();
    toLink();
  }

  render() {
    const { list } = this.props;
    return <ol className="breadcrumb">
        {list.length > 0 && list.map((bc, i) => {
          if (i == 0) {
            return <li key={`bk${i}`}><a href="" onClick={(event) => this.goToLink(event, bc.toUrl)} key={`i${i}`}><i className="fa fa-dashboard"></i>{bc.text}</a></li>
          } else if (!bc.toUrl) {
            return <li className="active" key={`i${i}`}>{bc.text}</li>
          } else {
            return <li key={`i${i}`}><a href="" onClick={(event) => this.goToLink(event, bc.toUrl)}>{bc.text}</a></li>
          }
        })}
      </ol>
    }
}

Breadcrumb.propTypes = {
    list: PropTypes.array
};

Breadcrumb.defaultProps = {
  list: []
};

module.exports = Breadcrumb;
