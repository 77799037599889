import React from 'react'

import Title from '../common/title';
import Card from '../common/card';
import Translate from '../shared/i18n/Translate';
import Select from 'react-select';
import Loader from '../shared/PulseLoader';
import Breadcrumb from '../common/breadcrumb';

class EditView extends React.Component {
  constructor(props){
    super(props)

    this.handlerSubmit = this.handlerSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.setTagsSelected = this.setTagsSelected.bind(this);

    this.state = {
        form: {
            datasourceId: 0,
            descr: '',
            externalId: '',
            accountid: '1',
            type: '',
            lat: '',
            lon: '',
            authorized: false,
            tags: {},
            url: '',
            port: '',
            jitEnabled: false,
            active: true
        },
        tags: [],
        tagsSelected: [],
        loading: true
    }
  }
  componentDidMount(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    if (this.props.match.params.id && this.props.match.params.id > 0) {
      this.props.getDataSource(this.props.match.params.id);
    } else {
      this.setState({
        loading: false,
        form: {
          ...this.state.form,
          accountid: this.props.loggedUser.currentAccount
        }
      })
    }
    this.props.getTags();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tags !== prevProps.tags) {
      this.setState({
        tags: this.props.tags
      })
    }
    if (this.props.ds !== prevProps.ds) {
      const  { ds } = this.props;
      console.log("PARSEA",parseFloat(ds.lat))
      this.setState({
          form: {
              datasourceId: ds.datasourceId,
              descr: ds.descr,
              externalId: ds.externalId,
              type: ds.type,
              lat: parseFloat(ds.lat),
              lon: parseFloat(ds.lon),
              authorized: ds.authorized,
              tags: ds.tags,
              url: ds.url,
              port: ds.port,
              jitEnabled: ds.jitEnabled,
              active: ds.active
          },
          loading: false
      })
      if (ds.tags && ds.tags.tags) {
        this.setTagsSelected(ds.tags.tags);
      }
    }
  }

  setTagsSelected(tagsDefault) {
    const ts = [];
    tagsDefault.map(t => {
      ts.push({value: t, label: t});
    })
    this.setState({
      tagsSelected: ts
    })
  }

  handlerSubmit(e) {
    e.preventDefault();
    this.setState({
      form: {
        ...this.state.form,
        lat: parseFloat(this.state.form.lat > 0 ? this.state.form.lat : 0),
        lon: parseFloat(this.state.form.lon > 0 ? this.state.form.lon : 0)
      }
    },()=>this.props.saveDataSource(this.state.form))
  }

  onChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.state.form[target.name] = value;
    this.setState({form: this.state.form});
  }

  handleSelectChange(selectedOption) {
    const selectedItems = [];
    selectedOption.map((so) => {
      selectedItems.push(so.value);
    })
    this.setState({
      form: {
        ...this.state.form,
        tags: {
          tags: selectedItems
        }
      }
    })
  }

  render() {
    const { strings, match, goList, goHome, typeList  } = this.props;

    let title = match.params.id == 0 ? strings.new : strings.edit;
    title = `${title} ${strings.title}`;
    let breadcrumb = [
      {text: strings.home, toUrl: goHome},
      {text: strings.title, toUrl: goList},
      {text: this.state.form.datasourceId > 0 ? this.state.form.descr : strings.newDataSource}
    ];
    return <div>
      <Title title={title} showBtn={false}>
        <Breadcrumb list={breadcrumb} />
      </Title>
      <Card>
        {this.state.loading && <Loader color="#3c8dbc" size="9px" margin="4px"/>}
        {!this.state.loading && <div className="col-md-12">
            <form className="form-horizontal" onSubmit={this.handlerSubmit}>
              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="descr" className="col-sm-3 col-lg-2 col-xs-12 col-md-3 control-label">Name</label>
                  <div className="col-sm-4 col-lg-4 col-xs-12 col-md-5">
                    <input type="text" name="descr" required className="form-control" id="descr" value={this.state.form.descr} placeholder="Descr" onChange={this.onChange}/>
                  </div>
                </div>
                <div className="form-group">
                    <label htmlFor="type" className="col-sm-3 col-lg-2 col-xs-12 col-md-3 control-label">Type</label>
                    <div className="col-sm-4 col-lg-4 col-xs-12 col-md-5">
                        <select className="form-control" value={this.state.form.type} required name="type" id="type" onChange={this.onChange}>
                            <option value="">Select Type</option>
                            {typeList.length > 0 && typeList.map((type, i) => {
                                return <option key={`type${i}`} value={type}>{type}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="form-group">
                  <label htmlFor="externalid" className="col-sm-3 col-lg-2 col-xs-12 col-md-3 control-label">Identification</label>
                  <div className="col-sm-4 col-lg-4 col-xs-12 col-md-5">
                    <input type="text" name="externalId" value={this.state.form.externalId} required className="form-control" id="externalId" placeholder="Identification" onChange={this.onChange}/>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="lat" className="col-sm-3 col-lg-2 col-xs-12 col-md-3 control-label">Latitude</label>
                  <div className="col-sm-3 col-lg-2 col-xs-12 col-md-2">
                    <input type="text" name="lat" className="form-control" value={this.state.form.lat} id="lat" placeholder="Latitude" onChange={this.onChange}/>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="lon" className="col-sm-3 col-lg-2 col-xs-12 col-md-3 control-label">Longitude</label>
                  <div className="col-sm-3 col-lg-2 col-xs-12 col-md-2">
                    <input type="text" name="lon" className="form-control" value={this.state.form.lon} id="lon" placeholder="Longitude" onChange={this.onChange}/>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-offset-2 col-lg-offset-2 col-sm-offset-2 col-sm-10 col-lg-3 col-xs-12 col-md-3">
                    <div className="checkbox">
                      <label>
                        <input name="jitEnabled" type="checkbox" checked={this.state.form.jitEnabled} onChange={this.onChange}/> Enable Just In Time Provisioning
                      </label>
                    </div>
                  </div>
                </div>
                <fieldset>
                    <div className="form-group">
                        <label htmlFor="url" className="col -sm-3 col-lg-2 col-xs-12 col-md-3 control-label">URL</label>
                        <div className="col-sm-4 col-lg-3 col-xs-12 col-md-3">
                            <input type="text" name="url" className="form-control" value={this.state.form.url} id="url" placeholder="url" onChange={this.onChange} disabled />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="port" className="col-sm-3 col-lg-2 col-xs-12 col-md-3 control-label">Port</label>
                        <div className="col-sm-2 col-lg-1 col-xs-12 col-md-1">
                            <input type="text" name="port" className="form-control" value={this.state.form.port} id="port" placeholder="port" onChange={this.onChange} disabled/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="tags" className="col-sm-3 col-lg-2 col-xs-12 col-md-3 control-label">Tags</label>
                        <div className="col-sm-5 col-lg-4 col-xs-12 col-md-4">
                            {this.state.tags && this.state.tags.length > 0 && this.state.tagsSelected.length == 0 && <Select className="iot-select2" options={this.state.tags} isMulti onChange={this.handleSelectChange} /> }
                            {this.state.tags && this.state.tags.length > 0 && this.state.tagsSelected.length > 0 && <Select className="iot-select2" defaultValue={this.state.tagsSelected}  options={this.state.tags} isMulti onChange={this.handleSelectChange} /> }
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-md-offset-2 col-lg-offset-2 col-sm-offset-2 col-sm-10 col-lg-3 col-xs-12 col-md-3">
                            <div className="checkbox">
                            <label>
                                <input name="authorized" checked={this.state.form.authorized} type="checkbox" onChange={this.onChange}/> Authorized
                            </label>
                            </div>
                        </div>
                    </div>
                </fieldset>
              </div>
              <div className="box-footer">
                <button type="button" className="btn btn-default" onClick={this.props.goList}>Cancel</button>
                <button type="submit" className="btn btn-info pull-right">Save</button>
              </div>
            </form>
        </div>}
      </Card>
    </div>
  }
}

export default Translate(EditView, "DataSource")
