import React from "react";
import ReactDOM from "reactDOM";
import helpers from "../../utils/helpers";
import api from "../../utils/api";
//import timezoneHelper from "../../utils/timezoneHelper"
import Translate from "../shared/i18n/Translate";
import $ from "jquery";
import moment from "moment";
import AssetsTank from "./AssetsTank";

var AssetManagePresentation = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autocompleteSerialList: [],
      suggestions: [],
      validation: true,

      inputDecrValid: true,

      devices: [],

      sensors: [],
      sensorsOpen: false,

      locations: [],
      inputLocationVal: "",
      inputLat: "",
      inputLon: "",
      inputInventory: "",

      assetTypes: [],
      inputAssetTypeVal: "",
      notifications_active: true,
      status: null,
      mode: null,
      tank: this.props.tank,
      tank_content: "",
      tank_content_type: "",
      inyection_status: "",
      inyection_treatment: "",
      minimum_inventory_level: "",
    };
    this.handleNewAssetClick = this.handleNewAssetClick.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.removeSensor = this.removeSensor.bind(this);
    this.addSensor = this.addSensor.bind(this);
    this.setListOfSerialNumbersAutocomplete =
      this.setListOfSerialNumbersAutocomplete.bind(this);
    this.handleSensorChange = this.handleSensorChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onHandlerSelect = this.onHandlerSelect.bind(this);
    this.onChangeState = this.onChangeState.bind(this);
  }
  componentDidMount() {
    // Default values
    this.inputDecr.value = "";
    this.inputIdent.value = "";
    this.setState({ sensors: [] });
    this.props.getModes();
    this.props.getStatus();
    // Asset values (editing)
    if (this.props.isEditing) {
      var {
        id,
        location_id,
        lat,
        lon,
        asset_type_id,
        decr,
        ident,
        sensors,
        notifications_active,
        mode,
        status,
        tank_content,
        tank_content_type,
        inyection_status,
        inyection_treatment,
        minimum_inventory_level,
      } = this.props.asset;
      //this.locateMap(parseFloat(lat),parseFloat(lon))
      this.inputDecr.value = decr;
      this.inputIdent.value = ident;
      this.inputLat.value = lat;
      this.inputLon.value = lon;
      this.setState({
        sensors: sensors.sensors,
        notifications_active,
        mode,
        status,
        tank_content,
        tank_content_type,
        inyection_status,
        inyection_treatment,
        minimum_inventory_level,
      });
    }

    this.setListOfSerialNumbersAutocomplete();

    api.getLocations(1, 0, 50).then((locs) => {
      this.setState({
        locations: locs,
        inputLocationVal: this.props.isEditing ? location_id : locs[0].id,
      });
    });

    api.getAssetTypes(1, 0, 50).then((ast) => {
      this.setState({
        assetTypes: ast,
        inputAssetTypeVal: this.props.isEditing ? asset_type_id : ast[0].id,
      });
    });
    this.props.getTankInformation(this.props.loggedUser.clientId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tank !== prevProps.tank) {
      this.setState({
        tank: this.props.tank,
      });
    }
  }

  setListOfSerialNumbersAutocomplete() {
    api.getDevices(null, 0, 100).then((devices) => {
      this.setState({
        devices: devices,
        autocompleteSerialList: devices
          .map((dev) => dev.deviceId)
          .filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
          }),
      });
    });
  }
  handleSensorChange() {
    console.log("sensor change", this.inputSensor.value);
    if (!this.inputSensor.value) this.setState({ suggestions: [] });

    if (this.inputSensor.value)
      this.setState({
        suggestions: this.state.autocompleteSerialList.filter(
          (s) => s.indexOf(this.inputSensor.value) > -1
        ),
      });
  }

  onChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ notifications_active: value });
  }
  onHandlerSelect(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    //this.state[name] = value;
    this.setState({
      [name]: value,
    });
  }
  onChangeState(field, value) {
    this.setState({
      [field]: value,
    });
  }
  handleNewAssetClick(e) {
    e.preventDefault();
    var inputDecr = ReactDOM.findDOMNode(this.inputDecr);
    var inputIdent = ReactDOM.findDOMNode(this.inputIdent);

    var valid = true;

    if (!helpers.isEmptyString(inputDecr.value)) {
      this.setState({ inputDecrValid: true });
    } else {
      valid = false;
      this.setState({ inputDecrValid: false });
    }

    if (
      this.state.mode === "automated" &&
      (!this.state.sensors || this.state.sensors.length == 0)
    ) {
      valid = false;
    }

    if (valid) {
      this.setState({ validation: true });

      // FALTA
      const assetTypeSelected = this.state.assetTypes.filter(
        (as) => as.id == this.inputAssetType.value
      )[0];
      const locationSelected = this.state.locations.filter(
        (l) => l.id == this.inputLocation.value
      )[0];

      console.log("VALID as loc", assetTypeSelected, locationSelected);

      var asset = {
        decr: this.inputDecr.value,
        ident: this.inputIdent.value,
        sensors: this.state.sensors,
        location_id: this.inputLocation.value,
        asset_type_id: this.inputAssetType.value,
        lat: locationSelected.lat,
        lon: locationSelected.lon,
        notifications_active: this.state.notifications_active,
        mode: this.state.mode,
        status: this.state.status,
        tank_content: this.state.tank_content,
        tank_content_type: this.state.tank_content_type,
        inyection_status: this.state.inyection_status,
        inyection_treatment: this.state.inyection_treatment,
        minimum_inventory_level: this.state.minimum_inventory_level,
      };

      if (this.props.isEditing) {
        this.props.editAsset(
          Object.assign({}, asset, { id: this.props.assetId })
        );
      } else {
        this.props.setAsset(asset);
      }

      setTimeout(() => {
        if (this.props.isEditing)
          return this.props.goToAsset(this.props.assetId);

        this.props.goToAssets();
      }, 200);
    } else {
      this.setState({ validation: false });
    }
  }
  getErrorMessage() {
    if (this.state.validation) return null;

    var element;
    if (!this.state.inputDecrValid) {
      element = this.props.strings.decr;
    }

    if (!this.state.sensors || this.state.sensors.length == 0)
      return (
        <span style={{ color: "#a94442" }}>
          {this.props.strings.sensorRequired}
        </span>
      );

    return (
      <span style={{ color: "#a94442" }}>
        {this.props.strings.badDataMsg +
          element +
          this.props.strings.badDataMsgValid}
      </span>
    );
  }
  locateMap(lat, lon) {
    var latLng = { lat: lat, lng: lon };
    //var latLng = {lat: -25.363, lng: 131.044}

    var map = new google.maps.Map(this.mapAssets, {
      zoom: 13,
      center: latLng,
      disableDefaultUI: true,
      gestureHandling: "greedy",
    });

    var marker = new google.maps.Marker({
      position: latLng,
      map: map,
      title: "",
    });
  }
  removeSensor(id) {
    const sensors = this.state.sensors.filter((s) => s.sensorId !== id);
    this.setState({ sensors: sensors });
  }
  addSensor(e) {
    e.preventDefault();
    var inputSensor = ReactDOM.findDOMNode(this.inputSensor);
    var inputPriority = ReactDOM.findDOMNode(this.inputPriority);
    var inputOffset = ReactDOM.findDOMNode(this.inputOffset);

    if (!inputSensor.value || !inputPriority.value || !inputOffset.value)
      return;

    const sensor = {
      sensorId: inputSensor.value,
      depthOffset: inputOffset.value,
      priority: inputPriority.value,
    };
    this.inputSensor.value = "";
    this.inputPriority.value = "";
    this.inputOffset.value = "";
    this.setState({ sensors: this.state.sensors.concat([sensor]) });
  }
  render() {
    const {
      goToHome,
      goToAssets,
      isEditing,
      asset,
      strings,
      listModes,
      listStatus,
    } = this.props;
    var Breadcrumb = ({ strings }) => (
      <ol className='breadcrumb'>
        <li>
          <div onClick={goToHome} style={{ cursor: "pointer" }}>
            <i className='fa fa-dashboard'></i> {strings.home}
          </div>
        </li>
        <li onClick={goToAssets} style={{ cursor: "pointer" }}>
          {strings.assets}
        </li>
        <li style={{ marginBottom: "20px" }} className='active'>
          {isEditing ? asset.name : strings.newAsset}
        </li>
      </ol>
    );
    Breadcrumb = Translate(Breadcrumb, "Breadcrumb");

    console.log(
      "ASET: ",
      asset,
      this.state.suggestions,
      this.state.sensors,
      this.state
    );

    const getDevice = (id) =>
      this.state.devices.filter((dev) => dev.deviceId == id)[0];
    return (
      <div>
        <section className='content-header'>
          <h1 style={{ marginBottom: "10px" }}>
            {isEditing ? strings.editAsset : strings.newAsset}
            <small></small>
          </h1>
          <Breadcrumb />
        </section>
        <div className='col-md-12'>
          <div className='box box-info'>
            {this.props.isEditing ? (
              <div
                className='box box-widget widget-user'
                style={{ marginBottom: "36px" }}
              >
                <div
                  className='widget-user-header'
                  style={{
                    borderRadius: "0",
                    background: "white",
                    height: "130px",
                    padding: "0px",
                    borderBottom: "solid 1px rgb(179, 179, 179)",
                  }}
                >
                  <div
                    ref={(i) => (this.mapAssets = i)}
                    style={{ height: "100%" }}
                  ></div>
                </div>
                <div
                  className='widget-user-image'
                  style={{ top: "67px", marginLeft: "-64px" }}
                >
                  <img
                    className='img-circle'
                    src='/dist/img/iotlogo.png'
                    alt='IoT'
                    style={{ border: "solid 1px #b3b3b3", width: "125px" }}
                  />
                </div>
              </div>
            ) : (
              <div
                className='box box-widget widget-user'
                style={{ marginBottom: "36px" }}
              >
                <div
                  className='widget-user-header'
                  style={{
                    borderRadius: "0",
                    background: "lightgray",
                    height: "100px",
                  }}
                ></div>
                <div
                  className='widget-user-image'
                  style={{ top: "27px", marginLeft: "-64px" }}
                >
                  <img
                    className='img-circle'
                    src='/dist/img/iotlogo.png'
                    alt='IoT'
                    style={{ border: "solid 1px #b3b3b3", width: "125px" }}
                  />
                </div>
              </div>
            )}

            <form autoComplete='off' className='form-horizontal'>
              <div className='box-body' style={{ marginTop: "57px" }}>
                <div className='crud-separator'>{strings.basic}</div>
                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.assetType}
                  </label>
                  <div className='col-sm-10'>
                    <select
                      style={{ maxWidth: "500px" }}
                      ref={(i) => (this.inputAssetType = i)}
                      className='form-control'
                      disabled=''
                      value={this.state.inputAssetTypeVal}
                      onChange={(i) => {
                        console.log("changed", i.value);
                        this.setState({ inputAssetTypeVal: i.value });
                      }}
                    >
                      {this.state.assetTypes.map((as, i) => (
                        <option key={i} value={as.id.toString()}>
                          {as.decr +
                            "   (longitude: " +
                            as.tlong +
                            ", width: " +
                            as.twidth +
                            ", depth: " +
                            as.tdepth +
                            " CSA: " +
                            as.sca +
                            ")"}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.decr}
                  </label>
                  <div
                    className={
                      "col-sm-10 " +
                      (!this.state.inputDecrValid ? "has-error" : "")
                    }
                  >
                    <input
                      style={{ maxWidth: "650px" }}
                      ref={(i) => (this.inputDecr = i)}
                      type='text'
                      className='form-control'
                      placeholder={strings.decr}
                      required
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.ident}
                  </label>
                  <div className={"col-sm-10 "}>
                    <input
                      style={{ maxWidth: "650px" }}
                      ref={(i) => (this.inputIdent = i)}
                      type='text'
                      className='form-control'
                      placeholder={strings.ident}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.status}
                  </label>
                  <div className='col-sm-4'>
                    <select
                      name='status'
                      className='form-control'
                      value={this.state.status}
                      onChange={this.onHandlerSelect}
                    >
                      <option value=''>
                        - {strings.select} {strings.status}-
                      </option>
                      {listStatus.length > 0 &&
                        listStatus.map((s, i) => {
                          return (
                            <option key={`ls${i}`} value={s.asset_status}>
                              {s.asset_status}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.location}
                  </label>
                  <div className='col-sm-10'>
                    <select
                      style={{ maxWidth: "300px" }}
                      ref={(i) => (this.inputLocation = i)}
                      className='form-control'
                      disabled=''
                      value={this.state.inputLocationVal}
                      onChange={(i) => {
                        console.log("changed", i.value);
                        this.setState({ inputLocationVal: i.value });
                      }}
                    >
                      {this.state.locations.map((loc, i) => (
                        <option key={i} value={loc.id.toString()}>
                          {loc.decr}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.lat}
                  </label>
                  <div className={"col-sm-4 "}>
                    <input
                      ref={(i) => (this.inputLat = i)}
                      type='text'
                      className='form-control'
                      placeholder={strings.lat}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.lon}
                  </label>
                  <div className={"col-sm-4 "}>
                    <input
                      ref={(i) => (this.inputLon = i)}
                      type='text'
                      className='form-control'
                      placeholder={strings.lon}
                    />
                  </div>
                </div>

                <AssetsTank
                  {...{ strings }}
                  {...this.state}
                  onChange={this.onChangeState}
                />

                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.notifications}
                  </label>
                  <div className='col-sm-10'>
                    <div className='checkbox'>
                      <label>
                        <input
                          name='notifications_active'
                          checked={this.state.notifications_active}
                          type='checkbox'
                          onChange={this.onChange}
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.mode}
                  </label>
                  <div className='col-sm-4'>
                    <select
                      name='mode'
                      className='form-control'
                      value={this.state.mode}
                      onChange={this.onHandlerSelect}
                    >
                      <option value=''>
                        - {strings.select} {strings.mode}-
                      </option>
                      {listModes.length > 0 &&
                        listModes.map((m, i) => {
                          return (
                            <option key={`lm${i}`} value={m.asset_mode}>
                              {m.asset_mode}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className='col-sm-1' />
                {this.state.mode === "automated" && (
                  <div className='col-sm-11' style={{ maxWidth: "775px" }}>
                    <div
                      className='box box-warning'
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className='box-header with-border'
                        onClick={() =>
                          this.setState({
                            sensorsOpen: !this.state.sensorsOpen,
                          })
                        }
                      >
                        <h3 className='box-title'>{strings.sensors}</h3>
                        <div className='box-tools pull-right'>
                          <button type='button' className='btn btn-box-tool'>
                            <i
                              className={
                                "fa fa-" +
                                (this.state.sensorsOpen ? "minus" : "plus")
                              }
                            />
                          </button>
                        </div>
                        {/* /.box-tools */}
                      </div>
                      {/* /.box-header */}
                      <div
                        className='box-body'
                        style={
                          this.state.sensorsOpen
                            ? { cursor: "initial" }
                            : { display: "none" }
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            marginBottom: "20px",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label className='col-sm-2 control-label'>
                              {strings.sensor}
                            </label>
                            <div
                              className={"col-sm-10"}
                              style={{ width: "105%" }}
                            >
                              <input
                                style={{ maxWidth: "650px" }}
                                onFocus={this.handleSensorChange}
                                onBlur={() =>
                                  setTimeout(
                                    () => this.setState({ suggestions: [] }),
                                    500
                                  )
                                }
                                onChange={this.handleSensorChange}
                                ref={(i) => (this.inputSensor = i)}
                                type='text'
                                className='form-control'
                                placeholder={strings.sensor}
                              />
                            </div>
                            {this.state.suggestions.length > 0 ? (
                              <div className='sensor-autocomplete'>
                                {this.state.suggestions.map((input, i) => (
                                  <div
                                    key={i}
                                    style={{
                                      padding: "3px 0px",
                                      fontWeight: "600",
                                    }}
                                    onClick={() =>
                                      (this.inputSensor.value = input)
                                    }
                                  >
                                    {input +
                                      "  (frecuency: " +
                                      getDevice(input).frecuency +
                                      ", last report: " +
                                      helpers._transformUtcToTimezone(
                                        getDevice(input).last_reading_time,
                                        null,
                                        true
                                      ) +
                                      ", status: " +
                                      getDevice(input).status +
                                      ", type: " +
                                      getDevice(input).device_type +
                                      ")"}
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label className='col-sm-2 control-label'>
                              {strings.priority}
                            </label>
                            <div
                              className={"col-sm-10"}
                              style={{ width: "105%" }}
                            >
                              <input
                                style={{ maxWidth: "650px" }}
                                ref={(i) => (this.inputPriority = i)}
                                type='text'
                                className='form-control'
                                placeholder={strings.priority}
                              />
                            </div>
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label className='col-sm-2 control-label'>
                              {strings.offset}
                            </label>
                            <div
                              className={"col-sm-10"}
                              style={{ width: "105%" }}
                            >
                              <input
                                style={{ maxWidth: "650px" }}
                                ref={(i) => (this.inputOffset = i)}
                                type='text'
                                className='form-control'
                                placeholder={strings.offset}
                              />
                            </div>
                          </div>

                          <button
                            onClick={(e) => this.addSensor(e)}
                            className='btn btn-info pull-right'
                            style={{
                              height: "max-content",
                              padding: "7px 35px",
                            }}
                          >
                            {strings.add}
                          </button>
                        </div>

                        {/*<div style={{display:"flex",fontWeight:"600",marginBottom:"10px"}}>
                            <div style={{width:"30%"}}>{strings.sensor}</div>
                            <div style={{width:"30%"}}>{strings.priority}</div>
                            <div style={{width:"30%"}}>{strings.offset}</div>
                          </div>*/}
                        {this.state.sensors && this.state.sensors.length
                          ? this.state.sensors.map((sensor, i) => {
                              return (
                                <div
                                  key={i}
                                  style={{
                                    display: "flex",
                                    borderBottom: "solid 1px #f9f9f9",
                                    borderTop: "solid 1px #f9f9f9",
                                    padding: "5px 0px",
                                  }}
                                >
                                  <div style={{ width: "30%" }}>
                                    {sensor.sensorId}
                                  </div>
                                  <div style={{ width: "30%" }}>
                                    {sensor.priority}
                                  </div>
                                  <div style={{ width: "30%" }}>
                                    {sensor.depthOffset}
                                  </div>
                                  <div
                                    style={{
                                      width: "10%",
                                      color: "gray",
                                      fontWeight: "600",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.removeSensor(sensor.sensorId)
                                    }
                                  >
                                    {strings.remove}
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </div>
                      {/* /.box-body */}
                    </div>
                    {/* /.box */}
                  </div>
                )}
              </div>

              <div
                className='box-footer'
                style={{ justifyContent: "center", display: "flex" }}
              >
                <button
                  type='submit'
                  onClick={this.handleNewAssetClick}
                  className='btn btn-info pull-right'
                  style={{ marginRight: "100px", padding: "6px 25px" }}
                >
                  {strings.save}
                </button>
                <button
                  className='btn btn-default pull-left'
                  style={{ padding: "6px 25px", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    goToAssets();
                  }}
                >
                  {strings.cancel}
                </button>
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                {this.getErrorMessage()}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default Translate(AssetManagePresentation, "AssetManage");
