import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import LocationsManagePresentation from './LocationsManagePresentation'
import * as locationsListActions from "../../redux/actionCreators/locationsListActionCreators"
import routes from "../../utils/routes"
import helpers from "../../utils/helpers"

const mapStateToProps = function(state, ownProps) {
  var locationId = helpers.queryStringToJSON(ownProps.location.search).id
  var location
  if(locationId)
    location = state.locationsList.filter(p=>p.id==locationId)[0]

  return {
    locationId: locationId,
    isEditing: locationId? true:false,
    location: location,
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    goToLocations:()=>dispatch(push(routes.routeLocations())),
    goToHome:()=>dispatch(push(routes.routeHome())),
    setLocation:(location)=>dispatch(locationsListActions.setLocation(location)),
    editLocation:(location)=>dispatch(locationsListActions.editLocation(location))
  }
}

const LocationsManageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsManagePresentation)

export default LocationsManageContainer
