import React, { Component } from "react";

const list_tank_shapes = [
  {
    label: "Rectangular Prism",
    value: "1",
  },
  {
    label: "Prism w/cone bottom",
    value: "2",
  },
  {
    label: "Vertical Cylinder",
    value: "3",
  },
  {
    label: "Horizontal Cylinder",
    value: "4",
  },
  {
    label: "Vertical Cylinder w/cone bottom",
    value: "5",
  },
  {
    label: "Horizontal Capsule",
    value: "6",
  },
];
class CommonField extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { strings, assetType, onChangeConfig } = this.props;
    return (
      <React.Fragment>
        <div className='form-group'>
          <label className='col-sm-4 control-label'>
            {strings.max_capacity}
          </label>
          <div className={"col-sm-5"}>
            <input
              type='text'
              name='max_capacity'
              className='form-control'
              value={assetType.waterTank_config.max_capacity}
              onChange={onChangeConfig}
            />
          </div>
        </div>
        <div className='form-group'>
          <label className='col-sm-4 control-label'>{strings.tankShape}</label>
          <div className={"col-sm-5 "}>
            <select
              className='form-control'
              name='tank_shape'
              onChange={onChangeConfig}
            >
              <option value=''>Select Tank Shape</option>
              {list_tank_shapes.map((tank) => (
                <option
                  value={tank.value}
                  selected={
                    tank.value === String(assetType.waterTank_config.tank_shape)
                  }
                  key={tank.label}
                >
                  {tank.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CommonField;
