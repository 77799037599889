import React, { Component } from "react";
import Loader from "../../components/shared/PulseLoader";

class ListControls extends Component {
  render() {
    const { enableNext, enablePrevious, onChange, loading } = this.props;
    return (
      <div className='mailbox-controls zd-list-controls'>
        <div className='controls-left'>
          <button
            type='button'
            disabled={loading}
            className='btn btn-default btn-sm'
            onClick={() => onChange("refresh")}
          >
            <i className='fa fa-refresh'></i>
          </button>
        </div>
        {loading && <Loader color='#3c8dbc' size='9px' margin='4px' />}
        <div className='controls-pagination'>
          <div className='btn-group'>
            <button
              type='button'
              className='btn btn-default btn-sm'
              disabled={!enablePrevious || loading}
              onClick={() => onChange("previous")}
            >
              <i className='fa fa-chevron-left'></i>
            </button>
            <button
              type='button'
              className='btn btn-default btn-sm'
              disabled={!enableNext || loading}
              onClick={() => onChange("next")}
            >
              <i className='fa fa-chevron-right'></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ListControls;
