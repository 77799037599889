import {handleActions} from "redux-actions"
import {CONSOLE_LOADING,SET_CONSOLE_OPTION} from "../actionTypes/consoleActions"
var update = require('immutability-helper')

const initialState={
  loading:false,
  conversationsFirst:false,
  conversationsMessages:false,
  deleteConversations:false,
  minimizeConversations:false
}

const reducer=handleActions({
  [CONSOLE_LOADING]:(state,action)=>update(state,{loading:{$set:action.payload}}),
  [SET_CONSOLE_OPTION]:(state,action)=>update(state,{[action.payload.option]:{$set:action.payload.bool}}),
},initialState)

export default reducer
