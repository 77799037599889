import React from "react";
import "../../../css/components.css";

const Application = ({ strings, asset }) => {
  return (
    <section className='col-lg-5 connectedSortable ui-sortable'>
      <div className='box box-info'>
        <div className='box-header'>
          <i className='fa fa-envelope' />
          <h3 className='box-title'>{strings.application}</h3>
        </div>
        <div className='box-body'>
          <div className='boxs-app'>
            <div className='box-row'>
              <span>{strings.content}</span>
              <div className='box-app box-app-danger'>
                {asset.tank_content || "-"}
              </div>
            </div>
            <div className='box-row'>
              <span>{strings.class}</span>
              <div className='box-app box-app-danger'>
                {asset.tank_content_type || "-"}
              </div>
            </div>
          </div>
          <div className='boxs-app box-app-top'>
            <div className='box-row'>
              <span>{strings.app_point}</span>
              <div className='box-app box-app-success'>
                {asset.inyection_status}
              </div>
            </div>
            <div className='box-row'>
              <span>{strings.treatment}</span>
              <div className='box-app box-app-info'>
                {asset.inyection_treatment}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Application;
