module.exports = {
    datasources: {
        types: [
            "Public LoRa",
            "Gateway LoRa",
            "Narrowband-IoT UDP",
            "Narrowband-IoT TCP",
            "Sigfox",
            "HTTP Post",
            "Amazon IoT",
            "Azure IoT",
            "IBM Watson IoT",
            "Oracle IoT Cloud"
        ]
    }
}