import React from "react";
import Loader from "../shared/PulseLoader";
import helpers from "../../utils/helpers";
import JSONPretty from "react-json-pretty";

var MessagesView = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { messages: [], page: 0, loading: false, show: 999 };
    this.refreshMessages = this.refreshMessages.bind(this);
    this.getRemainingMessages = this.getRemainingMessages.bind(this);
    this.refresh = this.refresh.bind(this);
    this.backPage = this.backPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }
  refreshMessages(offset, limit) {
    return new Promise((resolve, reject) => {
      if (this.state.loading) return resolve();

      this.setState({ loading: true });

      this.props.content
        .fetchMessages(this.props.deviceId, offset, limit)
        .then((data) => {
          this.setState({ messages: data, loading: false });
          resolve();
        });
    });
  }
  componentDidMount() {
    this.refresh();
  }
  refresh() {
    this.refreshMessages(0, 50).then(() => this.setState({ page: 0 }));
  }
  getRemainingMessages() {
    var cantMessages = this.state.messages.length;
    var start = this.state.page * 50 + (cantMessages > 0 ? 1 : 0);
    var end = this.state.page * 50 + this.state.messages.length;
    return start + "/" + end;
  }
  backPage() {
    if (this.state.page == 0 || this.state.loading) return;

    this.setState({ loading: true });
    this.refreshMessages(
      this.state.messages.length * (this.state.page + 1),
      50
    ).then(() => this.setState({ page: this.state.page - 1 }));
  }
  nextPage() {
    if (this.state.messages.length < 50 || this.state.loading) return;

    this.setState({ loading: true });
    this.refreshMessages(
      this.state.messages.length * (this.state.page + 1),
      50
    ).then(() => this.setState({ page: this.state.page + 1 }));
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.content &&
      this.props.content &&
      prevProps.content.title !== this.props.content.title
    )
      this.refresh();
  }
  render() {
    const { strings, device, deviceId, content } = this.props;
    const { loading, messages } = this.state;

    var MessagesControls = (
      <div className='mailbox-controls'>
        {/* <!-- Check all button
      <button type="button" className="btn btn-default btn-sm checkbox-toggle"><i className="fa fa-square-o"></i>
      </button>
      <div className="btn-group">
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-trash-o"></i></button>
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-reply"></i></button>
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-share"></i></button>
      </div> --> */}
        {/* <!-- /.btn-group --> */}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "center",
            margin: "0px 5px",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                marginRight: "40px",
                alignItems: "center",
              }}
            >
              <button
                type='button'
                className='btn btn-default btn-sm'
                onClick={this.refresh}
              >
                <i className='fa fa-refresh'></i>
              </button>
            </div>
          </div>
          <div style={!loading ? { display: "none" } : {}}>
            <Loader color='#3c8dbc' size='9px' margin='4px' />
          </div>
          <div className='pull-right' style={{ margin: "5px 0px" }}>
            {this.getRemainingMessages()}
            <div className='btn-group' style={{ marginLeft: "10px" }}>
              <button
                type='button'
                className='btn btn-default btn-sm'
                onClick={this.backPage}
              >
                <i className='fa fa-chevron-left'></i>
              </button>
              <button
                type='button'
                className='btn btn-default btn-sm'
                onClick={this.nextPage}
              >
                <i className='fa fa-chevron-right'></i>
              </button>
            </div>
            {/* <!-- /.btn-group --> */}
          </div>
        </div>
        {/* <!-- /.pull-right --> */}
      </div>
    );

    return (
      <div>
        {/* <!-- Content Header (Page header) --> */}
        {/* <section className='content-header'>
          <h1>
            {content.title}
            <small></small>
          </h1>
        </section> */}

        {/* <!-- Main content --> */}
        <section className='content'>
          <div className='row'>
            {/* <!-- /.col --> */}
            <div className='col-md-12'>
              <div className='box box-primary'>
                <div
                  className='box-header with-border'
                  style={{ padding: "0" }}
                >
                  {/* <!-- /.box-tools --> */}
                </div>
                {/* <!-- /.box-header --> */}
                <div className='box-body no-padding'>
                  {MessagesControls}
                  <div className='table-responsive mailbox-messages'>
                    <div className='table table-hover table-striped'>
                      <div>
                        <div className={"table-header"}>
                          <div style={{ maxWidth: "260px" }}>
                            {strings.messageTime}
                          </div>
                          <div>{strings.protocol}</div>
                          <div>{"mtype"}</div>
                          <div>{"mstype"}</div>
                          <div></div>
                        </div>
                        {messages.map((msg, key) => (
                          <div key={key}>
                            <div
                              className={"table-row"}
                              onClick={() => {
                                this.setState({
                                  show: this.state.show == key ? 999 : key,
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                className='mailbox-date'
                                style={{ verticalAlign: "middle" }}
                              >
                                {helpers._transformUtcToTimezone(
                                  msg.messagetime,
                                  null,
                                  true
                                )}
                              </div>
                              <div
                                className='mailbox-subject'
                                style={{ verticalAlign: "middle" }}
                              >
                                {msg.protocol}
                              </div>
                              <div
                                className='mailbox-subject'
                                style={{ verticalAlign: "middle" }}
                              >
                                {msg.mtype}
                              </div>
                              <div
                                className='mailbox-subject'
                                style={{ verticalAlign: "middle" }}
                              >
                                {msg.mstype}
                              </div>
                              <button
                                type='button'
                                className='btn btn-box-tool'
                              >
                                <i
                                  className={
                                    "fa fa-" +
                                    (this.state.show == key ? "minus" : "plus")
                                  }
                                />
                              </button>
                              {/*<JSONPretty style={{background:"#1a2226",width:"100%"}} themeClassName="custom-json-pretty" id="json-pretty" json={msg.msg}></JSONPretty>*/}
                            </div>
                            {this.state.show == key ? (
                              <JSONPretty
                                style={{ background: "#1a2226", width: "100%" }}
                                themeClassName='custom-json-pretty'
                                id='json-pretty'
                                json={msg.msg}
                              ></JSONPretty>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* <!-- /.table --> */}
                  </div>
                  {/* <!-- /.mail-box-messages --> */}
                </div>
                {/* <!-- /.box-body --> */}
                <div className='box-footer no-padding'>{MessagesControls}</div>
              </div>
              {/* <!-- /. box --> */}
            </div>
            {/* <!-- /.col --> */}
          </div>
          {/* <!-- /.row --> */}
        </section>
        {/* <!-- /.content --> */}
      </div>
    );
  }
};

export default MessagesView;
