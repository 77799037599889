import React from "react";
import Translate from "../shared/i18n/Translate";
import Notifications from "./notifications";
import WaterTank from "./WaterTank";
import Chemical from "./Chemical";

const commonFields = {
  tank_shape: "",
  tlength: "",
  max_capacity: "",
  twidth: "",
  theigth: "",
  diameter_top: "",
  diameter_bottom: "",
  cone_heigth: "",
  max_capacity: "",
};

const AssetTypesManagePresentation = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetType: this.props.isEditing
        ? {
            ...this.props.assetType,
            waterTank_config: {
              ...commonFields,
              ...this.props.assetType.waterTank_config,
            },
          }
        : {
            id: 0,
            decr: "",
            asset_class: null,
            asset_class_descr: "",
            manufacturer: "",
            manufacturer_product_code: "",
            manufacturer_product_description: "",
            notifications_active: false,
            notifications_config: {},
            waterTank_config: {
              ...commonFields,
            },
          },
      assetClassesValue: this.props.isEditing
        ? this.props.assetType.asset_class
        : "",
      listACAlerts: [],
      assetClassesAlerts: false,
      showAssetClassesConfigOpen:
        this.props.isEditing &&
        (this.props.assetType.asset_class == "0" ||
          this.props.assetType.asset_class == "5"),
      showAssetChemicalConfigOpen:
        this.props.isEditing && this.props.assetType.asset_class == "6",
      notifications: [],
    };
    //this.handleNewAssetTypeClick=this.handleNewAssetTypeClick.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeConfig = this.onChangeConfig.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onChangeManufacture = this.onChangeManufacture.bind(this);
    this.handleNewAssetTypeClick = this.handleNewAssetTypeClick.bind(this);
    this.onUpdateNotification = this.onUpdateNotification.bind(this);
    this.onResetTankConfig = this.onResetTankConfig.bind(this);
  }
  componentDidMount() {
    this.props.getAssetClasses();
    this.props.getManufactures();
    this.props.getTags();
    this.props.getTasks();
    // Default values
    if (this.props.isEditing) {
      var {
        waterTank_config,
        manufacturer,
        manufacturer_product_code,
        manufacturer_product_description,
      } = this.props.assetType;
      /* let config = {
        tlength: waterTank_config ? waterTank_config.tlength : "",
        max_capacity: waterTank_config ? waterTank_config.max_capacity : "",
      }; */

      this.setState({
        assetType: {
          ...this.state.assetType,
          waterTank_config,
          manufacturer,
          manufacturer_product_code,
          manufacturer_product_description,
        },
      });
      if (this.props.assetClasses.length > 0) {
        let q = this.getAlerts(
          this.props.assetClasses,
          this.props.assetType.asset_class
        );
        this.setState({
          listACAlerts: q.length > 0 ? q[0].alerts : [],
          assetClassesAlerts: q.length > 0,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.assetClasses !== this.props.assetClasses) {
      let q = [];
      if (this.props.assetType && this.props.assetType.asset_class != null) {
        q = this.getAlerts(
          this.props.assetClasses,
          this.props.assetType.asset_class
        );
      }
      this.setState({
        assetClasses: this.props.assetClasses,
        listACAlerts: q.length > 0 ? q[0].alerts : [],
        assetClassesAlerts: q.length > 0,
      });
    }

    if (prevProps.assetManufactures !== this.props.assetManufactures) {
      this.setState({
        assetManufactures: this.props.assetManufactures,
      });
    }
  }
  onChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.state.assetType[target.name] = value;
    this.setState({ assetType: this.state.assetType });
  }

  onChangeConfig(event) {
    const { value, name, checked, type } = event.target;
    this.state.assetType.waterTank_config[name] =
      type === "checkbox" ? checked : value;
    this.setState({
      assetType: {
        ...this.state.assetType,
        waterTank_config: this.state.assetType.waterTank_config,
      },
    });
    if (name === "tank_shape") {
      this.onResetTankConfig();
    }
  }
  onResetTankConfig() {
    const { tank_shape, max_capacity, ...rest } = commonFields;
    this.setState({
      assetType: {
        ...this.state.assetType,
        waterTank_config: {
          ...this.state.assetType.waterTank_config,
          ...rest,
        },
      },
    });
  }
  onChangeManufacture(event) {
    const target = event.target;
    const value = target.value;
    this.setState({
      ...this.state.assetType,
      [target.name]: value,
    });
  }

  handleNewAssetTypeClick(e) {
    e.preventDefault();
    let notis = [];
    if (
      this.state.assetType.notifications_active &&
      this.state.assetType.notifications_active != null
    ) {
      notis = this.state.assetType.notifications_config.notification;
    }
    this.setState(
      {
        assetType: {
          ...this.state.assetType,
          notifications_active:
            this.state.assetType.notifications_active == null
              ? false
              : this.state.assetType.notifications_active,
          notifications_config: {
            ...this.state.assetType.notifications_config,
            notification: notis,
          },
        },
      },
      () => {
        if (this.props.isEditing) {
          this.props.editAssetType(
            this.props.loggedUser.clientId,
            this.state.assetType
          );
        } else {
          this.props.setAssetType(
            this.props.loggedUser.clientId,
            this.state.assetType
          );
        }
      }
    );
  }
  getErrorMessage() {}
  getAlerts(assetClasses, v) {
    const q = assetClasses.filter((it) => {
      if (it.code == v) {
        return it;
      }
      return false;
    });
    return q;
  }

  onChangeHandler(event) {
    const v = event.target.value;
    const index = event.nativeEvent.target.selectedIndex;
    let text = event.nativeEvent.target[index].text;
    if (v == "") {
      text = "";
    }
    let waterConfig = {
      ...commonFields,
    };
    if (v == "0") {
      waterConfig = {
        ...waterConfig,
      };
    }
    this.setState({
      assetClassesValue: v,
      showAssetClassesConfigOpen: v == "0" || v == "5",
      showAssetChemicalConfigOpen: v == "6",
      assetType: {
        ...this.state.assetType,
        asset_class: v,
        asset_class_descr: text,
        waterTank_config: waterConfig,
      },
    });

    let q = this.getAlerts(this.props.assetClasses, v);
    if (q.length > 0) {
      this.setState({
        listACAlerts: q[0].alerts,
        assetClassesAlerts: true,
      });
    } else {
      this.setState({
        listACAlerts: [],
        assetClassesAlerts: false,
      });
    }
  }

  onUpdateNotification(noti) {
    this.setState({
      assetType: {
        ...this.state.assetType,
        notifications_config: {
          ...this.state.assetType.notifications_config,
          notification: noti,
        },
      },
    });
  }

  render() {
    const {
      goToHome,
      goToAssetTypes,
      isEditing,
      assetType,
      strings,
      assetClasses,
      assetManufactures,
    } = this.props;

    var Breadcrumb = ({ strings }) => (
      <ol className='breadcrumb'>
        <li>
          <div onClick={goToHome} style={{ cursor: "pointer" }}>
            <i className='fa fa-dashboard'></i> {strings.home}
          </div>
        </li>
        <li onClick={goToAssetTypes} style={{ cursor: "pointer" }}>
          {strings.assetTypes}
        </li>
        <li style={{ marginBottom: "20px" }} className='active'>
          {isEditing ? assetType.id : strings.newAssetType}
        </li>
      </ol>
    );
    Breadcrumb = Translate(Breadcrumb, "Breadcrumb");

    return (
      <div>
        <section className='content-header'>
          <h1 style={{ marginBottom: "10px" }}>
            {isEditing ? strings.editAssetType : strings.newAssetType}
            <small></small>
          </h1>
          <Breadcrumb />
        </section>
        <div className='col-md-12'>
          <div className='box box-info'>
            <form autoComplete='off' className='form-horizontal'>
              <div className='box-body' style={{ marginTop: "57px" }}>
                {this.props.isEditing && (
                  <div className='form-group'>
                    <label className='col-sm-2 control-label'>
                      {strings.id}
                    </label>
                    <div className={"col-sm-4 "}>
                      <input
                        value={this.state.assetType.id}
                        type='text'
                        className='form-control'
                        placeholder={strings.id}
                        required
                        disabled
                      />
                    </div>
                  </div>
                )}

                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.description}
                  </label>
                  <div className={"col-sm-4"}>
                    <input
                      type='text'
                      name='decr'
                      value={this.state.assetType.decr}
                      className='form-control'
                      placeholder={strings.description}
                      onChange={this.onChange}
                      required
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.class}
                  </label>
                  <div className='col-sm-6'>
                    <select
                      name='asset_classes_value'
                      value={this.state.assetClassesValue}
                      onChange={this.onChangeHandler}
                      className='form-control'
                      required
                      disabled={this.props.isEditing ? true : false}
                    >
                      <option value=''>{strings.selectClass}</option>
                      {assetClasses.map((cls, i) => {
                        return (
                          <option key={`asc${i}`} value={cls.code}>
                            {cls.descr}
                          </option>
                        );
                      })}
                    </select>
                    {this.state.assetClassesAlerts && (
                      <div>
                        <label className='control-label'>
                          {strings.metrics}
                        </label>
                        <div className='class-alerts'>
                          {this.state.listACAlerts.map((alert, i) => {
                            return (
                              <div
                                className='class-alerts-alert'
                                key={`al${i}`}
                              >
                                <span className='badge bg-light-blue'>
                                  {strings.alerts(alert)}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {this.state.showAssetClassesConfigOpen &&
                  this.state.assetType.waterTank_config != null && (
                    <WaterTank
                      {...{ strings }}
                      assetType={this.state.assetType}
                      onChangeConfig={this.onChangeConfig}
                    />
                  )}
                {this.state.showAssetChemicalConfigOpen &&
                  this.state.assetType.waterTank_config != null && (
                    <Chemical
                      {...{ strings }}
                      assetType={this.state.assetType}
                      onChangeConfig={this.onChangeConfig}
                    />
                  )}

                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.manufacture}
                  </label>
                  <div className={"col-sm-4"}>
                    <select
                      name='manufacturer'
                      value={this.state.assetType.manufacturer}
                      onChange={this.onChange}
                      className='form-control'
                    >
                      <option value=''>{strings.selectManufacture}</option>
                      {assetManufactures &&
                        assetManufactures.map((mf, i) => {
                          return (
                            <option key={`mn${i}`} value={mf.manufacturer}>
                              {mf.manufacturer}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.manufacturecode}
                  </label>
                  <div className={"col-sm-4"}>
                    <input
                      type='text'
                      name='manufacturer_product_code'
                      className='form-control'
                      value={this.state.assetType.manufacturer_product_code}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.manufacturedesc}
                  </label>
                  <div className={"col-sm-6"}>
                    <input
                      type='text'
                      name='manufacturer_product_description'
                      className='form-control'
                      value={
                        this.state.assetType.manufacturer_product_description
                      }
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.notification}
                  </label>
                  <div className='col-sm-10'>
                    <div className='checkbox'>
                      <label>
                        <input
                          name='notifications_active'
                          checked={this.state.assetType.notifications_active}
                          type='checkbox'
                          onChange={this.onChange}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                {this.state.assetType.notifications_active && (
                  <div className='form-group'>
                    <label className='col-sm-2'></label>
                    <div className='col-sm-10'>
                      <Notifications
                        {...this.props}
                        alerts={this.state.listACAlerts}
                        onUpdate={this.onUpdateNotification}
                      />
                    </div>
                  </div>
                )}

                <div className='col-sm-1' />
              </div>

              <div
                className='box-footer'
                style={{ justifyContent: "center", display: "flex" }}
              >
                <button
                  type='submit'
                  onClick={this.handleNewAssetTypeClick}
                  className='btn btn-info pull-right'
                  style={{ marginRight: "100px", padding: "6px 25px" }}
                >
                  {strings.save}
                </button>
                <button
                  className='btn btn-default pull-left'
                  style={{ padding: "6px 25px", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    goToAssetTypes();
                  }}
                >
                  {strings.cancel}
                </button>
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                {this.getErrorMessage()}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default Translate(AssetTypesManagePresentation, "AssetTypesManage");
