

import React from 'react';
import PropTypes from 'prop-types';

const TitlePage = props => {
  const { title, newActionLabel, children, showBtn } = props;
  const goToLink = (event) => {
    event.preventDefault();
    props.goTo();
  }
  return <section className="content-header">
        <h1 className="title">{title}</h1>
        <div className="actions">
          {children}
          {showBtn && <a href="" onClick={(event) => {goToLink(event)}} className=" btn btn-primary">
            {newActionLabel}
          </a>}
        </div>
    </section>;
};

TitlePage.propTypes = {
  title: PropTypes.string,
  newActionLabel: PropTypes.string,
  showBtn: PropTypes.bool
};

TitlePage.defaultProps = {
  title: '',
  newActionLabel: '',
  showBtn: true
};

module.exports = TitlePage;