
/*
  User ----*> Accounts ----*> Roles(APP + ROLE)
*/


/* -------- ROLES -------- */
export const ROLE_ADMIN={
  name:"ADMIN"
}
export const ROLE_USER={
  name:"USER"
}
export const ROLE_MONITOR={
  name:"MONITOR"
}
export const ROLE_ENGINEERING={
  name:"ENGINEERING"
}
/* ----------------------- */

/* -------- APPS -------- */
export const APP_ADMIN={
  id:"adminapp",
  name:"ADMIN",
  roles:[ROLE_ADMIN,ROLE_USER,ROLE_MONITOR]
};
export const APP_IOT={
  id:"iotapp",
  name:"IOT",
  roles:[ROLE_ADMIN,ROLE_ENGINEERING,ROLE_USER]
};
export const APP_BIKES={
  id:"bikesapp",
  name:"BIKES",
  roles:[ROLE_ADMIN,ROLE_USER]
}
export const APP_ASSET={
  id:"assetsapp",
  name:"ASSET",
  roles:[ROLE_ADMIN,ROLE_USER]
}
export const APP_PARKING={
  id:"parkingapp",
  name:"PARKING",
  roles:[ROLE_ADMIN,ROLE_USER]
}
export const APP_WASTE={
  id:"wasteapp",
  name:"WASTE",
  roles:[ROLE_ADMIN,ROLE_USER]
}
export const APP_DEEP={
  id:"deepapp",
  name:"DEEP",
  roles:[ROLE_ADMIN,ROLE_USER]
}
/* ----------------------- */

export default new class {

  getAllApps(){
    return {APP_ADMIN,APP_IOT,APP_BIKES,APP_ASSET,APP_PARKING,APP_WASTE,APP_DEEP}
  }

  getAllRoles(){
    return {ROLE_ADMIN,ROLE_USER,ROLE_MONITOR,ROLE_ENGINEERING}
  }

  getCurrentAccount(user){
    const currentRol = this.getAccounts(user).filter(r=>r.account==user.currentAccount)[0]
    return currentRol
  }

  getCurrentRoles(user){
    return this.getCurrentAccount(user).roles.map(r=>this.parseRole(r))
  }

  getAccounts(user){
    return user.rolesList.roles
  }

  hasApp(user,app){
    return this.getCurrentRoles(user).filter(r=>r.role_app==app.name)[0]
  }

  hasAppRole(user,app,role){
    return this.getCurrentRoles(user).filter(r=>r.role_app==app.name && r.role_role==role.name)[0]
  }

  parseRole(role){
    var parsedRole={}
    parsedRole.role_app=role.role.split("_")[0]
    parsedRole.role_role=role.role.split("_")[1]
    return Object.assign({},role,parsedRole)
  }

}
