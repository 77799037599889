import { connect } from "react-redux";
import { push } from "react-router-redux";
import AssetsManagePresentation from "./AssetsManagePresentation";
import * as assetsListActions from "../../redux/actionCreators/assetsListActionCreators";
import routes from "../../utils/routes";
import helpers from "../../utils/helpers";

const mapStateToProps = function (state, ownProps) {
  var assetId = helpers.queryStringToJSON(ownProps.location.search).id;
  var asset;
  if (assetId) asset = state.assetsList.list.filter((p) => p.id == assetId)[0];
  return {
    assetId: assetId,
    loggedUser: state.loggedUser,
    isEditing: assetId ? true : false,
    asset: state.assetSelected.assetSelected,
    listModes: state.assetsList.listModes,
    listStatus: state.assetsList.listStatus,
    tank: state.assetsList.tank,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    goToAssets: () => dispatch(push(routes.routeAssets())),
    goToAsset: (id) => dispatch(push(routes.routeAsset(id))),
    goToHome: () => dispatch(push(routes.routeHome())),
    setAsset: (asset) => dispatch(assetsListActions.setAsset(asset)),
    editAsset: (asset) => dispatch(assetsListActions.editAsset(asset)),
    getModes: () => dispatch(assetsListActions.getModes()),
    getStatus: () => dispatch(assetsListActions.getStatus()),
    getTankInformation: (client_id) =>
      dispatch(assetsListActions.getTanksInformation(client_id)),
  };
};

const AssetsManageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetsManagePresentation);

export default AssetsManageContainer;
