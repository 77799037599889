import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import View from './view';
import * as tags from '../../redux/actionCreators/tagsActionCreators'
import routes from "../../utils/routes"

const mapStateToProps = function(state) {
  return {
    listTags: state.tag.listTags,
    wasEdit: state.tag.wasEdit
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    getTags: (offset = 0, limit = 50) => dispatch(tags.getTags(offset, limit)),
    getTagsFilter: (method) => dispatch(tags.getTagsFilter(method)),
    goToHome:()=>dispatch(push(routes.routeHome())),
    goNew:()=>dispatch(push(routes.routeTag())),
    saveTag: (data, editInline) => dispatch(tags.postTag(data, editInline)),
    changeStatusEdit: () => dispatch(tags.changeStatus()),
  }
}

const TagsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(View)

export default TagsContainer
