import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import ClientsManagePresentation from './ClientsManagePresentation'
import * as clientsListActions from "../../redux/actionCreators/clientsListActionCreators"
import routes from "../../utils/routes"
import helpers from "../../utils/helpers"

const mapStateToProps = function(state, ownProps) {
  var clientId = helpers.queryStringToJSON(ownProps.location.search).id
  var client
  if(clientId && state.clientsList)
    client = state.clientsList.filter(cli=>cli.id==clientId)[0]

  return {
    clientId: clientId,
    isEditing: clientId? true:false,
    client: client,
    loggedUser:state.loggedUser
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    goToClients:()=>dispatch(push(routes.routeClients())),
    goToHome:()=>dispatch(push(routes.routeHome())),
    setClient:(client,isUser)=>dispatch(clientsListActions.setClient(client,isUser))
  }
}

const ClientsManageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientsManagePresentation)

export default ClientsManageContainer
