import { createAction } from 'redux-actions'
import {SET_OPERATORS} from "../actionTypes/operatorsListActions"
import api from "../../utils/api"
import routes from "../../utils/routes"
import { push } from 'react-router-redux'

export const setOperators=createAction(SET_OPERATORS)

export const fetchOperators = (offset=null,limit=null) => {
  return (dispatch, getState) => {
    var operators=getState().operators

    console.log("fetchOperators: offset:" + offset + " limit:" + limit +
                " status: " + operators.status + "filters:", operators.filters)

    return  _getOperators(operators.filters,operators.status,offset,limit)
              .then(data=>dispatch(setOperators(data)))

  }
}


const _getOperators=({search},status,offset,limit)=>{

  if(search)
    return api.getOperatorsSearch(search,status,offset,limit)

  return api.getOperators(status,offset,limit)

}

export const setOperator = (op,isUser) => {
  return (dispatch, getState) => {
    return  api.setOperator(op)
                .finally(data=>{isUser? "":dispatch(push(routes.routeOperators()))})
  }
}
