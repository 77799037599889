import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import DevicesPresentation from './DevicesPresentation.js'
import * as devicesList from "../../redux/actionCreators/devicesListActionCreators.js"
import * as devicesActions from "../../redux/actionCreators/devicesActionCreators.js"
import * as clientsActions from "../../redux/actionCreators/clientsActionCreators.js"
import * as operatorsActions from "../../redux/actionCreators/operatorsActionCreators"
import routes from "../../utils/routes"

const mapStateToProps = function(state) {
  return {
    devices: state.devicesList,
    page: state.devices.page,
    status: state.devices.status,
    loading:state.devices.loading,
    loggedUser:state.loggedUser,
    searchView:state.devices.filters.searchView,
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    nextPage:()=>dispatch(devicesActions.nextPage()),
    backPage:()=>dispatch(devicesActions.backPage()),
    setActiveStatus:()=>dispatch(devicesActions.setActiveStatus()),
    setInactiveStatus:()=>dispatch(devicesActions.setInactiveStatus()),
    refreshDevices:()=>dispatch(devicesActions.refreshDevices()),
    setSearchView:(query)=>dispatch(devicesActions.setSearchView(query)),
    refreshOperators:()=>dispatch(operatorsActions.refreshOperators()),
    setSearchFilter:(query)=>dispatch(devicesActions.setSearchFilter(query)),
    fetchDevices:()=>dispatch(devicesList.fetchDevices()),
    goToDevice:(id)=>dispatch(push(routes.routeDevice(id))),
    routeRegisterSingleDevice:()=>dispatch(push(routes.routeRegisterSignle())),
    goToHome:()=>dispatch(push(routes.routeHome())),
    refreshClients:()=>dispatch(clientsActions.refreshClients()),
  }
}

const DevicesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicesPresentation)

export default DevicesContainer
