import React from 'react';
import Redirect404 from "./Redirect404"
import { connect } from 'react-redux'

export default (WrappedComponent, allowedRoles, redirectTo404=false) => {
  var Auth = class WithAuthorization extends React.Component {
    constructor(props) {
      super(props)
      this.hasAnyRoleAllowed=this.hasAnyRoleAllowed.bind(this)
    }
    hasAnyRoleAllowed(){
      if (allowedRoles.indexOf(this.props.loggedUser.role) !== -1) {
          return true
      }
      return false
    }
    render() {
      if (this.hasAnyRoleAllowed()){
        return <WrappedComponent {...this.props} />
      } else if(redirectTo404){
        return <Redirect404 />
      } else {
        return null
      }
    }
  }

  const mapStateToProps = function(state) {
    return {
      loggedUser:state.loggedUser
    }
  }

  const mapDispatchToProps = function(dispatch)  {
    return {
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Auth)
}


/*
  Si el usuario logueado puede tener muchos roles usar esto:

  this.state = { // Los roles se van a sacar del usuario logueado
    user: {
      name: 'vcarl',
      roles: ["admin","operador","secretario"]
    }
  }

  hasAnyRoleAllowed(){
    const { roles } = this.state.user

    for (var i = 0; i < roles.length; i++) {
        if (allowedRoles.indexOf(roles[i]) !== -1) {
            return true
        }
    }
    return false
  }

  //souldComponentUpdate si cambian los roles sino no

*/
