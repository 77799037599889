import React, { useState } from "react";
import Translate from "../shared/i18n/Translate";
import { Input, Spin } from "antd";
import "antd/lib/input/style/css";
import api from "../../utils/api";
import FileSaver from "file-saver";
import "./refill.css";

const AssetRefillPresentation = ({ loggedUser }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUploading(false); // Reset the progress when a new file is selected
    setMessage({ type: "", text: "" });
  };

  const handleUpload = () => {
    if (!file) return;
    setUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      api
        .uploadRefillExcel(loggedUser.clientId, formData)
        .then((res) => {
          FileSaver.saveAs(res, "assets-refill.xlsx");
          setMessage({ type: "success", text: "File uploaded successfully!" });
        })
        .catch(() => {
          setMessage({
            type: "error",
            text: "Error: Uploading file. Please try again later.",
          });
        })
        .finally(() => setUploading(false));
    } catch (error) {
      setMessage({
        type: "error",
        text: "An error occurred while uploading the file.",
      });
      setUploading(false);
      console.error("Upload error:", error);
    }
  };

  return (
    <div>
      <section
        className='content-header'
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1>
          Import Measurement
          <small></small>
        </h1>
      </section>

      <section className='content'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='box box-primary'>
              <div className='box-body'>
                <div className='col-md-3' />
                <div className='col-md-6 box-uploader'>
                  <div className='box-upload'>
                    <label htmlFor='file'>Excel file</label>
                    <Input
                      type='file'
                      name='file'
                      id='file'
                      disabled={uploading}
                      onChange={handleFileChange}
                      placeholder='default size'
                    />
                    <button
                      className='btn btn-primary btn-lg'
                      onClick={handleUpload}
                      disabled={!file || uploading}
                    >
                      Upload
                    </button>
                  </div>
                  {uploading && <Spin tip='Uploading..!!' />}
                  <div className='explanation'>
                    <h4>
                      Select the excel spreadsheet containing the measurement of
                      the injection points to be incorporated.
                    </h4>
                    <h4>
                      If you want to generate a new spreadsheet to complete. go
                      to Assets monitoring, filter the assets to include in the
                      spreadsheet and export the result to excel.
                    </h4>
                  </div>

                  {message.type !== "" && (
                    <div className='message'>
                      <div
                        className={`alert alert-${message.type}`}
                        role='alert'
                      >
                        <i
                          className={`fa fa-${
                            message.type === "success"
                              ? "check"
                              : "exclamation-circle"
                          } fa-4x`}
                        />
                        <strong>{message.text}</strong>
                      </div>
                      {message.type === "success" && (
                        <div className='message-tip'>
                          Please check the processed result inthe spreadsheet
                          located in the Downloads folder of your computer.
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className='col-md-3' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Translate(AssetRefillPresentation, "AssetManage");
