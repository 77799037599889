import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import routes from "../../utils/routes"
import InboxPresentation from './InboxPresentation.js'
import * as inboxActions from "../../redux/actionCreators/inboxActionCreators.js"
import * as chatActions from "../../redux/actionCreators/chatActionCreators.js"
import * as conversationsActions from "../../redux/actionCreators/conversationsActionCreators.js"

const mapStateToProps = function(state) {
  return {
    conversations: state.conversations,
    page: state.inbox.page,
    folder: state.inbox.folder,
    loading:state.inbox.loading,
    unreaded:state.inbox.filters.unreaded,
    searchView:state.inbox.filters.searchView,
    indicators:state.indicators
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    nextPage:()=>dispatch(inboxActions.nextPage()),
    backPage:()=>dispatch(inboxActions.backPage()),
    setJunkFolder:()=>dispatch(inboxActions.setJunkFolder()),
    setInboxFolder:()=>dispatch(inboxActions.setInboxFolder()),
    refreshConversations:()=>dispatch(inboxActions.refreshInbox()),
    goToConversation:(conv)=>dispatch(chatActions.goToConversation(conv)),
    setUnreadedFilter:()=>dispatch(inboxActions.setUnreadedFilter()),
    setSearchView:(query)=>dispatch(inboxActions.setSearchView(query)),
    setSearchFilter:(query)=>dispatch(inboxActions.setSearchFilter(query)),
    fetchConversations:()=>dispatch(conversationsActions.fetchConversations()),
    goToHome:()=>dispatch(push(routes.routeHome())),
  }
}

const InboxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InboxPresentation)

export default InboxContainer
