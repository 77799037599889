import React from 'react';

const ViewButtons = ({ view, setView }) => {
  return (
    <div style={{ marginLeft: "auto" }}>
      <button
        type='button'
        className={`btn btn-default ${view === "timeseries" ? "btn-active" : ""}`}
        onClick={() => setView("timeseries")}
      >
        <i className='fa fa-line-chart' />
      </button>

      <button
        type='button'
        className={`btn btn-default ${view === "table-refills" ? "btn-active" : ""}`}
        onClick={() => setView("table-refills")}
      >
        <i className='fa fa-table' />
      </button>

      <button
        type='button'
        className={`btn btn-default ${view === "total-refills" ? "btn-active" : ""}`}
        onClick={() => setView("total-refills")}
      >
        <i className='fa fa-calculator' />
      </button>
    </div>
  );
};

export default ViewButtons;
