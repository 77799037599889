import { createAction } from 'redux-actions'
import { SET_ASSETTYPES, SET_ASSET_CLASSES, SET_MANUFACTURES } from "../actionTypes/assetTypesListActions"
import api from "../../utils/api"
import routes from "../../utils/routes"
import { push } from 'react-router-redux'
import { dataAssettypes, dataAssetClasses, dataManufactures } from './mocks'
export const setAssetTypes = createAction(SET_ASSETTYPES)
export const setAssetClasses = createAction(SET_ASSET_CLASSES)
export const setManufactures = createAction(SET_MANUFACTURES)

export const fetchAssetTypes = (offset = null, limit = null) => {
  return (dispatch) => {
    console.log("fetchAssetTypes: offset:" + offset + " limit:" + limit)
    return _getAssetTypes(offset, limit)
      .then(data => dispatch(setAssetTypes(data)))
  }
}

export const getAssetTypes = (clientId, offset = null, limit = null) => {
  return (dispatch) => {
    return api.getAssetTypes(clientId, offset, limit)
      .then(data => dispatch(setAssetTypes(data)));
  }
}

export const getAssetClasses = (offset = null, limit = null) => {
  return (dispatch) => {
    return api.getAssetClasses()
      .then(data => dispatch(setAssetClasses(data)));
  }
}

export const getManufactures = (offset = null, limit = null) => {
  return (dispatch) => {
    return api.getAssetManufactures()
      .then(data => dispatch(setManufactures(data)));
  }
}

const _getAssetTypes = (offset, limit) => {
  return api.getAssetTypes("1", offset, limit)
}

export const setAssetType = (id, at) => {
  return (dispatch, getState) => {
    return api.setAssetType(id, at)
      .then(data => dispatch(push(routes.routeAssetTypes())))
  }
}

export const editAssetType = (id, at) => {
  return (dispatch, getState) => {
    return api.editAssetType(id, at)
      .then(data => dispatch(push(routes.routeAssetTypes())))
  }
}
