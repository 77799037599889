import React, { Component } from "react";

class QuickEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descr: "",
    };
    this.onSave = this.onSave.bind(this);
    this.onHandlerChange = this.onHandlerChange.bind(this);
  }

  onSave(event) {
    event.preventDefault();
    const { task, loggedUser } = this.props;

    const data = {
      client_id: task.client_id,
      timelineType: 4,
      timeline_user: loggedUser.email,
      decr: this.state.descr,
      aud_time: new Date().getTime(),
      ident: loggedUser.name,
    };
    this.props.saveQEvent(data);
    this.setState({
      descr: "",
    });
  }

  onHandlerChange(event) {
    this.setState({
      descr: event.target.value,
    });
  }

  render() {
    const { strings } = this.props;

    return (
      <div className='box box-primary'>
        <div className='box-header with-border'>
          <i className='fa fa-calendar'></i>
          <h3 className='box-title'>{strings.quickEvent}</h3>
        </div>
        <div className='box-body'>
          <form role='form'>
            <div className='form-group'>
              <textarea
                className='form-control'
                onChange={this.onHandlerChange}
                rows='3'
                placeholder={strings.enterdesc}
                defaultValue={this.state.descr}
              />
            </div>
            <div className='box-footer zd-box-footer'>
              <button
                type='submit'
                className='btn btn-info'
                onClick={(e) => {
                  this.onSave(e);
                }}
              >
                {strings.add}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

module.exports = QuickEvent;
