import React from "react"
import ReactDOMServer from 'react-dom/server';
import { Link } from 'react-router-dom'
import routes from "../../utils/routes"


export default class extends React.Component{
  constructor(props){
    super(props)
    this.state=this.getInitialState(props)
  }
  getInitialState(){
    return {}
  }
  componentDidMount() {
    const {lat, lng, zoom=16, mapStatic=false,ports,goToPortsManage} = this.props;

      var mapOptions={
        center: {lat: lat, lng: lng},
        zoom: zoom,
        scrollwheel: true,
      }

      this.map = new window.google.maps.Map(this.map,mapOptions)


      var points = ports
      /*
      var points = [
        {
           center: {lat: -34.629412, lng: -58.42701},
           population: 2714856
         },
         {
           center: {lat: -34.620009, lng: -58.46735},
           population: 8405837
         },
        {
           center: {lat: -34.594577, lng: -58.397655},
           population: 3857799
         },
          {
           center: {lat: -34.623400, lng: -58.377743},
           population: 603502
         }
       ]
       */

       var contentString = '<div id="content">'+
            '<div id="siteNotice">'+
            '</div>'+
            '<h1 id="firstHeading" class="firstHeading">Uluru</h1>'+
            '<div id="bodyContent">'+
            '<p><b>Uluru</b>, also referred to as <b>Ayers Rock</b>, is a large ' +
            'sandstone rock formation in the southern part of the '+
            'Northern Territory, central Australia. It lies 335&#160;km (208&#160;mi) '+
            'south west of the nearest large town, Alice Springs; 450&#160;km '+
            '(280&#160;mi) by road. Kata Tjuta and Uluru are the two major '+
            'features of the Uluru - Kata Tjuta National Park. Uluru is '+
            'sacred to the Pitjantjatjara and Yankunytjatjara, the '+
            'Aboriginal people of the area. It has many springs, waterholes, '+
            'rock caves and ancient paintings. Uluru is listed as a World '+
            'Heritage Site.</p>'+
            '<p>Attribution: Uluru, <a href="https://en.wikipedia.org/w/index.php?title=Uluru&oldid=297882194">'+
            'https://en.wikipedia.org/w/index.php?title=Uluru</a> '+
            '(last visited June 22, 2009).</p>'+
            '</div>'+
            '</div>';




       for (var i = 0; i < points.length; i++) {

         var a = (points,i)=>{
           var point = points[i]

            var marker = new google.maps.Marker({
                  position: {lat: parseFloat(point.lat), lng: parseFloat(point.lon)},
                  map: this.map,
                  title: 'Hello World!'
                });
                /* to={routes.routePortsManageId(point.id)} */
            var myComponent = <div style={{display:"flex",flexDirection:"column"}}>
              <div style={{display:"flex",marginTop:"3px"}}>
                <div style={{fontWeight:"500",fontSize:"16px"}}>{"id: "}</div>
                <div style={{fontSize:"15px",marginLeft:"5px"}}>{point.id}</div>
              </div>
              <div style={{display:"flex",marginTop:"3px"}}>
                <div style={{fontWeight:"500",fontSize:"16px"}}>{"Nombre: "}</div>
                <div style={{fontSize:"15px",marginLeft:"5px"}}>{point.name}</div>
              </div>
                      <div style={{display:"flex",marginTop:"3px"}}>
                <div style={{fontWeight:"500",fontSize:"16px"}}>{"Lat: "}</div>
                <div style={{fontSize:"15px",marginLeft:"5px"}}>{point.lat}</div>
              </div>
                    <div style={{display:"flex",marginTop:"3px"}}>
                <div style={{fontWeight:"500",fontSize:"16px"}}>{"Depth Formula Type: "}</div>
                <div style={{fontSize:"15px",marginLeft:"5px"}}>{point.depth_formula_type}</div>
              </div>
                    <div style={{display:"flex",marginTop:"3px"}}>
                <div style={{fontWeight:"500",fontSize:"16px"}}>{"Mapid: "}</div>
                <div style={{fontSize:"15px",marginLeft:"5px"}}>{point.mapid}</div>
              </div>
              <div style={{display:"flex",marginTop:"3px"}}>
                <div style={{fontWeight:"500",fontSize:"16px"}}>{"Sensores: "}</div>
                <div style={{fontSize:"15px",marginLeft:"5px"}}>{(point.depthConfig.sensors? " ":"-")}</div>
              </div>
              {point.depthConfig.sensors && point.depthConfig.sensors.length>0 && point.depthConfig.sensors.map(s=><div style={{display:"flex",flexDirection:"column"}}>
                <div style={{display:"flex",marginLeft:"20px",marginTop:"3px"}}>
                  <div style={{fontWeight:"500",fontSize:"14px"}}>{"Sensor: "}</div>
                  <div style={{fontSize:"14px",marginLeft:"5px"}}>{(s.sensorId)}</div>
                </div>

                <div style={{display:"flex",marginLeft:"20px",marginTop:"3px"}}>
                  <div style={{fontWeight:"500",fontSize:"14px"}}>{"Priority: "}</div>
                  <div style={{fontSize:"14px",marginLeft:"5px"}}>{(s.priority)}</div>
                </div>

                <div style={{display:"flex",marginLeft:"20px",marginTop:"3px"}}>
                  <div style={{fontWeight:"500",fontSize:"14px"}}>{"Offset: "}</div>
                  <div style={{fontSize:"14px",marginLeft:"5px"}}>{(s.depthOffset)}</div>
                </div>


              </div>)}
              <div className="btnEditPortMap" id={"info"+point.id} >Editar</div>
            </div>
              var infowindow = new window.google.maps.InfoWindow({
                content: ReactDOMServer.renderToString(myComponent),
                position: {lat: parseFloat(point.lat), lng: parseFloat(point.lon)},
                //maxWidth: 500
              });
           /*
           var circle = new window.google.maps.Circle({
             strokeWeight: 0,
             fillColor: '#FF0000',
             fillOpacity: 0.29,
             clickable: true,
             map:this.map,
             center: {lat: parseFloat(point.lat), lng: parseFloat(point.lon)},
             radius: circleSize,
             title: "Title",
             cursor: 'pointer'
           })

           */




           marker.addListener('click', function(e) {
             setTimeout(()=>{

              document.getElementById("info"+point.id).addEventListener("click", function(){
                  goToPortsManage({id:point.id})
              });

            },200)

             infowindow.open(this.map, marker);
             infowindow.setPosition(marker.position)
             //this.map.setZoom(8);
             //this.map.setCenter(circle.getCenter());
           });


           this.map.addListener('click', function() {
             infowindow.close();
           });

         }
         a(points,i)
     }

  }
  render() {
    return (
      <div className={this.props.className}>
        <div className={this.props.className} ref={i=>this.map=i}></div>
      </div>
    )
  }
}
