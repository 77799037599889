import React,{Fragment} from 'react'
import helpers from "../../utils/helpers"
import JSONPretty from 'react-json-pretty'
import api from "../../utils/api"
import moment from "moment"

var AssignDeviceView = class extends React.Component {
  constructor(props){
    super(props)
    this.state={clients:[]}
    this.handleAssign=this.handleAssign.bind(this)
  }
  componentDidMount(){
    api.getClientsMove().then((data)=>{
      this.setState({clients:data})
      console.log("clients",data)
    })
  }
  handleAssign(){
    const client=this.state.clients.filter(cli=>cli.id==this.clients.value)[0]
    console.log("ASSIGN",client)

    const params={
      currentaccount:this.props.loggedUser.currentAccount,
      newaccount:client.id,
      newaccountName:client.company,
      userid:this.props.loggedUser.email,
      username:this.props.loggedUser.name,
      deviceid:this.props.deviceId
    }
    api.setAssignDevice(params)
    this.props.goToDevices()
  }
  render(){
    const {strings,device,deviceId,content,loggedUser,setDeviceView}=this.props
    const {clients}=this.state

console.log(clients)
    return (
      <div className="box-body" style={{padding:"0"}}>
        <section className="content-header" style={{display:"flex",justifyContent:"space-between"}}>
          <h1 style={{marginBottom:"15px"}}>
            {content.title}
            <small></small>
          </h1>
        </section>

        <div className="form-group" style={{marginTop:"3px"}}>
          <label className="col-sm-1 control-label">{"Assign to"}</label>
          <div className="col-sm-5">
            <select ref={(i)=>this.clients=i} className="form-control" disabled="">
              {clients.map((cli,i)=><option key={i} value={cli.id}>{cli.company}</option>)}
            </select>
          </div>
        </div>

        <div className="box-footer" style={{textAlign:"center",maxWidth:"300px",border:"none",marginTop:"40px"}}>
          <button type="submit" onClick={this.handleAssign}
            className="btn btn-info pull-right" style={{padding:"6px 25px"}}>{"Assign"}</button>
            <button onClick={()=>setDeviceView("details")} className="btn btn-default pull-left" style={{padding:"6px 25px"}}
              style={{"cursor":"pointer"}}>{"Cancel"}</button>
            </div>
    </div>
  )
}
}

export default AssignDeviceView
