export default {
  Login: {
    logIn: "Sign In",
    email: "Email",
    password: "Password",
    badLoginMsg: "Wrong email or password. Try again.",
  },
  Inbox: {
    inbox: "Inbox",
    junk: "Junk",
    newSms: "New SMS",
    newMessages: "New messages",
    search: "Search",
    folders: "Folders",
    unreadFilter: "Filter by unread",
    insertTag: "Enter a tag...",
  },
  Breadcrumb: {
    home: "home",
    ports: "Ports",
    devices: "Devices",
    inbox: "SMS Inbox",
    junk: "SMS Junk",
    users: "Users",
    newOperator: "New User",
    citizens: "Citizens",
    conversation: "Conversation",
  },
  Header: {
    logOut: "Log out",
  },
  Footer: {
    version: "Version",
  },
  Dashboard: {
    dashboard: "Dashboard",
    locations: "Locations",
    mode: "Assets By Automation Mode",
    status: "Assets By Status",
    type: "Assets By Type",
    assetsNot: "Offline Assets",
    openTasks: "High Priority Open Tasks",
    assets: "Assets",
    manufacter: "Assets By Manufacturer",
    locations_tasks: "High Priority Open Tasks By Location",
    all: "All",
    actives: "Actives",
    assetsByLocation: "Assets By Location",
    assetsByContent: "Assets By Content",
    assetsByContentType: "Assets By Content Type",
    select: "Select...",
  },
  NavigationMenu: {
    dashboard: "Dashboard",
    assets: "Assets Monitoring",
    erpIntegration: "ERP integration",
    locations: "Locations",
    assetTypes: "Asset Types",
    accounts: "Organizations",
    search: "Search...",
    online: "Online",
    new: "New",
    mainNavigation: "MAIN NAVIGATION",
    operationsConsole: "Operations Console",
    inbox: "Inbox",
    admin: "ADMIN",
    operators: "Operators",
    status: "Status",
    tags: "Tags",
    devops: "DevOps",
    deep: "DEEP",
    ports: "Ports",
    users: "Users",
    keys: "Keys",
    parking: "PARKING",
    slots: "Slots",
    bikesTitle: "BIKES",
    bikes: "Bikes",
    waste: "WASTE",
    trucks: "Trucks",
    bins: "Bins",
    iotdevices: "IOT DEVICES",
    devices: "Devices",
    gateways: "Gateways",
    mobileSupport: "Mobile Support",
    supportSessions: "Support Sessions",
    roles: "Roles",
    datasources: "Data Sources",
    tags: "Tags",
    taskTemplates: "Task Templates",
    tasks: "Tasks Monitoring",
  },
  Operators: {
    operatorManagement: "Operator Management",
    newOperator: "New Operator",
    activeOperators: "Active Operators",
    inactiveOperators: "Inactive Operators",
    search: "Search",
    status: "Status",
    active: "Active",
    inactive: "Inactive",
  },
  OperatorsManage: {
    newOperator: "New Operator",
    editOperator: "Edit Operator",
    name: "Name",
    email: "Email",
    rol: "Rol",
    timezone: "Timezone",
    password: "Password",
    active: "Active",
    cancel: "Cancel",
    save: "Save",
    badDataMsg: "You must enter a ",
    badDataMsgValid: " Valid",
    account: "Account",
  },
  Clients: {
    clientManagement: "Organization Management",
    newClient: "New Organization",
    activeClients: "Active Organizations",
    inactiveClients: "Inactive Organizations",
    search: "Search",
    status: "Status",
    active: "Active",
    inactive: "Inactive",
  },
  ClientsManage: {
    newClient: "New Organization",
    generateToken: "Generate",
    editClient: "Edit Organization",
    company: "Company",
    assetToken: "Asset App Key",
    adminApp: "Admin App Key",
    adminAppActive: "Admin App",
    iotApp: "IoT App Key",
    inputIoTAppActive: "IoT App",
    assetTokenActive: "Asset App",
    deepTokenActive: "Deep App",
    parkingTokenActive: "Parking App",
    wasteTokenActive: "Waste App",
    bikesTokenActive: "Bikes App",
    deepToken: "Deep App Key",
    parkingToken: "Parking App Key",
    bikesToken: "Bikes App Key",
    wasteToken: "Waste App Key",
    name: "Name",
    email: "Email",
    rol: "Rol",
    timezone: "Timezone",
    password: "Password",
    active: "Active",
    cancel: "Cancel",
    save: "Save",
    badDataMsg: "You must enter a ",
    badDataMsgValid: " Valid",
    create: "Create",
  },
  Ports: {
    portManagement: "Ports",
    newPort: "New Port",
    id: "id",
    name: "Name",
    delete: "Delete",
  },
  PortsManage: {
    newPort: "New Port",
    editPort: "Edit Port",
    name: "Name",
    ports: "Ports",
    lat: "Lat",
    lon: "Lon",
    depth_formula_type: "Depth Formula Type",
    mapid: "Mapid",
    sensors: "Sensors",
    sensor: "Sensor",
    priority: "Priority",
    offset: "Offset",
    cancel: "Cancel",
    save: "Save",
    offset: "Offset",
    add: "Add",
    remove: "Remove",

    badDataMsg: "You must enter a valid ",
    badDataMsgValid: " Valid",
  },
  DeviceManage: {
    newDevice: "New Device",
    editDevice: "Edit Device",
    registerDevice: "Registering",
    name: "Name",
    email: "Email",
    rol: "Rol",
    timezone: "Timezone",
    password: "Password",
    authorized: "Authorized",
    cancel: "Cancel",
    save: "Save",
    badDataMsg: "You must enter a valid ",
    badDataMsgValid: " Valid",

    cmmunicationType: "Communication Type",
    descr: "Description",
    deviceType: "Device Type",
    lat: "Latitude",
    lon: "Longitude",
  },
  ControlsMenu: {
    edit: "Edit",
    disable: "Disable",
    activate: "Activate",
    detail: "Details",
    messages: "Messages",
    reading: "Reading",
    information: "Information",
    debug: "Debug",
    engineering: "Engineering",
    hardwareVersion: "Hardware version",
    firmwareVersion: "Firmware version",
    updateFirmware: "Update Firmware",
    assignDevice: "Assign Device",

    layoutOptions: "Layout Options",
    inConstruction: "Under construction",
    newConversationsFirst: "New conversations first",
    putNewConversationFirst:
      "Put the new conversations on first place in the screen.",
    newMessagesArrives: "New messages arrives",
    activateConversationsFirst:
      "Activate the conversations with new messages in first place.",
    cleaning: "Cleaning",
    removeConversations: "Remove conversations",
    removeInactiveConversations:
      "Remove inactive conversations (30 mins inactivity).",
    minimizeAssignedConversations: "Minimize assigned conversations",
    minimizeConversationsBeingAnswered:
      "Minimize the conversation that are being answered by other agent.",
    timeline: "Timeline",
    assets: "Assets",
    analytics: "Analytics",
    dipstickCalibration: "Dipstick Calibration",
  },
  NotFoundPage: {
    inConstruction: "Under construction",
  },
  AppStatus: {
    title: "Platform Status",
  },
  Devices: {
    home: "Home",
    deviceManagement: "Device Management",
    status: "Status",
    active: "Active",
    authorized: "Authorized",
    deviceName: "Name",
    deviceSn: "SN",
    gateway: "Gateway",
    lastMsgTime: "Last Messsage Time",
    registerDeviceSingle: "Provisioning Single Device",
    reporting: "Reporting",
    lastRead: "Last read",
    reading: "Reading",
    healthy: "Healthy",
  },
  Device: {
    device: "Device ",

    lastValues: "Last Values",
    lastMessage: "Last Message",
    battery: "Battery",
    value: "Value",
    sn: "SN",
    name: "Name",
    type: "Type",
    source: "Source",
    gateway: "Gateway",

    newOperator: "New Operator",
    editOperator: "Edit Operador",
    name: "Name",
    email: "Email",
    rol: "Rol",
    timezone: "Timezone",
    password: "Password",
    active: "Active",
    cancel: "Cancel",
    save: "Save",
    badDataMsg: "You must enter a valid ",
    badDataMsgValid: " Valid",

    details: "Details",
    messages: "Messages",
    reading: "Reading",
    information: "Information",
    debug: "Debug",
    engineering: "Engineering",
    messageTime: "Message Time",
    protocol: "Protocol",
    addEvent: "Add Event",
    showLast: "Show last",
    days: "Days",
    health: "Device Health",

    totalMsgs: "Total Msg. ",
    lastReport: "Last Report",
    instDate: "Inst. Date",
    deviceInvalidReadings: "The device has had invalid readings in the last 24 hours.",
    deviceHealthIssues: "The device has reported in the last 24 hours on health issues that need attention.",
    reportingHealth: "Reporting Health",
    true: "True",
    false: "False",
    condition: "Condition",

    installation: "Installation",
    installationDate: "Install Date",
    activationDate: "Activation Date",
    lastReset: "Last Reset",
    batteryInstallDate: "Battery Install Date",

    chart: {
      lastValues: "Last Values",
      lastMessage: "Last Message",
      clickAndDrag: "Click and drag in the plot area to zoom in",
      pinchToZoom: "Pinch the chart to zoom in",
      temperature: "Temperature",
      values: "Values",
      battery: "Battery",
    }
  },
  PortDeleteModal: {
    areYouSure: "Are you sure you want to delete the port ",
    yes: "Yes",
    no: "No",
  },
  AssetTypeDeleteModal: {
    areYouSure: "Are you sure you want to delete the asset type ",
    yes: "Yes",
    no: "No",
  },
  AssetTypes: {
    id: "Id",
    description: "Description",
    long: "Height",
    width: "Width",
    assetTypes: "Asset Types",
    newAssetType: "New Asset Type",
    activeAssetTypes: "Active Asset Types",
    inactiveAssetTypes: "Inactive Asset Types",
    search: "Search",
    status: "Status",
    active: "Active",
    csa: "CSA",
    inactive: "Inactive",
    depth: "Depth",
    notification: "Notifications",
    class: "Class",
    description: "Description",
    manufacturer: "Manufacture",
  },
  AssetTypesManage: {
    assetTypes: "Asset Types",
    newAssetType: "New AssetType",
    editAssetType: "Edit AssetType",
    id: "Id",
    description: "Description",
    long: "Height (cm)",
    lenght: "Length (cm)",
    width: "Width (cm)",
    depth: "Depth",
    cancel: "Cancel",
    save: "Save",
    badDataMsg: "You must enter a ",
    badDataMsgValid: " Valid",
    notification: "Notifications & Alerts",
    galons: "Gallons",
    levelhight: "Level Hight",
    levellow: "Level Low",
    temphigh: "Temperature High",
    templow: "Temperature Low",
    batery: "Battery Low",
    tilthigh: "Tilt High",
    tiltlow: "Tilt Low",
    frecuence: "Device Report",
    class: "Class",
    selectClass: "Select Class",
    metrics: "Metrics",
    manufacture: "Manufacturer",
    manufacturecode: "Manufacturer code",
    manufacturedesc: "Manufacturer desc",
    selectManufacture: "Select Manufacturer",
    add_notification: "New Notification",
    field: "Field",
    compare: "Compare",
    value: "Value",
    severity: "Severity",
    title: "Title",
    text: "Text",
    short_text: "Short Text",
    transition: "Transition",
    alerts: (key) => {
      const c = {
        volumen: "Volumen",
        fill: "Fill Level",
        temperature: "Temperature",
        status: "IoT Device Status",
        health: "IoT Device Health",
        report: "IoT Device Reporting",
        compr_suction_pressure: "compr_suction_pressure",
        compr_discharge_pressure: "compr_discharge_pressure",
        comp_oil_level: "comp_oil_level",
        comp_liquid_sep_level: "comp_liquid_sep_level",
        comp_oil_pressure: "comp_oil_pressure",
        comp_temp_bco1: "comp_temp_bco1",
        comp_temp_bco2: "comp_temp_bco2",
        eng_oil_pressure: "eng_oil_pressure",
        eng_temperature: "eng_temperature",
        eng_oil_level: "eng_oil_level",
        eng_emergency_stop: "eng_emergency_stop",
        eng_manual_stop: "eng_manual_stop",
        eng_velocity: "eng_velocity",
        eng_pmr: "eng_pmr",
        flow_static_pressure: "flow_static_pressure",
        flow_diferential_pressure: "flow_diferential_pressure",
        flow_temperature: "flow_temperature",
        flow_rate_actual: "flow_rate_actual",
        enginestatus: "Engine Status",
        switchonoff: "Status of load and discharge ON/OFF",
        solar_battery: "Solar Powered Battery",
        flow: "Flow Rate",
        tics: "Tics",
        ticsperminute: "Tics per Minute",
        pumponoff: "Pump Status (ON|OFF)",
        cons_per_hour: "Consumption per hour",
        cons_per_day: "Consumption per day",
      };
      return c[key] ? c[key] : "";
    },
    confirmDelete: "Are you sure delete?",
    max_capacity: "Max capacity (lts)",
    tags: "Tags",
    selectTag: "Select tags",
    generateTask: "Generate Task?",
    task: "Task",
    selectTask: "Select a task",
    tankShape: "Tank shape (cm)",
    diameterTop: "Diameter top (cm)",
    diameterBottom: "Diameter bottom (cm)",
    conoHeight: "Cono Height (cm)",
  },
  LocationDeleteModal: {
    areYouSure: "Are you sure you want to delete the location ",
    yes: "Yes",
    no: "No",
  },
  Locations: {
    id: "Id",
    description: "Description",
    lat: "Latitude",
    lon: "Longitude",
    locations: "Locations",
    newLocation: "New Location",
    activeLocations: "Active Locations",
    inactiveLocations: "Inactive Locations",
    search: "Search",
    status: "Status",
    active: "Active",
    inactive: "Inactive",
  },
  LocationsManage: {
    locations: "Locations",
    newLocation: "New Location",
    editLocation: "Edit Location",
    id: "Id",
    description: "Description",
    lat: "Latitude",
    lon: "Longitude",
    depth: "Depth",
    csa: "CSA",
    cancel: "Cancel",
    save: "Save",
    badDataMsg: "You must enter a ",
    badDataMsgValid: " Valid",
  },
  Assets: {
    id: "Id",
    location: "Location",
    type: "Type",
    description: "Asset Name",
    status: "Reporting Status",
    assets: "Assets",
    newAsset: "New Asset",
    gallons: "Gallons",
    lastRead: "Last read",
    health: "Health",
    notification: "Notification",
    metrics: "Metrics",
    fill: "Fill Level",
    temperature: "Temperature",
    timestamp: "Last reading",
    volumen: "Volumen",
    days_to_reposition: "Days to reposition",
    expected_consumption_per_hour: "Expected consumption per hour",
    last_24hour_consumption: "Last 24 hours",
    last_hour_consumption: "Last hour",
    pumpOnOFF: "Pump On Off",
    tics_per_hour: "Tics per hour",
    on: "On",
    off: "Off",
    tics: "Pulses",
    flow: "Flow",
    level: "Level",
    content: "Content",
    class: "Class",
    ident: "Ident",
    import_measurements: "Import Measurements",
    filter_assets: "Filter Assets",
    name: "Name",
    select_automation_mode: "Select Automation Mode",
    select_app_point_status: "Select App Point Status",
    static: "Static",
    operated: "Operated",
    automated: "Automated",
    active: "Active",
    suspended: "Suspension",
    search: "Search",
    assets_by_well_zone: "Assets By Well Zone",
  },
  AssetDeleteModal: {
    areYouSure: "Are you sure you want to delete the asset ",
    yes: "Yes",
    no: "No",
  },
  Asset: {
    showLast: "Show last",
    days: "Days",
    manufacture: "Manufacture",
    manufacturecode: "Manufacture code",
    manufacturedesc: "Manufacture desc",
    device: "Device",
    on: "On",
    off: "Off",
    status: "Status",
    fill: "Fill Level",
    temperature: "Temperature",
    timestamp: "Last reading",
    volumen: "Liters",
    days_to_reposition: "Days to reposition",
    expected_consumption_per_hour: "Expected consumption per hour",
    last_24hour_consumption: "Last 24 hours consumption",
    last_hour_consumption: "Last hour consumption",
    pumpOnOFF: "Pump On Off",
    tics_per_hour: "Tics per hour",
    application: "Application",
    content: "Content",
    class: "Class",
    app_point: "App Point Status",
    treatment: "Treatment",
    type: "Type",
    mode: "Mode",
    dipstick_device_offset: "Dipstick / Device offset calibration",
    tank_height_config: "The configured tank height is",
    enter_dipstick_measurement: "Please enter the dipstick measurement (bottom to liquid) in cm",
    last_device_distance: "The last device distance from top to liquid was",
    dipstick_lecture: "Dipstick lecture",
    device_lecture: "Device lecture",
    adjustment: "Adjustment",
    the_actual_offset_is: "The actual offset is",
    the_suggested_offset_is: "The suggested offset is",
    close: "Close",
    centimeters: "Centimeters",
    recent_history: "Recent History",
    add_event: "Add Event",
    no_events_available: "No events available",
    view_all: "View all",
    load_more: "Load more",
    share_location: "Share Location",
    analytics: "Analytics",
    historic_values: "Historic Values",
    add_manual_reading: "Add Manual Reading",
    asset_refills: "Asset Refills",
    time_serie_tooltip: {
      start: "Start",
      end: "End",
      duration: "Duration",
      initial_volume: "Initial Volume",
      end_volume: "End Volume",
    },
    add_event_modal: {
      title: "Quick Event",
      description: "Description",
      type: "Type",
      save: "Save",
      cancel: "Cancel",
    },

    asset_tasks: {
      title: "Asset Tasks",
      add_task: "Add Task",
      code: "Code",
      enter_code: "Enter task code",
      description: "Description",
      enter_description: "Enter task description",
      priority: "Priority",
      planned_date: "Planned Date",
      select_date: "Select a date",
      assigned_to: "Assigned To",
      assigned_date: "Assigned Date",
      resolved_by: "Resolved By",
      resolved_date: "Resolved Date",
    }
  },
  AssetManage: {
    newAsset: "New Asset",
    editAsset: "Edit Asset",
    ports: "Assets",
    lat: "Lat",
    lon: "Lon",
    sensors: "Sensors",
    sensor: "Sensor",
    priority: "Priority",
    offset: "Offset",
    cancel: "Cancel",
    save: "Save",
    offset: "Offset",
    add: "Add",
    remove: "Remove",

    decr: "Description",
    ident: "Ident",
    location: "Location",
    assetType: "Asset Type",
    basic: "Basic",
    sensorRequired: "You must select at least one sensor",

    badDataMsg: "You must enter a valid ",
    badDataMsgValid: " Valid",
    notifications: "Notifications active",
    mode: "Automation Mode",
    status: "Status",
    select: "Select",
    content: "Content",
    class: "Class",
    app_point: "App Point Status",
    treatment: "Treatment",
    lat: "Lat",
    lon: "Lon",
    minInventory: "Min. Inventory (Lts)",
  },
  DataSource: {
    title: "DataSources",
    newDataSource: "new DataSource",
    new: "New",
    edit: "Edit",
    id: "ID",
    name: "Name",
    type: "Type",
    externalid: "External ID",
    lastseen: "Last Seen",
    authorized: "Authorized",
    home: "Home",
  },
  Tag: {
    title: "Tags",
    new: "New Tag",
    edit: "Edit",
    id: "ID",
    name: "Name",
    active: "Active",
    inactive: "Inactive",
    all: "All",
    filter: "Filter",
    platform: "Platform Tag",
    home: "Home",
    status: "Status",
    save: "Save",
    cancel: "Cancel",
  },
  Common: {
    areYouSure: "Estas seguro de eliminar",
    yes: "Si",
    no: "No",
  },
  TasksTemplates: {
    taskTemplates: "Task Templates",
    newTask: "New Task Template",
    editTask: "Edit Task Template",
    id: "ID",
    descr: "Description",
    code: "Code",
    trigger: "Fire Event",
    trigger_url: "Event Post URL",
    save: "Save",
    cancel: "Cancel",
    home: "Home",
    yes: "Yes",
    no: "No",
    areYouSure: "Are you sure you want to delete?",
    confirm: "Confirm",
  },
  Tasks: {
    tasks: "Tasks",
    newTask: "New Task",
    editTask: "Edit Task",
    id: "ID",
    save: "Save",
    cancel: "Cancel",
    home: "Home",
    yes: "Yes",
    no: "No",
    location: "Location",
    date: "Date",
    status: "Status",
    priority: "Priority",
    asset: "Asset",
    assetvalues: "Last Metrics",
    gallons: "Gallons",
    lastRead: "Last read",
    metrics: "Metrics",
    fill: "Fill Level",
    temperature: "Temperature",
    task: "Task",
    assignedto: "Assigned to",
    select: "Select",
    closed: "Closed",
    all: "All",
    order: "Order",
    search: "Search",
    clean: "Clean",
    descr: "Description",
    on: "On",
    plannedto: "Planned to",
    resolved: "Solved by",
    timeline: "Timeline",
    quickEvent: "Quick Event",
    add: "Add",
    edit: "Edit",
    code: "Code",
    createdAt: "Created at",
    enterdesc: "Enter desc...",
    filterTask: "Filter Tasks",
    close: "Close",
    more: "See More",
  },
};
