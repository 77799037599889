import { createStore, applyMiddleware, compose } from 'redux';
import { persistState } from 'redux-devtools';
import rootReducer from '../reducers/index.js'
import DevTools from './devTools';
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'react-router-redux'

export default function configureStore(history,initialState) {

  const enhancer = compose(
    applyMiddleware(thunkMiddleware,routerMiddleware(history)),
    DevTools.instrument(),
    persistState(getDebugSessionKey())
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )

  const store = createStore(rootReducer, initialState, enhancer);

  if (module.hot) {
    module.hot.accept('../reducers/index.js', function() {
        store.replaceReducer(require('../reducers/index.js').default)
      }
    );
  }

  return store;
}

function getDebugSessionKey() {
  const matches = window.location.href.match(/[?&]debug_session=([^&#]+)\b/);
  return (matches && matches.length > 0)? matches[1] : null;
}
