import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PortsPresentation from './PortsPresentation.js'
import * as portsList from "../../redux/actionCreators/portsListActionCreators"
import * as portsActions from "../../redux/actionCreators/portsActionCreators"
import routes from "../../utils/routes"

const mapStateToProps = function(state) {
  return {
    ports: state.portsList,
    page: state.ports.page,
    loading:state.ports.loading,
    view:state.ports.view
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    nextPage:()=>dispatch(portsActions.nextPage()),
    backPage:()=>dispatch(portsActions.backPage()),
    setView:(v)=>dispatch(portsActions.setView(v)),
    refreshPorts:()=>dispatch(portsActions.refreshPorts()),
    fetchPorts:()=>dispatch(portsList.fetchPorts()),
    goToPortsManage:(port)=>dispatch(push(routes.routePortsManage(port))),
    goToHome:()=>dispatch(push(routes.routeHome())),
  }
}

const PortsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PortsPresentation)

export default PortsContainer
