import { createAction } from "redux-actions";
import {
  NEXT_PAGE,
  BACK_PAGE,
  RESET_PAGE,
  ASSET_LOADING,
} from "../actionTypes/assetsActions";
import * as assetsListActions from "./assetsListActionCreators";
import api from "../../utils/api";

export const setNextPage = createAction(NEXT_PAGE);
export const setBackPage = createAction(BACK_PAGE);
export const setAssetsLoading = createAction(ASSET_LOADING);
export const setResetPage = createAction(RESET_PAGE);

export const refreshAssets = () => {
  return (dispatch, getState) => {
    const assets = getState().assets;
    if (assets.loading) return;

    dispatch(setAssetsLoading(true));

    return dispatch(assetsListActions.fetchAssets(0, 50))
      .then(() => dispatch(setResetPage()))
      .then(() => dispatch(setAssetsLoading(false)))
      .catch((err) => dispatch(setAssetsLoading(false)));
  };
};

export const setLoading = (isLoading) => {
  return (dispatch) => dispatch(setAssetsLoading(isLoading));
};

export const nextPage = () => {
  return (dispatch, getState) => {
    const assetsList = getState().assetsList;
    const assets = getState().assets;
    if (assetsList.length < 50 || assets.loading) return;

    dispatch(setAssetsLoading(true));

    return dispatch(
      assetsListActions.fetchAssets(assetsList.length * (assets.page + 1), 50)
    )
      .then(() => dispatch(setNextPage()))
      .then(() => dispatch(setAssetsLoading(false)));
  };
};

export const backPage = () => {
  return (dispatch, getState) => {
    const assetsList = getState().assetsList;
    const assets = getState().assets;
    if (assets.page == 0 || assets.loading) return;

    dispatch(setAssetsLoading(true));

    return dispatch(
      assetsListActions.fetchAssets(assetsList.length * (assets.page - 1), 50)
    )
      .then(() => dispatch(setBackPage()))
      .then(() => dispatch(setAssetsLoading(false)));
  };
};
