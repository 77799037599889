import { createAction } from "redux-actions";
import {
  SET_ASSETS,
  GET_MODES,
  GET_ASSET_STATUS,
  GET_INVENTORY,
  GET_TANK_INFORMATION,
  GET_ASSETS_LOADING,
} from "../actionTypes/assetsListActions";
import api from "../../utils/api";
import routes from "../../utils/routes";
import { push } from "react-router-redux";

export const setAssets = createAction(SET_ASSETS);
export const setAssetModes = createAction(GET_MODES);
export const setAssetStatus = createAction(GET_ASSET_STATUS);
export const setAssetsLoading = createAction(GET_ASSETS_LOADING);
export const setTankInformation = createAction(GET_TANK_INFORMATION);
export const setAssetInventory = createAction(GET_INVENTORY);

export const fetchAssets = (clientId, query, body) => {
  return (dispatch, getState) => {
    // var assets = getState().assets;
    dispatch(setAssetsLoading(true));
    return _getAssets(clientId, query, body)
      .then((data) => dispatch(setAssets(data)))
      .then(() => dispatch(setAssetsLoading(false)));
  };
};

export const fetchInventoryDeprecated = (offset = null, limit = null) => {
  return (dispatch, getState) => {
    console.log("fetchInventory: offset:" + offset + " limit:" + limit);
    return _getInventory(offset, limit).then((data) =>
      dispatch(setAssetInventory(data))
    );
  };
};
export const fetchInventory = (clientId, query, body) => {
  return (dispatch) => {
    console.log("fetchInventory: ", query, body);
    return _getInventory(clientId, query, body).then((data) =>
      dispatch(setAssetInventory(data))
    );
  };
};

const _getAssets = (client_id, query, body) => {
  return api.getAssets(client_id, query, body);
};

const _getInventory = (client_id, query, body) => {
  return api.getInventory(client_id, query, body);
};

export const setAsset = (asset) => {
  return (dispatch, getState) => {
    return api
      .setAsset(1, asset)
      .then((data) => dispatch(push(routes.routeAssets())));
  };
};

export const editAsset = (asset) => {
  return (dispatch, getState) => {
    return api.editAsset(1, asset);
    //.then(data=>dispatch(push(routes.routeAssets())))
  };
};

export const getModes = () => {
  return (dispatch) => {
    return _getModes().then((data) => dispatch(setAssetModes(data)));
  };
};

export const getStatus = () => {
  return (dispatch) => {
    return _getStatus().then((data) => dispatch(setAssetStatus(data)));
  };
};

const _getModes = () => {
  return api.getAssetModes();
};

const _getStatus = () => {
  return api.getAssetStatus();
};

export const getTanksInformation = (client_id) => {
  return (dispatch) => {
    return Promise.all([
      api.getTankData(client_id, "tankcontent"),
      api.getTankData(client_id, "tankcontenttype"),
      api.getTankData(client_id, "tankinyectionstatus"),
      api.getTankData(client_id, "tankinyectiontreatment"),
    ])
      .then(([content, contentType, inyectionStatus, inyectionTreatment]) => {
        dispatch(
          setTankInformation({
            content,
            contentType,
            inyectionStatus,
            inyectionTreatment,
          })
        );
      })
      .catch((error) => {
        console.error("Error fetching tank information:", error);
      });
  };
};
