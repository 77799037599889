import React from 'react'
import ReactDOM from  'reactDOM'
import helpers from "../../utils/helpers"
import timezoneHelper from "../../utils/timezoneHelper"
import Translate from '../shared/i18n/Translate'
import $ from 'jquery';
import api from "../../utils/api"
import {APP_ADMIN,ROLE_USER,APP_IOT,APP_BIKES,APP_ASSET,APP_PARKING,APP_WASTE,APP_DEEP} from "../../utils/roles"
import rolesUtils from "../../utils/roles"
import toastrUtils from "../../utils/toastrs"

var OperatorsManagePresentation = class extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      validation:true,
      inputNameValid:true,
      inputEmailValid:true,
      inputPasswordValid:true,
      preferencesOpen:false,
      clients:[],
      rolSelects:[],
      rolSelectsRestrictions:[],

      email:"",
      appsActive:[],
      appsActiveOpen:[]
    }
    this.handleNewChatClick=this.handleNewChatClick.bind(this)
    this.getErrorMessage=this.getErrorMessage.bind(this)
    this.onEmailChange=this.onEmailChange.bind(this)
    this.getOperatorFromEmail=this.getOperatorFromEmail.bind(this)
  }
  componentDidMount(){
    // Default values
    this.inputName.value=""
    //this.inputEmail.value=""
    this.inputRol.value="ADMIN" // TODO: Ojo aca cuando los roles no esten hardcodeados. Tomar el primero de la lista
    this.selectTimezone.value="(GMT-08:00) Pacific Time | America/Los_Angeles"
    this.inputCheckBox.checked=true
    this.inputPassword.value=""

    // Operator values (editing)
    if(this.props.isEditing){
      var operator = this.props.operator
      this.inputName.value=operator.name
      //this.inputEmail.value=operator.email
      this.inputRol.value=operator.role
      this.inputCheckBox.checked=operator.active
      this.inputCBWebNotificarion.checked=operator.notificationConfig.notifWeb;
      this.inputCBEmailNotificarion.checked=operator.notificationConfig.notifEmail;
      this.inputCBMobileNotificarion.checked=operator.notificationConfig.notifMobile;
      this.gcmId = operator.notificationConfig.gcmId;
      this.inputPassword.value=operator.password
      this.selectLanguage.value=operator.preferences.language || "EN"
      this.selectUnits.value=operator.preferences.unitSystem || "metric"
      this.selectTimezone.value=operator.preferences.timezone + " | " + timezoneHelper.getTimezones()[operator.preferences.timezone]

      this.setState({email:operator.email})
    }

    api.getClients(0,0,50).then(clients=>{
      this.setState({clients:clients})
      var cli=clients.filter(cli=>cli.id==this.props.loggedUser.currentAccount)[0]
      var appsActive=[]
      if(cli.deepAppActive) appsActive.push(APP_DEEP)
      if(cli.assetAppActive) appsActive.push(APP_ASSET)
      if(cli.parkingAppActive) appsActive.push(APP_PARKING)
      if(cli.wasteAppActive) appsActive.push(APP_WASTE)
      if(cli.bikesAppActive) appsActive.push(APP_BIKES)
      if(cli.iotAppActive) appsActive.push(APP_IOT)
      if(cli.adminAppActive) appsActive.push(APP_ADMIN)
      this.setState({clients:clients,appsActive:appsActive})
    })

    /*
    api.getActiveApps(this.props.loggedUser.currentAccount).then(cli=>{
      var appsActive=[]
      if(cli.deepAppActive) appsActive.push(APP_DEEP)
      if(cli.assetAppActive) appsActive.push(APP_ASSET)
      if(cli.parkingAppActive) appsActive.push(APP_PARKING)
      if(cli.wasteAppActive) appsActive.push(APP_WASTE)
      if(cli.bikesAppActive) appsActive.push(APP_BIKES)
      if(cli.iotAppActive) appsActive.push(APP_IOT)
      if(cli.adminAppActive) appsActive.push(APP_ADMIN)
      this.setState({appsActive:appsActive})
    })

    */
  }
  getOperatorFromEmail(){
    console.log('getting operator', this.state.email)
    api.getOperatorFromEmail(this.state.email)
      .then((op)=>{
        if(op)
          console.log("operator from email: ",op)

        var operatorExists=false
        if(op && op.rolesList)
          operatorExists=op.rolesList.roles.filter(r=>r.account==this.props.loggedUser.currentAccount)[0]

        if(operatorExists)
          toastrUtils.error("Email already registered for this account")

        if(op && !operatorExists){
          this.inputName.value=op.name
          this.inputCheckBox.checked=op.active
        }

      })
      .catch(err=>console.log("not found"))
  }
  onEmailChange(e){
    var email=e.target.value
    if(!helpers.isEmptyString(email) && helpers.validateEmail(email)){
      this.to=setTimeout(()=>{
        if(!this.state.calledEmail){
          this.setState({calledEmail:true})
          setTimeout(()=>this.setState({calledEmail:false}),2000)
          this.getOperatorFromEmail()
        }
      },2000)
    }

    this.setState({email:e.target.value})
  }
  handleNewChatClick(e){
    e.preventDefault()
    var inputName=ReactDOM.findDOMNode(this.inputName)
    var inputEmail=ReactDOM.findDOMNode(this.inputEmail)
    var inputRol=ReactDOM.findDOMNode(this.inputRol)
    var selectTimezone=ReactDOM.findDOMNode(this.selectTimezone)
    var inputPassword=ReactDOM.findDOMNode(this.inputPassword)
    var inputCheckBox=ReactDOM.findDOMNode(this.inputCheckBox)

    var valid=true
    if(!helpers.isEmptyString(inputName.value)){
      this.setState({inputNameValid:true})
    }else{valid=false; this.setState({inputNameValid:false})}

    if(!helpers.isEmptyString(inputEmail.value) && helpers.validateEmail(inputEmail.value)){
      this.setState({inputEmailValid:true})
    }else{valid=false; this.setState({inputEmailValid:false})}

    if(!helpers.isEmptyString(inputPassword.value)){
      this.setState({inputPasswordValid:true})
    }else{valid=false; this.setState({inputPasswordValid:false})}


    var roles=[]
    const hasRoleUser=rolesUtils.hasAppRole(this.props.loggedUser,APP_ADMIN,ROLE_USER)
    this.state.appsActive.map(app=>{
      var ob={}
      const roleSelected=this.state.rolSelects["rolSelect"+app.name] || (hasRoleUser? app.roles[1].name:app.roles[0].name)
      const roleSelectedRestriction=this.state.rolSelectsRestrictions["rolSelectRestrictions"+app.name] || "none"
      var tagsOrEntities=[]
      if(roleSelectedRestriction=="tags" || roleSelectedRestriction=="entities")
        tagsOrEntities=this["rolSelectTagsEntities"+app.name].value.split(",")

      ob.role=app.name+"_"+roleSelected
      ob.restricted=roleSelectedRestriction
      ob.entities=tagsOrEntities
      roles.push(ob)
    })

    console.log(roles)
    if(valid){
      this.setState({validation:true})

      var o={
        name: this.inputName.value,
        rol: this.inputRol.value,
        email: this.inputEmail.value,
        password: this.inputPassword.value,
        active: this.inputCheckBox.checked,
        rolesList: {"roles":[{"account": this.props.loggedUser.currentAccount, "account_name": rolesUtils.getCurrentAccount(this.props.loggedUser).account_name,"roles": roles}]},
        preferences:{
          timezone: Object.keys(timezoneHelper.getTimezones())[this.selectTimezone.selectedIndex],
          unitSystem:this.selectUnits.value || "metric",
          language:this.selectLanguage.value || "EN"
        },
        notificationConfig: {
          notifWeb: this.inputCBWebNotificarion.checked,
          notifEmail: this.inputCBEmailNotificarion.checked,
          notifMobile: this.inputCBMobileNotificarion.checked,
          notifSMS: false,
          notifPhoneCall: false,
          gcmId: this.gcmId
        }
      }

      console.log("OPERATOR",o)
      this.props.setOperator(o,hasRoleUser)
    }else{this.setState({validation:false})}
  }
  getErrorMessage(){
    if(this.state.validation)
      return null

    var element
    if(!this.state.inputNameValid){
      element=this.props.strings.name
    }else if(!this.state.inputEmailValid){
      element=this.props.strings.email
    }else if(!this.state.inputPasswordValid){
      element=this.props.strings.password
    }

    return <span style={{color:"#a94442"}}>{this.props.strings.badDataMsg + element + this.props.strings.badDataMsgValid }</span>
  }
    render(){
      const {goToHome,goToOperators,isEditing,operator,strings} =this.props

      var Breadcrumb = ({strings}) => (
        <ol className="breadcrumb">
          <li><div onClick={goToHome} style={{"cursor":"pointer"}}><i className="fa fa-dashboard"></i> {strings.home}</div></li>
          <li onClick={goToOperators} style={{"cursor":"pointer"}}>{strings.operators}</li>
          <li style={{marginBottom:"20px"}} className="active">{isEditing? operator.name : strings.newOperator}</li>
        </ol>
      )
      Breadcrumb = Translate(Breadcrumb,"Breadcrumb")

      const hasRoleUser=rolesUtils.hasAppRole(this.props.loggedUser,APP_ADMIN,ROLE_USER)
      console.log(operator)

      return (
        <div>
          <section className="content-header">
            <h1 style={{marginBottom:"10px"}}>
              {isEditing? strings.editOperator : strings.newOperator}
              <small></small>
            </h1>
            <Breadcrumb />
          </section>
            <div className="col-md-12">
              <div className="box box-info">
                <div className="box box-widget widget-user" style={{marginBottom:"36px"}}>
                  <div className="widget-user-header" style={{borderRadius:"0",background:"lightgray",height:"100px"}}>
                  </div>
                  <div className="widget-user-image" style={{top:"23px"}}>
                    <img className="img-circle" src="dist/img/user-gcba.jpeg" alt="Operator Avatar" style={{width:"105px"}}/>
                  </div>
                </div>
                <form autoComplete="off" className="form-horizontal">
                  <div className="box-body">
                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.name}</label>
                      <div className={"col-sm-10 "+ (!this.state.inputNameValid? "has-error":"")}>
                        <input ref={(i)=>this.inputName=i} type="text" className="form-control" placeholder={strings.name}/>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.email}</label>
                      <div className={"col-sm-10 "+ (!this.state.inputEmailValid? "has-error":"")}>
                        <input ref={(i)=>this.inputEmail=i} onChange={this.onEmailChange} value={this.state.email} type="email" className="form-control" placeholder={strings.email} required disabled={isEditing}/>
                      </div>
                    </div>
                    <div className="form-group hidden">
                      <label className="col-sm-2 control-label">{strings.rol}</label>
                      <div className="col-sm-10">
                        <select ref={(i)=>this.inputRol=i} className="form-control" disabled="">
                          <option>ADMIN</option>
                          <option>IoT_ADMIN</option>
                          <option>APP_ADMIN</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group hidden">
                      <label className="col-sm-2 control-label">{strings.account}</label>
                      <div className="col-sm-10">
                        <select ref={(i)=>this.inputAccount=i} className="form-control" disabled="">
                          {this.state.clients.map((cli,i)=><option key={i} >{cli.company}</option>)}
                        </select>
                      </div>
                    </div>


                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.password}</label>
                      <div className={"col-sm-10 "+ (!this.state.inputPasswordValid? "has-error":"")}>
                        <input autoComplete="new-password" ref={(i)=>this.inputPassword=i} type="password" className="form-control" placeholder={strings.password} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.active}</label>
                      <div className="col-sm-10">
                        <div className="checkbox">
                          <label>
                            <input ref={(i)=>this.inputCheckBox=i} type="checkbox" />
                            {strings.active}
                          </label>
                        </div>
                      </div>
                    </div>



                  <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                    <div  className="col-sm-11" style={{maxWidth:"775px"}}>
                      <div className="box box-warning"  style={{cursor:"pointer"}} >
                        <div className="box-header with-border" onClick={()=>{
                          this.setState({preferencesOpen:!this.state.preferencesOpen})
                        } }>
                          <h3 className="box-title">{"Preferences"}</h3>
                          <div className="box-tools pull-right">
                            <button type="button" className="btn btn-box-tool" ><i className={"fa fa-" + (this.state.preferencesOpen? "minus":"plus")} />
                            </button>
                          </div>

                          {/* /.box-tools */}
                        </div>
                        {/* /.box-header */}
                        <div className="box-body" style={this.state.preferencesOpen? {cursor:"initial"}:{display:"none"}}>


                          <div className="form-group">
                            <label className="col-sm-2 control-label">{"Timezone"}</label>
                            <div className="col-sm-10">
                              <select ref={(i)=>this.selectTimezone=i} className="form-control" disabled="">
                                {Object.keys(timezoneHelper.getTimezones()).map(key=><option key={key}>{key +" | " + timezoneHelper.timezones[key]}</option>)}
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">{"Language"}</label>
                            <div className="col-sm-10">
                              <select ref={(i)=>this.selectLanguage=i} className="form-control" disabled="">
                              <option key={0} value={"EN"}>{"EN"}</option>
                              <option key={1} value={"ES"}>{"ES"}</option>
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">{"Measurement Units"}</label>
                            <div className="col-sm-10">
                              <select ref={(i)=>this.selectUnits=i} className="form-control" disabled="">
                              <option key={0} value={"metric"}>{"metric"}</option>
                              <option key={1} value={"imperial"}>{"imperial"}</option>
                              </select>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                  </div>

                  <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                    <div  className="col-sm-11" style={{maxWidth:"775px"}}>
                      <div className="box box-warning"  style={{cursor:"pointer"}} >
                        <div className="box-header with-border" onClick={()=>{
                          this.setState({notificationsOpen:!this.state.notificationsOpen})
                        } }>
                          <h3 className="box-title">{"Notifications & Alerts"}</h3>
                          <div className="box-tools pull-right">
                            <button type="button" className="btn btn-box-tool" ><i className={"fa fa-" + (this.state.notificationsOpen? "minus":"plus")} />
                            </button>
                          </div>
                        </div>
                        <div className="box-body" style={this.state.notificationsOpen? {cursor:"initial"}:{display:"none"}}>
                          <div className="form-group">
                            <label className="col-sm-4 control-label">{"Web notifications"}</label>
                            <div className="col-sm-8">
                              <div className="checkbox">
                                <label>
                                  <input ref={(i)=>this.inputCBWebNotificarion=i} type="checkbox" />
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-sm-4 control-label">{"Email notifications"}</label>
                            <div className="col-sm-8">
                              <div className="checkbox">
                                <label>
                                  <input ref={(i)=>this.inputCBEmailNotificarion=i} type="checkbox" />
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-sm-4 control-label">{"Mobile notifications"}</label>
                            <div className="col-sm-8">
                              <div className="checkbox">
                                <label>
                                  <input ref={(i)=>this.inputCBMobileNotificarion=i} type="checkbox" />
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-sm-4 control-label">{"SMS notifications"}</label>
                            <div className="col-sm-8">
                              <div className="checkbox">
                                <label>
                                  <input name="active"  type="checkbox" disabled/>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="col-sm-4 control-label">{"Phone Call notifications"}</label>
                            <div className="col-sm-8">
                              <div className="checkbox">
                                <label>
                                  <input name="active"  type="checkbox" disabled/>
                                </label>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                    <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                    {this.state.appsActive.map(app=><div  key={app.id} className="col-sm-11" style={{maxWidth:"775px"}}>
                      <div className="box box-warning"  style={{cursor:"pointer"}} >
                        <div className="box-header with-border" onClick={()=>{
                           var appsOpen=this.state.appsActiveOpen || []
                            appsOpen[app.id]=!appsOpen[app.id]
                          this.setState({appsActiveOpen:appsOpen})
                        } }>
                          <h3 className="box-title">{app.name + " APP"}</h3>
                          <div className="box-tools pull-right">
                            <button type="button" className="btn btn-box-tool" ><i className={"fa fa-" + (this.state.appsActiveOpen[app.id]? "minus":"plus")} />
                            </button>
                          </div>

                          {/* /.box-tools */}
                        </div>
                        {/* /.box-header */}
                        <div className="box-body" style={this.state.appsActiveOpen[app.id]? {cursor:"initial"}:{display:"none"}}>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">{"Rol"}</label>
                            <div className="col-sm-10">
                              <select disabled={hasRoleUser} style={{maxWidth:"300px"}} ref={(i)=>this["rolSelect"+app.name]=i} className="form-control" value={this.state.rolSelects["rolSelect"+app.name]} onChange={i=>{
                                var rolSelects=this.state.rolSelects
                                console.log("CHANGED",i.target.value,rolSelects)
                                rolSelects["rolSelect"+app.name]=i.target.value
                                this.setState({rolSelects:rolSelects})
                              }}>
                                {
                                  (app.name=="ADMIN" && rolesUtils.hasAppRole(this.props.loggedUser,APP_ADMIN,ROLE_USER)? app.roles.slice(1):app.roles).map((r,i)=><option key={i} value={r.name} >{app.name+"_"+r.name}</option>)
                                }
                              </select>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="col-sm-2 control-label">{"Restriction"}</label>
                            <div className="col-sm-10">
                              <select disabled={hasRoleUser} style={{maxWidth:"300px"}} ref={(i)=>this["rolSelectRestrictions"+app.name]=i} className="form-control" value={this.state.rolSelectsRestrictions["rolSelectRestrictions"+app.name]} onChange={i=>{
                                var rolSelectsRes=this.state.rolSelectsRestrictions
                                rolSelectsRes["rolSelectRestrictions"+app.name]=i.target.value
                                this.setState({rolSelectsRestrictions:rolSelectsRes})
                              }}>
                                <option key={0} value={"none"} >{"NO"}</option>
                                <option key={1} value={"tags"} >{"TAGS"}</option>
                                <option key={2} value={"entities"} >{"ENTITIES"}</option>
                              </select>
                            </div>
                          </div>

                          {this.state.rolSelectsRestrictions["rolSelectRestrictions"+app.name]=="tags" || this.state.rolSelectsRestrictions["rolSelectRestrictions"+app.name]=="entities"?

                          <div className="form-group">
                            <label className="col-sm-2 control-label">{this.state.rolSelectsRestrictions["rolSelectRestrictions"+app.name]}</label>
                            <div className={"col-sm-10 "}>
                              <input disabled={hasRoleUser} ref={(i)=>this["rolSelectTagsEntities"+app.name]=i} type="text" className="form-control" />
                            </div>
                          </div>
                          :null}


                        </div>
                        {/* /.box-body */}
                      </div>
                      {/* /.box */}
                    </div>)}
                    </div>


                  </div>


                  <div className="box-footer" style={{textAlign:"center"}}>
                    <button className="btn btn-default pull-left" style={{padding:"6px 25px"}}
                            onClick={(e)=>{e.preventDefault();goToOperators()}} style={{"cursor":"pointer"}}>{strings.cancel}</button>
                    <button type="submit" onClick={this.handleNewChatClick}
                            className="btn btn-info pull-right" style={{padding:"6px 25px"}}>{strings.save}</button>
                    {this.getErrorMessage()}
                  </div>

                </form>
              </div>
            </div>
        </div>
      )
    }
  }

  export default Translate(OperatorsManagePresentation,"OperatorsManage")
