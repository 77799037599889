import React from 'react';
import Select from 'react-select';

import JSONPretty from 'react-json-pretty'
const compares =  [
                  {key: 'GT', value: '>'},
                  {key: 'GTE', value: '>='},
                  {key: 'LT', value: '<'},
                  {key: 'LTE', value: '<='},
                  {key: 'EQ', value: '='},
                  {key: 'NEQ', value: '!='}];

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      notification: {},
      notifications: this.props.isEditing && this.props.assetType.notifications_config.notification ? this.props.assetType.notifications_config.notification: []
    }
    this.onShowForm = this.onShowForm.bind(this);
    this.onHideForm = this.onHideForm.bind(this);
    this.onShowMoreInfo = this.onShowMoreInfo.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDeleteNotification = this.onDeleteNotification.bind(this);
    this.onChangeHandleTag = this.onChangeHandleTag.bind(this);
    this.onChangeHandleTask = this.onChangeHandleTask.bind(this);
  }
  onSave(event) {
    event.preventDefault();
    this.setState({
      notifications: [...this.state.notifications, this.state.notification],
      notification: this.initNotification,
      showForm: false
    }, () => {
      this.props.onUpdate(this.state.notifications);
    });
  }
  onChangeHandle(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.state.notification[target.name] = value;
    this.setState({notification: this.state.notification});
  }
  onChangeHandleTag(selectedOption) {
    const selectedItems = [];
    selectedOption.map((so) => {
      selectedItems.push(so.value);
    })
    this.setState({
      notification: {
        ...this.state.notification,
        tags: {
          tags: selectedItems
        }
      }
    })
  }
  onChangeHandleTask(event) {
    const target = event.target;
    const value = target.value;
    const index = event.nativeEvent.target.selectedIndex;
    console.log(index);
    console.log();
    if (index > 0) {
      const text = event.nativeEvent.target[index].text;
      this.state.notification['task_code'] = value;
      this.state.notification['task_descr'] = text;
      this.state.notification['task_template'] = this.props.listTasks[index - 1];
    }else {
      this.state.notification['task_code'] = null;
      this.state.notification['task_descr'] = null;
      this.state.notification['task_template'] = null;
    }
    this.setState({notification: this.state.notification});
  }
  onShowForm() {
    this.setState({
      showForm: true,
      notification: {
        field: "",
        compare: "",
        value: "",
        severity: 0,
        task: false,
        task_code: "",
        transition: false,
        tags: {
          tags: []
        },
        task: false,
        task_code: "",
        task_descr: "",
        title: "@location_descr@ Tank temperature too low ",
        text: "@location_descr@ -(@asset_descr@)- Tank temperature too low (@value@ F) min:@limit_value@ F",
        short_text: "@location_descr@ -(@asset_descr@)- Tank temperature too low (@value@ F) min:@limit_value@ F"
      }
    });
  }
  onHideForm() {
    this.setState({
      showForm: false
    });
  }
  onShowMoreInfo(index) {
    this.setState({
      notifications: this.state.notifications.map((n, i) =>{
        if (i == index) {
          return {
            ...n,
            visible: !n.visible
          }
        }
        return n
      })
    })
  }
  onDeleteNotification(index) {
    if(confirm(this.props.strings.confirmDelete)) {
      this.state.notifications.splice(index, 1);
      this.setState({
        notifications: this.state.notifications
      }, () => {
        this.props.onUpdate(this.state.notifications);
      });
    }
  }
  getSymbol(simbol) {
    let result = "";
    switch (simbol) {
      case "GT":
        result = ">";
        break;
      case "GTE":
        result = ">=";
        break;
      case "LT":
        result = "<";
        break;
      case "LTE":
        result = "<=";
        break;
      case "EQ":
        result = "=";
        break;
      case "NEQ":
        result = "!=";
        break;
    }
    return result;
  }
  render() {
    const { strings, alerts } = this.props;

    const options = [];
    this.props.listTags.length > 0 && this.props.listTags.map((tag, i) => {
      options.push({value: tag.descr, label: tag.descr});
    });

    return (
      <div className="flex">
        <div  className="col-sm-10 col-xs-12">
          <div className="box box-warning">
            <div className="box-header with-border" onClick={()=>{}}>
              <h3 className="box-title">{"Notifications & Alerts"}</h3>
              <div className="box-tools pull-right">
                <button type="button" className="btn btn-box-tool" ><i className="fa fa-minus" /></button>
              </div>
            </div>
            <div className="box-body">

              {!this.state.showForm && <div className="box-footer">
                  <button type="button" onClick={this.onShowForm} className="btn btn-primary pull-right">{strings.add_notification}</button>
                </div>
              }
              {this.state.showForm && <form role="form">
                <div className="form-group">
                  <div className="col-lg-3">
                    <label htmlFor="field">{strings.field}</label>
                    <select name="field" id="field" value={this.state.notification.field} className="form-control" onChange={this.onChangeHandle}>
                      <option value="">- {strings.field} -</option>
                      {alerts.length > 0 && alerts.map(alert => {
                        return <option value={alert}>{strings.alerts(alert)}</option>
                      })}
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="compare">{strings.compare}</label>
                    <select name="compare" value={this.state.notification.compare} id="compare" className="form-control" onChange={this.onChangeHandle}>
                      <option>- {strings.compare} -</option>
                      {compares.map(comp => {
                        return <option value={comp.key}>{comp.value}</option>
                      })}
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <label className="" htmlFor="value">{strings.value}</label>
                    <input type="number" name="value" id="value" value={this.state.notification.value} className="form-control" onChange={this.onChangeHandle}/>
                  </div>
                  <div className="col-lg-3">
                    <label htmlFor="severity">{strings.severity}</label>
                    <select name="severity" value={this.state.notification.severity} id="severity" className="form-control" onChange={this.onChangeHandle}>
                      {[1,2,3,4,5,6,7,8,9,10].map(v => {
                         return <option value={v}>{v}</option>
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-lg-2">
                    <label className="" htmlFor="title">{strings.title}</label>
                  </div>
                  <div className="col-lg-8">
                    <input name="title" id="title" value={this.state.notification.title} className="form-control" onChange={this.onChangeHandle}/>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-2">
                    <label className="" htmlFor="transition">{strings.transition}</label>
                  </div>
                  <div className="col-sm-8">
                    <div className="checkbox">
                      <label>
                        <input name="transition" id="transition" checked={this.state.notification.transition}  type="checkbox" onChange={this.onChangeHandle}/> {strings.transition}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-2">
                    <label className="" htmlFor="tags">{strings.tags}</label>
                  </div>
                  <div className="col-sm-8">
                    {options.length > 0 && <Select className="iot-select2" options={options} isMulti onChange={this.onChangeHandleTag} /> }
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-sm-2">
                    <label className="" htmlFor="task">{strings.generateTask}</label>
                  </div>
                  <div className="col-sm-8">
                    <div className="checkbox">
                      <label>
                        <input name="task" id="task" checked={this.state.notification.task}  type="checkbox" onChange={this.onChangeHandle}/>
                      </label>
                    </div>
                  </div>
                </div>
                {this.state.notification.task && <div className="form-group">
                  <div className="col-sm-2">
                    <label className="" htmlFor="task">{strings.task}</label>
                  </div>
                  <div className="col-sm-8">
                    <select name="task_descr" value={this.state.notification.task_code} id="task_descr" className="form-control" onChange={this.onChangeHandleTask} required={this.state.notification.task ? true : false}>
                      <option>-{strings.selectTask}-</option>
                      {this.props.listTasks.length > 0 && this.props.listTasks.map(v => {
                         return <option value={v.code}>{v.descr}</option>
                      })}
                    </select>
                  </div>
                </div>}

                <div className="form-group">
                  <div className="col-lg-2">
                    <label className="" htmlFor="short_text">{strings.short_text}</label>
                  </div>
                  <div className="col-lg-8">
                    <input name="short_text" id="short_text" value={this.state.notification.short_text} className="form-control" onChange={this.onChangeHandle}/>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-lg-2">
                    <label className="" htmlFor="text">{strings.text}</label>
                  </div>
                  <div className="col-lg-8">
                    <textarea name="text" id="text" className="form-control" onChange={this.onChangeHandle} defaultValue={this.state.notification.text}></textarea>
                  </div>
                </div>
                <div className="box-footer">
                  <button type="submit" className="btn btn-info" onClick={this.onSave}>{strings.save}</button>
                  <button type="button" onClick={this.onHideForm} className="btn btn-default pull-right">{strings.cancel}</button>
                </div>
              </form>}
              {!this.state.showForm && <table className="table table-hover table-striped">
                <tbody>
                  {this.state.notifications.length > 0 &&
                    this.state.notifications.map((noti, i) =>{
                      return <React.Fragment>
                      <tr id={`rel${i}`}>
                        <td className="mailbox-name" width="65%">{noti.field}</td>
                        <td width="10%"><span className="badge bg-red">{this.getSymbol(noti.compare)}</span></td>
                        <td width="15%"><span>{noti.value}</span></td>
                        <td width="10%">
                          <button type="button" onClick={() => this.onShowMoreInfo(i)} className="btn btn-xs pull-right">
                            <i className={`fa fa-${noti.visible ? 'minus':'plus'}`}></i>
                          </button>
                          <button type="button" onClick={() => this.onDeleteNotification(i)} className="btn btn-xs pull-right">
                            <i className={`fa fa-trash`}></i>
                          </button>
                        </td>
                      </tr>
                      {noti.visible && <tr>
                        <td colSpan="4">
                          <JSONPretty style={{background:"#1a2226",width:"100%"}} themeClassName="custom-json-pretty" id="json-pretty" json={noti} />
                        </td>
                      </tr>}
                      </React.Fragment>
                    })
                  }
                </tbody>
              </table>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

module.exports = Notifications;