import { createAction } from 'redux-actions';
import {NEXT_PAGE,BACK_PAGE,INBOX_LOADING,SET_UNREADED,
      SET_SEARCH,SET_SEARCH_VIEW,RESET_PAGE,SET_TAGS,
      SET_AVAILABLE_TAGS_VIEW,SET_FOLDER} from "../actionTypes/inboxActions.js"
import * as conversationsActions from "./conversationsActionCreators"
import api from "../../utils/api"
import {transformTags} from "../../utils/transformers"


// ---------- Folders

export const setFolder=createAction(SET_FOLDER)
export const inboxFolder=(folder)=>{
  return (dispatch, getState) => {
    const inbox=getState().inbox
    if(inbox.loading) return;

    dispatch(setFolder(folder))

    return dispatch(refreshInbox())
  }
}
export const setInboxFolder=()=>dispatch=>dispatch(inboxFolder("inbox"))
export const setJunkFolder=()=>dispatch=>dispatch(inboxFolder("junk"))



// --------- Paginacion

export const setNextPage=createAction(NEXT_PAGE)
export const setBackPage=createAction(BACK_PAGE)
export const setInboxLoading=createAction(INBOX_LOADING)
export const setResetPage=createAction(RESET_PAGE)

export const refreshInbox=()=>{
  return (dispatch, getState) => {
    const inbox=getState().inbox
    if(inbox.loading) return;

    dispatch(setInboxLoading(true))


    dispatch(setInboxLoading(false))
    return ;  // TODO OLD


    return dispatch(conversationsActions.fetchConversations(0,50))
              .then(()=>dispatch(setResetPage()))
              .then(()=>dispatch(setInboxLoading(false)))
              .catch(err=>dispatch(setInboxLoading(false)))
  }
}

export const nextPage=()=>{
  return (dispatch, getState) => {
    const conversations=getState().conversations
    const inbox=getState().inbox
    if(conversations.length < 50 || inbox.loading) return;

    dispatch(setInboxLoading(true))

    return dispatch(conversationsActions.fetchConversations(conversations.length*(inbox.page+1),50))
              .then(()=>dispatch(setNextPage()))
              .then(()=>dispatch(setInboxLoading(false)))
  }
}

export const backPage=()=>{
  return (dispatch, getState) => {
    const conversations=getState().conversations
    const inbox=getState().inbox
    if(inbox.page==0 || inbox.loading) return;

    dispatch(setInboxLoading(true))

    return dispatch(conversationsActions.fetchConversations(conversations.length*(inbox.page-1),50))
              .then(()=>dispatch(setBackPage()))
              .then(()=>dispatch(setInboxLoading(false)))
  }
}



// ----------- Filters

export const setUnreaded=createAction(SET_UNREADED)
export const setSearch=createAction(SET_SEARCH)
export const setSearchView=createAction(SET_SEARCH_VIEW)
export const setTags=createAction(SET_TAGS)
export const setAvailableTagsView=createAction(SET_AVAILABLE_TAGS_VIEW)

export const setTagsFilter=(tags)=>{
  return (dispatch, getState) => {
    const inbox=getState().inbox
    if(inbox.loading) return;

    dispatch(setTags(tags))

    return dispatch(refreshInbox())
  }
}

export const setUnreadedFilter= (optionalBool=null)=>{
  return (dispatch, getState) => {
    const inbox=getState().inbox
    if(inbox.loading) return;

    if(optionalBool){
      dispatch(setUnreaded(optionalBool))
    } else{
      dispatch(setUnreaded(!inbox.filters.unreaded))
    }


    return dispatch(refreshInbox())
  }
}

export const setSearchFilter=(query)=>{
  return (dispatch, getState) => {
    const inbox=getState().inbox
    if(inbox.loading) return;

    dispatch(setSearch(query))

    return dispatch(refreshInbox())
  }
}

export const fetchTags=()=>{
  return (dispatch, getState) => {
    const inbox=getState().inbox
    if(inbox.availableTagsView) return;

    return api.getTags()
            .then(tags=>transformTags(tags))
            .then(tags=>dispatch(setAvailableTagsView(tags)))
  }
}
