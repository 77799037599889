import { connect } from "react-redux";
import { push } from "react-router-redux";
import DevicePresentation from "./DevicePresentation.js";
import * as deviceSelectedActions from "../../redux/actionCreators/deviceSelectedActionCreators";
import routes from "../../utils/routes";

const mapStateToProps = function (state, ownProps) {
  return {
    device: state.deviceSelected.deviceSelected,
    deviceView: state.deviceSelected.deviceView,
    deviceId: ownProps.match.params.id,
    listMetricsDevice: state.deviceSelected.listMetricsDevice,
    listHelthMetrics: state.deviceSelected.listHelthMetrics,
    listReadQualityMetrics: state.deviceSelected.listReadQualityMetrics,
    loggedUser: state.loggedUser,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    //setOperator:(operator)=>dispatch(operatorsListActions.setOperator(operator)),
    setDeviceView: (dv) => dispatch(deviceSelectedActions.setDeviceView(dv)),

    goToHome: () => dispatch(push(routes.routeHome())),
    goToDevices: () => dispatch(push(routes.routeDevices())),
    getDevice: (id) => dispatch(deviceSelectedActions.fetchDevice(id)),
    getMetricsDevice: (id, day) =>
      dispatch(deviceSelectedActions.getListMetricsDevice(id, day)),
    getMetricsDeviceV2: (id, day) =>
      dispatch(deviceSelectedActions.getListMetricsDeviceV2(id, day)),
    getHelthMetrics: (id, day) =>
      dispatch(deviceSelectedActions.getListHelthMetrics(id, day)),
    getReadQualityMetrics: (id, day) =>
      dispatch(deviceSelectedActions.getListReadQualityMetrics(id, day)),
  };
};

const DeviceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicePresentation);

export default DeviceContainer;
