import helpers from "./helpers";

export default new (class {
  constructor() {}

  routeHome() {
    return "/";
  }

  routeInbox() {
    return "/inbox";
  }

  routeDashboard() {
    return "/dashboard";
  }

  routeDevices() {
    return "/"; // /devices
  }

  routeRegisterSignle() {
    return "/registerSingleDevice";
  }

  routeDevice(id) {
    return "/device/" + id;
  }

  routeOperationsConsole() {
    return "/operations";
  }

  routeOperations(id) {
    return "/operations/" + id;
  }

  routeOperators() {
    return "/operators";
  }

  routeOperatorsManage(queryObj = false) {
    return (
      "/operatorsmanage" + (queryObj ? helpers.jsonToQueryString(queryObj) : "")
    );
  }

  routeClients() {
    return "/clients";
  }

  routeClientsManage(queryObj = false) {
    return (
      "/clientsmanage" + (queryObj ? helpers.jsonToQueryString(queryObj) : "")
    );
  }

  routeAssetTypes() {
    return "/assettypes";
  }

  routeAssetTypesManage(queryObj = false) {
    return (
      "/assettypesmanage" +
      (queryObj ? helpers.jsonToQueryString(queryObj) : "")
    );
  }

  routeAssets() {
    return "/assets";
  }

  routeAssetsManage(queryObj = false) {
    return (
      "/assetsmanage" + (queryObj ? helpers.jsonToQueryString(queryObj) : "")
    );
  }

  routeAssetsRefill() {
    return "/assets-refill";
  }

  routeAsset(id) {
    return "/asset/" + id;
  }

  routeLocations() {
    return "/locations";
  }

  routeLocationsManage(queryObj = false) {
    return (
      "/locationsmanage" + (queryObj ? helpers.jsonToQueryString(queryObj) : "")
    );
  }

  routePorts() {
    return "/ports";
  }

  routePortsManage(queryObj = false) {
    return (
      "/portsmanage" + (queryObj ? helpers.jsonToQueryString(queryObj) : "")
    );
  }
  routePortsManageId(id) {
    return "/portsmanage?id=" + id;
  }

  routeERPIntegration() {
    return "/ERPIntegration";
  }

  routeDeviceManage(queryObj = false) {
    return (
      "/devicemanage" + (queryObj ? helpers.jsonToQueryString(queryObj) : "")
    );
  }

  route404() {
    return "/404";
  }

  routeAppStatus() {
    return "/status";
  }

  routeDataSources() {
    return "/datasources";
  }

  routeDataSourcesEdit(id) {
    return `/datasource/${id}`;
  }

  routeTags() {
    return "/tags";
  }

  routeTag() {
    return "/tag";
  }

  routeTaskTemplates() {
    return "/task-templates";
  }
  routeTaskTemplatesEdit(params = false) {
    let paramsQP = "";
    if (params) {
      paramsQP = helpers.jsonToQueryString(params);
    }
    return `/task-template${paramsQP}`;
  }

  routeTasks() {
    return "/tasks";
  }
  routeTask(params = false) {
    let paramsTask = "";
    if (params) {
      paramsTask = helpers.jsonToQueryString(params);
    }
    return `/task${paramsTask}`;
  }
})();
