import { handleActions } from 'redux-actions';
import * as types from '../actionTypes/tagsActions';

export default handleActions({
    [types.GET_TAGS](state, action) {
        return {
            ...state,
            listTags: action.payload
        }
    },
    [types.GET_TAG](state, action) {
        return {
            ...state,
            tag: action.payload
        }
    },
    [types.POST_TAG](state, action) {
        return {
            ...state,
            tag: action.payload
        }
    },
    [types.WAS_EDIT](state, action) {
        return {
            ...state,
            wasEdit: action.payload.edit
        }
    },
    [types.CHANGE_STATUS_EDIT](state, action) {
        return {
            ...state,
            wasEdit: action.payload.edit
        }
    }
}, {
    listTags: [],
    tag: {},
    wasEdit: false
});