import loggedUser from "../reducers/loggedUserReducer"
import login from "../reducers/loginReducer"
import language from "../reducers/languageReducer"
import console from "../reducers/consoleReducer"
import consoleConversations from "../reducers/consoleConversationsReducer"
import inbox from "../reducers/inboxReducer"
import conversations from "../reducers/conversationsReducer"
import chat from "../reducers/chatReducer"
import conversationSelected from "../reducers/conversationSelectedReducer"
import conversationsTyping from "../reducers/conversationsTypingReducer"
import operators from "../reducers/operatorsReducer"
import operatorsList from "../reducers/operatorsListReducer"
import clients from "../reducers/clientsReducer"
import clientsList from "../reducers/clientsListReducer"
import ports from "../reducers/portsReducer"
import portsList from "../reducers/portsListReducer"
import devicesList from "../reducers/devicesListReducer"
import devices from "../reducers/devicesReducer"
import deviceSelected from "../reducers/deviceSelectedReducer"
import citizens from "../reducers/citizensReducer"
import citizensList from "../reducers/citizensListReducer"
import assetTypes from "../reducers/assetTypesReducer"
import assetTypesList from "../reducers/assetTypesListReducer"
import locations from "../reducers/locationsReducer"
import locationsList from "../reducers/locationsListReducer"
import assets from "../reducers/assetsReducer"
import assetsList from "../reducers/assetsListReducer"
import assetSelected from "../reducers/assetSelectedReducer"
import dataSource from "./dataSource";
import tag from "./tag";
import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import {handleActions} from "redux-actions"
import {USER_LOGOUT} from "../actionTypes/loginActions"
import tasksTemplates from './tasktemplates';
import tasks from './tasks';

const appReducer = combineReducers({
  loggedUser,
  login,
  language,
  console,
  consoleConversations,
  inbox,
  conversations,
  chat,
  conversationSelected,
  conversationsTyping,
  operators,
  operatorsList,
  clients,
  clientsList,
  citizens,
  citizensList,
  devicesList,
  devices,
  deviceSelected,
  ports,
  portsList,
  assetTypes,
  assetTypesList,
  locations,
  locationsList,
  assets,
  assetsList,
  assetSelected,
  dataSource,
  tag,
  routing: routerReducer,
  tasksTemplates,
  tasks
})

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
