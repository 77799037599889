import React from "react";
import CommonField from "./CommonFields";

const CustomInput = ({ label, value, name, onChange, visible }) => {
  if (!visible) {
    return null;
  }
  return (
    <div className='form-group'>
      <label className='col-sm-4 control-label'>{label}</label>
      <div className={"col-sm-5 "}>
        <input
          type='text'
          required
          {...{ name, onChange, value }}
          className='form-control'
        />
      </div>
    </div>
  );
};

const Chemical = (props) => {
  const { strings, assetType, onChangeConfig } = props;
  const { tank_shape } = assetType.waterTank_config;
  const tank_shape_parsed = String(tank_shape);
  const tank_shape_defined = tank_shape_parsed && tank_shape_parsed !== "";
  const showTlength =
    tank_shape_defined && !["5", "3"].includes(tank_shape_parsed);
  const showTwidth =
    tank_shape_defined && ["1", "2"].includes(tank_shape_parsed);
  const showTheight =
    tank_shape_defined && ["1", "2", "3", "5"].includes(tank_shape_parsed);
  const showDiameterTop = tank_shape_defined && tank_shape_parsed !== "1";
  const showDiameterBottom =
    tank_shape_defined && ["2", "5"].includes(tank_shape_parsed);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className='col-sm-11' style={{ maxWidth: "775px" }}>
        <div className='box box-warning' style={{ cursor: "pointer" }}>
          <div className='box-body'>
            <CommonField {...props} />
            <CustomInput
              label={strings.lenght}
              visible={showTlength}
              name='tlength'
              value={assetType.waterTank_config.tlength}
              onChange={onChangeConfig}
            />
            <CustomInput
              label={strings.width}
              visible={showTwidth}
              name='twidth'
              value={assetType.waterTank_config.twidth}
              onChange={onChangeConfig}
            />
            <CustomInput
              label={strings.long}
              visible={showTheight}
              name='theigth'
              value={assetType.waterTank_config.theigth}
              onChange={onChangeConfig}
            />
            <CustomInput
              label={strings.diameterTop}
              visible={showDiameterTop}
              name='diameter_top'
              value={assetType.waterTank_config.diameter_top}
              onChange={onChangeConfig}
            />
            <CustomInput
              label={strings.diameterBottom}
              visible={showDiameterBottom}
              name='diameter_bottom'
              value={assetType.waterTank_config.diameter_bottom}
              onChange={onChangeConfig}
            />
            <CustomInput
              label={strings.conoHeight}
              visible={showDiameterBottom}
              name='cone_heigth'
              value={assetType.waterTank_config.cone_heigth}
              onChange={onChangeConfig}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chemical;
