import { createAction } from 'redux-actions'
import {SET_PORTS} from "../actionTypes/portsListActions"
import api from "../../utils/api"
import routes from "../../utils/routes"
import { push } from 'react-router-redux'

export const setPorts=createAction(SET_PORTS)

export const fetchPorts = (offset=null,limit=null) => {
  return (dispatch, getState) => {
    var ports=getState().ports

    console.log("fetchPorts: offset:" + offset + " limit:" + limit )

    return  _getPorts(offset,limit)
              .then(data=>dispatch(setPorts(data)))

  }
}

const _getPorts=(offset,limit)=>{
  return api.getPorts(status,offset,limit)
}

export const setPort = (op) => {
  return (dispatch, getState) => {
    return  api.createPort(op)
                .then(data=>dispatch(push(routes.routePorts())))
                .catch(err=>{console.log(err);dispatch(push(routes.routePorts()))})
  }
}

export const editPort = (op) => {
  return (dispatch, getState) => {
    return  api.editPort(op)
                .then(data=>dispatch(push(routes.routePorts())))
                .catch(err=>{console.log(err);dispatch(push(routes.routePorts()))})
  }
}
