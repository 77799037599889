import React from 'react'
import { connect } from 'react-redux'
import * as conversationsActions from "../../redux/actionCreators/conversationsActionCreators.js"

export default ({id, children})=>{
  return (
    <div className="modal fade" id={id}  role="dialog" aria-labelledby="myModalLabel">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  )
}
