import React from 'react'
import ReactDOM from  'reactDOM'
import Translate from '../shared/i18n/Translate'
import config from "../../../../CONFIG"

import Loader from '../shared/PulseLoader'

const LoginPresentation = class extends React.Component {
  constructor(props){
    super(props)
    this.inputEnterListener=this.inputEnterListener.bind(this)
  }
  componentDidMount(){
    document.getElementById('body').className='login-page'
  }
  componentWillUnmount(){
    document.getElementById('body').className='skin-blue sidebar-mini'
  }
  inputEnterListener(e){
    var input=e.target
    var keyCode = e.charCode;
    if (keyCode == '13'){// Enter pressed
      this.props.login()
    }
  }
  render(){
    const {email,password,authMessage,setEmail,setPassword,login,loading,strings} = this.props

    return (
      <div className="login-box">
        <div className="login-logo" style={{color:"#1f1f1f"}}>
          <img src="dist/img/logo.png" alt="" style={{width: '100px', height: '50px'}}/>
          <span className="brand">IOT STUDIO</span>
        </div>
        <div className="login-box-body" style={{background:"rgba(0, 0, 0, 0.60)", padding:"25px", position:"relative"}}>
          <div className="form-group has-feedback">
            <input type="email" className="form-control" placeholder={strings.email}
                   value={email}
                   onChange={(e)=>setEmail(e.target.value)}
                   onKeyPress={this.inputEnterListener} />
            <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
          </div>
          <div className="form-group has-feedback">
            <input type="password" className="form-control" placeholder={strings.password}
                   value={password}
                   onChange={(e)=>setPassword(e.target.value)}
                   onKeyPress={this.inputEnterListener} />
            <span className="glyphicon glyphicon-lock form-control-feedback"></span>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <button type="submit" className="btn btn-primary btn-block btn-flat"
                      onClick={login}>{strings.logIn}</button>
            </div>
          </div>
          <div className="row" style={{position:"relative"}}>
            <div style={loading? {position:"absolute",top:"2px",right:"14px"} : {display:"none"}}>
              <Loader color="#3c8dbc" size="7px" margin="4px"/>
            </div>
          </div>
          <div style={{marginTop:"5px",color:"white"}}>{authMessage? strings.badLoginMsg : null}</div>
          <div style={{position:"absolute",bottom:"3px",left:"12px",color:"gray"}}>{config.app_version}</div>
          <div>  <a href="https://blinkiotplatformhelp.zendesk.com/hc/en-us/articles/360020425932-I-forgot-my-password-How-do-I-get-it-">I forgot my password</a> </div>
          <div>   <a href="https://blinkiotplatformhelp.zendesk.com/hc/en-us/articles/360020659691-How-do-I-get-a-trial-account-" className="text-center">Open new account</a> </div>
        </div>
        <div className="">
          <span className="lema">OIL&GAS INDUSTRY</span>
        </div>
      </div>
    )
  }
}

export default Translate(LoginPresentation,"Login")
