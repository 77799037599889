import React, { Component, useEffect } from "react";
import api from "../../utils/api";
import AssetDeleteModal from "../modals/AssetDeleteModal";
import Translate from "../shared/i18n/Translate";
import Maps from "./Maps";
import List from "./List";
import Actions from "./Actions";
import Inventory from "./Inventory";
import { useState } from "react";
import Search from "./Search";

const AssetsPresentation = (props) => {
  const {
    loggedUser,
    goToAsset,
    goToAssetsManage,
    goToAssetsRefill,
    assets,
    strings,
    inventories,
    loadingList,
  } = props;
  const [config, setConfig] = useState({ assetIdToDelete: null, view: "list" });
  const [filter, setFilter] = useState({
    location_id: null,
    location_descr: null,
    needReposition: null,
    content: null,
    content_type: null,
    name: null,
    ident: null,
    operationMode: null,
    appPointStatus: null,
  });
  const [query, setQuery] = useState({
    limit: 80,
    offset: 0,
    enablePrevious: false,
    enableNext: false,
  });

  const onDelete = () => {
    api
      .deleteAsset(loggedUser.clientId, config.assetIdToDelete)
      .catch((err) => console.log(err));
  };

  const setAssetToDelete = (asset) =>
    setConfig((prev) => ({
      ...prev,
      assetIdToDelete: asset.id,
    }));
  const onSetView = (view) => {
    setConfig((prev) => ({
      ...prev,
      view,
    }));
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    props.fetchAssets(loggedUser.clientId, query, filter);
    props.getInventory(loggedUser.clientId, query, filter);
  }, [props.loggedUser.clientId]);

  useEffect(() => {
    const count = props.assets.length;

    setQuery((prev) => ({
      ...prev,
      enableNext: count >= query.limit,
      enablePrevious: query.offset > 0 && count > 0,
    }));
  }, [props.assets]);

  const onChangeQuery = (field, value) => {
    setQuery((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onChangePage = (type) => {
    if (type === "previous") {
      const newOffset = parseInt(query.offset) - parseInt(query.limit);
      onChangeQuery("offset", newOffset < 0 ? 0 : newOffset);
    }
    if (type === "next") {
      const newOffset = parseInt(query.offset) + parseInt(query.limit);
      onChangeQuery("offset", newOffset);
    }
    if (type === "refresh") {
      onChangeQuery("offset", "0");
    }
  };

  useEffect(() => {
    if (props.assets.length > 0) {
      props.fetchAssets(loggedUser.clientId, query, filter);
    }
    if (props.inventories.length > 0) {
      props.getInventory(loggedUser.clientId, query, filter);
    }
  }, [query.offset, filter]);

  const onChangeFilter = (newFilter) => {
    setFilter((prev) => ({
      ...prev,
      ...newFilter,
    }));
    onChangeQuery("offset", "0");
  };

  return (
    <div>
      <Actions
        {...{ strings, goToAssetsManage, goToAssetsRefill, filter }}
        view={config.view}
        clientId={loggedUser.clientId}
        onSetView={onSetView}
      />

      {(config.view === "list" || config.view === "inventory") && (
        <Search {...{ filter, strings }} onSubmit={onChangeFilter} />
      )}

      {config.view === "list" && (
        <List
          {...props}
          enableNext={query.enableNext}
          enablePrevious={query.enablePrevious}
          onChange={onChangePage}
          setAssetToDelete={(asset) => setAssetToDelete(asset)}
        />
      )}

      {assets.length > 0 && config.view === "map" && (
        <Maps listMarkers={assets} {...{ goToAsset }} />
      )}
      {config.view === "inventory" && (
        <Inventory {...{ strings }} series={inventories} />
      )}
      <AssetDeleteModal onDelete={onDelete} assetId={config.assetIdToDelete} />
    </div>
  );
};

export default Translate(AssetsPresentation, "Assets");
