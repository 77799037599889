import {handleActions} from "redux-actions"
import {ADD_TYPING,REMOVE_TYPING} from "../actionTypes/conversationsTyping.js"
var update = require('immutability-helper');

const initialState=[]

const reducer=handleActions({
  [ADD_TYPING]:(state,action)=>update(state,{$push:[action.payload]}),
  [REMOVE_TYPING]:(state,action)=>update(state,{$set:state.filter(c=>c!==action.payload)}),
},initialState)

export default reducer
