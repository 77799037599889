"use strict";
import React from 'react'
import ReactDOM from 'reactDOM'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import * as clientsActions from "./redux/actionCreators/clientsActionCreators"

import LoginContainer from "./components/login/LoginContainer"
import HeaderBar from './components/app/header/HeaderBar'
import NavigationMenu from  './components/app/navigationMenu/NavigationMenuContainer'
import DevTools from "./components/app/DevTools"
import ControlsMenu from './components/app/controlsMenu/ControlsMenu'
import Footer from "./components/app/footer/Footer"
import Authentication from "./components/shared/Authentication"

var AppComponent = class extends React.Component {
  componentDidMount(){
    //this.props.refreshClients() //TODO
  }
  render(){
    const {Routes,loggedUser}=this.props

  if(!Authentication.getToken('t'))
    return (<div>
            <DevTools />
            <LoginContainer />
           </div>)

  return (
        <div className="wrapper">
          <DevTools />
          <HeaderBar />
          <NavigationMenu />
          <div className="content-wrapper" style={{minHeight:"850px"}}>
            {Routes}
          </div>
          <Footer />
          <ControlsMenu />
        </div>
    )
    }
}


const mapStateToProps = function(state) {
  return {
    loggedUser: state.loggedUser,
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    refreshClients:()=>dispatch(clientsActions.refreshClients()),
  }
}

const App = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AppComponent))

export default App
