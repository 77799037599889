import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import editDataSourcesView from './editView';
import * as datasources from '../../redux/actionCreators/dataSourcesActionCreators';
import routes from "../../utils/routes";
import config  from "../../utils/config";

const mapStateToProps = function(state) {
  return {
    typeList: config.datasources.types,
    ds: state.dataSource.dataSource,
    tags: state.dataSource.tags,
    loggedUser: state.loggedUser
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    goList: () => dispatch(push(routes.routeDataSources())),
    getDataSource: (datasourceId) => dispatch(datasources.getDataSource(datasourceId)),
    saveDataSource: (data) => dispatch(datasources.postDataSource(data)),
    getTags: () => dispatch(datasources.getListTags()),
    goHome:()=>dispatch(push(routes.routeHome()))
  }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(editDataSourcesView)