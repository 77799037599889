export default {
  Login: {
    logIn: "Iniciar sesión",
    email: "Correo electrónico",
    password: "Contraseña",
    badLoginMsg: "Correo electrónico o contraseña incorrectos. Inténtalo de nuevo.",
  },
  Inbox: {
    inbox: "Bandeja de entrada",
    junk: "Papelera",
    newSms: "Nuevo SMS",
    newMessages: "Nuevos mensajes",
    search: "Buscar",
    folders: "Carpetas",
    unreadFilter: "Filtrar por no leídos",
    insertTag: "Introduce una etiqueta...",
  },
  Breadcrumb: {
    home: "Inicio",
    ports: "Puertos",
    devices: "Dispositivos",
    inbox: "Bandeja de entrada de SMS",
    junk: "Papelera de SMS",
    users: "Usuarios",
    newOperator: "Nuevo usuario",
    citizens: "Ciudadanos",
    conversation: "Conversación",
  },
  Header: {
    logOut: "Cerrar sesión",
  },
  Footer: {
    version: "Versión",
  },
  Dashboard: {
    dashboard: "Panel",
    locations: "Ubicaciones",
    mode: "Activos por modo de automatización",
    status: "Activos por estado",
    type: "Activos por tipo",
    assetsNot: "Activos fuera de línea",
    openTasks: "Tareas abiertas de alta prioridad",
    assets: "Activos",
    manufacter: "Activos por fabricante",
    locations_tasks: "Tareas abiertas de alta prioridad por ubicación",
    all: "Todos",
    actives: "Activos",
    assetsByLocation: "Activos por ubicación",
    assetsByContent: "Activos por contenido",
    assetsByContentType: "Activos por tipo de contenido",
    select: "Seleccionar...",
  },
  NavigationMenu: {
    dashboard: "Panel",
    assets: "Monitoreo de activos",
    erpIntegration: "Integración ERP",
    locations: "Ubicaciones",
    assetTypes: "Tipos de activos",
    accounts: "Organizaciones",
    search: "Buscar...",
    online: "En línea",
    new: "Nuevo",
    mainNavigation: "NAVEGACIÓN PRINCIPAL",
    operationsConsole: "Consola de operaciones",
    inbox: "Bandeja de entrada",
    admin: "ADMIN",
    operators: "Operadores",
    status: "Estados",
    tags: "Etiquetas",
    devops: "DevOps",
    deep: "PROFUNDIDAD",
    ports: "Puertos",
    users: "Usuarios",
    keys: "Llaves",
    parking: "ESTACIONAMIENTO",
    slots: "Espacios",
    bikesTitle: "BICICLETAS",
    bikes: "Bicicletas",
    waste: "RESIDUOS",
    trucks: "Camiones",
    bins: "Papeleras",
    iotdevices: "DISPOSITIVOS IOT",
    devices: "Dispositivos",
    gateways: "Gateways",
    mobileSupport: "Soporte móvil",
    supportSessions: "Sesiones de soporte",
    roles: "Roles",
    datasources: "Fuentes de datos",
    tags: "Etiquetas",
    taskTemplates: "Plantillas de tareas",
    tasks: "Monitoreo de tareas",
  },
  Operators: {
    operatorManagement: "Gestión de operadores",
    newOperator: "Nuevo operador",
    activeOperators: "Operadores activos",
    inactiveOperators: "Operadores inactivos",
    search: "Buscar",
    status: "Estado",
    active: "Activo",
    inactive: "Inactivo",
  },
  OperatorsManage: {
    newOperator: "Nuevo operador",
    editOperator: "Editar operador",
    name: "Nombre",
    email: "Correo electrónico",
    rol: "Rol",
    timezone: "Zona horaria",
    password: "Contraseña",
    active: "Activo",
    cancel: "Cancelar",
    save: "Guardar",
    badDataMsg: "Debes ingresar un ",
    badDataMsgValid: " válido",
    account: "Cuenta",
  },
  Clients: {
    clientManagement: "Gestión de organizaciones",
    newClient: "Nueva organización",
    activeClients: "Organizaciones activas",
    inactiveClients: "Organizaciones inactivas",
    search: "Buscar",
    status: "Estado",
    active: "Activo",
    inactive: "Inactivo",
  },
  ClientsManage: {
    newClient: "Nueva organización",
    generateToken: "Generar",
    editClient: "Editar organización",
    company: "Compañía",
    assetToken: "Clave de aplicación de activos",
    adminApp: "Clave de aplicación de administración",
    adminAppActive: "Aplicación de administración",
    iotApp: "Clave de aplicación IoT",
    inputIoTAppActive: "Aplicación IoT",
    assetTokenActive: "Aplicación de activos",
    deepTokenActive: "Aplicación Deep",
    parkingTokenActive: "Aplicación de estacionamiento",
    wasteTokenActive: "Aplicación de residuos",
    bikesTokenActive: "Aplicación de bicicletas",
    deepToken: "Clave de aplicación Deep",
    parkingToken: "Clave de aplicación de estacionamiento",
    bikesToken: "Clave de aplicación de bicicletas",
    wasteToken: "Clave de aplicación de residuos",
    name: "Nombre",
    email: "Correo electrónico",
    rol: "Rol",
    timezone: "Zona horaria",
    password: "Contraseña",
    active: "Activo",
    cancel: "Cancelar",
    save: "Guardar",
    badDataMsg: "Debes ingresar un ",
    badDataMsgValid: " válido",
    create: "Crear",
  },
  Ports: {
    portManagement: "Gestión de puertos",
    newPort: "Nuevo puerto",
    id: "Id",
    name: "Nombre",
    delete: "Eliminar",
  },
  PortsManage: {
    newPort: "Nuevo puerto",
    editPort: "Editar puerto",
    name: "Nombre",
    ports: "Puertos",
    lat: "Latitud",
    lon: "Longitud",
    depth_formula_type: "Tipo de fórmula de profundidad",
    mapid: "Id de mapa",
    sensors: "Sensores",
    sensor: "Sensor",
    priority: "Prioridad",
    offset: "Desplazamiento",
    cancel: "Cancelar",
    save: "Guardar",
    offset: "Desplazamiento",
    add: "Agregar",
    remove: "Eliminar",

    badDataMsg: "Debes ingresar un ",
    badDataMsgValid: " válido",
  },
  DeviceManage: {
    newDevice: "Nuevo dispositivo",
    editDevice: "Editar dispositivo",
    registerDevice: "Registrando",
    name: "Nombre",
    email: "Correo electrónico",
    rol: "Rol",
    timezone: "Zona horaria",
    password: "Contraseña",
    authorized: "Autorizado",
    cancel: "Cancelar",
    save: "Guardar",
    badDataMsg: "Debes ingresar un ",
    badDataMsgValid: " válido",

    cmmunicationType: "Tipo de comunicación",
    descr: "Descripción",
    deviceType: "Tipo de dispositivo",
    lat: "Latitud",
    lon: "Longitud",
  },
  ControlsMenu: {
    edit: "Editar",
    disable: "Desactivar",
    activate: "Activar",
    detail: "Detalles",
    messages: "Mensajes",
    reading: "Lecturas",
    information: "Información",
    debug: "Depurar",
    engineering: "Ingeniería",
    hardwareVersion: "Versión de hardware",
    firmwareVersion: "Versión de firmware",
    updateFirmware: "Actualizar firmware",
    assignDevice: "Asignar dispositivo",

    layoutOptions: "Opciones de diseño",
    inConstruction: "En construcción",
    newConversationsFirst: "Nuevas conversaciones primero",
    putNewConversationFirst: "Colocar las nuevas conversaciones en primer lugar en la pantalla.",
    newMessagesArrives: "Nuevos mensajes que llegan",
    activateConversationsFirst: "Colocar las conversaciones con nuevos mensajes en primer lugar.",
    cleaning: "Limpieza",
    removeConversations: "Eliminar conversaciones",
    removeInactiveConversations: "Eliminar conversaciones inactivas (30 min. de inactividad).",
    minimizeAssignedConversations: "Minimizar conversaciones asignadas",
    minimizeConversationsBeingAnswered: "Minimizar la conversación que está siendo respondida por otro agente.",
    timeline: "Línea de tiempo",
    assets: "Activos",
    analytics: "Analítica",
    dipstickCalibration: "Calibración de varilla",
  },
  NotFoundPage: {
    inConstruction: "En construcción",
  },
  AppStatus: {
    title: "Estado de la plataforma",
  },
  Devices: {
    home: "Inicio",
    deviceManagement: "Gestión de dispositivos",
    status: "Estado",
    active: "Activo",
    authorized: "Autorizado",
    deviceName: "Nombre",
    deviceSn: "Número de serie",
    gateway: "Gateway",
    lastMsgTime: "Hora del último mensaje",
    registerDeviceSingle: "Provisionar dispositivo único",
    reporting: "Reportando",
    lastRead: "Última lectura",
    reading: "Lectura",
    healthy: "Saludable",
  },
  Device: {
    device: "Dispositivo",

    lastValues: "Últimos valores",
    lastMessage: "Último mensaje",
    battery: "Batería",
    value: "Valor",
    sn: "Número de serie",
    name: "Nombre",
    type: "Tipo",
    source: "Fuente",
    gateway: "Gateway",

    newOperator: "Nuevo operador",
    editOperator: "Editar operador",
    name: "Nombre",
    email: "Correo electrónico",
    rol: "Rol",
    timezone: "Zona horaria",
    password: "Contraseña",
    active: "Activo",
    cancel: "Cancelar",
    save: "Guardar",
    badDataMsg: "Debes ingresar un ",
    badDataMsgValid: " válido",

    details: "Detalles",
    messages: "Mensajes",
    reading: "Lecturas",
    information: "Información",
    debug: "Depurar",
    engineering: "Ingeniería",
    messageTime: "Hora del mensaje",
    protocol: "Protocolo",
    addEvent: "Agregar evento",
    showLast: "Mostrar últimos",
    days: "Días",
    health: "Salud del dispositivo",

    totalMsgs: "Total de mensajes",
    lastReport: "Último informe",
    instDate: "Fecha de instalación",
    deviceInvalidReadings: "El dispositivo ha tenido lecturas inválidas en las últimas 24 horas.",
    deviceHealthIssues: "El dispositivo ha informado problemas de salud en las últimas 24 horas que necesitan atención.",
    reportingHealth: "Informe de salud",
    true: "Verdadero",
    false: "Falso",
    condition: "Condición",

    installation: "Instalación",
    installationDate: "Fecha de instalación",
    activationDate: "Fecha de activación",
    lastReset: "Último reinicio",
    batteryInstallDate: "Fecha de instalación de la batería",

    chart: {
      lastValues: "Últimos valores",
      lastMessage: "Último mensaje",
      clickAndDrag: "Haz clic y arrastra en el área del gráfico para hacer zoom",
      pinchToZoom: "Pellizca el gráfico para hacer zoom",
      temperature: "Temperatura",
      values: "Valores",
      battery: "Batería",
    },
  },
  PortDeleteModal: {
    areYouSure: "¿Estás seguro de que deseas eliminar el puerto?",
    yes: "Sí",
    no: "No",
  },
  AssetTypeDeleteModal: {
    areYouSure: "¿Estás seguro de que deseas eliminar el tipo de activo?",
    yes: "Sí",
    no: "No",
  },
  AssetTypes: {
    id: "Id",
    description: "Descripción",
    long: "Altura",
    width: "Ancho",
    assetTypes: "Tipos de activos",
    newAssetType: "Nuevo tipo de activo",
    activeAssetTypes: "Tipos de activos activos",
    inactiveAssetTypes: "Tipos de activos inactivos",
    search: "Buscar",
    status: "Estado",
    active: "Activo",
    csa: "CSA",
    inactive: "Inactivo",
    depth: "Profundidad",
    notification: "Notificaciones",
    class: "Clase",
    description: "Descripción",
    manufacturer: "Fabricante",
  },
  AssetTypesManage: {
    assetTypes: "Tipos de activos",
    newAssetType: "Nuevo tipo de activo",
    editAssetType: "Editar tipo de activo",
    id: "Id",
    description: "Descripción",
    long: "Altura (cm)",
    lenght: "Longitud (cm)",
    width: "Ancho (cm)",
    depth: "Profundidad",
    cancel: "Cancelar",
    save: "Guardar",
    badDataMsg: "Debes ingresar un ",
    badDataMsgValid: " válido",
    notification: "Notificaciones y alertas",
    galons: "Galones",
    levelhight: "Nivel alto",
    levellow: "Nivel bajo",
    temphigh: "Temperatura alta",
    templow: "Temperatura baja",
    batery: "Batería baja",
    tilthigh: "Inclinación alta",
    tiltlow: "Inclinación baja",
    frecuence: "Frecuencia de reporte del dispositivo",
    class: "Clase",
    selectClass: "Seleccionar clase",
    metrics: "Métricas",
    manufacture: "Fabricante",
    manufacturecode: "Código de fabricante",
    manufacturedesc: "Descripción de fabricante",
    selectManufacture: "Seleccionar fabricante",
    add_notification: "Nueva notificación",
    field: "Campo",
    compare: "Comparar",
    value: "Valor",
    severity: "Gravedad",
    title: "Título",
    text: "Texto",
    short_text: "Texto corto",
    transition: "Transición",
    alerts: (key) => {
      const c = {
        volumen: "Volumen",
        fill: "Nivel de llenado",
        temperature: "Temperatura",
        status: "Estado del dispositivo IoT",
        health: "Salud del dispositivo IoT",
        report: "Informe del dispositivo IoT",
        compr_suction_pressure: "Presión de succión del compresor",
        compr_discharge_pressure: "Presión de descarga del compresor",
        comp_oil_level: "Nivel de aceite del compresor",
        comp_liquid_sep_level: "Nivel del separador de líquidos del compresor",
        comp_oil_pressure: "Presión de aceite del compresor",
        comp_temp_bco1: "Temperatura del compresor BCO1",
        comp_temp_bco2: "Temperatura del compresor BCO2",
        eng_oil_pressure: "Presión de aceite del motor",
        eng_temperature: "Temperatura del motor",
        eng_oil_level: "Nivel de aceite del motor",
        eng_emergency_stop: "Parada de emergencia del motor",
        eng_manual_stop: "Parada manual del motor",
        eng_velocity: "Velocidad del motor",
        eng_pmr: "PMR del motor",
        flow_static_pressure: "Presión estática del flujo",
        flow_diferential_pressure: "Presión diferencial del flujo",
        flow_temperature: "Temperatura del flujo",
        flow_rate_actual: "Tasa de flujo actual",
        enginestatus: "Estado del motor",
        switchonoff: "Estado de la válvula de carga y descarga ON/OFF",
        solar_battery: "Batería con energía solar",
        flow: "Tasa de flujo",
        tics: "Pulsos",
        ticsperminute: "Pulsos por minuto",
        pumponoff: "Estado de la bomba (ON|OFF)",
        cons_per_hour: "Consumo por hora",
        cons_per_day: "Consumo por día",
      };
      return c[key] ? c[key] : "";
    },
    confirmDelete: "¿Estás seguro de eliminar?",
    max_capacity: "Capacidad máxima (lts)",
    tags: "Etiquetas",
    selectTag: "Seleccionar etiquetas",
    generateTask: "¿Generar tarea?",
    task: "Tarea",
    selectTask: "Seleccionar tarea",
    tankShape: "Forma del tanque (cm)",
    diameterTop: "Diámetro superior (cm)",
    diameterBottom: "Diámetro inferior (cm)",
    conoHeight: "Altura del cono (cm)",
  },
  LocationDeleteModal: {
    areYouSure: "¿Estás seguro de que deseas eliminar la ubicación?",
    yes: "Sí",
    no: "No",
  },
  Locations: {
    id: "Id",
    description: "Descripción",
    lat: "Latitud",
    lon: "Longitud",
    locations: "Ubicaciones",
    newLocation: "Nueva ubicación",
    activeLocations: "Ubicaciones activas",
    inactiveLocations: "Ubicaciones inactivas",
    search: "Buscar",
    status: "Estado",
    active: "Activo",
    inactive: "Inactivo",
  },
  LocationsManage: {
    locations: "Ubicaciones",
    newLocation: "Nueva ubicación",
    editLocation: "Editar ubicación",
    id: "Id",
    description: "Descripción",
    lat: "Latitud",
    lon: "Longitud",
    depth: "Profundidad",
    csa: "CSA",
    cancel: "Cancelar",
    save: "Guardar",
    badDataMsg: "Debes ingresar un ",
    badDataMsgValid: " válido",
  },
  Assets: {
    id: "Id",
    location: "Ubicación",
    type: "Tipo",
    description: "Nombre del activo",
    status: "Estado del reporte",
    assets: "Activos",
    newAsset: "Nuevo activo",
    gallons: "Galones",
    lastRead: "Última lectura",
    health: "Salud",
    notification: "Notificación",
    metrics: "Métricas",
    fill: "Nivel de llenado",
    temperature: "Temperatura",
    timestamp: "Última lectura",
    volumen: "Volumen",
    days_to_reposition: "Días para reposicionar",
    expected_consumption_per_hour: "Consumo esperado por hora",
    last_24hour_consumption: "Consumo en las últimas 24 horas",
    last_hour_consumption: "Consumo en la última hora",
    pumpOnOFF: "Bomba encendida/apagada",
    tics_per_hour: "Pulsos por hora",
    on: "Encendido",
    off: "Apagado",
    tics: "Pulsos",
    flow: "Flujo",
    level: "Nivel",
    content: "Contenido",
    class: "Clase",
    ident: "Identificación",
    import_measurements: "Importar mediciones",
    filter_assets: "Filtrar activos",
    name: "Nombre",
    select_automation_mode: "Seleccionar modo de automatización",
    select_app_point_status: "Seleccionar estado del punto de aplicación",
    static: "Estático",
    operated: "Operado",
    automated: "Automatizado",
    active: "Activo",
    suspended: "Suspendido",
    search: "Buscar",
    assets_by_well_zone: "Activos por zona de pozo",
  },
  AssetDeleteModal: {
    areYouSure: "¿Estás seguro de que deseas eliminar el activo?",
    yes: "Sí",
    no: "No",
  },
  Asset: {
    showLast: "Mostrar últimos",
    days: "Días",
    manufacture: "Fabricante",
    manufacturecode: "Código de fabricante",
    manufacturedesc: "Descripción de fabricante",
    device: "Dispositivo",
    on: "Encendido",
    off: "Apagado",
    status: "Estado",
    fill: "Nivel de llenado",
    temperature: "Temperatura",
    timestamp: "Última lectura",
    volumen: "Litros",
    days_to_reposition: "Días para reposicionar",
    expected_consumption_per_hour: "Consumo esperado por hora",
    last_24hour_consumption: "Consumo en las últimas 24 horas",
    last_hour_consumption: "Consumo en la última hora",
    pumpOnOFF: "Bomba encendida/apagada",
    tics_per_hour: "Pulsos por hora",
    application: "Aplicación",
    content: "Contenido",
    class: "Clase",
    app_point: "Estado del punto de aplicación",
    treatment: "Tratamiento",
    type: "Tipo",
    mode: "Modo",
    dipstick_device_offset: "Calibración de desplazamiento de varilla/dispositivo",
    tank_height_config: "La altura configurada del tanque es",
    enter_dipstick_measurement: "Por favor, ingrese la medición de la varilla (de la parte inferior al líquido) en cm",
    last_device_distance: "La última distancia del dispositivo desde la parte superior al líquido fue",
    dipstick_lecture: "Lectura de la varilla",
    device_lecture: "Lectura del dispositivo",
    adjustment: "Ajuste",
    the_actual_offset_is: "El desplazamiento actual es",
    the_suggested_offset_is: "El desplazamiento sugerido es",
    close: "Cerrar",
    centimeters: "Centímetros",
    recent_history: "Historial reciente",
    add_event: "Agregar evento",
    no_events_available: "No hay eventos disponibles",
    view_all: "Ver todo",
    load_more: "Cargar más",
    share_location: "Compartir ubicación",
    analytics: "Analítica",
    historic_values: "Valores históricos",
    add_manual_reading: "Agregar lectura manual",
    asset_refills: "Recargas de activos",
    time_serie_tooltip: {
      start: "Inicio",
      end: "Fin",
      duration: "Duración",
      initial_volume: "Volumen inicial",
      end_volume: "Volumen final",
    },
    add_event_modal: {
      title: "Evento rápido",
      description: "Descripción",
      type: "Tipo",
      save: "Guardar",
      cancel: "Cancelar",
    },

    asset_tasks: {
      title: "Tareas del activo",
      add_task: "Agregar tarea",
      code: "Código",
      enter_code: "Introduce el código de la tarea",
      description: "Descripción",
      enter_description: "Introduce la descripción de la tarea",
      priority: "Prioridad",
      planned_date: "Fecha planificada",
      select_date: "Selecciona una fecha",
      assigned_to: "Asignado a",
      assigned_date: "Fecha de asignación",
      resolved_by: "Resuelto por",
      resolved_date: "Fecha de resolución",
    },
  },
  AssetManage: {
    newAsset: "Nuevo activo",
    editAsset: "Editar activo",
    ports: "Activos",
    lat: "Latitud",
    lon: "Longitud",
    sensors: "Sensores",
    sensor: "Sensor",
    priority: "Prioridad",
    offset: "Desplazamiento",
    cancel: "Cancelar",
    save: "Guardar",
    offset: "Desplazamiento",
    add: "Agregar",
    remove: "Eliminar",

    decr: "Descripción",
    ident: "Identificación",
    location: "Ubicación",
    assetType: "Tipo de activo",
    basic: "Básico",
    sensorRequired: "Debes seleccionar al menos un sensor",

    badDataMsg: "Debes ingresar un ",
    badDataMsgValid: " válido",
    notifications: "Notificaciones activas",
    mode: "Modo de automatización",
    status: "Estado",
    select: "Seleccionar",
    content: "Contenido",
    class: "Clase",
    app_point: "Estado del punto de aplicación",
    treatment: "Tratamiento",
    lat: "Latitud",
    lon: "Longitud",
    minInventory: "Inventario mínimo (Lts)",
  },
  DataSource: {
    title: "Fuentes de datos",
    newDataSource: "Nueva fuente de datos",
    new: "Nuevo",
    edit: "Editar",
    id: "ID",
    name: "Nombre",
    type: "Tipo",
    externalid: "ID externo",
    lastseen: "Visto por última vez",
    authorized: "Autorizado",
    home: "Inicio",
  },
  Tag: {
    title: "Etiquetas",
    new: "Nueva etiqueta",
    edit: "Editar",
    id: "ID",
    name: "Nombre",
    active: "Activo",
    inactive: "Inactivo",
    all: "Todos",
    filter: "Filtrar",
    platform: "Etiqueta de la plataforma",
    home: "Inicio",
    status: "Estado",
    save: "Guardar",
    cancel: "Cancelar",
  },
  Common: {
    areYouSure: "¿Estás seguro de eliminar?",
    yes: "Sí",
    no: "No",
  },
  TasksTemplates: {
    taskTemplates: "Plantillas de tareas",
    newTask: "Nueva plantilla de tarea",
    editTask: "Editar plantilla de tarea",
    id: "ID",
    descr: "Descripción",
    code: "Código",
    trigger: "Evento de activación",
    trigger_url: "URL de evento",
    save: "Guardar",
    cancel: "Cancelar",
    home: "Inicio",
    yes: "Sí",
    no: "No",
    areYouSure: "¿Estás seguro de que deseas eliminar?",
    confirm: "Confirmar",
  },
  Tasks: {
    tasks: "Tareas",
    newTask: "Nueva tarea",
    editTask: "Editar tarea",
    id: "ID",
    save: "Guardar",
    cancel: "Cancelar",
    home: "Inicio",
    yes: "Sí",
    no: "No",
    location: "Ubicación",
    date: "Fecha",
    status: "Estado",
    priority: "Prioridad",
    asset: "Activo",
    assetvalues: "Últimas métricas",
    gallons: "Galones",
    lastRead: "Última lectura",
    metrics: "Métricas",
    fill: "Nivel de llenado",
    temperature: "Temperatura",
    task: "Tarea",
    assignedto: "Asignado a",
    select: "Seleccionar",
    closed: "Cerrado",
    all: "Todos",
    order: "Orden",
    search: "Buscar",
    clean: "Limpiar",
    descr: "Descripción",
    on: "Encendido",
    plannedto: "Planificado para",
    resolved: "Resuelto por",
    timeline: "Línea de tiempo",
    quickEvent: "Evento rápido",
    add: "Agregar",
    edit: "Editar",
    code: "Código",
    createdAt: "Creado en",
    enterdesc: "Introduce la descripción...",
    filterTask: "Filtrar tareas",
    close: "Cerrar",
    more: "Ver más",
  },
};
