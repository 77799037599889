import { createAction } from 'redux-actions'
import {SET_STATUS,NEXT_PAGE,BACK_PAGE,RESET_PAGE,
        CLIENTS_LOADING,SET_SEARCH,SET_SEARCH_VIEW } from "../actionTypes/clientsActions"
import * as clientsListActions from "./clientsListActionCreators"
import api from "../../utils/api"


// ---------- Folders

export const setStatus=createAction(SET_STATUS)
export const clientsStatus=(status)=>{
  return (dispatch, getState) => {
    const clients=getState().clients
    if(clients.loading) return;

    dispatch(setStatus(status))

    return dispatch(refreshClients())
  }
}
export const setActiveStatus=()=>dispatch=>dispatch(clientsStatus(true))
export const setInactiveStatus=()=>dispatch=>dispatch(clientsStatus(false))



// --------- Paginacion

export const setNextPage=createAction(NEXT_PAGE)
export const setBackPage=createAction(BACK_PAGE)
export const setClientsLoading=createAction(CLIENTS_LOADING)
export const setResetPage=createAction(RESET_PAGE)

export const refreshClients=()=>{
  return (dispatch, getState) => {
    const clients=getState().clients
    if(clients.loading) return;

    dispatch(setClientsLoading(true))

    return dispatch(clientsListActions.fetchClients(0,50))
              .then(()=>dispatch(setResetPage()))
              .then(()=>dispatch(setClientsLoading(false)))
              .catch(err=>dispatch(setClientsLoading(false)))
  }
}

export const nextPage=()=>{
  return (dispatch, getState) => {
    const clientsList=getState().clientsList
    const clients=getState().clients
    if(clientsList.length < 50 || clients.loading) return;

    dispatch(setClientsLoading(true))

    return dispatch(clientsListActions.fetchClients(clientsList.length*(clients.page+1),50))
              .then(()=>dispatch(setNextPage()))
              .then(()=>dispatch(setClientsLoading(false)))
  }
}

export const backPage=()=>{
  return (dispatch, getState) => {
    const clientsList=getState().clientsList
    const clients=getState().clients
    if(clients.page==0 || clients.loading) return;

    dispatch(setClientsLoading(true))

    return dispatch(clientsListActions.fetchClients(clientsList.length*(clients.page-1),50))
              .then(()=>dispatch(setBackPage()))
              .then(()=>dispatch(setClientsLoading(false)))
  }
}



// ----------- Filters

export const setSearch=createAction(SET_SEARCH)
export const setSearchView=createAction(SET_SEARCH_VIEW)

export const setSearchFilter=(query)=>{
  return (dispatch, getState) => {
    const clients=getState().clients
    if(clients.loading) return;

    dispatch(setSearch(query))

    return dispatch(refreshClients())
  }
}
