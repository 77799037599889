import React from 'react'

import Title from '../common/title';
import Card from '../common/card';
import DeleteModal from "../modals/delete";
import Translate from '../shared/i18n/Translate';
import Controls from '../common/controls';
import helpers from "../../utils/helpers"

class DataSourceView extends React.Component {
  constructor(props){
    super(props)
    this.onDelete = this.onDelete.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onNext = this.onNext.bind(this);

    this.state = {
      itemSelectedText: null,
      paging: {
        offset: 0,
        page: 1,
        limit: 50,
        total: 0
      }
    };
  }
  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.props.getDataSources(this.state.paging.offset, this.state.paging.limit);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.listDataSources !== prevProps.listDataSources) {
      this.setState({
        paging: {
          ...this.state.paging,
          total: this.props.listDataSources.length
        }
      })
    }
  }

  lastSeen(lastseen) {
    return lastseen? helpers.transformUtcToTimezone(lastseen,this.props.loggedUser.preferences.timezone).format("HH:mm:ss"):"-"
  }

  openModal(event, ds) {
    //event.preventDefault();
    event.stopPropagation();
    console.log('to delete');
    this.setState({itemSelectedText: ds.descr});
  }
  onDelete() {
    console.log('dijo q si')
  }

  onEdit(datasourceId) {
    this.props.goEdit(datasourceId);
  }

  onRefresh() {
    this.props.getDataSources(0, this.state.paging.limit);
  }

  onNext(offset) {
    this.setState({
      paging: {
        ...this.state.paging,
        offset: offset,
        page: this.state.paging.page + 1
      }
    })
    this.props.getDataSources(offset, this.state.paging.limit);
  }

  onPrev(offset) {
    this.setState({
      paging: {
        ...this.state.paging,
        page: this.state.paging.page - 1,
        offset: offset
      }
    })
    this.props.getDataSources(offset, this.state.paging.limit);
  }

  render() {
    const { listDataSources, strings, goEdit } = this.props;
    const modalId = 'deleteModal';

    return <div>
      <Title title={strings.title} newActionLabel={strings.newDataSource} goTo={goEdit} />
      <Card>
        <Controls onRefresh={this.onRefresh} paging={this.state.paging} onNext={this.onNext} onPrev={this.onPrev}/>
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>{strings.id}</th>
              <th>{strings.name}</th>
              <th>{strings.type}</th>
              <th>{strings.externalid}</th>
              <th>{strings.lastseen}</th>
              <th>{strings.authorized}</th>
              <th className="iot-short"></th>
            </tr>
          </thead>
          <tbody>
            {listDataSources.length > 0 && listDataSources.map((ds, index) => {
              return <tr key={`dt${index}`} onClick={() => this.onEdit(ds.datasourceId)} className="item-row">
                <td>{ds.datasourceId}</td>
                <td>{ds.descr}</td>
                <td>{ds.type}</td>
                <td>{ds.externalId}</td>
                <td>{this.lastSeen(ds.last_seen)}</td>
                <td><span className={"label label-" + (ds.authorized ? 'success' : 'warning')}>{ds.authorized ? 'yes' : 'no'}</span></td>
                <td className="iot-short">
                  <a data-toggle="modal" data-target={`#${modalId}`} onClick={(event) => {this.openModal(event, ds)}} className="btn btn-default btn-sm btn-block">
                    <i className="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
            })}
          </tbody>
        </table>
        <Controls onRefresh={this.onRefresh} paging={this.state.paging} onNext={this.onNext} onPrev={this.onPrev}/>
      </Card>
      <DeleteModal id={modalId} onDelete={this.onDelete} itemText={this.state.itemSelectedText}/>
    </div>
  }
}

export default Translate(DataSourceView, "DataSource")
