import React, { Component } from 'react';
import Translate from '../shared/i18n/Translate';
import { Form, Icon, Input, Button, Select, Col, Row } from 'antd';

import 'antd/lib/form/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/button/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/row/style/css';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }
  componentDidMount() {
    //this.props.form.validateFields();
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.onLoadTasks(values);
      }
    });
  };

  onHandlerChange(e) {
    //console.log(e.target.value);
  }

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
    const { listLocations, listOperators, listStatus, listOrdersTask, filter, strings } = this.props;

    // Only show error after a field is touched.
    const usernameError = isFieldTouched('username') && getFieldError('username');
    const passwordError = isFieldTouched('password') && getFieldError('password');
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Form {...formItemLayout} className="zd-advanced-search-form" onSubmit={this.handleSubmit}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label={strings.location}>
            {getFieldDecorator('location_id', {
                initialValue: filter.location_id
              })(
                <select className="form-control" initialValue={filter.location_id} name="location_id" onChange={this.onHandlerChange}>
                  <option value=''>- {strings.select} {strings.location} -</option>
                  {listLocations.length > 0 && listLocations.map((l, i) => {
                    return <option key={`il${i}`} value={l.id}>{l.decr}</option>
                  })}
                </select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={strings.assignedto}>
            {getFieldDecorator('assigned_to', {
                initialValue: filter.assigned_to
              })(
                <select className="form-control" initialValue={filter.assigned_to} name="assigned_to" onChange={this.onHandlerChange}>
                  <option value=''>- {strings.select} {strings.assignedto} -</option>
                  {listOperators.length > 0 && listOperators.map((o, i) => {
                    return <option key={`at${i}`} value={o.email}>{o.name}</option>
                  })}
                </select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={strings.status}>
            {getFieldDecorator('status', {
                initialValue: filter.status
              })(
                <select className="form-control" initialValue={filter.status} name="status" onChange={this.onHandlerChange}>
                  <option value=''>- {strings.select} {strings.status} -</option>
                  {listStatus.length > 0 && listStatus.map((k, i) => {
                    return <option key={`st${i}`} value={k.taskStatus}>{k.taskStatus}</option>
                  })}
                </select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={strings.closed}>
            {getFieldDecorator('closed', {
                initialValue: filter.closed
              })(
                <select className="form-control" initialValue={filter.closed} name="closed" onChange={this.onHandlerChange}>
                  <option value='-1'>{strings.all}</option>
                  <option value='0'>{strings.no}</option>
                  <option value='1'>{strings.yes}</option>
                </select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={strings.priority}>
            {getFieldDecorator('priority', {
                initialValue: filter.priority
              })(
                <select className="form-control" initialValue={filter.priority} name="priority" onChange={this.onHandlerChange}>
                  <option value="-1">- {strings.select} {strings.priority} -</option>
                  {[1,2,3,4,5,6,7,8,9,10].map((k, i) => {
                    return <option key={`p${i}`} value={k}>{k}</option>
                  })}
                </select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={strings.order}>
            {getFieldDecorator('order', {
                initialValue: filter.order
              })(
                <select className="form-control" initialValue={filter.order} name="order" onChange={this.onHandlerChange}>
                  <option value=''>- {strings.select} {strings.order} -</option>
                  {listOrdersTask.length > 0 && listOrdersTask.map((o, i) => {
                    return <option key={`o${i}`} value={o.taskStatusOrder}>{o.taskStatusOrder}</option>
                  })}
                </select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6} offset={9} style={{ textAlign: 'left' }}>
            <Form.Item>
              <Button type="primary" className="btn btn-info pull-left" htmlType="submit" icon="search" disabled={this.hasErrors(getFieldsError())}>
                {strings.search}
              </Button>
              <Button type="secondary" className="btn btn-default pull-right" htmlType="button" onClick={this.props.onResetLoad}>
                {strings.clean}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

module.exports = Filter;