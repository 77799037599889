import React from "react";
import PropTypes from "prop-types";

class Filter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.state = {
      countDays: 2,
    };
  }

  onChangeHandler(event) {
    //this.setState({loadingChart: true});
    const countDays = event.target.value;
    if (this.props.serialNumber) {
      this.props.onGetMetrics(
        this.props.resourceId,
        this.props.serialNumber,
        countDays
      );
    } else {
      this.props.onGetMetrics(
        this.props.userId,
        this.props.resourceId,
        countDays
      );
    }
    this.setState({
      countDays,
    });
    if (this.props.onUpdateDays) {
      this.props.onUpdateDays(Number(countDays));
    }
  }

  render() {
    const { strings } = this.props;
    return (
      <form className='form-horizontal'>
        <div className='box-body'>
          <div className='form-group'>
            <label
              htmlFor='inputPassword3'
              className='col-sm-4 col-xs-4 control-label'
            >
              {strings.showLast}
            </label>
            <div className='col-sm-2 col-xs-4'>
              <select
                name='change'
                value={this.state.countDays}
                onChange={this.onChangeHandler}
                className='form-control'
              >
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='4'>4</option>
                <option value='7'>7</option>
                <option value='10'>10</option>
                <option value='30'>30</option>
                <option value='60'>60</option>
                <option value='90'>90</option>
              </select>
            </div>
            <div className='pull-left col-sm-1 col-xs-4'>{strings.days}</div>
          </div>
        </div>
      </form>
    );
  }
}

Filter.propTypes = {
  onGetMetrics: PropTypes.func.isRequired,
  strings: PropTypes.object,
};
Filter.defaultProps = {
  strings: {},
};

/**
 * Expose Component
 */
module.exports = Filter;
