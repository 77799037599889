import { handleActions } from "redux-actions";
import {
  SET_ASSETS,
  GET_MODES,
  GET_ASSET_STATUS,
  GET_INVENTORY,
  GET_TANK_INFORMATION,
  GET_ASSETS_LOADING,
} from "../actionTypes/assetsListActions";
var update = require("immutability-helper");

const initialState = {
  list: [],
  listModes: [],
  listStatus: [],
  inventory: [],
  tank: {
    content: [],
    contentType: [],
    inyectionStatus: [],
    inyectionTreatment: [],
  },
  loading: false,
};

const reducer = handleActions(
  {
    [SET_ASSETS]: (state, action) =>
      update(state, {
        list: { $set: action.payload },
      }),
    [GET_MODES]: (state, action) =>
      update(state, {
        listModes: { $set: action.payload },
      }),
    [GET_ASSET_STATUS]: (state, action) =>
      update(state, {
        listStatus: { $set: action.payload },
      }),
    [GET_ASSETS_LOADING]: (state, action) =>
      update(state, {
        loading: { $set: action.payload },
      }),
    [GET_TANK_INFORMATION]: (state, action) =>
      update(state, {
        tank: { $set: action.payload },
      }),
    [GET_INVENTORY]: (state, action) =>
      update(state, {
        inventory: { $set: action.payload },
      }),
  },
  initialState
);

export default reducer;
