import React, { Component } from "react";
import moment from "moment";
import helpers from "../../../utils/helpers";

class Data extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { status, code, priority, entry_date } = this.props.task;
    const { strings } = this.props;
    const { timezone } = this.props.loggedUser.preferences;
    return (
      <div className='box box-primary'>
        {
          <div className='box-header with-border'>
            <i className='fa fa-tasks'></i>
            <h3 className='box-title'>Task</h3>
          </div>
        }
        <div className='box-body'>
          <dl className='dl-horizontal'>
            <dt>{strings.status}</dt>
            <dd>{status}</dd>
            <dt>{strings.code}</dt>
            <dd>{code}</dd>
            <dt>{strings.createdAt}</dt>
            <dd>
              {helpers._transformUtcToTimezone(entry_date, timezone, true)}
            </dd>
            <dt></dt>
            <dd>
              <strong>{moment(entry_date).fromNow()}</strong>
            </dd>
            <dt>{strings.priority}</dt>
            <dd>{priority}</dd>
          </dl>
        </div>
      </div>
    );
  }
}

module.exports = Data;
