import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const getOptions = (arr, title) => ({
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    plotTitle: false,
    type: "pie",
  },
  title: {
    text: "",
  },
  tooltip: {
    pointFormat: "Qty: <b>{point.qty}</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        distance: -50,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: "Brands",
      colorByPoint: true,
      data: arr,
    },
  ],
});

const PieChart = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { opened: true };
  }
  render() {
    const { data, title } = this.props;
    const { opened } = this.state;

    return (
      <div className='box box-default'>
        <div className='box-header with-border'>
          <h3 className='box-title'>{title}</h3>
          <div className='box-tools pull-right'>
            <button
              onClick={() => this.setState({ opened: !opened })}
              type='button'
              className='btn btn-box-tool'
              data-widget='collapse'
            >
              <i className={"fa fa-" + (opened ? "minus" : "plus")} />
            </button>
          </div>
        </div>
        {opened ? (
          <div className='box-body'>
            <div className='row'>
              <div className='col-md-12' style={{ overflow: "hidden" }}>
                <div className='chart-responsive'>
                  {data && data.length > 0 ? (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={getOptions(
                        data.map((o) => ({
                          name:
                            (o.decr || "-") +
                            " (" +
                            (o.qty_percent || "0") +
                            "%)",
                          y: o.qty_percent || 0,
                          qty: o.qty || "-",
                        })),
                        title
                      )}
                    />
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
};

export default PieChart;
