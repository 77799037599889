import React from "react";
import Application from "./Application";

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.getTimelineType = this.getTimelineType.bind(this);
  }

  getTimelineType(id) {
    if (id == 0) return "Device Creation";
    if (id == 1) return "Device Assigned";
    if (id == 2) return "Device Removed";
    if (id == 4) return "Note";
    if (id == 5) return "Event";
    return "";
  }

  render() {
    const { asset, goToDevice, strings } = this.props;
    let sensorId = null;
    if (asset.sensors.sensors.length > 0) {
      sensorId = asset.sensors.sensors[0].sensorId; //first sensor //TODO CAROUSEL
    }
    const { assetType } = asset;

    return (
      <div>
        {/* Detail Info */}
        <div className='row'>
          <section className='col-lg-7 connectedSortable ui-sortable'>
            <div className='box box-info'>
              <div
                className='box-header ui-sortable-handle'
                style={{ cursor: "move" }}
              >
                <i className='fa fa-envelope' />
                <h3 className='box-title'>Info</h3>
                <div className='pull-right box-tools'></div>
              </div>
              <div className='box-body'>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", marginRight: "30px" }}>
                    <div style={{ marginRight: "30px", marginBottom: "15px" }}>
                      <div style={{ fontWeight: "600", fontSize: "17px" }}>
                        {strings.type}
                      </div>
                      <div style={{ fontSize: "16px" }}>
                        {asset.assetType.decr}
                      </div>
                    </div>
                    <div style={{ marginRight: "30px", marginBottom: "15px" }}>
                      <div style={{ fontWeight: "600", fontSize: "17px" }}>
                        {strings.status}
                      </div>
                      <div style={{ fontSize: "16px" }}>{asset.status}</div>
                    </div>
                    <div>
                      <div style={{ fontWeight: "600", fontSize: "17px" }}>
                        {strings.mode}
                      </div>
                      <div style={{ fontSize: "16px" }}>{asset.mode}</div>
                    </div>
                  </div>

                  <div style={{ display: "flex", marginRight: "30px" }}>
                    <div style={{ marginRight: "30px", marginBottom: "15px" }}>
                      <div style={{ fontWeight: "600", fontSize: "17px" }}>
                        {strings.manufacture}
                      </div>
                      <div style={{ fontSize: "16px" }}>
                        {assetType.manufacturer}
                      </div>
                    </div>
                    <div style={{ marginRight: "30px", marginBottom: "15px" }}>
                      <div style={{ fontWeight: "600", fontSize: "17px" }}>
                        {strings.manufacturecode}
                      </div>
                      <div style={{ fontSize: "16px" }}>
                        {assetType.manufacturer_product_code}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", marginRight: "30px" }}>
                    <div style={{ marginRight: "30px", marginBottom: "15px" }}>
                      <div style={{ fontWeight: "600", fontSize: "17px" }}>
                        {strings.manufacturedesc}
                      </div>
                      <div style={{ fontSize: "16px" }}>
                        {assetType.manufacturer_product_description}
                      </div>
                    </div>
                    <div style={{ marginRight: "30px", marginBottom: "15px" }}>
                      <div style={{ fontWeight: "600", fontSize: "17px" }}>
                        {strings.device}
                      </div>
                      {sensorId && (
                        <div style={{ fontSize: "16px" }}>
                          <a
                            href=''
                            onClick={(e) => {
                              e.preventDefault();
                              goToDevice(sensorId);
                            }}
                          >
                            {sensorId}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Application {...{ strings, asset }} />
        </div>
      </div>
    );
  }
}

module.exports = Detail;
