import {handleActions} from "redux-actions"
import {NEXT_PAGE,BACK_PAGE,INBOX_LOADING,SET_UNREADED,SET_SEARCH,
      SET_SEARCH_VIEW,INBOX_REFRESH,RESET_PAGE,SET_TAGS,
      SET_AVAILABLE_TAGS_VIEW,SET_FOLDER} from "../actionTypes/inboxActions.js"
var update = require('immutability-helper');

const initialState={
  filters:{
    unreaded:false,
    search:"",
    tags:[],
    searchView:"",
    availableTagsView:[],
  },
  folder:"inbox",
  page:0,
  loading:false
}

const reducer=handleActions({
  [SET_FOLDER]:(state,action)=>update(state,{folder:{$set:action.payload}}),
  [NEXT_PAGE]:(state,action)=>update(state,{page:{$set:state.page+1}}),
  [BACK_PAGE]:(state,action)=>update(state,{page:{$set:state.page-1}}),
  [RESET_PAGE]:(state,action)=>update(state,{page:{$set:initialState.page}}),
  [INBOX_LOADING]:(state,action)=>update(state,{loading:{$set:action.payload}}),
  [SET_UNREADED]:(state,action)=>update(state,{filters:{unreaded:{$set:action.payload},
                                                        searchView:{$set:initialState.filters.searchView},
                                                        tags:{$set:initialState.filters.tags},
                                                        search:{$set:initialState.filters.search}}}),
  [SET_SEARCH]:(state,action)=>update(state,{filters:{search:{$set:action.payload},
                                                      tags:{$set:initialState.filters.tags},
                                                      unreaded:{$set:initialState.filters.unreaded}}}),
  [SET_SEARCH_VIEW]:(state,action)=>update(state,{filters:{searchView:{$set:action.payload}}}),
  [SET_TAGS]:(state,action)=>update(state,{filters:{tags:{$set:action.payload},
                                                      searchView:{$set:initialState.filters.searchView},
                                                      search:{$set:initialState.filters.search},
                                                      unreaded:{$set:initialState.filters.unreaded}}}),
  [SET_AVAILABLE_TAGS_VIEW]:(state,action)=>update(state,{filters:{availableTagsView:{$set:action.payload}}})
},initialState)

export default reducer
