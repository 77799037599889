import React, { Component } from "react";
import Translate from "../shared/i18n/Translate";
import Breadcrumb from "../common/breadcrumb";
import Title from "../common/title";
import Location from "./cards/location";
import Data from "./cards/date";
import QuickEvent from "./cards/quick-event";
import Timeline from "./cards/timeline";
import Form from "./cards/form";

class Edit extends Component {
  constructor(props) {
    super(props);
    this.saveQEvent = this.saveQEvent.bind(this);
  }
  saveQEvent(data) {
    //this.props.saveQuickEvent(data);
    this.props.task.timeline.timeline.push(data);
  }
  render() {
    const { goToHome, goToList, isEditing, strings, loggedUser, task } =
      this.props;
    const title = isEditing ? `${strings.editTask}` : `${strings.newTask}`;
    let breadcrumb = [
      { text: strings.home, toUrl: goToHome },
      { text: strings.tasks, toUrl: goToList },
      { text: title },
    ];
    return (
      <React.Fragment>
        <Title title={title} showBtn={false}>
          <Breadcrumb list={breadcrumb} />
        </Title>
        <section className='content'>
          <div className='row'>
            {task && (
              <div className='col-md-4'>
                <Location {...this.props} />
              </div>
            )}
            {task && (
              <div className='col-md-4'>
                <Data {...this.props} />
              </div>
            )}
            <div className='col-md-4'>
              <QuickEvent {...this.props} saveQEvent={this.saveQEvent} />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-8'>
              <Form {...this.props} />
            </div>
            <div className='col-md-4'>
              {task && task.timeline && (
                <Timeline
                  loggedUser={loggedUser}
                  timeline={task.timeline}
                  strings={strings}
                />
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Translate(Edit, "Tasks");
