export const SET_FOLDER="app/inbox/SET_FOLDER"

export const NEXT_PAGE="app/inbox/NEXT_PAGE"
export const BACK_PAGE="app/inbox/BACK_PAGE"
export const RESET_PAGE="app/inbox/RESET_PAGE"
export const INBOX_LOADING="app/inbox/INBOX_LOADING"

export const SET_UNREADED="app/inbox/SET_UNREADED"
export const SET_SEARCH="app/inbox/SET_SEARCH"
export const SET_SEARCH_VIEW="app/inbox/SET_SEARCH_VIEW"
export const SET_TAGS="app/inbox/SET_TAGS"
export const SET_AVAILABLE_TAGS_VIEW="app/inbox/SET_AVAILABLE_TAGS_VIEW"
