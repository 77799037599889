import React from 'react'
import ReactDOM from  'reactDOM'
import ReactTooltip from 'react-tooltip'
import $ from 'jquery'
import jQuery from 'jquery'
var moment = require('moment')
import Loader from '../shared/PulseLoader'
import TagsFilter from "../filters/TagsFilter"
window.$ = $
window.jQuery = jQuery

import helpers from "../../utils/helpers"
//import timezoneHelper from "../../utils/timezoneHelper"

import Translate from '../shared/i18n/Translate'

var DevicesPresentation =  class extends React.Component {
  constructor(props){
    super(props)
    this.getRemainingDevices=this.getRemainingDevices.bind(this)
    this.searchInputEnterListener=this.searchInputEnterListener.bind(this)
    this.handleSetSearchFilter=this.handleSetSearchFilter.bind(this)
  }
  componentDidMount(){
    this.props.refreshDevices()
    this.props.refreshClients()
    this.props.refreshOperators()
  }
  componentDidUpdate(prevProps){
    if(this.props.loggedUser && prevProps.loggedUser && prevProps.loggedUser.currentAccount!==this.props.loggedUser.currentAccount)
      this.props.refreshDevices()
  }
  getRemainingDevices(){
    var cantDevices = this.props.devices.length
    var start = this.props.page*50 + (cantDevices>0? 1:0)
    var end = this.props.page*50 + this.props.devices.length
    return start + "/" + end
  }
  searchInputEnterListener(e){
    var input=e.target
    var keyCode = e.charCode;
    if (keyCode == '13'){// Enter pressed
      this.handleSetSearchFilter(input.value)
    }
  }
  handleSetSearchFilter(query){
    if(!this.props.loading)
      this.props.setSearchFilter(query)
  }
  renderToolTip(id, time, timezone, trads, isTime) {
    if (time == 0) {
      return null;
    }
    return <ReactTooltip id={id}>
      <span>{isTime ? `${trads.lastRead} :` : ''} {isTime ? helpers._transformUtcToTimezone(time, timezone, true) : time}</span>
    </ReactTooltip>
  }
  renderTextToolTip(id, label, status) {
    return <a data-tip data-for={id} className={`label label-${label}`}>
      {status}
    </a>
  }
  renderStatus(status, time, trads, loggedUser, id, isTime = true) {
    const timezone = loggedUser.preferences.timezone;
    const ids = `${id}Device`;
    const statusCondition = status.toLowerCase();
    if (statusCondition === 'warning'){
      return <div>
        {this.renderTextToolTip(ids, 'warning', status)}
        {this.renderToolTip(ids, time, timezone, trads, isTime)}
      </div>
    }
    if (statusCondition === 'invalid'){
      return <div>
        {this.renderTextToolTip(ids, 'danger', status)}
        {this.renderToolTip(ids, time, timezone, trads, isTime)}
      </div>
    }
    if (statusCondition === 'offline'){
      return <div>
        {this.renderTextToolTip(ids, 'danger', status)}
        {this.renderToolTip(ids, time, timezone, trads, isTime)}
      </div>
    }
    return (<span className="label label-success">
      {status}
    </span>)
  }
  renderHealth(item, trads, loggedUser, ids) {
    if (item.healthConditionListMessage == '') {
      return null;
    }
    let label = 'success';
    switch (item.healthCondition) {
      case '2':
        label = 'gold';
        break;
      case '3':
      case '4':
      case '5':
      case '7':
      case '8':
      case '9':
      case '10':
      case '11':
      case '12':
      case '13':
      case '14':
      case '15':
        label = 'danger'
        break;
      case '6':
        label = 'warning'
        break;
      default:
        break;
    }
    return <div>
      <a data-tip data-for={ids} className={`label label-${label}`}>
        {item.healthConditionListMessage}
      </a>
      {this.renderToolTip(ids, item.healthConditionMessage, loggedUser.preferences.timezone, trads, false)}
    </div>
  }
  render() {
    const {loading,searchView,setSearchView,setSearchFilter,status,fetchDevices, goToDevice,goToHome,routeRegisterSingleDevice,
        devices,refreshDevices,nextPage,backPage,setActiveStatus,setInactiveStatus,strings,loggedUser} = this.props

    var DevicesControls =
    (<div className="mailbox-controls">
      <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap",alignItems:"center", margin:"0px 5px"}}>
        <div style={{display:"flex"}}>
          <div style={{display:"flex",marginRight:"40px",alignItems:"center"}}>
            <button type="button" className="btn btn-default btn-sm" onClick={refreshDevices}>
              <i className="fa fa-refresh"></i>
            </button>
          </div>
          <div style={{display:"flex", flexWrap:"wrap"}}>
            <div className="checkbox">
              <label className="box-title" style={{marginRight:"10px"}}>
                <input ref={(i)=>this.checkboxActive=i} type="checkbox" onChange={refreshDevices} />
                {strings.active}
              </label>
              <label className="box-title">
                <input ref={(i)=>this.checkboxAuthorized=i} type="checkbox" onChange={refreshDevices} />
                {strings.authorized}
              </label>
            </div>
            <div style={{display:"flex", alignItems:"center", marginLeft:"40px"}}>
              <div className="box-title" style={{fontWeight:"600",marginRight:"10px"}}>{"Tags"}</div>
              <TagsFilter strings={strings} />
            </div>
          </div>
        </div>
        <div style={!loading? {display:"none"} : {}}>
          <Loader color="#3c8dbc" size="9px" margin="4px"/>
        </div>
        <div className="pull-right" style={{margin:"5px 0px"}}>
          {this.getRemainingDevices()}
          <div className="btn-group" style={{marginLeft:"10px"}}>
            <button type="button" className="btn btn-default btn-sm" onClick={backPage}><i className="fa fa-chevron-left"></i></button>
            <button type="button" className="btn btn-default btn-sm" onClick={nextPage}><i className="fa fa-chevron-right"></i></button>
          </div>
          {/* <!-- /.btn-group --> */}
        </div>
      </div>
      {/* <!-- /.pull-right --> */}
    </div>
  )


  var Breadcrumb = ({strings}) => (
    <ol className="breadcrumb">
      <li><div onClick={goToHome} style={{cursor:"pointer"}}><i className="fa fa-dashboard"></i> {strings.home}</div></li>
      <li className="active">{"Devices"}</li>
    </ol>
  )
  Breadcrumb = Translate(Breadcrumb,"Breadcrumb")

  return (
            <div>
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">

                    <h1 style={{display:"flex",justifyContent:"space-between"}}>
                      {strings.deviceManagement}
                      <small></small>
                      <div style={{display:"flex"}}>
                        <div className="btn btn-primary btn-block margin-bottom" style={{marginBottom:"0",width:"auto",marginRight:"164px"}} onClick={()=>routeRegisterSingleDevice()}>
                          {strings.registerDeviceSingle}
                        </div>
                      </div>
                    </h1>

               {/* <!--   <Breadcrumb strings={strings} />  --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                  <div className="row">
                    {/* <!-- /.col --> */}
                    <div className="col-md-10">
                      <div className="box box-primary">
                        <div className="box-header with-border" style={{padding:"0"}}>

                          {/* <!-- /.box-tools --> */}
                        </div>
                        {/* <!-- /.box-header --> */}
                        <div className="box-body no-padding">
                          {DevicesControls}
                          <div className="table-responsive mailbox-messages">
                            <table className="table table-hover table-striped">
                              <tbody>
                                <tr>
                                  <th>{strings.deviceName}</th>
                                  <th>{strings.deviceSn}</th>
                                  <th>{strings.gateway}</th>
                                  <th>{strings.reporting}</th>
                                  <th>{strings.reading}</th>
                                  <th>{strings.healthy}</th>
                                </tr>
                                {devices.map((op,key)=>(
                                  <tr key={key} onClick={()=>{goToDevice(op.deviceId)}} style={{cursor:"pointer"}}>
                                    <td className="mailbox-name" style={{verticalAlign:"middle"}}>{(op.devInfo? op.devInfo.name:"")}</td>
                                    <td className="mailbox-subject" style={{verticalAlign:"middle"}}>{(op.devInfo? op.devInfo.sn:"")}</td>
                                    <td className="mailbox-subject" style={{verticalAlign:"middle"}}>{op.gateway_guid}</td>
                                    <td className="mailbox-subject" style={{verticalAlign:"middle"}}>
                                      {this.renderStatus(op.status, op.last_reading_time, strings, loggedUser, `reporting${key}`)}
                                    </td>
                                    <td className="mailbox-subject" style={{verticalAlign:"middle"}}>
                                      {this.renderStatus(op.last_reading_status, op.last_reading_status_description, strings, loggedUser, `reading${key}`, false)}
                                    </td>
                                    <td className="mailbox-subject" style={{verticalAlign:"middle"}}>
                                      {this.renderHealth(op, strings, loggedUser, `health${key}`)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {/* <!-- /.table --> */}
                          </div>
                          {/* <!-- /.mail-box-messages --> */}
                        </div>
                        {/* <!-- /.box-body --> */}
                        <div className="box-footer no-padding">
                          {DevicesControls}
                        </div>
                      </div>
                      {/* <!-- /. box --> */}
                    </div>
                    {/* <!-- /.col --> */}
                  </div>
                  {/* <!-- /.row --> */}
                </section>
                {/* <!-- /.content --> */}
            </div>
    )
  }
}

export default Translate(DevicesPresentation,"Devices")
