import React from 'react'

import Title from '../common/title';
import Card from '../common/card';
import DeleteModal from "../modals/delete";
import Translate from '../shared/i18n/Translate';
import Controls from '../common/controls';
import Breadcrumb from '../common/breadcrumb';

class TagsView extends React.Component {
  constructor(props){
    super(props)
    this.onNew = this.onNew.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.onPrev = this.onPrev.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);

    this.state = {
      itemSelectedText: null,
      filterActive: null,
      paging: {
        offset: 0,
        page: 1,
        limit: 50,
        total: 0
      }
    };
  }
  componentDidMount() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.props.getTags(this.state.paging.offset, this.state.paging.limit);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.listTags !== prevProps.listTags) {
      this.setState({
        paging: {
          ...this.state.paging,
          total: this.props.listTags.length
        }
      })
    }
    if (this.props.wasEdit !== prevProps.wasEdit && this.props.wasEdit) {
      this.props.getTags(0, this.state.paging.limit);
      this.props.changeStatusEdit();
    }
  }

  onNew(event) {
    event.preventDefault();
    this.props.goNew();
  }

  onRefresh() {
    this.props.getTags(0, this.state.paging.limit);
  }

  onNext(offset) {
    this.setState({
      paging: {
        ...this.state.paging,
        offset: offset,
        page: this.state.paging.page + 1
      }
    })
    this.props.getTags(offset, this.state.paging.limit);
  }

  onPrev(offset) {
    this.setState({
      paging: {
        ...this.state.paging,
        page: this.state.paging.page - 1,
        offset: offset
      }
    })
    this.props.getTags(offset, this.state.paging.limit);
  }

  onFilter(event, method) {
    event.preventDefault();
    if (method) {
      this.setState({filterActive: method});
      this.props.getTagsFilter(method);
    } else {
      this.setState({filterActive: null});
      this.onRefresh();
    }
  }

  onChangeStatus(event, ds, index) {
    event.preventDefault();
    let data = ds;
    data = {
      ...data,
      active: !ds.active
    }
    const editInline = true;
    this.props.saveTag(data, editInline);
  }

  renderStatus(ds, index) {
    if (!ds.platform_tag) {
      return <a onClick={(event) => this.onChangeStatus(event, ds, index)} href="" className="item-row">
        <span className={"label label-" + (ds.active ? 'success' : 'warning')}>{ds.active ? 'yes' : 'no'}</span>
      </a>
    }
    return <span className={"label label-" + (ds.active ? 'success' : 'warning')}>{ds.active ? 'yes' : 'no'}</span>
  }

  render() {
    const { listTags, strings, goToHome } = this.props;
    const breadcrumb = [
      {text: strings.home, toUrl: goToHome},
      {text: strings.title}
    ];
    return <div>
      <Title title={strings.title} showBtn={false} >
        <Breadcrumb list={breadcrumb} />
      </Title>
      <section className="content">
        <div className="row">
          <div className="col-md-3">
            <a href="" onClick={(event) => {this.onNew(event)}} className="btn btn-primary btn-block margin-bottom">
                {strings.new}
            </a>
            <div className="box box-solid">
              <div className="box-header with-border">
                <h3 className="box-title">{strings.filter}</h3>
              </div>
              <div className="box-body no-padding">
                <ul className="nav nav-pills nav-stacked" style={{cursor:"pointer"}}>
                  <li>
                    <a href="" onClick={(event) => {this.onFilter(event)}}>
                      <i className="fa fa-check"></i> {strings.all}
                    </a>
                  </li>
                  <li className={this.state.filterActive == "active" ? "active" : ""}>
                    <a href="" onClick={(event) => {this.onFilter(event, 'active')}}>
                      <i className="fa fa-check-circle"></i> {strings.active}
                    </a>
                  </li>
                  <li className={this.state.filterActive == "inactive" ? "active" : ""}>
                    <a href="" onClick={(event) => {this.onFilter(event, 'inactive')}}>
                      <i className="fa fa-times-circle"></i> {strings.inactive}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">
                  {this.state.filterActive == "active" ? `${strings.active} ` : ""}
                  {this.state.filterActive == "inactive" ? `${strings.inactive} ` : ""}
                  {strings.title}
                </h3>
              </div>
              <div className="box-body no-padding">
                <div className="table-responsive mailbox-messages">
                  <Controls onRefresh={this.onRefresh} paging={this.state.paging} onNext={this.onNext} onPrev={this.onPrev}/>
                  <table className="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>{strings.name}</th>
                        <th>{strings.active}</th>
                        <th>{strings.platform}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listTags.length > 0 && listTags.map((ds, index) => {
                        return <tr key={`dt${index}`}>
                          <td>{ds.descr}</td>
                          <td>
                            {this.renderStatus(ds, index)}
                          </td>
                          <td><span className={"label label-" + (ds.platform_tag ? 'success' : 'warning')}>{ds.platform_tag ? 'yes' : 'no'}</span></td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                  <Controls onRefresh={this.onRefresh} paging={this.state.paging} onNext={this.onNext} onPrev={this.onPrev}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  }
}

export default Translate(TagsView, "Tag")
