import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PortsManagePresentation from './PortsManagePresentation'
import * as portsListActions from "../../redux/actionCreators/portsListActionCreators"
import routes from "../../utils/routes"
import helpers from "../../utils/helpers"

const mapStateToProps = function(state, ownProps) {
  var portId = helpers.queryStringToJSON(ownProps.location.search).id
  var port
  if(portId)
    port = state.portsList.filter(p=>p.id==portId)[0]

  return {
    portId: portId,
    isEditing: portId? true:false,
    port: port,
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    goToPorts:()=>dispatch(push(routes.routePorts())),
    goToHome:()=>dispatch(push(routes.routeHome())),
    setPort:(port)=>dispatch(portsListActions.setPort(port)),
    editPort:(port)=>dispatch(portsListActions.editPort(port))
  }
}

const PortsManageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PortsManagePresentation)

export default PortsManageContainer
