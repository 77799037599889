import React, { Component } from "react";
import CommonField from "./CommonFields";
class WaterTank extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { strings, assetType, onChangeConfig } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className='col-sm-11' style={{ maxWidth: "775px" }}>
          <div className='box box-warning' style={{ cursor: "pointer" }}>
            <div className='box-header with-border'>
              <h3 className='box-title'>{"Configuration"}</h3>
              <div className='box-tools pull-right'>
                <button type='button' className='btn btn-box-tool'>
                  <i className='fa fa-plus' />
                </button>
              </div>
            </div>
            <div className='box-body'>
              <CommonField {...this.props} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WaterTank;
