import React from 'react'

const ERPIntegration =()=>{

  return   <div>
      <section className="content-header">
        <h1 style={{marginBottom:"10px"}}>
          {"ERP Integration"}
          <small></small>
        </h1>
      </section>
      <div className="col-md-12">
        <div className="box box-info">


      <form autoComplete="off" className="form-horizontal">
        <div className="box-body" style={{padding:"26px auto"}}>

          <div className="form-group">
            <label className="col-sm-2 control-label">{"ERP System"}</label>
            <div className="col-sm-10">
              <select  className="form-control" disabled="">
                <option>OpenBravo</option>
                <option>SAP</option>
              </select>
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-2 control-label">{"URL"}</label>
            <div className={"col-sm-10 "}>
              <input  className="form-control" placeholder={"URL"} />
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-2 control-label">{"User"}</label>
            <div className={"col-sm-10 "}>
              <input  className="form-control" placeholder={"User"} />
            </div>
          </div>

          <div className="form-group">
            <label className="col-sm-2 control-label">{"Password"}</label>
            <div className={"col-sm-10 "}>
              <input  type="password" className="form-control" placeholder={"Password"} />
            </div>
          </div>

        </div>

        <div className="box-footer" style={{display:"flex",justifyContent:"center"}}>
          <button className="btn btn-default pull-left" style={{cursor:"pointer",padding:"6px 25px",marginRight:"100px"}} onClick={(e)=>e.preventDefault()}
                   >{"Cancel"}</button>
          <button type="submit" onClick={(e)=>e.preventDefault()}
                  className="btn btn-info pull-right" style={{padding:"6px 25px"}}>{"Save"}</button>
        </div>

      </form>
        </div>
      </div>
    </div>
}

export default ERPIntegration
