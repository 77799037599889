import {handleActions} from "redux-actions"
import {SET_CONVERSATIONS} from "../actionTypes/conversationsActions.js"
var update = require('immutability-helper');

const initialState=[]

const reducer=handleActions({
  [SET_CONVERSATIONS]:(state,action)=>update(state,{$set:action.payload}),
  ["NEW_CONVERSATION"]:(state,action)=>{
    const {chat_id, id} = action.payload

    var index=0
    const conversation = state.filter(conv=>conv.id==chat_id)[0]
    if(!conversation){
      return update(state, {$unshift:[action.payload]}) // unshift: meto adelante (asi sigue ordenado por tiempo)
    }

    return state
  },
},initialState)

export default reducer
