import React, { Component } from "react";
import { Tooltip } from "antd";
import "antd/lib/tooltip/style/css";
import helpers from "../../utils/helpers";

class Metrics extends Component {
  constructor(props) {
    super(props);
    this._parseValue = this._parseValue.bind(this);
  }
  _renderLevel(values, strings, minimunInventoryLevel) {
    const backgroundColorStyle =
      minimunInventoryLevel &&
      minimunInventoryLevel !== 0 &&
      values.volumen < minimunInventoryLevel
        ? { backgroundColor: "#f9acbb" }
        : {
            backgroundColor: "#3c8dbc",
          };

    return (
      <div>
        <Tooltip title={this._renderToolTip(values, strings)}>
          <div className='zd-box-inline'>
            <span>{strings.level}: </span>
            <span className={`label`} style={backgroundColorStyle}>
              {values.fill.toFixed(0)} %
            </span>
          </div>
        </Tooltip>
      </div>
    );
  }

  _parseValue(keys, value) {
    switch (keys) {
      case "fill":
        return `${value.toFixed(0)} %`;
        break;
      case "temperature":
        return `${value} °C`;
        break;
      case "pumpOnOFF":
        return value ? "true" : "false";
        break;
      case "timestamp":
        return helpers._transformUtcToTimezone(
          value,
          "America/Belize", // this.props.loggedUser.preferences.timezone,
          true
        );
        break;

      default:
        return value;
        break;
    }
  }

  _renderToolTip(values, strings) {
    const listValues = Object.keys(values);
    return (
      <ul className='zd-list-tooltip'>
        {listValues.map((value) => {
          return (
            <li key={value}>
              {strings[value]}:{" "}
              <strong>{this._parseValue(value, values[value])}</strong>
            </li>
          );
        })}
      </ul>
    );
  }

  render() {
    const { values, strings, minimunInventoryLevel } = this.props;
    return (
      <React.Fragment>
        {this._renderLevel(values, strings, minimunInventoryLevel)}
      </React.Fragment>
    );
  }
}

export default Metrics;
