import { createAction } from 'redux-actions'
import {NEXT_PAGE,BACK_PAGE,RESET_PAGE,ASSETTYPES_LOADING} from "../actionTypes/assetTypesActions"
import * as assetTypesListActions from "./assetTypesListActionCreators"
import api from "../../utils/api"

export const setNextPage=createAction(NEXT_PAGE)
export const setBackPage=createAction(BACK_PAGE)
export const setAssetTypesLoading=createAction(ASSETTYPES_LOADING)
export const setResetPage=createAction(RESET_PAGE)

export const refreshAssetTypes=()=>{
  return (dispatch, getState) => {
    const assetTypes=getState().assetTypes
    if(assetTypes.loading) return;

    dispatch(setAssetTypesLoading(true))

    return dispatch(assetTypesListActions.fetchAssetTypes(0,50))
              .then(()=>dispatch(setResetPage()))
              .then(()=>dispatch(setAssetTypesLoading(false)))
              .catch(err=>dispatch(setAssetTypesLoading(false)))
  }
}

export const nextPage=()=>{
  return (dispatch, getState) => {
    const assetTypesList=getState().assetTypesList
    const assetTypes=getState().assetTypes
    if(assetTypesList.length < 50 || assetTypes.loading) return;

    dispatch(setAssetTypesLoading(true))

    return dispatch(assetTypesListActions.fetchAssetTypes(assetTypesList.length*(assetTypes.page+1),50))
              .then(()=>dispatch(setNextPage()))
              .then(()=>dispatch(setAssetTypesLoading(false)))
  }
}

export const backPage=()=>{
  return (dispatch, getState) => {
    const assetTypesList=getState().assetTypesList
    const assetTypes=getState().assetTypes
    if(assetTypes.page==0 || assetTypes.loading) return;

    dispatch(setAssetTypesLoading(true))

    return dispatch(assetTypesListActions.fetchAssetTypes(assetTypesList.length*(assetTypes.page-1),50))
              .then(()=>dispatch(setBackPage()))
              .then(()=>dispatch(setAssetTypesLoading(false)))
  }
}
