import React, { Component } from "react";

class Location extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { location, asset_descr } = this.props.task;
    const { strings } = this.props;
    return (
      <div className='box box-primary'>
        {
          <div className='box-header with-border'>
            <i className='fa fa-map-marker'></i>
            <h3 className='box-title'>{strings.location}</h3>
          </div>
        }
        <div className='box-body'>
          <dl>
            <dt>{strings.location}</dt>
            <dd>{location.decr}</dd>
            <dt>{strings.asset}</dt>
            <dd>{asset_descr}</dd>
            <dt></dt>
            <dd></dd>
            <dt></dt>
            <dd></dd>
          </dl>
        </div>
      </div>
    );
  }
}

module.exports = Location;
