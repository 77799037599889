var moment = require("moment-timezone");
import timezoneHelper from "./timezoneHelper";

export const transformConversations = (convsFromApi, timezone = null) => {
  convsFromApi.forEach((conv, key) => {
    convsFromApi[key].creation_time = _transformUtcToTimezone(
      conv.creation_time,
      timezone
    );
    convsFromApi[key].last_time = _transformUtcToTimezone(
      conv.last_time,
      timezone
    );
    if (conv.lastMessages) {
      convsFromApi[key].lastMessages.forEach((msg, msgKey) => {
        convsFromApi[key].lastMessages[msgKey].msg_time =
          _transformUtcToTimezone(msg.msg_time, timezone);
        convsFromApi[key].lastMessages[msgKey].read_time =
          _transformUtcToTimezone(msg.read_time, timezone);
      });
    }
  });

  return convsFromApi;
};

export const transformConversation = (convFromApi, timezone = null) => {
  convFromApi.creation_time = _transformUtcToTimezone(
    convFromApi.creation_time,
    timezone
  );
  convFromApi.last_time = _transformUtcToTimezone(
    convFromApi.last_time,
    timezone
  );
  if (convFromApi.lastMessages) {
    convFromApi.lastMessages.forEach((msg, msgKey) => {
      convFromApi.lastMessages[msgKey].msg_time = _transformUtcToTimezone(
        msg.msg_time,
        timezone
      );
      convFromApi.lastMessages[msgKey].read_time = _transformUtcToTimezone(
        msg.read_time,
        timezone
      );
    });
  }

  return convFromApi;
};

export const transformMessage = (messageFromApi, timezone = null) => {
  messageFromApi.msg_time = _transformUtcToTimezone(
    messageFromApi.msg_time,
    timezone
  );
  messageFromApi.read_time = _transformUtcToTimezone(
    messageFromApi.read_time,
    timezone
  );
  return messageFromApi;
};

export const transformTags = (tagsFromApi) => {
  var tags = [];
  tagsFromApi.forEach((tag) => tags.push(tag.descr));

  return tags;
};

const _transformUtcToTimezone = (apiUtcDate, timezone) => {
  return moment
    .utc(apiUtcDate)
    .tz(timezoneHelper.getTimezone(timezone))
    .format("YYYY-MM-DD HH:mm:ss.SSS");
};

export const transformSeries = (datas, type = "area") => {
  const series = [];
  if (datas.length > 0) {
    const keys = Object.keys(datas[0]);
    keys.shift();
    keys.map((k) => {
      series.push({
        type: type,
        name: k,
        data: [],
      });
    });
    datas.map((data) => {
      const time = data["time"].toFixed(3).toString().replace(".", "");
      keys.map((g, x) => {
        const aux = [Number(time), data[g]];
        series[x].data.push(aux);
      });
    });
  }
  return series;
};

export const transformSerie = (datas, name, type = "area") => {
  let serie = {};
  if (datas.length > 0) {
    serie = {
      type: type,
      name: name,
      data: [],
    };
    datas.map((data) => {
      const time = data["time"].toFixed(3).toString().replace(".", "");
      const aux = [Number(time), data.val];
      serie.data.push(aux);
    });
  }
  return serie;
};
const FORMAT_DATE = "DD-MM-YYYY";
const FORMAT_DATE_HR = "DD-MM-YYYY h:mm";
export const transformToColumn = (datas, type = "area") => {
  /* let data = {categories: [],
    series: []}; */
  let series = [];
  if (datas.length > 0) {
    /* const cats = data.map((data) => moment.unix(data.startTime).format(FORMAT_DATE))
    data.categories = cats;
    data.forEach(data => {

    }); */
    const data = datas.map((data) => {
      const today = moment.unix(data.startTime).format(FORMAT_DATE_HR);
      return {
        name: today,
        y: data.fillLiters,
        drilldown: today,
        startTime: today,
        endTime: moment.unix(data.endTime).format(FORMAT_DATE_HR),
        duration: data.durationInHours,
        initialVolume: data.initialVolume,
        endVolume: data.endVolume.toFixed(2),
      };
    });
    series.push({ name: "Refill", colorByPoint: true, data });
  }
  return series;
};
