import {
  createAction
} from 'redux-actions'
import {
  GET_TASK_TEMPLATES
} from '../../actionTypes/tasktemplates';
import api from '../../../utils/api'
import routes from '../../../utils/routes'
import {
  push
} from 'react-router-redux'

export const getTaskTemplates=createAction(GET_TASK_TEMPLATES)

export const fetchTasks = (offset, limit) => {
  return (dispatch) => {
    return _getTaskTemplates(offset, limit)
      .then(data => {
        return data.map(task => {
          return {
            ...task,
            key: task.id
          }
        })
      })
      .then(data => dispatch(getTaskTemplates(data)))
  }
}

export const onDeleteTask = (id) => {
  return (dispatch) => {
    return _delTaskTemplates(id)
    .then(() => dispatch(fetchTasks()));
  }

}

export const saveTasks = (data) => {
  return (dispatch) => {
    return _postTasks(data)
      .then(() => dispatch(push(routes.routeTaskTemplates())));
  }
}

const _getTaskTemplates = (offset, limit) => {
  return api.getTaskTemplates(offset, limit);
}

const _postTasks = (data) => {
  return api.postTaskTemplates(data);
}

const _delTaskTemplates = (id) => {
  return api.delTaskTemplates(id);
}