import React, { Component } from "react";
import helpers from "../../utils/helpers";

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = { listValues: [] };
  }

  componentDidMount() {
    const list = [];
    const opt = [
      "fill",
      "volumen",
      "temperature",
      "timestamp",
      "last_hour_consumption",
      "last_24hour_consumption",
      "expected_consumption_per_hour",
      "days_to_reposition",
      "tics_per_hour",
      "engineOn",
    ];
    const { strings } = this.props;
    opt.forEach((value) => {
      const item = this.props.values[value];
      if (item) {
        switch (value) {
          case "fill":
            list.push({
              value: item.toFixed(2),
              sup: "%",
              label: strings.fill,
              icon: "fa fa-angle-double-up",
              bg: "bg-aqua",
            });
            break;
          case "volumen":
            list.push({
              value: item < 0 ? "--.-" : item,
              sup: null,
              label: strings.volumen,
              icon: "ion ion-stats-bars",
              bg: item < 0 ? "bg-empty" : "bg-light-blue",
            });
            break;
          case "temperature":
            list.push({
              value: item,
              sup: "°C",
              label: strings.temperature,
              icon: "ion ion-thermometer",
              bg: "bg-green",
            });
            break;
          case "timestamp":
            list.push({
              value: helpers._transformUtcToTimezone(
                item,
                "America/Belize",
                true
              ),
              sup: null,
              label: strings.timestamp,
              icon: "ion ion-calendar",
              bg: "bg-yellow",
              style: { fontSize: "26px", marginBottom: "26px" },
            });
            break;
          case "last_hour_consumption":
            list.push({
              value: item,
              sup: null,
              label: strings[value],
              icon: "ion ion-ios-timer",
              bg: "bg-yellow",
            });
            break;
          case "last_24hour_consumption":
            list.push({
              value: item,
              sup: null,
              label: strings[value],
              icon: "ion ion-calendar",
              bg: "bg-olive",
            });
            break;
          case "expected_consumption_per_hour":
            list.push({
              value: item,
              sup: null,
              label: strings[value],
              icon: "ion ion-battery-charging",
              bg: "bg-purple",
            });
            break;
          case "days_to_reposition":
            list.push({
              value: item,
              sup: null,
              label: strings[value],
              icon: "fa fa-clock-o",
              bg: "bg-teal",
            });
            break;
          case "tics_per_hour":
            list.push({
              value: item,
              sup: null,
              label: strings[value],
              icon: "fa fa-clock-o",
              bg: "bg-blue",
            });
            break;
          case "engineOn":
            list.push({
              value: item ? strings.on : strings.off,
              sup: null,
              label: strings.status,
              icon: "ion ion-happy",
              bg: item ? "bg-green" : "bg-empty",
            });
            break;

          default:
            break;
        }
      }
    });
    this.setState({
      listValues: list,
    });
  }
  render() {
    return (
      <React.Fragment>
        {this.state.listValues.length > 0 &&
          this.state.listValues.map((value) => {
            return (
              <div key={value.label} className='col-lg-3 col-xs-6'>
                <div className={`small-box ${value.bg}`}>
                  <div className='inner'>
                    <h3 style={value.style ? value.style : {}}>
                      {value.value}
                      {value.sup && (
                        <sup style={{ fontSize: 20 }}>{value.sup}</sup>
                      )}
                    </h3>
                    <p>{value.label}</p>
                  </div>
                  <div className='icon'>
                    <i className={value.icon} />
                  </div>
                </div>
              </div>
            );
          })}
      </React.Fragment>
    );
  }
}

export default Card;
