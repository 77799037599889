import React from "react";
import ReactDOM from "reactDOM";
import helpers from "../../utils/helpers";
import api from "../../utils/api";
//import timezoneHelper from "../../utils/timezoneHelper"
import Translate from "../shared/i18n/Translate";
import $ from "jquery";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";

class CustomDatePickerInput extends React.Component {
  render() {
    return (
      <div onClick={this.props.onClick}>
        <div className='react-datepicker-wrapper'>
          <div className='react-datepicker__input-container'>
            <input type='text' className='' value={this.props.value} readOnly />
          </div>
        </div>
      </div>
    );
  }
}

var DeviceManagePresentation = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: true,
      inputDescrValid: true,
      inputDeviceTypeValid: true,
      inputLatValid: true,
      inputLonValid: true,
      inputFrecuencyValid: true,

      inputInstallDate: moment(new Date()),
      inputActivationDate: moment(new Date()),
      inputResetDate: moment(new Date()),
      inputBatteryInstallDate: moment(new Date()),
      tagsSelected: null,
      tagsPost: [],
    };
    this.handleNewChatClick = this.handleNewChatClick.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    // Default values
    //this.inputCommunicationType.value=""
    this.inputDescr.value = "";
    this.inputDeviceType.value = "";
    this.inputCheckBox.checked = true;
    this.inputLat.value = "";
    this.inputLon.value = "";
    this.inputFrecuency.value = "";

    // Device values (editing)
    if (this.props.isEditing) {
      var device = this.props.device;
      this.inputDescr.value = device.descr;
      this.inputDeviceType.value = device.device_type;
      this.inputCheckBox.checked = device.authorized;
      this.inputLat.value = device.lat;
      this.inputLon.value = device.lon;
      this.inputFrecuency.value = device.frecuency;
      this.setState({
        inputInstallDate: device.installDate
          ? moment(device.installDate)
          : null,
        inputActivationDate: device.activationDate
          ? moment(device.activationDate)
          : null,
        inputResetDate: device.resetDate ? moment(device.resetDate) : null,
        inputBatteryInstallDate: device.batteryInstallDate
          ? moment(device.batteryInstallDate)
          : null,
        tagsSelected:
          device.tags && device.tags.tags
            ? this.getTagsSelected(device.tags.tags)
            : [],
      });
    }

    this.props.getDataSources().then((a) => {
      if (this.props.isEditing) {
        this.selectDataSource.value =
          device.communication_type ||
          this.props.listDataSources[0].datasourceId;
      }
    });

    this.props.getTags();
  }

  getTagsSelected(tagsDefault) {
    const ts = [];
    tagsDefault.map((t) => {
      ts.push({ value: t, label: t });
    });
    return ts;
  }

  handleNewChatClick(e) {
    e.preventDefault();
    //  var inputCommunicationType=ReactDOM.findDOMNode(this.inputCommunicationType)
    var inputDescr = ReactDOM.findDOMNode(this.inputDescr);
    var inputDeviceType = ReactDOM.findDOMNode(this.inputDeviceType);
    var inputCheckBox = ReactDOM.findDOMNode(this.inputCheckBox);
    var inputLat = ReactDOM.findDOMNode(this.inputLat);
    var inputLon = ReactDOM.findDOMNode(this.inputLon);
    var inputFrecuency = ReactDOM.findDOMNode(this.inputFrecuency);

    var valid = true;
    /*
    if(!helpers.isEmptyString(inputCommunicationType.value)){
      this.setState({inputCommunicationTypeValid:true})
    }else{valid=false; this.setState({inputCommunicationTypeValid:false})}

    if(!helpers.isEmptyString(inputDescr.value)){
      this.setState({inputDescrValid:true})
    }else{valid=false; this.setState({inputDescrValid:false})}

    if(!helpers.isEmptyString(inputDeviceType.value)){
      this.setState({inputDeviceTypeValid:true})
    }else{valid=false; this.setState({inputDeviceTypeValid:false})}

    if(!helpers.isEmptyString(inputLat.value)){
      this.setState({inputLatValid:true})
    }else{valid=false; this.setState({inputLatValid:false})}

    if(!helpers.isEmptyString(inputLon.value)){
      this.setState({inputLonValid:true})
    }else{valid=false; this.setState({inputLonValid:false})}
*/
    if (valid) {
      this.setState({ validation: true });
      var device = {
        deviceId: this.props.isEditing
          ? this.props.device.deviceId
          : this.props.newDeviceId,
        descr: this.inputDescr.value,
        device_type: this.inputDeviceType.value,
        lat: this.inputLat.value,
        lon: this.inputLon.value,
        authorized: this.inputCheckBox.checked ? 1 : 0,
        installDate: this.state.inputInstallDate
          ? this.state.inputInstallDate.valueOf()
          : this.state.inputInstallDate,
        activationDate: this.state.inputActivationDate
          ? this.state.inputActivationDate.valueOf()
          : this.state.inputActivationDate,
        resetDate: this.state.inputResetDate
          ? this.state.inputResetDate.valueOf()
          : this.state.inputResetDate,
        batteryInstallDate: this.state.inputBatteryInstallDate
          ? this.state.inputBatteryInstallDate.valueOf()
          : this.state.inputBatteryInstallDate,
        frecuency: parseInt(this.inputFrecuency.value),
        communication_type:
          this.selectDataSource.value ||
          this.props.listDataSources[0].datasourceId,
        tags: this.state.tagsPost,
      };

      if (this.props.isEditing) {
        api.setDevice(device);
        //this.props.setDevice(device)
        setTimeout(
          () => this.props.goToDevice(this.props.device.deviceId),
          200
        );
      } else {
        api.setSigleDevice(device);
        //this.props.setDevice(device)
        setTimeout(() => this.props.goToDevices(), 200);
      }
    } else {
      this.setState({ validation: false });
    }
  }

  getErrorMessage() {
    if (this.state.validation) return null;

    var element;
    if (!this.state.inputCommunicationTypeValid) {
      element = this.props.strings.name;
    } else if (!this.state.inputDescrValid) {
      element = "description";
    } else if (!this.state.inputDeviceTypeValid) {
      element = "device type";
    }

    return (
      <span style={{ color: "#a94442" }}>
        {this.props.strings.badDataMsg +
          element +
          this.props.strings.badDataMsgValid}
      </span>
    );
  }

  handleSelectChange(selectedOption) {
    const selectedItems = [];
    selectedOption.map((so) => {
      selectedItems.push(so.value);
    });
    this.setState({
      tagsPost: selectedItems,
    });
  }

  render() {
    const { goToHome, goToDevice, device, isEditing, strings, newDeviceId } =
      this.props;

    let defaultSelecteds = [];
    if (isEditing) {
      defaultSelecteds =
        device.tags && device.tags.tags
          ? this.getTagsSelected(device.tags.tags)
          : [];
    }
    var Breadcrumb = ({ strings }) => (
      <ol className='breadcrumb'>
        <li>
          <div onClick={goToHome} style={{ cursor: "pointer" }}>
            <i className='fa fa-dashboard'></i> {strings.home}
          </div>
        </li>
        <li
          onClick={() => goToDevice(device.deviceId)}
          style={{ cursor: "pointer" }}
        >
          {"**device"}
        </li>
        <li style={{ marginBottom: "20px" }} className='active'>
          {isEditing ? device.deviceId : "strings.newOperator"}
        </li>
      </ol>
    );
    Breadcrumb = Translate(Breadcrumb, "Breadcrumb");

    return (
      <div>
        <section className='content-header'>
          <h1 style={{ marginBottom: "10px" }}>
            {isEditing
              ? strings.editDevice
              : strings.registerDevice + ": " + newDeviceId}
            <small></small>
          </h1>
          <Breadcrumb />
        </section>
        <div className='col-md-12'>
          <div className='box box-info'>
            <div
              className='box box-widget widget-user'
              style={{ marginBottom: "36px" }}
            >
              <div
                className='widget-user-header'
                style={{
                  borderRadius: "0",
                  background: "lightgray",
                  height: "100px",
                }}
              ></div>
              <div className='widget-user-image' style={{ top: "23px" }}>
                <img
                  className='img-circle'
                  src='dist/img/user-gcba.jpeg'
                  alt='Device Avatar'
                  style={{ width: "105px" }}
                />
              </div>
            </div>
            <form autoComplete='off' className='form-horizontal'>
              <div className='crud-separator'>{"Info"}</div>
              <div className='box-body'>
                {/*<div className="form-group">
                      <label className="col-sm-2 control-label">{strings.cmmunicationType}</label>
                      <div className={"col-sm-10 "+ (!this.state.inputCommunicationTypeValid? "has-error":"")}>
                        <input ref={(i)=>this.inputCommunicationType=i} type="text" className="form-control" placeholder={strings.cmmunicationType}/>
                      </div>
                    </div>*/}
                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.descr}
                  </label>
                  <div
                    className={
                      "col-sm-10 " +
                      (!this.state.inputDescrValid ? "has-error" : "")
                    }
                  >
                    <input
                      ref={(i) => (this.inputDescr = i)}
                      type='email'
                      className='form-control'
                      placeholder={strings.descr}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.deviceType}
                  </label>
                  <div
                    className={
                      "col-sm-10 " +
                      (!this.state.inputDeviceTypeValid ? "has-error" : "")
                    }
                  >
                    <input
                      autoComplete='new-password'
                      ref={(i) => (this.inputDeviceType = i)}
                      className='form-control'
                      placeholder={strings.deviceType}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.lat}
                  </label>
                  <div
                    className={
                      "col-sm-10 " +
                      (!this.state.inputLatValid ? "has-error" : "")
                    }
                  >
                    <input
                      type='number'
                      ref={(i) => (this.inputLat = i)}
                      className='form-control'
                      placeholder={strings.lat}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.lon}
                  </label>
                  <div
                    className={
                      "col-sm-10 " +
                      (!this.state.inputDeviceTypeValid ? "has-error" : "")
                    }
                  >
                    <input
                      type='number'
                      ref={(i) => (this.inputLon = i)}
                      className='form-control'
                      placeholder={strings.lon}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {strings.authorized}
                  </label>
                  <div className='col-sm-10'>
                    <div className='checkbox'>
                      <label>
                        <input
                          ref={(i) => (this.inputCheckBox = i)}
                          type='checkbox'
                        />
                        {strings.authorized}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <label className='col-sm-2 control-label'>{"Datasource"}</label>
                <div className='col-sm-6 col-lg-4 col-xs-12'>
                  <select
                    ref={(i) => (this.selectDataSource = i)}
                    className='form-control'
                    disabled=''
                  >
                    {this.props.listDataSources.map((d, i) => (
                      <option key={i} value={d.datasourceId}>
                        {d.descr + "-" + d.type + "(" + d.datasourceId + ")"}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='form-group'>
                <label className='col-sm-2 col-xs-12 control-label'>
                  {"Tags"}
                </label>
                <div className='col-sm-6 col-lg-4 col-xs-12'>
                  {/*<Select className="iot-select2" options={this.props.tags} isMulti onChange={this.handleSelectChange} />*/}
                  {/*this.state.tags && this.state.tags.length > 0 && this.state.tagsSelected.length == 0 && <Select className="iot-select2" options={this.state.tags} isMulti onChange={this.handleSelectChange} /> */}
                  {/*this.state.tags && this.state.tags.length > 0 && this.state.tagsSelected.length > 0 && <Select className="iot-select2" defaultValue={this.state.tagsSelected}  options={this.state.tags} isMulti onChange={this.handleSelectChange} /> */}
                  {this.props.listTags.length > 0 && (
                    <Select
                      className='iot-select2'
                      defaultValue={defaultSelecteds}
                      options={this.props.listTags}
                      isMulti
                      onChange={this.handleSelectChange}
                    />
                  )}
                </div>
              </div>

              <div className='crud-separator'>{"Installation"}</div>
              <div className='box-body'>
                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {"Install Date"}
                  </label>
                  <div className='input-group date'>
                    <div className='input-group-addon'>
                      <i className='fa fa-calendar' />
                    </div>
                    <div className='datepicker-wrap'>
                      <DatePicker
                        customInput={<CustomDatePickerInput />}
                        style={{ height: "35px" }}
                        selected={
                          this.state.inputInstallDate
                            ? moment(this.state.inputInstallDate)
                            : null
                        }
                        onChange={(d) => {
                          console.log("D", d, d.valueOf());
                          this.setState({ inputInstallDate: d });
                        }}
                      />
                    </div>
                  </div>
                  {/* /.input group */}
                </div>

                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {"Activation Date"}
                  </label>
                  <div className='input-group date'>
                    <div className='input-group-addon'>
                      <i className='fa fa-calendar' />
                    </div>
                    <div className='datepicker-wrap'>
                      <DatePicker
                        customInput={<CustomDatePickerInput />}
                        style={{ height: "35px" }}
                        selected={
                          this.state.inputActivationDate
                            ? moment(this.state.inputActivationDate)
                            : null
                        }
                        onChange={(d) => {
                          this.setState({ inputActivationDate: d });
                        }}
                      />
                    </div>
                  </div>
                  {/* /.input group */}
                </div>

                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {"Last Reset"}
                  </label>
                  <div className='input-group date'>
                    <div className='input-group-addon'>
                      <i className='fa fa-calendar' />
                    </div>
                    <div className='datepicker-wrap'>
                      <DatePicker
                        customInput={<CustomDatePickerInput />}
                        style={{ height: "35px" }}
                        selected={
                          this.state.inputResetDate
                            ? moment(this.state.inputResetDate)
                            : null
                        }
                        onChange={(d) => {
                          this.setState({ inputResetDate: d });
                        }}
                      />
                    </div>
                  </div>
                  {/* /.input group */}
                </div>

                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {"Frecuency"}
                  </label>
                  <div
                    className={"col-sm-10 "}
                    style={{ paddingLeft: "0px", maxWidth: "237px" }}
                  >
                    <input
                      autoComplete='new-password'
                      ref={(i) => (this.inputFrecuency = i)}
                      className='form-control'
                      placeholder={"Frecuency"}
                    />
                  </div>
                  <div>{"Secs."}</div>
                </div>

                <div className='form-group'>
                  <label className='col-sm-2 control-label'>
                    {"Battery Inst. Date"}
                  </label>
                  <div className='input-group date'>
                    <div className='input-group-addon'>
                      <i className='fa fa-calendar' />
                    </div>
                    <div className='datepicker-wrap'>
                      <DatePicker
                        customInput={<CustomDatePickerInput />}
                        style={{ height: "35px" }}
                        selected={
                          this.state.inputBatteryInstallDate
                            ? moment(this.state.inputBatteryInstallDate)
                            : null
                        }
                        onChange={(d) => {
                          this.setState({ inputBatteryInstallDate: d });
                        }}
                      />
                    </div>
                  </div>
                  {/* /.input group */}
                </div>
              </div>

              <div className='box-footer' style={{ textAlign: "center" }}>
                <button
                  className='btn btn-default pull-left'
                  style={{ padding: "6px 25px", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    goToDevice(device.deviceId);
                  }}
                >
                  {strings.cancel}
                </button>
                <button
                  type='submit'
                  onClick={this.handleNewChatClick}
                  className='btn btn-info pull-right'
                  style={{ padding: "6px 25px" }}
                >
                  {strings.save}
                </button>
                {this.getErrorMessage()}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default Translate(DeviceManagePresentation, "DeviceManage");
