import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import ClientsPresentation from './ClientsPresentation.js'
import * as clientsList from "../../redux/actionCreators/clientsListActionCreators"
import * as clientsActions from "../../redux/actionCreators/clientsActionCreators"
import routes from "../../utils/routes"

const mapStateToProps = function(state) {
  return {
    clients: state.clientsList,
    page: state.clients.page,
    status: state.clients.status,
    loading:state.clients.loading,
    searchView:state.clients.filters.searchView,
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    nextPage:()=>dispatch(clientsActions.nextPage()),
    backPage:()=>dispatch(clientsActions.backPage()),
    setActiveStatus:()=>dispatch(clientsActions.setActiveStatus()),
    setInactiveStatus:()=>dispatch(clientsActions.setInactiveStatus()),
    refreshClients:()=>dispatch(clientsActions.refreshClients()),
    setSearchView:(query)=>dispatch(clientsActions.setSearchView(query)),
    setSearchFilter:(query)=>dispatch(clientsActions.setSearchFilter(query)),
    fetchClients:()=>dispatch(clientsList.fetchClients()),
    goToClientsManage:(client)=>dispatch(push(routes.routeClientsManage(client))),
    goToHome:()=>dispatch(push(routes.routeHome())),
  }
}

const ClientsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientsPresentation)

export default ClientsContainer
