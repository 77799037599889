import { createAction } from "redux-actions";
import {
  SET_DEVICE,
  MERGE_DEVICE,
  SET_DEVICE_VIEW,
  GET_METRICS,
  GET_HELTH_METRICS,
  GET_READ_QUALITY_METRICS,
} from "../actionTypes/deviceSelectedActions";
import api from "../../utils/api";
import routes from "../../utils/routes";
import { push } from "react-router-redux";

export const setDevice = createAction(SET_DEVICE);
export const mergeDevice = createAction(MERGE_DEVICE);
export const setDeviceView = createAction(SET_DEVICE_VIEW);
export const getMetricsDevice = createAction(GET_METRICS);
export const getHelthMetrics = createAction(GET_HELTH_METRICS);
export const getReadQualityMetrics = createAction(GET_READ_QUALITY_METRICS);

export const fetchDevice = (id) => {
  return (dispatch, getState) => {
    return _getDevice(id).then((data) => dispatch(setDevice(data)));
  };
};

export const getListMetricsDevice = (id, day) => {
  return (dispatch) => {
    return _getMetricsDevice(id, day)
      .then((response) => {
        dispatch(getMetricsDevice(_processSerie(response)));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getListMetricsDeviceV2 = (id, day) => {
  return (dispatch) => {
    return _getMetricsDeviceV2(id, day)
      .then((response) => {
        dispatch(getMetricsDevice(_processSerie(response)));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getListHelthMetrics = (id, day) => {
  return (dispatch) => {
    return _getHelthMetrics(id, day)
      .then((response) => {
        dispatch(getHelthMetrics(_processHelth(response)));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
export const getListReadQualityMetrics = (id, day) => {
  return (dispatch) => {
    return _getReadQualityMetrics(id, day)
      .then((response) => {
        dispatch(getReadQualityMetrics(_processReadQuality(response)));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const _getDevice = (id) => {
  return api.getDevice(id);
};

const _getMetricsDevice = (id, day) => {
  return api.getDeviceMetrics(id, day);
};
const _getMetricsDeviceV2 = (id, day) => {
  return api.getDeviceMetricsV2(id, day);
};

const _getHelthMetrics = (id, day) => {
  return api.getHelthMetrics(id, day);
};
const _getReadQualityMetrics = (id, day) => {
  return api.getReadQualityMetrics(id, day);
};

const _processSerie = (datas) => {
  const series = [];
  if (datas.length > 0) {
    const keys = Object.keys(datas[0]);
    keys.shift();
    keys.map((k) => {
      series.push({
        type: "area",
        name: k,
        data: [],
      });
    });
    datas.map((data) => {
      const time = data["time"].toFixed(3).toString().replace(".", "");
      keys.map((g, x) => {
        const aux = [Number(time), data[g]];
        series[x].data.push(aux);
      });
    });
  }
  return series;
};

const _processHelth = (datas) => {
  let series = {};
  if (datas.length > 0) {
    series = {
      type: "flags",
      name: "health",
      color: "#333333",
      shape: "squarepin",
      data: [],
      showInLegend: false,
    };
    datas.map((data) => {
      const time = data["time"].toFixed(3).toString().replace(".", "");
      const aux = {
        x: parseFloat(time),
        title: data.message,
        text: `${data.health} ${data.message_desc}`,
      };
      series.data.push(aux);
    });
  }
  return series;
};
const _processReadQuality = (datas) => {
  let series = {};
  if (datas.length > 0) {
    series = {
      type: "flags",
      name: "Quality",
      color: "#333333",
      shape: "circlepin",
      data: [],
      showInLegend: false,
    };
    datas.map((data) => {
      const time = data["time"].toFixed(3).toString().replace(".", "");
      const aux = {
        x: parseFloat(time),
        title: data.att,
        text: data.message,
      };
      series.data.push(aux);
    });
  }
  return series;
};
