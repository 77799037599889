import { createAction } from 'redux-actions'
import {SET_STATUS,NEXT_PAGE,BACK_PAGE,RESET_PAGE,
        PORTS_LOADING,SET_VIEW } from "../actionTypes/portsActions"
import * as portsListActions from "./portsListActionCreators"
import api from "../../utils/api"

// --------- Paginacion

export const setNextPage=createAction(NEXT_PAGE)
export const setBackPage=createAction(BACK_PAGE)
export const setPortsLoading=createAction(PORTS_LOADING)
export const setResetPage=createAction(RESET_PAGE)
export const setView=createAction(SET_VIEW)


export const refreshPorts=()=>{
  return (dispatch, getState) => {
    const ports=getState().ports
    if(ports.loading) return;

    dispatch(setPortsLoading(true))

    return dispatch(portsListActions.fetchPorts(0,50))
              .then(()=>dispatch(setResetPage()))
              .then(()=>dispatch(setPortsLoading(false)))
              .catch(err=>dispatch(setPortsLoading(false)))
  }
}

export const nextPage=()=>{
  return (dispatch, getState) => {
    const portsList=getState().portsList
    const ports=getState().ports
    if(portsList.length < 50 || ports.loading) return;

    dispatch(setPortsLoading(true))

    return dispatch(portsListActions.fetchPorts(portsList.length*(ports.page+1),50))
              .then(()=>dispatch(setNextPage()))
              .then(()=>dispatch(setPortsLoading(false)))
  }
}

export const backPage=()=>{
  return (dispatch, getState) => {
    const portsList=getState().portsList
    const ports=getState().ports
    if(ports.page==0 || ports.loading) return;

    dispatch(setPortsLoading(true))

    return dispatch(portsListActions.fetchPorts(portsList.length*(ports.page-1),50))
              .then(()=>dispatch(setBackPage()))
              .then(()=>dispatch(setPortsLoading(false)))
  }
}
