import { createAction } from 'redux-actions'
import {SET_LANGUAGE} from "../actionTypes/languageActions"
var moment = require("moment")


const setLang = createAction(SET_LANGUAGE)

export const setLanguage = (lang) => {
  return (dispatch, getState) => {
    moment.locale(lang)
    dispatch(setLang(lang))
  }
}
