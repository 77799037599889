import React from 'react'
import Loader from '../shared/PulseLoader'
import api from "../../utils/api"
import AssetTypeDeleteModal from "../modals/AssetTypeDeleteModal"
import Translate from '../shared/i18n/Translate'


var AssetTypesPresentation =  class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      assetTypeIdToDelete: null
    }
    this.getRemainingAssetTypes = this.getRemainingAssetTypes.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.setAssetTypeToDelete = this.setAssetTypeToDelete.bind(this)
  }
  componentDidMount() {
    //this.props.refreshAssetTypes()
    this.props.getAssetTypes(this.props.loggedUser.clientId, 0, 10);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  getRemainingAssetTypes() {
    var cantAssetTypes = this.props.assetTypes.length
    var start = this.props.page * 50 + (cantAssetTypes > 0 ? 1 : 0)
    var end = this.props.page * 50 + this.props.assetTypes.length
    return start + "/" + end
  }
  setAssetTypeToDelete(assetType) {
    this.setState({
      assetTypeIdToDelete: assetType.id
    })
  }
  onDelete() {
    setTimeout(() => this.props.refreshAssetTypes(), 500)
    api.deleteAssetType(1, this.state.assetTypeIdToDelete).catch(err => console.log(err))
  }
  render() {
    const {
      loading,
      setView,
      view,
      goToAssetTypesManage,
      goToHome,
      assetTypes,
      refreshAssetTypes,
      nextPage,
      backPage,
      strings
    } = this.props

    var AssetTypesControls = (
      <div className="mailbox-controls">
        <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap",alignItems:"center", margin:"0px 5px"}}>
          <div style={{display:"flex"}}>
            <button type="button" className="btn btn-default btn-sm" onClick={refreshAssetTypes}>
              <i className="fa fa-refresh"></i>
            </button>
          </div>
          <div style={(!loading? {display:"none"} : {})}>
            <Loader color="#3c8dbc" size="9px" margin="4px"/>
          </div>
          <div className="pull-right" style={{margin:"5px 0px"}}>
            {this.getRemainingAssetTypes()}
            <div className="btn-group" style={{marginLeft:"15px"}}>
              <button type="button" className="btn btn-default btn-sm" onClick={backPage}><i className="fa fa-chevron-left"></i></button>
              <button type="button" className="btn btn-default btn-sm" onClick={nextPage}><i className="fa fa-chevron-right"></i></button>
            </div>
          </div>
        </div>
      </div>
    )
    return (
      <div>
        <section className="content-header" style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
          <h1>
            {strings.assetTypes}
            <small></small>
          </h1>
          <div style={{display:"flex"}}>
            <div className="btn btn-primary btn-block margin-bottom" style={{marginBottom:"0"}} onClick={()=>goToAssetTypesManage()}>
              {strings.newAssetType}
            </div>
          </div>
        </section>
        {view=="map" ?
          <AssetTypesMap goToAssetTypesManage={goToAssetTypesManage} className="mapContent" assetTypes={assetTypes} lat={-34.440305} lng={-58.546842} zoom={12} />
          : null
        }

        <section className={"content " + (view=="map"? "hidden":"")}>
          <div className="row">
            <div className="col-md-12">
              <div className="box box-primary">
                <div className="box-body no-padding">
                  { AssetTypesControls }
                  <div className="table-responsive mailbox-messages">
                    <table className="table table-hover table-striped">
                      <tbody>
                        <tr>
                          <th style={{paddingLeft:"15px"}}>{strings.id}</th>
                          <th>{strings.class}</th>
                          <th>{strings.description}</th>
                          <th>{strings.manufacturer}</th>
                          <th>{strings.notification}</th>
                          <th></th>
                        </tr>
                        {assetTypes.map((op,key)=>(
                          <tr key={key} onClick={()=>{goToAssetTypesManage({id:op.id})}} style={{cursor:"pointer"}}>
                            <td className="mailbox-name" style={{verticalAlign:"middle"}}>{op.id}</td>
                            <td className="mailbox-name" style={{verticalAlign:"middle"}}>{op.asset_class_descr}</td>
                            <td className="mailbox-date" style={{verticalAlign:"middle"}}>{op.decr}</td>
                            <td className="mailbox-name" style={{verticalAlign:"middle"}}>{op.manufacturer}</td>
                            <td className="mailbox-name" style={{verticalAlign:"middle"}}>
                              {op.notifications_active ? strings.active : strings.inactive}
                            </td>
                            <td className="" style={{verticalAlign:"middle",width:"50px"}}>
                              <a data-toggle="modal" data-target="#AssetTypeDeleteModal" style={{cursor:"pointer"}} onClick={e=>{this.setAssetTypeToDelete(op);e.stopPropagation();}}>
                                <button type="button" className="btn btn-default btn-sm" style={{padding:"1px 17px",marginRight:"0",background:"white",fontSize:"17px"}}><i className="fa fa-trash"></i></button>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="box-footer no-padding">
                  {AssetTypesControls}
                </div>
              </div>
            </div>
          </div>
        </section>
        <AssetTypeDeleteModal onDelete={this.onDelete} assetTypeId={this.state.assetTypeIdToDelete}/>
      </div>
    )
  }
}

export default Translate(AssetTypesPresentation, "AssetTypes")
