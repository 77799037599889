export default new class {
  constructor(){}

  authUser(token){
    localStorage.setItem('t', btoa(JSON.stringify(token)))
  }

  getToken(){
    if(!localStorage.getItem('t')) return null

    return JSON.parse(atob(localStorage.getItem('t')))
  }

  deAuthUser(msg){
    localStorage.removeItem('t')
    localStorage.removeItem('loggedUser')
  }

  isUserAuthenticated(token){
     return localStorage.getItem('t') !== null
  }

}
