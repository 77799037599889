import React from 'react';
import ReactDOM from  'reactDOM';
import { connect } from 'react-redux'
import * as devicesActions from "../../redux/actionCreators/devicesActionCreators.js"

import TagsInput from 'react-tagsinput'
import Autosuggest from 'react-autosuggest'

require("../../../../public/tagsInput/react-tagsinput.css")

const TagsFilter = class extends React.Component {
  constructor(props){
    super(props)
    this.handleTagsChange=this.handleTagsChange.bind(this)
  }
  componentDidMount(){
    //if (this.props.filters.availableTagsView.length == 0) {
      this.props.fetchTags()
    //}
  }
  componentDidUpdate(prevProps) {
    if (prevProps.loggedUser.currentAccount != this.props.loggedUser.currentAccount) {
      this.props.fetchTags();
    }
  }
  handleTagsChange(tags){
    var tags=tags.filter(tag=>{
        var tagsAvailable = this.props.filters.availableTagsView
        return tagsAvailable.filter(tagAvailable=>tagAvailable.toUpperCase()==tag.toUpperCase()).length!==0
      }
    )
    if(tags.length!=this.props.filters.tags.length)
      this.props.setTagsFilter(tags)
  }
  render() {
    const {availableTagsView,tags} = this.props.filters

    function autocompleteRenderInput({addTag, ...props}) {

      function renderSuggestionsContainer({containerProps, children, query }) {
            return (
            <div {... containerProps} className="autoSuggest">
              {children}
            </div>
            );
          }

      const handleOnChange = (e, {newValue, method}) => {
        if (method === 'enter') {
          e.preventDefault()
        } else {
          props.onChange(e)
        }
      }

      const inputValue = (props.value && props.value.trim().toLowerCase()) || ''
      const inputLength = inputValue.length

      var suggestions = availableTagsView.filter((tag) => {
        return tag.toLowerCase().slice(0, inputLength) === inputValue
      })
      if(!inputValue)
        suggestions=availableTagsView

      return (
        <Autosuggest
          ref={props.ref}
          suggestions={suggestions.filter(s=>tags.indexOf(s)=="-1")}
          shouldRenderSuggestions={(value) => true} // value && value.trim().length > 0
          getSuggestionValue={(suggestion) => suggestion}
          renderSuggestion={(suggestion) => <span style={{paddingTop:"5px"}}>{suggestion}</span>}
          inputProps={{...props, onChange: handleOnChange}}
          onSuggestionSelected={(e, {suggestion}) => {
            addTag(suggestion)
          }}
          onSuggestionsClearRequested={() => {}}
          onSuggestionsFetchRequested={() => {}}
          renderSuggestionsContainer={renderSuggestionsContainer}
        />
      )
    }

    var style={
            className: 'react-tagsinput-input',
            placeholder: this.props.strings.insertTag
          }
    return (
      <div>
        <TagsInput renderInput={autocompleteRenderInput}
                   value={tags}
                   onChange={this.handleTagsChange}
                   inputProps={style}
                  />
      </div>)
  }
}

const mapStateToProps = function(state) {
  return {
    filters: state.devices.filters,
    loggedUser: state.loggedUser
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    fetchTags:()=>dispatch(devicesActions.fetchTags()),
    setTagsFilter:(tags)=>dispatch(devicesActions.setTagsFilter(tags))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsFilter)
