import { connect } from 'react-redux'
import HeaderMessages from './HeaderMessages'
import * as inboxActions from "../../../../redux/actionCreators/inboxActionCreators"
import routes from "../../../../utils/routes"
import { push } from 'react-router-redux'

const mapStateToProps = function(state) {
  return {
    indicators:state.indicators
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    goToInbox:()=>dispatch(push(routes.routeInbox())),
    setUnreadedFilter:(bool)=>dispatch(inboxActions.setUnreadedFilter(bool)),
    setFolder:(folder)=>dispatch(inboxActions.setFolder(folder)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderMessages)
