import React from 'react'
import ReactDOM from  'reactDOM'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
//import DevicesPresentation from './DevicesPresentation.js'
//import * as devicesList from "../../redux/actionCreators/devicesListActionCreators.js"
import routes from "../../utils/routes"
import api from '../../utils/api'

var RegisterSingleDevicePresentation =  class extends React.Component {
  constructor(props){
    super(props)
    this.state={status:null,message:""}
  }

  render(){
    const {goToDevices,goToDeviceManage}=this.props
    const {status,message}=this.state

    return <div>

      {/* <!-- Main content --> */}
      <section className="content">
        <div className="row">
          {/* <!-- /.col --> */}
          <div className="col-md-10">

              <div style={{textAlign:"center",fontSize:"44px"}}>Single Device Provisioning</div>
              <div style={{textAlign:"center",fontSize:"27px",margin:"10px",marginTop:"15px"}}>Enter the Serial Number</div>

              <div style={{margin:"0px auto",display:"flex",alignItems:"center",justifyContent:"center",marginBottom:"30px"}}>

                <input style={{maxWidth:"409px",fontSize:"34px",textAlign:"center"}} ref={(i)=>this.sn=i} type="text" placeholder="Serial Number" className="form-control" />
                <div onClick={()=>{
                  console.log("click")
                  api.registerSingleDevice(this.props.loggedUser.currentAccount,this.sn.value)
                  .then((data)=>{
                    if(!data) return;

                    if(data.deviceRegistrationStatus=="NE"){
                      goToDeviceManage({newId:this.sn.value})
                      return;
                    }
                      this.setState({status:data.deviceRegistrationStatus,message:data.userMessage})
                  })
                }} style={{display:"flex"}}>
                <div className="btn btn-primary btn-block margin-bottom" style={{marginBottom:"0",width:"auto",marginLeft:"10px"}}>
                  {"Register"}
                </div>
              </div>

              </div>
              <div style={{textAlign:"center",fontSize:"12px",margin:"10px",marginTop:"15px"}}>Example : 90:FD:9F:FF:FE:16:1E:EF</div>


              {status=="EO" || status=="ED" || status=="EM"? <div style={{textAlign:"center"}}>
                <div style={{marginBottom:"10px"}}>{message}</div>
                <div style={{display:"flex",justifyContent:"center"}}>
                  <div onClick={goToDevices} className="btn btn-primary btn-block margin-bottom" style={{marginBottom:"0",width:"auto",backgroundColor:"#969696",borderColor:"gray"}}>
                    {"Cancel"}
                  </div>
                </div>
              </div>:null}

            </div>
          </div>
      </section>
    </div>
  }
}



const mapStateToProps = function(state) {
  return {
    loggedUser: state.loggedUser,
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    goToDevices:()=>dispatch(push(routes.routeDevices())),
    goToDeviceManage:(id)=>dispatch(push(routes.routeDeviceManage(id))),
    //goToHome:()=>dispatch(push(routes.routeHome())),
    //refreshClients:()=>dispatch(clientsActions.refreshClients()),
  }
}

const RegisterSingleDevice = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterSingleDevicePresentation)

export default RegisterSingleDevice
