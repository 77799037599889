import React, { Component } from 'react';
import { Table, Modal, Form, Tooltip} from 'antd';

import 'antd/lib/table/style/css';
import 'antd/lib/modal/style/css';
import 'antd/lib/tooltip/style/css';
import Metrics from '../../assets/Metrics';
import helpers from '../../../utils/helpers';
import moment from "moment";

class TableList extends Component {
  constructor(props) {
    super(props);
    this._selectRow = this._selectRow.bind(this);
    this._getColumns = this._getColumns.bind(this);
    this._assignMe = this._assignMe.bind(this);
  }
  _renderToolTipDate(date,strings,timezone) {
    return <ul className="zd-list-tooltip">
          <li>{strings.date}: <strong>{helpers._transformUtcToTimezone(date, timezone,true)}</strong></li>
        </ul>

  }
  _selectRow(task, rowIndex) {
    return {
      onClick: event => {
        this.props.goToEdit({id: task.id})
      }
    }
  }

  _assignMe(record) {
    this.props.postAssignMeTask(record.id);
  }

  _getColumns() {
    const { strings, reduced } = this.props;

    const { timezone } = this.props.loggedUser.preferences;
    let columns = [
      {
        title: strings.location,
        dataIndex: 'location.decr',
        key: 'id',
        hideOnSmall: true,
      },
      {
        title: strings.date,
        dataIndex: 'entry_date',
        key: 'entry_date',
        render: (text, record) => (
          <Tooltip title={this._renderToolTipDate(record.entry_date, strings,timezone)}>
          <span>{moment(record.entry_date).fromNow()}</span>
          </Tooltip>
        )
      },
      {
        title: strings.status,
        dataIndex: 'status',
        sorter: (a, b) => a.status.length - b.status.length,
        key: 'status',
        render: (text, record) => (
          <span>
            <span className={this._renderStatus(record.status)}>{record.status}</span>
          </span>
        )
      },
      {
        title: strings.priority,
        dataIndex: 'priority',
        sorter: (a, b) => a.priority - b.priority,
        key: 'priority',
      },
      {
        title: strings.assetvalues,
        dataIndex: 'lastAssetMetrics',
        key: 'lastAssetMetrics',
        render: (text, record) => (
          <Metrics strings={strings} asset_class={record.lastAssetMetrics.asset_class} values={record.lastAssetMetrics.values.values} />
        )
      },
      {
        title: strings.asset,
        dataIndex: 'asset_descr',
        key: 'asset',
      },
      {
        title: strings.task,
        dataIndex: 'descr',
        key: 'task',
        hideOnSmall: true,
        render: (text, record) => (
          <span>
            <span className={this._renderTask(record.status, record.priority)}>{record.descr}</span>
          </span>
        )
      },
      {
        title: strings.assignedto,
        dataIndex: 'assigned_to',
        key: 'assignedto',
      }
    ];
    if (reduced) {
      const action = {
        title: 'Action',
        key: 'action',
        width: 50,
        render: (text, record) => (
          <a href="javascript:;" className="btn btn-default" title="Assign me" onClick={ (e) => {e.preventDefault(); e.stopPropagation(); this._assignMe(record);}}>
            <i className="fa fa-user"></i>
          </a>
        ),
      }
      columns.push(action)
    }
    return columns;
  }
  _renderStatus(item) {
    let style="label label-succes";
    switch(item) {
      case "Open":
        style = "label label-warning";
        break;
      case "New":
        style = "label label-danger";
        break;
      case "Assigned":
        style = "label label-primary";
        break;
      case "Asigned":
        style = "label label-primary";
        break;
      case "Working":
        style = "label label-primary";
        break;
      case "Done":
        style = "label label-success";
        break;
      case "Canceled":
        style = "label label-success";
        break;
      default:
        style="label label-succes";
    }
    return style;
  }

  _renderTask(item,priority) {
    let style="text-muted";
    switch(item) {
      case "Open":
      if (priority>5)
        style = "text-red";
        break;
      case "New":
      if (priority>5)
        style = "text-red";
        break;
      default:
        style="text-muted";
    }
    return style;
  }

  _getResponsiveColumns(reduced) {
    const f = this._getColumns();
    return f.filter(({ hideOnSmall = false }) => !(reduced && hideOnSmall));
  }

  render() {
    const { listTasks, reduced } = this.props;
    return (
      <Table loading={this.props.loading} pagination={false} onRow={this._selectRow} columns={this._getResponsiveColumns(reduced)} dataSource={listTasks} size="small" />
    )
  }
}

module.exports = TableList;