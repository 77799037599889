import { createAction } from 'redux-actions'
import {SET_CLIENTS} from "../actionTypes/clientsListActions"
import api from "../../utils/api"
import routes from "../../utils/routes"
import { push } from 'react-router-redux'

export const setClients=createAction(SET_CLIENTS)

export const fetchClients = (offset=null,limit=null) => {
  return (dispatch, getState) => {
    var clients=getState().clients

    console.log("fetchClients: offset:" + offset + " limit:" + limit +
                " status: " + clients.status + "filters:", clients.filters)

    return  _getClients(clients.filters,clients.status,offset,limit)
              .then(data=>dispatch(setClients(data)))

  }
}


const _getClients=({search},status,offset,limit)=>{

  if(search)
    return api.getClientsSearch(search,status,offset,limit)

  return api.getClients(status,offset,limit)

}

export const setClient = (op,isUser) => {
  return (dispatch, getState) => {
    return  api.setClient(op)
                .finally(data=>{isUser? "":dispatch(push(routes.routeClients()))})
  }
}
