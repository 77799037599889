var moment = require('moment')
import tzHelper from "./timezoneHelper.js"

export default new (class {
  constructor() {}

  jsonToQueryString(json = {}) {
    // jsonToQueryString({name:"Juan",tag:["alto","flaco"]})
    // => "?name=Juan&tag=alto&tag=flaco"
    if (!(typeof json === "object"))
      throw new TypeError("Argument must be an object");

    var queryString = Object.keys(json)
      .filter((key) => json[key])
      .map((key) => {
        if (Array.isArray(json[key])) {
          var dataArray = "";
          json[key].forEach((data) => {
            dataArray =
              dataArray +
              encodeURIComponent(key) +
              "=" +
              encodeURIComponent(data) +
              "&";
          });
          if (dataArray) dataArray = dataArray.slice(0, -1);
          return dataArray;
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
        }
      })
      .filter((data) => data)
      .join("&");
    return queryString ? "?" + queryString : queryString;
  }

  queryStringToJSON(queryString) {
    if (queryString.indexOf("?") > -1) {
      queryString = queryString.split("?")[1];
    }
    var pairs = queryString.split("&");
    var result = {};
    pairs.forEach(function (pair) {
      pair = pair.split("=");
      result[pair[0]] = decodeURIComponent(pair[1] || "");
    });
    return result;
  }

  isEmptyString(str) {
    return /^\s*$/.test(str);
  }

  getCurrentTime() {
    return moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss.SSS");
  }

  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  _transformUtcToTimezone(
    utcDate,
    timezone,
    ampm = false
  ) {
    //  console.log(utcDate,!utcDate)
    if (!utcDate) return "";

    return this.transformUtcToTimezone(utcDate, timezone).format(
      ampm ? "MM/DD/YYYY, h:mm A" : "MM/DD/YYYY, hh:mm"
    );
  }

  transformUtcToTimezone(utcDate, timezone) {
    return moment.utc(utcDate).tz(tzHelper.getTimezone(timezone));
  }

  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x > y ? -1 : x < y ? 1 : 0;
    });
  }

  noData() {
    return {
      title: {
        text: "",
      },
      series: [
        {
          type: "line",
          name: "Data",
          data: [],
        },
      ],

      credits: {
        enabled: false,
      },
      lang: {
        noData: "No data points",
      },
      noData: {
        style: {
          fontWeight: "bold",
          fontSize: "15px",
          color: "#303030",
        },
      },
    };
  }

  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }
})();
