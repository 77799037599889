import { handleActions } from "redux-actions";
import {
  SET_DEVICE,
  MERGE_DEVICE,
  SET_DEVICE_VIEW,
  GET_METRICS,
  GET_HELTH_METRICS,
  GET_READ_QUALITY_METRICS,
} from "../actionTypes/deviceSelectedActions.js";
var update = require("immutability-helper");

const initialState = {
  deviceSelected: null,
  deviceView: "details",
  listMetricsDevice: [],
  listHelthMetrics: {},
  listReadQualityMetrics: {},
};

const reducer = handleActions(
  {
    [SET_DEVICE]: (state, action) =>
      update(state, { deviceSelected: { $set: action.payload } }),
    [MERGE_DEVICE]: (state, action) =>
      update(state, { deviceSelected: { $merge: action.payload } }),
    [SET_DEVICE_VIEW]: (state, action) =>
      update(state, { deviceView: { $set: action.payload } }),
    [GET_METRICS](state, action) {
      return {
        ...state,
        listMetricsDevice: action.payload,
      };
    },
    [GET_HELTH_METRICS](state, action) {
      return {
        ...state,
        listHelthMetrics: action.payload,
      };
    },
    [GET_READ_QUALITY_METRICS](state, action) {
      return {
        ...state,
        listReadQualityMetrics: action.payload,
      };
    },
  },
  initialState
);

export default reducer;
