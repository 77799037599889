import React, { useState } from "react";
import api from "../../utils/api";
import { Modal } from "antd";
import "antd/lib/modal/style/css";

const Types = [
  {
    label: "Note",
    value: "4",
  },
  { label: "Event", value: "5" },
];
const NewEvent = ({ open, asset, loggedUser, getAsset, onClose, strings }) => {
  const [formValues, setFormValues] = useState({
    description: "",
    type: "4",
  });

  const handleSetQuickEvent = () => {
    if (formValues.description.trim() === "") {
      return;
    }
    const data = {
      client_id: asset.client_id,
      timelineType: parseInt(formValues.type),
      timeline_user: loggedUser.email,
      decr: formValues.description,
      aud_time: new Date().getTime(),
      ident: loggedUser.name,
    };

    api
      .setQuickEvent(asset.id, data)
      .then(() => {
        setFormValues({ description: "", type: "4" });
      })
      .then(() => {
        getAsset();
      })
      .finally(() => {
        onClose();
      });
  };

  const onChange = (field, value) => {
    setFormValues((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Modal
      title={strings.add_event_modal.title}
      visible={open}
      onOk={() => handleSetQuickEvent()}
      onCancel={onClose}
      okText={strings.add_event_modal.save}
      cancelText={strings.add_event_modal.cancel}
    >
      <div className='box box-info'>
        <div className='box-body'>
          <form action='#' method='post'>
            <div className='form-group'>
              <select
                className='form-control'
                placeholder={strings.add_event_modal.type}
                name='type'
                onChange={(evt) => onChange(evt.target.name, evt.target.value)}
              >
                {Types.map((type) => (
                  <option key={type.label} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <textarea
                className='textarea'
                style={{
                  width: "100%",
                  height: 125,
                  fontSize: 14,
                  border: "1px solid rgb(221, 221, 221)",
                }}
                name={strings.add_event_modal.description}
                defaultValue={formValues.description}
                onChange={(evt) => onChange(evt.target.name, evt.target.value)}
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default NewEvent;
