import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const getOptions = (arr, title, original) => ({
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    plotTitle: false,
    type: "column",
  },
  title: {
    text: "",
  },
  tooltip: {
    pointFormat: "Qty: <b>{point.qty}</b>",
  },
  xAxis: {
    type: "category",
  },
  yAxis: {
    title: {
      text: "%",
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: `{point.y:.1f} ${original ? "" : "%"}`,
      },
    },
  },

  series: [
    {
      name: "",
      colorByPoint: true,
      data: arr,
    },
  ],
});

const parseData = (list, originalName = false) => {
  if (!list || list.length === 0) {
    return [];
  }
  return list.map((o) => ({
    name: originalName
      ? o.decr
      : (o.decr || "-") + " (" + (o.qty_percent || 0) + "%)",
    y: originalName ? o.qty || 0 : o.qty_percent || 0,
    qty: originalName ? o.qty_percent || "-" : o.qty || "-",
  }));
};

const ColumnChart = (props) => {
  const { data, title, originalName = false } = props;
  const [opened, setOpened] = useState(true);

  const options = getOptions(
    parseData(data, originalName),
    title,
    originalName
  );

  return (
    <div className='box box-default'>
      <div className='box-header with-border'>
        <h3 className='box-title'>{title}</h3>
        <div className='box-tools pull-right'>
          <button
            onClick={() => setOpened(!opened)}
            type='button'
            className='btn btn-box-tool'
            data-widget='collapse'
          >
            <i className={"fa fa-" + (opened ? "minus" : "plus")} />
          </button>
        </div>
      </div>
      {opened ? (
        <div className='box-body'>
          <div className='row'>
            <div className='col-md-12' style={{ overflow: "hidden" }}>
              <div className='chart-responsive'>
                <HighchartsReact highcharts={Highcharts} {...{ options }} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ColumnChart;
