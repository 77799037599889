import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Filter from "./filter";
import helpers from "../../../utils/helpers";
import Moment from "moment";
import MomentTimeZone from "moment-timezone";

class Graphics extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      chart: {
        chart: {
          zoomType: "x",
        },
        title: {
          text: "",
        },
        subtitle: {
          text:
            document.ontouchstart === undefined
              ? "Click and drag in the plot area to zoom in"
              : "Pinch the chart to zoom in",
        },
        tooltip: {
          shared: true,
        },
        xAxis: {
          type: "datetime",
          dateTimeLabelFormats: {
            day: "%e %b %y",
          },
        },
        yAxis: {
          title: {
            enabled: false,
          },
          min: this.props.series.length == 0 ? 1 : this.props.yaxisMin,
          tickInterval:
            this.props.series.length == 0 ? 1 : this.props.yaxisTickInterval,
        },
        legend: {
          enabled: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          area: {
            fillOpacity: 0.3,
          },
          series: {
            marker: {
              lineWidth: 1,
              lineColor: null,
            },
          },
        },
        colors: ["#7EB26D", "#EAB839", "#6ED0E0"],
        series: [],
        time: {
          timezone: "America/Buenos_Aires",
        },
      },
      loadingChart: true,
    };
  }

  componentDidMount() {
    window.moment = Moment;
    MomentTimeZone();

    if (this.props.series.length == 0) {
      this.setState({
        chart: {
          ...this.state.chart,
          series: this.props.seriesDefault,
        },
        loadingChart: false,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.series !== prevProps.series) {
      this.setState({
        chart: {
          ...this.state.chart,
          series: this.props.series,
        },
        loadingChart: false,
      });
    }
  }

  render() {
    const { strings, onGetMetrics, resourceId, loggedUser, onUpdateDays } =
      this.props;

    return (
      <div className='messaging-user'>
        <Filter
          {...{ strings, onUpdateDays, onGetMetrics, resourceId }}
          userId={loggedUser.clientId}
        />
        <HighchartsReact
          highcharts={Highcharts}
          options={
            this.state.chart.series.length > 0
              ? this.state.chart
              : helpers.noData()
          }
        />
      </div>
    );
  }
}

Graphics.propTypes = {
  strings: PropTypes.object,
  onGetMetrics: PropTypes.func.isRequired,
  resourceId: PropTypes.string.isRequired,
  yaxisMin: PropTypes.number,
  yaxisTickInterval: PropTypes.number,
};
Graphics.defaultProps = {
  yaxisMin: 0,
  yaxisTickInterval: undefined,
};

/**
 * Expose Component
 */
module.exports = Graphics;
