import React from 'react'
import ReactDOM from  'reactDOM'
import $ from 'jquery'
import jQuery from 'jquery'
var moment = require('moment')
import Loader from '../shared/PulseLoader'
window.$ = $
window.jQuery = jQuery
import api from "../../utils/api"
import LocationDeleteModal from "../modals/LocationDeleteModal"

import helpers from "../../utils/helpers"

import Translate from '../shared/i18n/Translate'


var LocationsPresentation =  class extends React.Component {
  constructor(props){
    super(props)
    this.state={locationIdToDelete:null}
    this.getRemainingLocations=this.getRemainingLocations.bind(this)
    this.onDelete=this.onDelete.bind(this)
    this.setLocationToDelete=this.setLocationToDelete.bind(this)
  }
  componentDidMount(){
    this.props.refreshLocations()
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  getRemainingLocations(){
    var cantLocations = this.props.locations.length
    var start = this.props.page*50 + (cantLocations>0? 1:0)
    var end = this.props.page*50 + this.props.locations.length
    return start + "/" + end
  }
  setLocationToDelete(location){
    this.setState({locationIdToDelete:location.id})
  }
  onDelete(){
    console.log("deleting ")
    setTimeout(()=>this.props.refreshLocations(),500)
    api.deleteLocation(1,this.state.locationIdToDelete).catch(err=>console.log(err))
    console.log("mmm")
  }
  render() {
    const {loading,setView,view,goToLocationsManage,goToHome,locations,refreshLocations,nextPage,backPage,strings} = this.props

    var LocationsControls =
    (<div className="mailbox-controls">
      {/* <!-- Check all button
      <button type="button" className="btn btn-default btn-sm checkbox-toggle"><i className="fa fa-square-o"></i>
      </button>
      <div className="btn-group">
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-trash-o"></i></button>
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-reply"></i></button>
        <button type="button" className="btn btn-default btn-sm"><i className="fa fa-share"></i></button>
      </div> --> */}
      {/* <!-- /.btn-group --> */}

      <div style={{display:"flex",justifyContent:"space-between",flexWrap:"wrap",alignItems:"center", margin:"0px 5px"}}>
        <div style={{display:"flex"}}>
          <button type="button" className="btn btn-default btn-sm" onClick={refreshLocations}>
            <i className="fa fa-refresh"></i>
          </button>
        </div>
        <div style={(!loading? {display:"none"} : {})}>
          <Loader color="#3c8dbc" size="9px" margin="4px"/>
        </div>
        <div className="pull-right" style={{margin:"5px 0px"}}>
          {this.getRemainingLocations()}
          <div className="btn-group" style={{marginLeft:"15px"}}>
            <button type="button" className="btn btn-default btn-sm" onClick={backPage}><i className="fa fa-chevron-left"></i></button>
            <button type="button" className="btn btn-default btn-sm" onClick={nextPage}><i className="fa fa-chevron-right"></i></button>
          </div>
          {/* <!-- /.btn-group --> */}
        </div>
      </div>
      {/* <!-- /.pull-right --> */}
    </div>
  )


/*
  var Breadcrumb = ({strings}) => (
    <ol className="breadcrumb">
      <li><div onClick={goToHome} style={{cursor:"pointer"}}><i className="fa fa-dashboard"></i> {strings.home}</div></li>
      <li className="active">{"locations"}</li>
    </ol>
  )
  Breadcrumb = Translate(Breadcrumb,"Breadcrumb")
*/
    return (
            <div>
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header" style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                  <h1>
                    {strings.locations}
                    <small></small>
                  </h1>
                  <div style={{display:"flex"}}>
                    <div className="btn btn-primary btn-block margin-bottom" style={{marginBottom:"0",width:"120px"}} onClick={()=>goToLocationsManage()}>
                      {strings.newLocation}
                    </div>
                  </div>
                  {/*<Breadcrumb strings={strings} />*/}
                </section>


                {view=="map"? <LocationsMap goToLocationsManage={goToLocationsManage} className="mapContent" locations={locations} lat={-34.440305} lng={-58.546842} zoom={12} />:null}
                {/* <!-- Main content --> */}
                <section className={"content " + (view=="map"? "hidden":"")}>
                  <div className="row">
                    {/* <!-- /.col --> */}
                    <div className="col-md-12">
                      <div className="box box-primary">
                        {/* <!-- /.box-header --> */}
                        <div className="box-body no-padding">
                          {LocationsControls}
                          <div className="table-responsive mailbox-messages">
                            <table className="table table-hover table-striped">
                              <tbody>
                                <tr>
                                  <th style={{paddingLeft:"15px"}}>{strings.id}</th>
                                  <th>{strings.description}</th>
                                  <th>{strings.lat}</th>
                                  <th>{strings.lon}</th>
                                  <th></th>
                                </tr>
                                {locations.map((op,key)=>(
                                  <tr key={key} onClick={()=>{goToLocationsManage({id:op.id})}} style={{cursor:"pointer"}}>
                                    <td className="mailbox-name" style={{verticalAlign:"middle"}}>{op.id}</td>
                                    <td className="mailbox-date" style={{verticalAlign:"middle"}}>{op.decr}</td>
                                    <td className="mailbox-name" style={{verticalAlign:"middle"}}>{op.lat}</td>
                                  <td className="mailbox-name" style={{verticalAlign:"middle"}}>{op.lon}</td>
                                    <td className="" style={{verticalAlign:"middle",width:"50px"}}>
                                      <a data-toggle="modal" data-target="#LocationDeleteModal" style={{cursor:"pointer"}} onClick={e=>{this.setLocationToDelete(op);e.stopPropagation();}}>
                                        <button type="button" className="btn btn-default btn-sm" style={{padding:"1px 17px",marginRight:"0",background:"white",fontSize:"17px"}}><i className="fa fa-trash"></i></button>
                                      </a></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {/* <!-- /.table --> */}
                          </div>
                          {/* <!-- /.mail-box-messages --> */}
                        </div>
                        {/* <!-- /.box-body --> */}
                        <div className="box-footer no-padding">
                          {LocationsControls}
                        </div>
                      </div>
                      {/* <!-- /. box --> */}
                    </div>
                    {/* <!-- /.col --> */}
                  </div>
                  {/* <!-- /.row --> */}
                </section>
                {/* <!-- /.content --> */}
                <LocationDeleteModal onDelete={this.onDelete} locationId={this.state.locationIdToDelete}/>
            </div>
    )
  }
}

export default Translate(LocationsPresentation,"Locations")
