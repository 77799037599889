import React from 'react';

const TotalRefills = ({ data }) => {

  if (!data || data.length === 0) {
    return null;
  }

  const totalLiters = data.reduce((acc, dataset) => {
    return acc + dataset.data.reduce((sum, item) => sum + item.y, 0);
  }, 0);

  return (
    <div className='col-lg-4 col-xs-12'>
      <div className='small-box bg-aqua'>
        <div className='inner'>
          <h3>{totalLiters.toFixed(2)} <sup style={{ fontSize: 20 }}>L</sup></h3>
          <p>Total Liters</p>
        </div>
        <div className='icon'>
          <i className='fa fa-angle-double-up' />
        </div>
      </div>
    </div>
  );
}

export default TotalRefills;
