

import { createAction } from 'redux-actions'
import * as types from '../actionTypes/tagsActions';
import api from "../../utils/api"
import routes from "../../utils/routes"
import { push } from 'react-router-redux'


export const tags = createAction(types.GET_TAGS);
export const tag = createAction(types.GET_TAG);
export const saveTag = createAction(types.POST_TAG);
export const wasEdit = createAction(types.WAS_EDIT);
export const changeStatusEdit = createAction(types.CHANGE_STATUS_EDIT);

export const changeStatus = () => {
  return (dispatch) => {
    dispatch(changeStatusEdit({edit: false}))
  }
}
export const getTags = (offset, limit) => {
    return (dispatch) => {
        return _getTags(offset, limit)
          .then(response => {
              dispatch(tags(response))
          })
          .catch(err => {
              console.log(err);
          })
    }
}

export const getTagsFilter = (method) => {
  return (dispatch) => {
      return _getTagsFilter(method)
        .then(response => {
            dispatch(tags(response))
        })
        .catch(err => {
            console.log(err);
        })
  }
}

export const getTag = (tagId) => {
  return (dispatch) => {
    return _getTag(tagId)
      .then(response => {
          dispatch(tag(response))
      })
      .catch(err => {
          console.log(err);
      })
  }
}

export const postTag = (data, editInline = false) => {
  return (dispatch) => {
    return _postTag(data)
      .then(() => {
        if (editInline) {
          //dispatch(push(routes.routeTags()));
          dispatch(wasEdit({edit: true}))
        } else {
          dispatch(push(routes.routeTags()));
        }
      })
      .catch(err => {
        console.log('err in post');
        console.log(err);
      })
  }
}

const _getTags = (offset, limit)=>{
  return api.getTags(offset, limit);
}
const _getTagsFilter = (method)=>{
  if (method == 'active') {
    return api.getActiveTags();
  } else {
    return api.getInactiveTags();
  }
}
const _getTag = (tagId)=>{
  return api.getDataSource(tagId);
}

const _postTag = (data)=>{
  return api.createTag(data);
}