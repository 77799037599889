import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import OperatorsManagePresentation from './OperatorsManagePresentation'
import * as operatorsListActions from "../../redux/actionCreators/operatorsListActionCreators"
import routes from "../../utils/routes"
import helpers from "../../utils/helpers"

const mapStateToProps = function(state, ownProps) {
  var operatorId = helpers.queryStringToJSON(ownProps.location.search).id
  var operator

  if(operatorId)
    operator = state.operatorsList.filter(op=>op.email==operatorId)[0]


  return {
    operatorId: operatorId,
    isEditing: operatorId? true:false,
    operator: operator,
    loggedUser:state.loggedUser
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    goToOperators:()=>dispatch(push(routes.routeOperators())),
    goToHome:()=>dispatch(push(routes.routeHome())),
    setOperator:(operator,isUser)=>dispatch(operatorsListActions.setOperator(operator,isUser))
  }
}

const OperatorsManageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(OperatorsManagePresentation)

export default OperatorsManageContainer
