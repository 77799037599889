import React from 'react';
import ReactDOM from  'reactDOM';
import $ from 'jquery';
import jQuery from 'jquery';
import Translate from '../shared/i18n/Translate'

window.$ = $;
window.jQuery = jQuery;

var StatusPresentation = ({strings}) => (
  <div>
    <section className="content-header">
      <h1>
        - {strings.title} -
      </h1>
    </section>

    {/*
    <section className="content" style={{'minHeight': '650px'}}>
      <div className="error-page">
        <h2 className="headline text-yellow"> NYI</h2>
        <div className="error-content">
          <h3><i className="fa fa-warning text-yellow"></i> Oops! Page not implemented yet.</h3>
          <p>
            We could not find the page you were looking for.
            Meanwhile, you may <a href="../../index.html">return to dashboard</a> or try using the search form.
          </p>
        </div>
      </div>
    </section>
  */}

<section className="content" style={{'minHeight': '650px'}}>
      <iframe style={{width:"100%",height:"1024px"}} src={"http://209.126.120.191:7080/d/BSwvEF2iz/public-dashboard?orgId=2&var-prefix=srv&var-server=graphite01&var-serverdw=blink-iot&from=now-1w%2Fw&to=now-1w%2Fw"} frameBorder="0"   ></iframe>
    </section>
  </div>
)

export default Translate(StatusPresentation,"AppStatus")
