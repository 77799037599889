import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal, Button, Form, Input, Select, DatePicker } from 'antd';

const { TextArea } = Input;
const { Option } = Select;

const AssetTasks = (props) => {
  const {
    assetId,
    fetchAssetsTasks,
    assetTasks,
    asset,
    fetchOperators,
    listOperators,
    postAssetsTask,
    strings
  } = props;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    client_id: asset.client_id || '',
    code: '',
    descr: '',
    status: 'New',
    entry_date: Date.now(),
    priority: 1,
    priority_descr: 'high',
    planned_date: '',
    assigned_to: '',
    assigned_to_descr: '',
    assigned_to_date: '',
    resolved_by: '',
    resolved_by_descr: '',
    resolved_by_date: '',
    location_id: asset.location.id || '',
    asset_id: assetId,
    closed: false,
  });

  useEffect(() => {
    fetchAssetsTasks(assetId);
  }, [assetId, fetchAssetsTasks]);

  useEffect(() => {
    fetchOperators();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (name, date) => {
    setFormData((prev) => ({ ...prev, [name]: date ? date.valueOf() : '' }));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = () => {
    postAssetsTask(formData);
    setIsModalVisible(false);
  };

  return (
    <div>
      <div className='row'>
        <section className='col-lg-12 connectedSortable ui-sortable'>
          <div className='box box-info'>
            <div className='box-header ui-sortable-handle' style={{ cursor: "move" }}>
              <i className='fa fa-envelope' />
              <h3 className='box-title'>{strings.asset_tasks.title}</h3>
              <div className='pull-right box-tools'>
                <Button className='btn btn-primary btn-xs' onClick={showModal}>
                  Add Task
                </Button>
              </div>
            </div>
            <div className='box-body'>
              <ul className='timeline'>
                {assetTasks && assetTasks.length > 0 && assetTasks.map((task, key) => (
                  <li key={key}>
                    <i className='fa fa-user bg-aqua' />
                    <div className='timeline-item'>
                      <span className='time'>
                        <i className='fa fa-clock-o' /> {moment(task.entry_date).format('DD-MM-YYYY, hh:mm a')}
                      </span>
                      <h3 className='timeline-header no-border'>
                        <span role='button'>{task.assigned_to ? task.assigned_to : 'Not Assigned'}</span>
                        <span style={{
                          marginLeft: '10px',
                        }}>
                          Priority:
                          <span className={`label label-danger`} style={{ marginLeft: '5px' }}>
                            {task.priority_descr}
                          </span>
                        </span>
                      </h3>
                      <div className='timeline-body'>
                        {task.descr}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div>

      <Modal
        title={strings.asset_tasks.add_task}
        visible={isModalVisible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Add Task
          </Button>
        ]}
      >
        <Form layout="vertical">
          <Form.Item label={strings.asset_tasks.code}>
            <Input
              name="code"
              value={formData.code}
              onChange={handleInputChange}
              placeholder={strings.asset_tasks.enter_code}
            />
          </Form.Item>
          <Form.Item label={strings.asset_tasks.description}>
            <TextArea
              name="descr"
              value={formData.descr}
              onChange={handleInputChange}
              rows={4}
              placeholder={strings.asset_tasks.enter_description}
            />
          </Form.Item>
          <Form.Item label={strings.asset_tasks.priority}>
            <Select
              value={formData.priority}
              onChange={(value) => handleSelectChange("priority", value)}
            >
              {Array.from(Array(10).keys()).map((i) => (
                <Option key={i} value={i + 1}>
                  {i + 1}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label={strings.asset_tasks.planned_date}>
            <DatePicker
              value={formData.planned_date ? moment(formData.planned_date) : null}
              onChange={(date) => handleDateChange('planned_date', date)}
              format="DD-MM-YYYY"
              placeholder={strings.asset_tasks.select_date}
            />
          </Form.Item>

          <Form.Item label={strings.asset_tasks.assigned_to}>
            <Select
              value={formData.assigned_to}
              onChange={(value) => {
                const operator = listOperators.find(op => op.email === value);
                handleSelectChange("assigned_to", value);
                handleSelectChange("assigned_to_descr", operator.name);
                handleDateChange("assigned_to_date", moment());
              }}
            >
              {listOperators && listOperators.map((op) => (
                <Option key={op.email} value={op.email}>
                  {op.name} - {op.email}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label={strings.asset_tasks.assigned_date}>
            <DatePicker
              value={formData.assigned_to_date ? moment(formData.assigned_to_date) : null}
              onChange={(date) => handleDateChange('assigned_to_date', date)}
              format="DD-MM-YYYY"
              placeholder={strings.asset_tasks.select_date}
            />
          </Form.Item>

          <Form.Item label={strings.asset_tasks.resolved_by}>
            <Select
              value={formData.resolved_by}
              onChange={(value) => {
                const operator = listOperators.find(op => op.email === value);
                handleSelectChange("resolved_by", value);
                handleSelectChange("resolved_by_descr", operator.name);
                handleDateChange("resolved_by_date", moment());
                handleInputChange("closed", true);
              }}
            >
              {listOperators && listOperators.map((op) => (
                <Option key={op.email} value={op.email}>
                  {op.name} - {op.email}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label={strings.asset_tasks.resolved_date}>
            <DatePicker
              value={formData.resolved_by_date ? moment(formData.resolved_by_date) : null}
              onChange={(date) => handleDateChange('resolved_by_date', date)}
              format="DD-MM-YYYY"
              placeholder={strings.asset_tasks.select_date}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AssetTasks;
