import {
  connect
} from 'react-redux';
import {
  push
} from 'react-router-redux';
import edit from './edit';
import * as tasks from '../../redux/actionCreators/tasks';
import { fetchLocations } from "../../redux/actionCreators/locationsListActionCreators"
import { fetchOperators } from "../../redux/actionCreators/operatorsListActionCreators"
import routes from '../../utils/routes';
import helpers from "../../utils/helpers"

const mapStateToProps = function (state, ownProps) {
  const taskId = helpers.queryStringToJSON(ownProps.location.search).id;
  let task = null;
  if (taskId) {
    task = state.tasks.listTasks.filter(p => p.id == taskId)[0];
  }
  return {
    taskId: taskId,
    task: task,
    isEditing: taskId ? true : false,
    listTasks: state.tasks.listTasks,
    loading: state.tasks.loading,
    loggedUser: state.loggedUser,
    listLocations: state.locationsList,
    listOperators: state.operatorsList,
    listStatus: state.tasks.listStatus,
    listOrdersTask: state.tasks.listOrdersTask,
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    //fetchTasks: (filter) => dispatch(tasks.fetchTasks(filter)),
    fetchLocations: (offset = 0, limit = 50) => dispatch(fetchLocations(offset, limit)),
    fetchOperators: (offset = 0, limit = 50) => dispatch(fetchOperators(offset, limit)),
    fetchStatus: () => dispatch(tasks.fetchStatus()),
    fetchOrdersTask: () => dispatch(tasks.fetchOrdersTask()),
    updateTask: (data) => dispatch(tasks.updateTask(data)),
      goToHome: () => dispatch(push(routes.routeHome())),
      goToList: () => dispatch(push(routes.routeTasks())),
      saveQuickEvent: (data) => dispatch(tasks.saveQuickEvent(data)),
  }
}

const EditContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(edit)

export default EditContainer
