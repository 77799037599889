import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import DataSourcesPresentation from './view';
import * as datasources from '../../redux/actionCreators/dataSourcesActionCreators'
import routes from "../../utils/routes"

const mapStateToProps = function(state) {
  return {
    listDataSources: state.dataSource.listDataSources,
    loggedUser: state.loggedUser
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    getDataSources: (offset = 0, limit = 50) => dispatch(datasources.getDataSources(offset, limit)),
    goEdit: (dataSourceId = 0) => dispatch(push(routes.routeDataSourcesEdit(dataSourceId)))
  }
}

const DataSourcesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DataSourcesPresentation)

export default DataSourcesContainer
