import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import AssetTypesManagePresentation from './AssetTypesManagePresentation'
import * as assetTypesListActions from "../../redux/actionCreators/assetTypesListActionCreators"
import { getTags } from '../../redux/actionCreators/tagsActionCreators'
import { fetchTasks } from '../../redux/actionCreators/tasktemplates'
import routes from "../../utils/routes"
import helpers from "../../utils/helpers"

const mapStateToProps = function (state, ownProps) {
  var assetTypeId = helpers.queryStringToJSON(ownProps.location.search).id
  var assetType
  if (assetTypeId)
    assetType = state.assetTypesList.listAssets.filter(p => p.id == assetTypeId)[0]
  return {
    assetTypeId: assetTypeId,
    isEditing: assetTypeId ? true : false,
    assetType: assetType,
    loggedUser: state.loggedUser,
    assetClasses: state.assetTypesList.listAssetClasses,
    assetManufactures: state.assetTypesList.listManufactures,
    listTags: state.tag.listTags,
    listTasks: state.tasksTemplates.listTasks,
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    goToAssetTypes: () => dispatch(push(routes.routeAssetTypes())),
    getTags: (offset = 0, limit = 50) => dispatch(getTags(offset, limit)),
    getTasks: (offset = 0, limit = 50) => dispatch(fetchTasks(offset, limit)),
    goToHome: () => dispatch(push(routes.routeHome())),
    setAssetType: (id, assetType) => dispatch(assetTypesListActions.setAssetType(id, assetType)),
    editAssetType: (id, assetType) => dispatch(assetTypesListActions.editAssetType(id, assetType)),
    getAssetClasses: () => dispatch(assetTypesListActions.getAssetClasses()),
    getManufactures: () => dispatch(assetTypesListActions.getManufactures())
  }
}

const AssetTypesManageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetTypesManagePresentation)

export default AssetTypesManageContainer
