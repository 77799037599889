import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import DeviceManagePresentation from './DeviceManagePresentation'
import * as devicesListActions from "../../redux/actionCreators/devicesListActionCreators"
import * as deviceSelectedActions from "../../redux/actionCreators/deviceSelectedActionCreators"
import * as datasources from "../../redux/actionCreators/dataSourcesActionCreators"
import routes from "../../utils/routes"
import helpers from "../../utils/helpers"

const mapStateToProps = function(state, ownProps) {
  var deviceId = helpers.queryStringToJSON(ownProps.location.search).id
  var newDeviceId = helpers.queryStringToJSON(ownProps.location.search).newId
  var device
  if(deviceId) {
    device = state.devicesList.filter(d=>d.deviceId==deviceId)[0]
  }

  return {
    deviceId: deviceId,
    newDeviceId:newDeviceId,
    isEditing: deviceId? true:false,
    device: (deviceId? state.deviceSelected.deviceSelected:null),
    listDataSources: state.dataSource.listDataSources,
    listTags: state.dataSource.tags
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    getDataSources: (offset = 0, limit = 50) => dispatch(datasources.getDataSources(offset, limit)),
    goToDevice:(id)=>dispatch(push(routes.routeDevice(id))),
    goToDevices:(id)=>dispatch(push(routes.routeDevices())),
    goToHome:()=>dispatch(push(routes.routeHome())),
    //setDevice:(device)=>dispatch(deviceSelectedActions.mergeDevice(device)),
    getTags: () => dispatch(datasources.getListTags())
  }
}

const DeviceManageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceManagePresentation)

export default DeviceManageContainer
