import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import list from './list'
import * as taskTemplates from "../../redux/actionCreators/tasktemplates"
import * as Actions from "../../redux/actionCreators/tasktemplates"
import routes from "../../utils/routes"

const mapStateToProps = function (state) {
  return {
    listTasks: state.tasksTemplates.listTasks,
    loading: state.tasksTemplates.loading,
    loggedUser: state.loggedUser
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    fetchTasks: (offset, limit) => dispatch(taskTemplates.fetchTasks(offset, limit)),
      goToEdit: (task) => dispatch(push(routes.routeTaskTemplatesEdit(task))),
      goToHome: () => dispatch(push(routes.routeHome())),
      onDelete: (id) => dispatch(taskTemplates.onDeleteTask(id)),
  }
}

const ListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(list)

export default ListContainer
