import React from 'react'
import ReactDOM from  'reactDOM'
import helpers from "../../utils/helpers"
//import timezoneHelper from "../../utils/timezoneHelper"
import Translate from '../shared/i18n/Translate'
import $ from 'jquery';
import api from "../../utils/api"
import roles from "../../utils/roles"
import {APP_ADMIN,ROLE_USER} from "../../utils/roles"

var ClientsManagePresentation = class extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      validation:true,
      inputNameValid:true,
      inputCompanyValid:true,
      inputEmailValid:true,
      inputAssetTokenValid:true,
      inputDeepTokenValid:true,
      inputBikesTokenValid:true,
      inputParkingTokenValid:true,
      inputWasteTokenValid:true,

      inputAdminApp:(props.client? props.client.adminAppActive:false),
      inputIoTApp:(props.client? props.client.iotAppActive:false),
      inputAssetTokenApp:(props.client? props.client.assetAppActive:false),
      inputDeepTokenApp:(props.client? props.client.deepAppActive:false),
      inputParkingTokenApp:(props.client? props.client.parkingAppActive:false),
      inputBikesTokenApp:(props.client? props.client.bikesAppActive:false),
      inputWasteTokenApp:(props.client? props.client.wasteAppActive:false),

    }
    this.handleNewChatClick=this.handleNewChatClick.bind(this)
    this.getErrorMessage=this.getErrorMessage.bind(this)
  }
  componentDidMount(){
    // Default values
    this.inputName.value=""
    this.inputCompany.value=""
    this.inputEmail.value=""
    this.inputCheckBox.checked=true


    // Operator values (editing)
    if(this.props.isEditing){
      this.inputAssetToken.value=""
      this.inputAdminAppToken.value=""
      this.inputIoTAppToken.value=""
      this.inputDeepToken.value=""
      this.inputBikesToken.value=""
      this.inputParkingToken.value=""
      this.inputWasteToken.value=""

      this.inputAdminApp.checked=false
      this.inputIoTApp.checked=false
      this.inputAssetTokenApp.checked=false
      this.inputDeepTokenApp.checked=false
      this.inputParkingTokenApp.checked=false
      this.inputBikesTokenApp.checked=false
      this.inputWasteTokenApp.checked=false

      var client = this.props.client
      this.inputName.value=client.name
      this.inputCompany.value=client.company
      this.inputEmail.value=client.email

    this.inputAssetToken.value=client.assetAppToken
    this.inputAdminAppToken.value=client.adminAppToken
    this.inputIoTAppToken.value=client.iotAppToken
    this.inputDeepToken.value=client.deepAppToken
    this.inputBikesToken.value=client.bikesAppToken
    this.inputParkingToken.value=client.parkingAppToken
    this.inputWasteToken.value=client.wasteAppToken

    this.inputCheckBox.checked=client.active
    this.inputAdminApp.checked=client.adminAppActive
    this.inputIoTApp.checked=client.iotAppActive
    this.inputAssetTokenApp.checked=client.assetAppActive
    this.inputDeepTokenApp.checked=client.deepAppActive
    this.inputParkingTokenApp.checked=client.parkingAppActive
    this.inputBikesTokenApp.checked=client.bikesAppActive
    this.inputWasteTokenApp.checked=client.wasteAppActive
    }
  }
  handleNewChatClick(e){
    e.preventDefault()
    var inputName=ReactDOM.findDOMNode(this.inputName)
    var inputCompany=ReactDOM.findDOMNode(this.inputCompany)
    var inputEmail=ReactDOM.findDOMNode(this.inputEmail)

    var inputAssetToken=ReactDOM.findDOMNode(this.inputAssetToken)
    var inputAdminAppToken=ReactDOM.findDOMNode(this.inputAdminAppToken)
    var inputIoTAppToken=ReactDOM.findDOMNode(this.inputIoTAppToken)
    var inputDeepToken=ReactDOM.findDOMNode(this.inputDeepToken)
    var inputBikesToken=ReactDOM.findDOMNode(this.inputBikesToken)
    var inputParkingToken=ReactDOM.findDOMNode(this.inputParkingToken)
    var inputWasteToken=ReactDOM.findDOMNode(this.inputWasteToken)

    var inputCheckBox=ReactDOM.findDOMNode(this.inputCheckBox)
    var inputAdminApp=ReactDOM.findDOMNode(this.inputAdminApp)
    var inputIoTApp=ReactDOM.findDOMNode(this.inputIoTApp)
    var inputAssetTokenApp=ReactDOM.findDOMNode(this.inputAssetTokenApp)
    var inputDeepTokenApp=ReactDOM.findDOMNode(this.inputDeepTokenApp)
    var inputParkingTokenApp=ReactDOM.findDOMNode(this.inputParkingTokenApp)
    var inputBikesTokenApp=ReactDOM.findDOMNode(this.inputBikesTokenApp)
    var inputWasteTokenApp=ReactDOM.findDOMNode(this.inputWasteTokenApp)

    var valid=true
    if(!helpers.isEmptyString(inputName.value)){
      this.setState({inputNameValid:true})
    }else{valid=false; this.setState({inputNameValid:false})}

    if(!helpers.isEmptyString(inputCompany.value)){
      this.setState({inputCompanyValid:true})
    }else{valid=false; this.setState({inputCompanyValid:false})}


    if(!helpers.isEmptyString(inputEmail.value) && helpers.validateEmail(inputEmail.value)){
      this.setState({inputEmailValid:true})
    }else{valid=false; this.setState({inputEmailValid:false})}


    if(valid){
      this.setState({validation:true})
      var client={
        name: this.inputName.value,
        company: this.inputCompany.value,
        email: this.inputEmail.value,
        id:(this.props.client? this.props.client.id:null),
        active: this.inputCheckBox.checked,
      }
      if(this.props.isEditing)
        client={
          name: this.inputName.value,
          company: this.inputCompany.value,
          email: this.inputEmail.value,
          id:(this.props.client? this.props.client.id:null),
          active: this.inputCheckBox.checked,

          adminAppActive:this.inputAdminApp.checked,
          adminAppToken:this.inputAdminAppToken.value,
          iotAppActive:this.inputIoTApp.checked,
          iotAppToken:this.inputIoTAppToken.value,
          parkingAppActive:this.inputParkingTokenApp.checked,
          parkingAppToken: this.inputParkingToken.value,
          wasteAppActive: this.inputWasteTokenApp.checked,
          wasteAppToken: this.inputWasteToken.value,
          bikesAppActive:this.inputBikesTokenApp.checked,
          bikesAppToken: this.inputBikesToken.value,
          assetAppActive:this.inputAssetTokenApp.checked,
          assetAppToken: this.inputAssetToken.value,
          deepAppActive:this.inputDeepTokenApp.checked,
          deepAppToken: this.inputDeepToken.value
        }

      console.log("setting client",client)
      const isUser=roles.hasAppRole(this.props.loggedUser,APP_ADMIN,ROLE_USER)
      this.props.setClient(client,isUser)
    }else{this.setState({validation:false})}
  }
  getErrorMessage(){
    if(this.state.validation)
      return null

    var element
    if(!this.state.inputNameValid){
      element=this.props.strings.name
    }else if(!this.state.inputCompanyValid){
      element=this.props.strings.company
    }else if(!this.state.inputEmailValid){
      element=this.props.strings.email
    }

    return <span style={{color:"#a94442"}}>{this.props.strings.badDataMsg + element + this.props.strings.badDataMsgValid }</span>
  }

  getDemoMessage(){


    return null
    //return <span style={{color:"#a94442"}}>only administrator can modify clients information</span>
  }
    render(){
      const {goToHome,goToClients,isEditing,client,strings,loggedUser} =this.props

      const roleUser=roles.hasAppRole(loggedUser,APP_ADMIN,ROLE_USER)

      var Breadcrumb = ({strings}) => (
        <ol className="breadcrumb">
          <li><div onClick={goToHome} style={{"cursor":"pointer"}}><i className="fa fa-dashboard"></i> {strings.home}</div></li>
          {!roleUser? <li onClick={goToClients} style={{"cursor":"pointer"}}>{strings.clients}</li>:null}
          <li style={{marginBottom:"20px"}} className="active">{isEditing? client.name : strings.newClient}</li>
        </ol>
      )
      Breadcrumb = Translate(Breadcrumb,"Breadcrumb")

      console.log(client)
      return (
        <div>
          <section className="content-header">
            <h1 style={{marginBottom:"10px"}}>
              {isEditing? strings.editClient : strings.newClient}
              <small></small>
            </h1>
            <Breadcrumb />
          </section>
            <div className="col-md-12">
              <div className="box box-info">
                <div className="box box-widget widget-user" style={{marginBottom:"36px"}}>
                  <div className="widget-user-header" style={{borderRadius:"0",background:"lightgray",height:"100px"}}>
                  </div>
                  <div className="widget-user-image" style={{top:"23px"}}>
                    <img className="img-circle" src="dist/img/company.png" alt="Company Avatar" style={{width:"105px"}}/>
                  </div>
                </div>
                <form autoComplete="off" className="form-horizontal">
                  <div className="box-body">
                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.company}</label>
                      <div className={"col-sm-10 "+ (!this.state.inputCompanyValid? "has-error":"")}>
                        <input ref={(i)=>this.inputCompany=i} type="text" className="form-control" placeholder={strings.company}/>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.name}</label>
                      <div className={"col-sm-10 "+ (!this.state.inputNameValid? "has-error":"")}>
                        <input ref={(i)=>this.inputName=i} type="text" className="form-control" placeholder={strings.name}/>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.email}</label>
                      <div className={"col-sm-10 "+ (!this.state.inputEmailValid? "has-error":"")}>
                        <input ref={(i)=>this.inputEmail=i} type="email" className="form-control" placeholder={strings.email} required disabled={isEditing}/>
                      </div>
                    </div>


                    {isEditing? <div>
                      <div className="form-group" style={{marginBottom:"5px"}}>
                      <div className={roleUser? "hidden":""}>
                        <label className="col-sm-2 control-label" style={{paddingTop:0}}>{strings.adminAppActive}</label>
                        <div className={"col-sm-10 "}>
                          <input ref={(i)=>this.inputAdminApp=i}  onChange={()=>this.setState({inputAdminApp:!this.state.inputAdminApp})}  type="checkbox"  style={{"cursor":"pointer"}}/>
                        </div>
                      </div>
                    </div>
                <div className={"form-group " + (this.state.inputAdminApp? "":"hidden")}>
                      <div>
                        <label className="col-sm-2 control-label">{strings.adminApp}</label>
                        <div className={"col-sm-8 "}>
                          <input ref={(i)=>this.inputAdminAppToken=i} type="text" disabled className="form-control" placeholder={strings.adminApp}/>
                        </div>
                        <div className={"col-sm-2 "}>
                          <button className="btn btn-default pull-left" style={{padding:"6px 25px"}}
                            onClick={(e)=>{e.preventDefault();e.stopPropagation();api.getAppToken(client.id,"adminapp").then(t=>this.inputAdminAppToken.value=t.authToken)}} style={{"cursor":"pointer"}}>{strings.generateToken}</button>
                          </div>
                      </div>
                    </div>

                    <div className="form-group" style={{marginBottom:"5px"}}>
                  <div className={roleUser? "hidden":""}>
                      <label className="col-sm-2 control-label" style={{paddingTop:0}}>{strings.inputIoTAppActive}</label>
                      <div className={"col-sm-10 "}>
                        <input ref={(i)=>this.inputIoTApp=i} onChange={()=>this.setState({inputIoTApp:!this.state.inputIoTApp})} type="checkbox"  style={{"cursor":"pointer"}}/>
                      </div>
                    </div>
                  </div>
                    <div className={"form-group " + (this.state.inputIoTApp? "":"hidden")}>
                  <div>
                      <label className="col-sm-2 control-label">{strings.iotApp}</label>
                      <div className={"col-sm-8 "}>
                        <input ref={(i)=>this.inputIoTAppToken=i} type="text" disabled className="form-control" placeholder={strings.iotApp}/>
                      </div>
                      <div className={"col-sm-2 "}>
                        <button className="btn btn-default pull-left" style={{padding:"6px 25px"}}
                          onClick={(e)=>{e.preventDefault();e.stopPropagation();api.getAppToken(client.id,"iotapp").then(t=>this.inputIoTAppToken.value=t.authToken)}} style={{"cursor":"pointer"}}>{strings.generateToken}</button>
                      </div>
                    </div>
                    </div>

                    <div className="form-group" style={{marginBottom:"5px"}}>
                  <div className={roleUser? "hidden":""}>
                      <label className="col-sm-2 control-label" style={{paddingTop:0}}>{strings.assetTokenActive}</label>
                      <div className={"col-sm-10 "}>
                        <input ref={(i)=>this.inputAssetTokenApp=i} onChange={()=>this.setState({inputAssetTokenApp:!this.state.inputAssetTokenApp})} type="checkbox"  style={{"cursor":"pointer"}}/>
                      </div>
                    </div>
                  </div>
              <div className={"form-group " + (this.state.inputAssetTokenApp? "":"hidden")}>
                      <div>
                      <label className="col-sm-2 control-label">{strings.assetToken}</label>
                      <div className={"col-sm-8 "+ (!this.state.inputAssetTokenValid? "has-error":"")}>
                        <input ref={(i)=>this.inputAssetToken=i} type="text" disabled className="form-control" placeholder={strings.assetToken}/>
                      </div>
                      <div className={"col-sm-2 "}>
                        <button className="btn btn-default pull-left" style={{padding:"6px 25px"}}
                          onClick={(e)=>{e.preventDefault();e.stopPropagation();api.getAppToken(client.id,"assetapp").then(t=>this.inputAssetToken.value=t.authToken)}} style={{"cursor":"pointer"}}>{strings.generateToken}</button>
                      </div>
                    </div>
                    </div>

              <div className="form-group" style={{marginBottom:"5px"}}>
                <div className={roleUser? "hidden":""}>
                      <label className="col-sm-2 control-label" style={{paddingTop:0}}>{strings.deepTokenActive}</label>
                      <div className={"col-sm-10 "}>
                        <input ref={(i)=>this.inputDeepTokenApp=i} onChange={()=>this.setState({inputDeepTokenApp:!this.state.inputDeepTokenApp})} type="checkbox"  style={{"cursor":"pointer"}}/>
                      </div>
                    </div>
                  </div>
                    <div className={"form-group " + (this.state.inputDeepTokenApp? "":"hidden")}>
                      <div>
                      <label className="col-sm-2 control-label">{strings.deepToken}</label>
                      <div className={"col-sm-8 "+ (!this.state.inputDeepTokenValid? "has-error":"")}>
                        <input ref={(i)=>this.inputDeepToken=i} type="text" disabled className="form-control" placeholder={strings.deepToken}/>
                      </div>
                      <div className={"col-sm-2 "}>
                        <button className="btn btn-default pull-left" style={{padding:"6px 25px"}}
                          onClick={(e)=>{e.preventDefault();e.stopPropagation();api.getAppToken(client.id,"deepapp").then(t=>this.inputDeepToken.value=t.authToken)}} style={{"cursor":"pointer"}}>{strings.generateToken}</button>
                      </div>
                    </div>
                    </div>

                <div className="form-group" style={{marginBottom:"5px"}}>
                <div className={roleUser? "hidden":""}>
                        <label className="col-sm-2 control-label" style={{paddingTop:0}}>{strings.parkingTokenActive}</label>
                        <div className={"col-sm-10 "}>
                          <input ref={(i)=>this.inputParkingTokenApp=i} onChange={()=>this.setState({inputParkingTokenApp:!this.state.inputParkingTokenApp})} type="checkbox"  style={{"cursor":"pointer"}}/>
                        </div>
                      </div>
                    </div>
              <div className={"form-group " + (this.state.inputParkingTokenApp? "":"hidden")}>
                        <div>
                      <label className="col-sm-2 control-label">{strings.parkingToken}</label>
                      <div className={"col-sm-8 "+ (!this.state.inputParkingTokenValid? "has-error":"")}>
                        <input ref={(i)=>this.inputParkingToken=i} type="text" disabled className="form-control" placeholder={strings.parkingToken}/>
                      </div>
                      <div className={"col-sm-2 "}>
                        <button className="btn btn-default pull-left" style={{padding:"6px 25px"}}
                          onClick={(e)=>{e.preventDefault();e.stopPropagation();api.getAppToken(client.id,"parkingapp").then(t=>this.inputParkingToken.value=t.authToken)}} style={{"cursor":"pointer"}}>{strings.generateToken}</button>
                      </div>
                    </div>
                    </div>

          <div className="form-group" style={{marginBottom:"5px"}}>
                  <div className={roleUser? "hidden":""}>
                      <label className="col-sm-2 control-label" style={{paddingTop:0}}>{strings.bikesTokenActive}</label>
                      <div className={"col-sm-10 "}>
                        <input ref={(i)=>this.inputBikesTokenApp=i} onChange={()=>this.setState({inputBikesTokenApp:!this.state.inputBikesTokenApp})} type="checkbox"  style={{"cursor":"pointer"}}/>
                      </div>
                    </div>
                  </div>
            <div className={"form-group " + (this.state.inputBikesTokenApp? "":"hidden")}>
                      <div>
                      <label className="col-sm-2 control-label">{strings.bikesToken}</label>
                      <div className={"col-sm-8 "+ (!this.state.inputBikesTokenValid? "has-error":"")}>
                        <input ref={(i)=>this.inputBikesToken=i} type="text" disabled className="form-control" placeholder={strings.bikesToken}/>
                      </div>
                      <div className={"col-sm-2 "}>
                        <button className="btn btn-default pull-left" style={{padding:"6px 25px"}}
                          onClick={(e)=>{e.preventDefault();e.stopPropagation();api.getAppToken(client.id,"bikesapp").then(t=>this.inputBikesToken.value=t.authToken)}} style={{"cursor":"pointer"}}>{strings.generateToken}</button>
                      </div>
                    </div>
                    </div>

            <div className="form-group" style={{marginBottom:"5px"}}>
                <div className={roleUser? "hidden":""}>
                      <label className="col-sm-2 control-label" style={{paddingTop:0}}>{strings.wasteTokenActive}</label>
                      <div className={"col-sm-10 "}>
                        <input ref={(i)=>this.inputWasteTokenApp=i} onChange={()=>this.setState({inputWasteTokenApp:!this.state.inputWasteTokenApp})} type="checkbox"  style={{"cursor":"pointer"}}/>
                      </div>
                    </div>
                  </div>
                      <div className={"form-group " + (this.state.inputWasteTokenApp? "":"hidden")}>
                      <div>
                      <label className="col-sm-2 control-label">{strings.wasteToken}</label>
                      <div className={"col-sm-8 "+ (!this.state.inputWasteTokenValid? "has-error":"")}>
                        <input ref={(i)=>this.inputWasteToken=i} type="text" disabled className="form-control" placeholder={strings.wasteToken}/>
                      </div>
                      <div className={"col-sm-2 "}>
                        <button className="btn btn-default pull-left" style={{padding:"6px 25px"}}
                          onClick={(e)=>{e.preventDefault();e.stopPropagation();api.getAppToken(client.id,"wasteapp").then(t=>this.inputWasteToken.value=t.authToken)}} style={{"cursor":"pointer"}}>{strings.generateToken}</button>
                      </div>
                    </div>
                    </div>
                  </div>:null}





                    {/*<div className="form-group">
                      <label className="col-sm-2 control-label">{strings.timezone}</label>
                      <div className="col-sm-10">
                        <select ref={(i)=>this.selectTimezone=i} className="form-control" disabled="">
                          {Object.keys(timezoneHelper.getTimezones()).map(key=><option key={key}>{key +" | " + timezoneHelper.timezones[key]}</option>)}
                        </select>
                      </div>
                    </div>*/}

                    <div className="form-group">
                      <label className="col-sm-2 control-label">{strings.active}</label>
                      <div className="col-sm-10">
                        <div className="checkbox">
                          <label>
                            <input ref={(i)=>this.inputCheckBox=i} type="checkbox" />
                            {strings.active}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="box-footer" style={{textAlign:"center"}}>
                    {!roleUser? <button className="btn btn-default pull-left" style={{padding:"6px 25px"}}
                            onClick={(e)=>{e.preventDefault();goToClients()}} style={{"cursor":"pointer"}}>{strings.cancel}</button>:null}
                    <button type="submit" onClick={this.handleNewChatClick}
                            className="btn btn-info pull-right" style={{padding:"6px 25px"}}  >{(isEditing? strings.save:strings.create)}</button>
                    {this.getErrorMessage()}
                    {this.getDemoMessage()}
                  </div>

                </form>
              </div>
            </div>
        </div>
      )
    }
  }

  export default Translate(ClientsManagePresentation,"ClientsManage")
