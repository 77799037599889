import React from "react";
import helpers from "../../utils/helpers";
import moment from "moment";
import "moment-timezone";
import Chart from "./Chart";
window.moment = moment;

var DetailsView = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstBoxOpen: false,
      secondBoxOpen: true,
      thirdBoxOpen: false,
    };
  }

  render() {
    const { strings, device, loggedUser } = this.props;

    return (
      <div className='box-body' style={{ padding: "0" }}>
        <section className='content-header'>
          <h1 style={{ marginBottom: "15px" }}>
            {strings.details}
            <small></small>
          </h1>
        </section>

        <div className='col-md-6 col-xs-12'>
          <div className='box box-warning' style={{ cursor: "pointer" }}>
            <div
              className='box-header with-border'
              onClick={() =>
                this.setState({ healthBoxOpen: !this.state.healthBoxOpen })
              }
            >
              <h3 className='box-title'>{strings.health}</h3>
              <div className='box-tools pull-right'>
                <button
                  type='button'
                  className='btn btn-box-tool'
                  onClick={() =>
                    this.setState({ healthBoxOpen: !this.state.healthBoxOpen })
                  }
                >
                  <i
                    className={
                      "fa fa-" + (this.state.healthBoxOpen ? "minus" : "plus")
                    }
                  />
                </button>
              </div>
            </div>
            <div
              className='box-body'
              style={this.state.healthBoxOpen ? {} : { display: "none" }}
            >
              <div className='form-group'>
                <label className='col-sm-4 col-md-4 col-lg-4 col-xs-6 control-label'>
                  {strings.reportingHealth}
                </label>
                <div className='input-group date'>
                  <div className='datepicker-wrap'>
                    <div>
                      {device.ReportinghealthCondition ? strings.true : strings.false}
                    </div>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <label className='col-sm-4 col-md-4 col-lg-4 col-xs-6 control-label'>
                  {strings.condition}
                </label>
                <div className='input-group date'>
                  <div className='datepicker-wrap'>
                    <div>{device.healthConditionListMessage}</div>
                    <div>{device.healthConditionMessage}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6 col-xs-12'>
          <div
            className='box box-warning'
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.setState({ firstBoxOpen: !this.state.firstBoxOpen })
            }
          >
            <div className='box-header with-border'>
              <h3 className='box-title'>
                {strings.installation}
              </h3>
              <div className='box-tools pull-right'>
                <button
                  type='button'
                  className='btn btn-box-tool'
                  onClick={() =>
                    this.setState({ firstBoxOpen: !this.state.firstBoxOpen })
                  }
                >
                  <i
                    className={
                      "fa fa-" + (this.state.firstBoxOpen ? "minus" : "plus")
                    }
                  />
                </button>
              </div>
            </div>
            <div
              className='box-body'
              style={this.state.firstBoxOpen ? {} : { display: "none" }}
            >
              <div className='form-group'>
                <label className='col-sm-4 col-md-4 col-lg-4 col-xs-6 control-label'>
                  {strings.installationDate}
                </label>
                <div className='input-group date'>
                  <div className='datepicker-wrap'>
                    <div>
                      {helpers._transformUtcToTimezone(
                        device.installDate,
                        loggedUser.preferences.timezone,
                        true
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <label className='col-sm-4 col-md-4 col-lg-4 col-xs-6 control-label'>
                  {strings.activationDate}
                </label>
                <div className='input-group date'>
                  <div className='datepicker-wrap'>
                    <div>
                      {helpers._transformUtcToTimezone(
                        device.activationDate,
                        loggedUser.preferences.timezone,
                        true
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <label className='col-sm-4 col-md-4 col-lg-4 col-xs-6 control-label'>
                  {strings.lastReset}
                </label>
                <div className='input-group date'>
                  <div className='datepicker-wrap'>
                    <div>
                      {helpers._transformUtcToTimezone(
                        device.resetDate,
                        loggedUser.preferences.timezone,
                        true
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <label className='col-sm-4 col-md-4 col-lg-4 col-xs-6 control-label'>
                  {strings.batteryInstallDate}
                </label>
                <div className='input-group date'>
                  <div className='datepicker-wrap'>
                    <div>
                      {helpers._transformUtcToTimezone(
                        device.batteryInstallDate,
                        loggedUser.preferences.timezone,
                        true
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-12'>
          <div className='box box-warning' style={{ cursor: "pointer" }}>
            <div
              className='box-header with-border'
              onClick={() =>
                this.setState({ secondBoxOpen: !this.state.secondBoxOpen })
              }
            >
              <h3 className='box-title'>{strings.lastValues}</h3>
              <div className='box-tools pull-right'>
                <button
                  type='button'
                  className='btn btn-box-tool'
                  onClick={() =>
                    this.setState({ secondBoxOpen: !this.state.secondBoxOpen })
                  }
                >
                  <i
                    className={
                      "fa fa-" + (this.state.secondBoxOpen ? "minus" : "plus")
                    }
                  />
                </button>
              </div>
            </div>
            <div
              className='box-body'
              style={this.state.secondBoxOpen ? {} : { display: "none" }}
            >
              <Chart {...this.props} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default DetailsView;
