import { connect } from "react-redux";
import { push } from "react-router-redux";
import AssetsPresentation from "./AssetsPresentation.js";
import * as assetsList from "../../redux/actionCreators/assetsListActionCreators";
import * as assetsActions from "../../redux/actionCreators/assetsActionCreators";
import routes from "../../utils/routes";

const mapStateToProps = function (state) {
  return {
    assets: state.assetsList.list,
    page: state.assets.page,
    loading: state.assets.loading,
    loadingList: state.assetsList.loading,
    loggedUser: state.loggedUser,
    inventories: state.assetsList.inventory,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    nextPage: () => dispatch(assetsActions.nextPage()),
    backPage: () => dispatch(assetsActions.backPage()),
    setView: (v) => dispatch(assetsActions.setView(v)),
    refreshAssets: () => dispatch(assetsActions.refreshAssets()),
    fetchAssets: (clientId, query, body) =>
      dispatch(assetsList.fetchAssets(clientId, query, body)),
    getInventory: (clientId, query, body) =>
      dispatch(assetsList.fetchInventory(clientId, query, body)),
    goToAssetsManage: (asset) =>
      dispatch(push(routes.routeAssetsManage(asset))),
    goToAssetsRefill: () => dispatch(push(routes.routeAssetsRefill())),
    goToAsset: (id) => dispatch(push(routes.routeAsset(id))),
    goToHome: () => dispatch(push(routes.routeHome())),
  };
};

const AssetsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetsPresentation);

export default AssetsContainer;
