var moment = require('moment')
import toastr from "toastr"

export default new class {
  constructor(){}

  info(msg){
    return toastr.info(msg)
  }

  warning(msg){
    return toastr.warning(msg)
  }

  success(msg){
    return toastr.success(msg)
  }

  error(msg){
    return toastr.error(msg)
  }

}
