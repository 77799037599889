import React from 'react'

import Title from '../common/title';
import Card from '../common/card';
import Translate from '../shared/i18n/Translate';
import Loader from '../shared/PulseLoader';
import Breadcrumb from '../common/breadcrumb';

class ViewEdit extends React.Component {
  constructor(props){
    super(props)

    this.handlerSubmit = this.handlerSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
        form: {
            descr: '',
            active: true,
            platform_tag: false
        },
        loading: false
    }
  }

  handlerSubmit(e) {
    e.preventDefault();
    this.setState({
      form: {
        ...this.state.form,
        platform_tag: false
      }
    });
    this.state.form.descr=this.state.form.descr.replace(/\s+/g, '-').toLowerCase();
    this.props.saveTag(this.state.form);
  }

  onChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.state.form[target.name] = value;
    this.setState({form: this.state.form});
  }

  render() {
    const { strings, goList, goHome  } = this.props;

    let title = `${strings.new}`;
    let breadcrumb = [
      {text: strings.home, toUrl: goHome},
      {text: strings.title, toUrl: goList},
      {text: title}
    ];
    return <div>
      <Title title={title} showBtn={false}>
        <Breadcrumb list={breadcrumb} />
      </Title>
      <Card>
        {this.state.loading && <Loader color="#3c8dbc" size="9px" margin="4px"/>}
        <div className="col-md-12">
            <form className="form-horizontal" onSubmit={this.handlerSubmit}>
              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="descr" className="col-sm-3 col-lg-2 col-xs-12 col-md-3 control-label">{strings.name}</label>
                  <div className="col-sm-4 col-lg-4 col-xs-12 col-md-5">
                    <input type="text" name="descr" required className="form-control" id="descr" value={this.state.form.descr} placeholder="Descr" onChange={this.onChange}/>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="descr" className="col-sm-3 col-lg-2 col-xs-12 col-md-3 control-label">{strings.status}</label>
                  <div className="col-sm-4 col-lg-4 col-xs-12 col-md-5">
                    <div className="checkbox">
                    <label>
                        <input name="active" checked={this.state.form.active} type="checkbox" onChange={this.onChange}/> {strings.active}
                    </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-footer">
                <button type="button" className="btn btn-default" onClick={this.props.goList}>{strings.cancel}</button>
                <button type="submit" className="btn btn-info pull-right">{strings.save}</button>
              </div>
            </form>
        </div>
      </Card>
    </div>
  }
}

export default Translate(ViewEdit, "Tag")
