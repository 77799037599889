import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import LocationsPresentation from './LocationsPresentation.js'
import * as locationsList from "../../redux/actionCreators/locationsListActionCreators"
import * as locationsActions from "../../redux/actionCreators/locationsActionCreators"
import routes from "../../utils/routes"

const mapStateToProps = function(state) {
  return {
    locations: state.locationsList,
    page: state.locations.page,
    loading:state.locations.loading
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    nextPage:()=>dispatch(locationsActions.nextPage()),
    backPage:()=>dispatch(locationsActions.backPage()),
    setView:(v)=>dispatch(locationsActions.setView(v)),
    refreshLocations:()=>dispatch(locationsActions.refreshLocations()),
    fetchLocations:()=>dispatch(locationsList.fetchLocations()),
    goToLocationsManage:(location)=>dispatch(push(routes.routeLocationsManage(location))),
    goToHome:()=>dispatch(push(routes.routeHome())),
  }
}

const LocationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsPresentation)

export default LocationsContainer
