import {handleActions} from "redux-actions"
import {SET_CONVERSATION,ADD_LOCAL_MESSAGE} from "../actionTypes/conversationSelectedActions.js"
var update = require('immutability-helper');

const initialState=null

const reducer=handleActions({
  [SET_CONVERSATION]:(state,action)=>update(state,{$set:action.payload}),
  ["NEW_MESSAGE"]:(state,action)=>{
    const {chat_id,id} = action.payload
    if(state && state.id==chat_id){
      const messageExists = state.lastMessages.filter(msg=>msg.id==id)[0]
      if(!messageExists){
        return update(state,{lastMessages:{$push:[action.payload]},cant:{$set:state.cant+1}})
      }
    }

    return state
  },
  [ADD_LOCAL_MESSAGE]:(state,action)=>update(state,{lastMessages:{$push:[action.payload]},cant:{$set:state.cant+1}})
},initialState)

export default reducer
