import React from 'react'
import { connect } from 'react-redux'
import languages from "./languages"

export default (WrappedComponent, key) => {
  var Translate = class extends React.Component {
      render() {
          const language = languages[this.props.currentLanguage]
          const strings = language.values[key]
          const merged = {
              ...this.props.strings,
              ...strings
          }
          if (strings) {
              return (<WrappedComponent {...this.props} strings={merged}/>)
          }

          return (<WrappedComponent {...this.props}/>)
      }
    }

    const mapStateToProps = function(state) {
      return {
        currentLanguage:state.language.currentLanguage
      }
    }

    const mapDispatchToProps = function(dispatch)  {return {}}

    return connect(
      mapStateToProps,
      mapDispatchToProps
    )(Translate)
}
