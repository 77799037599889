import helpers from "../utils/helpers.js";
import toastrUtils from "../utils/toastrs";
import config from "../../../CONFIG";
import Authentication from "../components/shared/Authentication";

var API_URL = config.api_development_url;

//if (process.env.NODE_ENV === "production") API_URL = config.api_production_url;
if (process.env.ENV === "prod") {
  API_URL = config.api_production_url;
}
if (process.env.NODE_ENV === "testing") API_URL = config.api_testing_url;

const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const DELETE = "DELETE";

export default new (class {
  //------------------- Api Endpoints

  login(email, password, token) {
    // returns 422 if bad password or mail
    return this._request(
      "operators/login",
      POST,
      { email: email, password: password },
      { email: email, password: password },
      false
    );
  }

  setOperator(operator) {
    // returns 403 if the email already exists
    var {
      name,
      rol,
      email,
      password,
      active,
      rolesList,
      preferences,
      notificationConfig,
    } = operator; //
    return this._request(
      "operators",
      POST,
      {
        email: email,
        authToken: password,
        name: name,
        role: rol,
        active: active,
        rolesList: rolesList,
        preferences: preferences,
        notificationConfig: notificationConfig,
      },
      { email: email, password: password }
    );
  }

  getOperators(status = null, offset = 0, limit = 10) {
    return this._request("operators", GET, {
      active: status ? "1" : "0",
      offset: offset,
      limit: limit,
    });
  }

  getOperatorsSearch(query, status, offset, limit) {
    return this._request("operators", GET, {
      q: query,
      active: status ? "1" : "0",
      offset: offset,
      limit: limit,
    });
  }

  setClient(client) {
    console.log("SET CLIENT");
    // TODO format the post
    // returns 403 if the email already exists
    var {
      iotAppActive,
      id,
      parkingAppActive,
      bikesAppActive,
      assetAppActive,
      wasteAppActive,
      deepAppActive,
      iotAppToken,
      adminAppActive,
      adminAppToken,
      name,
      email,
      password,
      company,
      active,
      assetAppToken,
      deepAppToken,
      bikesAppToken,
      parkingAppToken,
      wasteAppToken,
    } = client; //timezone

    const body = {
      company: company,
      email: email,
      authToken: password,
      name: name,
      active: active,
      id: id || null,
      avatar: null,

      adminAppActive: adminAppActive ? 1 : 0,
      adminAppToken: adminAppToken,
      iotAppActive: iotAppActive ? 1 : 0,
      iotAppToken: iotAppToken,
      parkingAppActive: parkingAppActive ? 1 : 0,
      parkingAppToken: parkingAppToken,
      wasteAppActive: wasteAppActive ? 1 : 0,
      wasteAppToken: wasteAppToken,
      bikesAppActive: bikesAppActive ? 1 : 0,
      bikesAppToken: bikesAppToken,
      assetAppActive: assetAppActive ? 1 : 0,
      assetAppToken: assetAppToken,
      deepAppActive: deepAppActive ? 1 : 0,
      deepAppToken: deepAppToken,
    };

    console.log(body);

    return this._request("operators/clients", POST, body, {
      email: email,
      password: password,
    });
  }

  getClients(status = null, offset = 0, limit = 10) {
    return this._request("operators/clients", GET, {
      active: status ? "1" : "0",
      offset: offset,
      limit: limit,
    });
  }

  getClientsSearch(query, status, offset, limit) {
    return this._request("operators/clients", GET, {
      q: query,
      active: status ? "1" : "0",
      offset: offset,
      limit: limit,
    });
  }

  getDevices(status = null, offset = 0, limit = 10) {
    return this._request("gate/device", GET, {
      active: status ? "1" : "0",
      offset: offset,
      limit: limit,
    });
  }

  getDevicesTags(tags, status = null, offset = 0, limit = 10) {
    return this._request("gate/device", GET, {
      tags: tags,
      active: status ? "1" : "0",
      offset: offset,
      limit: limit,
    });
  }

  getDeviceTimeline(accountId, deviceId) {
    return this._request(
      "gate/" + accountId + "/" + deviceId + "/event",
      GET,
      {}
    );
  }

  getDevice(id) {
    return this._request("gate/device/" + id, GET, {});
  }

  registerSingleDevice(accountId, serial) {
    return this._request("gate/register/" + accountId + "/" + serial, GET, {});
  }

  uploadRefillExcel(clientId, data) {
    return this._requestUpload(
      `assets/${clientId}/batch/exceldipstick`,
      POST,
      data,
      null,
      {}
    );
  }

  setDevice(device) {
    var {
      deviceId,
      communication_type,
      descr,
      device_type,
      lat,
      lon,
      authorized,
      tags,
      installDate,
      activationDate,
      resetDate,
      batteryInstallDate,
      frecuency,
    } = device;

    return this._request(
      "gate/device",
      POST,
      {
        deviceId: deviceId,
        frecuency: frecuency,
        installDate: installDate,
        activationDate: activationDate,
        resetDate: resetDate,
        batteryInstallDate: batteryInstallDate,
        communication_type: communication_type,
        descr: descr,
        device_type: device_type,
        lat: lat,
        lon: lon,
        authorized: authorized,
        tags: { tags: tags },
      },
      true
    );
  }

  setSigleDevice(device) {
    var {
      deviceId,
      communication_type,
      descr,
      device_type,
      lat,
      lon,
      authorized,
      tags,
      installDate,
      activationDate,
      resetDate,
      batteryInstallDate,
      frecuency,
    } = device;

    return this._request(
      "gate/device/provisioning/single",
      POST,
      {
        deviceId: deviceId,
        frecuency: frecuency,
        installDate: installDate,
        activationDate: activationDate,
        resetDate: resetDate,
        batteryInstallDate: batteryInstallDate,
        communication_type: communication_type,
        descr: descr,
        device_type: device_type,
        lat: lat,
        lon: lon,
        authorized: authorized,
        tags: { tags: tags },
      },
      true
    );
  }

  getClientsMove() {
    return this._request("operators/clientstomove", GET, {});
  }

  setAssignDevice(params) {
    var {
      currentaccount,
      newaccount,
      newaccountName,
      userid,
      username,
      deviceid,
    } = params;
    return this._request(
      "gate/device/" + deviceid + "/assignment",
      PUT,
      {
        currentaccount: currentaccount,
        newaccount: newaccount,
        newaccountName: newaccountName,
        userid: userid,
        username: username,
        deviceid: deviceid,
      },
      {}
    );
  }

  authorizeDevice(id) {
    return this._request("gate/device/authorize/" + id, POST, {});
  }

  unauthorizeDevice(id) {
    return this._request("gate/device/unauthorize/" + id, POST, {});
  }

  getDeviceMessages(id, offset, limit) {
    return this._request("gate/device/" + id + "/msg", GET, {
      offset: offset,
      limit: limit,
    });
  }

  getDeviceReading(id, offset, limit) {
    return this._request("gate/device/" + id + "/msg/reading", GET, {
      offset: offset,
      limit: limit,
    });
  }

  getDeviceInformation(id, offset, limit) {
    return this._request("gate/device/" + id + "/msg/info", GET, {
      offset: offset,
      limit: limit,
    });
  }

  getDeviceDebug(id, offset, limit) {
    return this._request("gate/device/" + id + "/msg/debug", GET, {
      offset: offset,
      limit: limit,
    });
  }

  getDeviceEngineering(id, offset, limit) {
    return this._request("gate/device/" + id + "/msg/eng", GET, {
      offset: offset,
      limit: limit,
    });
  }

  changePassword(oldEmail, oldPassword, newPassword, authToken) {
    // returns 422 if bad password or mail
    return this._request(
      "operators/pwdchg",
      PUT,
      { authtoken: oldPassword, newAuthtoken: newPassword },
      { email: oldEmail, password: oldPassword },
      authToken
    );
  }

  refreshToken(authToken) {
    // returns 401 if bad token or expired
    return this._request(
      "operators/token/refresh",
      GET,
      null,
      false,
      authToken
    );
  }

  getTags(offset, limit) {
    let filter = {};
    if (offset && limit) {
      filter = {
        offset,
        limit,
      };
    }
    return this._request("gate/tag", GET, filter, undefined, true);
  }

  getActiveTags() {
    return this._request("gate/tag/actives", GET, {}, undefined, true);
  }

  getInactiveTags() {
    return this._request("gate/tag/inactives", GET, {}, undefined, true);
  }

  createTag(data) {
    // not used
    return this._requestVoid(`gate/tag`, POST, data, undefined, true);
  }
  getPorts(offset = 0, limit = 10) {
    return this._request(
      "ports/listall",
      GET,
      { offset: offset, limit: limit },
      undefined,
      true
    );
  }

  editPort(port) {
    var {
      id,
      name,
      lat,
      lon,
      depth_formula_type = 1,
      mapid = 0,
      depthConfig,
    } = port;
    return this._request(
      "ports/" + id,
      POST,
      {
        id: id,
        name: name,
        lat: lat,
        lon: lon,
        depth_formula_type: depth_formula_type,
        mapid: mapid,
        depthConfig: depthConfig,
      },
      {}
    );
  }

  createPort(port) {
    var {
      name,
      lat,
      lon,
      depth_formula_type = 1,
      mapid = 0,
      depthConfig,
    } = port;
    return this._request(
      "ports",
      PUT,
      {
        name: name,
        lat: lat,
        lon: lon,
        depth_formula_type: depth_formula_type,
        mapid: mapid,
        depthConfig: depthConfig,
      },
      {}
    );
  }

  deletePort(id) {
    return this._request("ports/" + id, DELETE, {});
  }

  getPortData(port) {
    return this._request("ports/" + id, GET, {});
  }

  getLocations(clientId, offset, limit) {
    return this._request("assets/" + clientId + "/locations", GET, {
      offset: offset,
      limit: limit,
    });
  }

  getDashboard(locationId) {
    return this._request(
      "statistics/maindashboard",
      GET,
      locationId ? { location: locationId } : {}
    );
  }

  getAssetTypes(clientId, offset, limit) {
    return this._request("assets/" + clientId + "/assettypes", GET, {
      offset: offset,
      limit: limit,
    });
  }

  deleteAssetType(clientId, id) {
    return this._request("assets/" + clientId + "/assettypes", DELETE, {});
  }

  getAssets(clientId, query, body) {
    const params = `?limit=${query.limit}&offset=${query.offset}`;
    return this._request(
      `assets/${clientId}/assets/filter${params}`,
      POST,
      body
    );
  }

  getAssetsExport(clientId, body) {
    return this._request(
      `assets/${clientId}/assets/filter/excel`,
      POST,
      body,
      {
        email: "1",
        password: "1",
      },
      true,
      true
    );
  }

  getInventory(clientId, query, body) {
    const params = `?limit=${query.limit}&offset=${query.offset}`;
    return this._request(
      `assets/${clientId}/assets/inventory/filtered${params}`,
      POST,
      body
    );
  }

  getAsset(clientId, id) {
    return this._request("assets/" + clientId + "/assets/" + id, GET, {});
  }

  setAsset(clientId, asset) {
    var {
      id,
      decr,
      ident,
      location_id,
      asset_type_id,
      sensors,
      lat,
      lon,
      notifications_active,
      mode,
      status,
      tank_content,
      tank_content_type,
      inyection_status,
      inyection_treatment,
      minimum_inventory_level,
    } = asset; //timezone
    return this._request(
      "assets/" + clientId + "/0",
      POST,
      {
        id: 0,
        client_id: parseInt(clientId),
        decr: decr,
        ident: ident,
        location_id: parseInt(location_id),
        asset_type_id: parseInt(asset_type_id),
        sensors: { sensors: sensors },
        lat,
        lon,
        aud_user: "leodangal@gmail.com",
        aud_time: 1535448869,
        notifications_active,
        mode,
        status,
        tank_content,
        tank_content_type,
        inyection_status,
        inyection_treatment,
        minimum_inventory_level,
      },
      {}
    );
  }

  editAsset(clientId, asset) {
    var {
      id,
      decr,
      ident,
      location_id,
      asset_type_id,
      sensors,
      lat,
      lon,
      notifications_active,
      mode,
      status,
      tank_content,
      tank_content_type,
      inyection_status,
      inyection_treatment,
      minimum_inventory_level,
    } = asset; //timezone
    return this._request(
      "assets/" + clientId + "/" + id,
      POST,
      {
        id: parseInt(id),
        client_id: parseInt(clientId),
        decr: decr,
        ident: ident,
        location_id: parseInt(location_id),
        asset_type_id: parseInt(asset_type_id),
        sensors: { sensors: sensors },
        lat,
        lon,
        aud_user: "leodangal@gmail.com",
        aud_time: 1535448869,
        notifications_active,
        status,
        mode,
        tank_content,
        tank_content_type,
        inyection_status,
        inyection_treatment,
        minimum_inventory_level,
      },
      {}
    );
  }

  deleteAssetType(clientId, id) {
    return this._request(
      "assets/" + clientId + "/assettypes/" + id,
      DELETE,
      {}
    );
  }

  deleteAsset(clientId, id) {
    return this._request("assets/" + clientId + "/" + id, DELETE, {});
  }

  setAssetType(clientId, assetType) {
    return this._request(
      "assets/" + clientId + "/assettypes",
      PUT,
      assetType,
      {}
    );
  }

  editAssetType(clientId, assetType) {
    const { id } = assetType; //timezone
    return this._request(
      "assets/" + clientId + "/assettypes/" + id,
      POST,
      assetType,
      {}
    );
  }

  getAssetClasses() {
    return this._request("assets/assetsclasses", GET);
  }
  getAssetManufactures() {
    return this._request("assets/manufacturers", GET);
  }

  setLocation(clientId, location) {
    var { id, lat, lon, decr } = location; //timezone
    return this._request(
      "assets/" + clientId + "/locations",
      PUT,
      {
        id: parseInt(id),
        client_id: parseInt(clientId),
        decr: decr,
        lat: lat.toString(),
        lon: lon.toString(),
        assetCount: 0,
        aud_user: "leodangal@gmail.com",
        aud_time: 1535448869,
      },
      {}
    );
  }

  editLocation(clientId, location) {
    var { id, lat, lon, decr } = location; //timezone
    return this._request(
      "assets/" + clientId + "/locations/" + id,
      POST,
      {
        id: parseInt(id),
        client_id: parseInt(clientId),
        decr: decr,
        lat: lat.toString(),
        lon: lon.toString(),
        assetCount: 0,
        aud_user: "leodangal@gmail.com",
        aud_time: 1535448869,
      },
      {}
    );
  }

  deleteLocation(clientId, id) {
    return this._request("assets/" + clientId + "/locations/" + id, DELETE, {});
  }

  changeCurrentAccount(currentAccount, newAccount) {
    // returns 403 if error
    return this._request(
      "operators/chacc",
      POST,
      { currentAccount: currentAccount, newAccount: newAccount },
      {},
      true
    );
  }

  getAppToken(clientId, app) {
    return this._request(
      "operators/clients/" + clientId + "/apptoken/" + app,
      GET,
      {},
      true
    );
  }

  getActiveApps(clientId) {
    return this._request("operators/clients/" + clientId, GET, {}, true);
  }

  getOperatorFromEmail(email) {
    return this._request("operators/" + email, GET, {}, true);
  }

  setQuickEvent(assetId, data) {
    return this._request(
      "assets/" + assetId + "/" + assetId + "/timeline",
      POST,
      data
    );
  }

  setManualReading(clientId, assetId, data) {
    return this._request(
      "assets/" + clientId + "/" + assetId + "/manualreading",
      PUT,
      data
    );
  }

  setTimelineEvent(data) {
    return this._request("gate/device/event", POST, data);
  }

  deleteTimelineEvent(deviceId, time) {
    return this._request(
      "gate/device/" + deviceId + "/event/" + time,
      DELETE,
      {}
    );
  }

  getDataSources(offset = 0, limit = 50) {
    return this._request(
      "gate/datasource",
      GET,
      { offset: offset, limit: limit },
      undefined,
      true
    );
  }

  getDataSource(dataSourceId) {
    return this._request(
      `gate/datasource/${dataSourceId}`,
      GET,
      null,
      undefined,
      true
    );
  }
  postDataSource(data) {
    return this._request(`gate/datasource`, POST, data, undefined, true);
  }

  deleteDataSource(dataSourceId) {
    return this._request(`gate/datasource/${dataSourceId}`, DELETE, {});
  }

  getDeviceMetrics(id, day) {
    return this._request(
      `gate/device/${id}/metrics/${day}`,
      GET,
      {},
      undefined,
      true
    );
  }
  getDeviceMetricsV2(id, day) {
    return this._request(
      `gate/device/${id}/metrics/messages/${day}`,
      GET,
      {},
      undefined,
      true
    );
  }

  getHelthMetrics(id, day) {
    return this._request(
      `gate/device/${id}/metrics/health/all/${day}`,
      GET,
      {},
      undefined,
      true
    );
  }

  getReadQualityMetrics(id, day) {
    return this._request(
      `gate/device/${id}/metrics/readquality/${day}`,
      GET,
      {},
      undefined,
      true
    );
  }

  getAssetHistoricalMetrics(id, day) {
    return this._request(
      `gate/asset/${id}/metrics/${day}`,
      GET,
      {},
      undefined,
      true
    );
  }
  getAssetTimeSerie(idUser, idAsset, day) {
    return this._request(
      `assets/${idUser}/assets/${idAsset}/timeserie/${day}`,
      GET,
      {},
      undefined,
      true
    );
  }
  getAssetRefill(idUser, idAsset, day) {
    return this._request(
      `assets/${idUser}/assets/${idAsset}/refill/timeserie/${day}`,
      GET,
      {},
      undefined,
      true
    );
  }

  getAssetTemperatureMetrics(id, day) {
    return this._request(
      `gate/device/${id}/metrics/temp/${day}`,
      GET,
      {},
      undefined,
      true
    );
  }

  getAssetTiltMetrics(id, day) {
    return this._request(
      `gate/device/${id}/metrics/tilt/${day}`,
      GET,
      {},
      undefined,
      true
    );
  }
  getAnalyticsMetrics(id, serialNumber, day) {
    return this._request(
      `gate/asset/${id}/${serialNumber}/metrics/analytics/${day}`,
      GET,
      {},
      undefined,
      true
    );
  }

  putAsset(clientId, assetId, status) {
    if (status == "disable") {
      return this._request(
        `assets/${clientId}/${assetId}/disable`,
        PUT,
        {},
        undefined,
        true
      );
    } else {
      return this._request(
        `assets/${clientId}/${assetId}/activate`,
        PUT,
        {},
        undefined,
        true
      );
    }
  }

  getTaskTemplates(offset, limit) {
    let filter = {};
    if (offset && limit) {
      filter = {
        offset,
        limit,
      };
    }
    return this._request(`assets/tasks/template`, GET, filter, undefined, true);
  }

  postTaskTemplates(data) {
    return this._request(`assets/tasks/template`, POST, data, undefined, true);
  }
  delTaskTemplates(id) {
    return this._request(
      `assets/tasks/template/${id}`,
      DELETE,
      {},
      undefined,
      true
    );
  }

  getTasks(filter) {
    /*if (offset && limit) {
      filter = {
        offset, limit
      };
    }*/
    return this._request(`assets/tasks/list`, POST, filter, undefined, true);
  }
  getMapMarkers(filter) {
    return this._request(
      `assets/tasks/list/map`,
      POST,
      filter,
      undefined,
      true
    );
  }
  postAssignMe(taskId) {
    return this._request(
      `assets/tasks/assingtome/${taskId}`,
      POST,
      {},
      undefined,
      true
    );
  }

  getStatus() {
    return this._request(`assets/tasks/status`, GET, {}, undefined, true);
  }

  getOrdersTask() {
    return this._request(`assets/tasks/order`, GET, {}, undefined, true);
  }

  updateTask(data) {
    return this._request(`assets/tasks`, POST, data, undefined, true);
  }

  postQuickEvent(data) {
    return this._request(`assets/tasks/timeline`, POST, data, undefined, true);
  }

  getAssetsTasks(assetId) {
    return this._request(`assets/tasks/assets/${assetId}`, GET, {}, undefined, true);
  }

  postAssetsTask(data) {
    return this._request(`assets/tasks/create`, POST, data, undefined, true);
  }

  getAssetModes() {
    return this._request(`assets/modes`, GET, {}, undefined, true);
  }
  getAssetStatus() {
    return this._request(`assets/status`, GET, {}, undefined, true);
  }
  getTankData(client_id, type) {
    return this._request(
      `assets/${client_id}/${type}`,
      GET,
      {},
      undefined,
      true
    );
  }

  // ------------------- Private Methods

  _request(
    url,
    method = "GET",
    body = null,
    authCredentials = { email: "1", password: "1" },
    authToken = true,
    isBlob = false
  ) {
    var payload = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
      method: method,
    };

    if (authCredentials) {
      const { email, password } = authCredentials;
      var auth = "Basic " + window.btoa(email + ":" + password);
      payload.headers = Object.assign({}, payload.headers, {
        Authorization: auth,
      });
    }

    if (authToken && Authentication.getToken("t")) {
      var token = Authentication.getToken("t").token;
      if (typeof authToken == "string") token = authToken;
      payload.headers = Object.assign({}, payload.headers, {
        "x-blink-token": token,
      });
    }

    url = API_URL + url;

    if (method === GET) {
      if (body) {
        url = url + helpers.jsonToQueryString(body);
      }
    }

    if (body && (method === POST || method === PUT || method === DELETE)) {
      payload["body"] = JSON.stringify(body);
    }

    console.log("Requested: ", url, payload);
    return fetch(url, payload)
      .then(this.handleApiErrors)
      .then((res) => {
        const contentType = res.headers.get("content-type");
        if (res && res.status && res.status == 204) {
          return res;
        }
        if (isBlob) {
          return res.blob();
        }
        return res.json();
      })
      .catch((error) => {
        console.log("🚀 ~ error:", error);
        const { status, statusText } = error;
        //toastrUtils.error("Ha ocurrido un error.")
        console.log("API Error:", status, statusText, "API URL:", url);
        if (status == 401 && !url.indexOf("refresh") !== -1) {
          // TOKEN EXPIRED
          /*

                const tokens=Authentication.getToken('t')
                if(tokens && tokens.refreshToken){ // HAS REFRESH TOKEN
                  return this.refreshToken(tokens.refreshToken).then(tokens=>{
                    Authentication.deAuthUser()
                    Authentication.authUser({token:tokens.token,refreshToken:tokens.refreshToken})
                    return fetch(url,payload).then(this.handleApiErrors).then(res=>res.json())
                  }).catch(()=>Authentication.deAuthUser())





                }
                */
        }

        throw status;
      });
  }

  _requestUpload(
    url,
    method = "POST",
    formData,
    onUploadProgress,
    authCredentials = { email: "1", password: "1" }
  ) {
    let payload = {
      method,
      body: formData,
    };

    if (onUploadProgress) {
      payload = {
        ...payload,
        onUploadProgress,
      };
    }
    if (authCredentials) {
      const { email, password } = authCredentials;
      var auth = "Basic " + window.btoa(email + ":" + password);
      payload.headers = Object.assign({}, payload.headers, {
        Authorization: auth,
      });
    }

    if (Authentication.getToken("t")) {
      var token = Authentication.getToken("t").token;
      if (typeof authToken == "string") token = authToken;
      payload.headers = Object.assign({}, payload.headers, {
        "x-blink-token": token,
      });
    }

    url = API_URL + url;

    return fetch(url, payload)
      .then(this.handleApiErrors)
      .then((res) => {
        return res.blob();
      })
      .catch((error) => {
        console.log("🚀 ~ error:", error);
      });
  }

  _requestVoid(
    url,
    method = "GET",
    body = null,
    authCredentials = { email: "1", password: "1" },
    authToken = true
  ) {
    var payload = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
      method: method,
    };

    if (authCredentials) {
      const { email, password } = authCredentials;
      var auth = "Basic " + window.btoa(email + ":" + password);
      payload.headers = Object.assign({}, payload.headers, {
        Authorization: auth,
      });
    }

    if (authToken && Authentication.getToken("t")) {
      var token = Authentication.getToken("t").token;
      if (typeof authToken == "string") token = authToken;
      payload.headers = Object.assign({}, payload.headers, {
        "x-blink-token": token,
      });
    }

    url = API_URL + url;

    if (method === GET) {
      if (body) {
        url = url + helpers.jsonToQueryString(body);
      }
    }

    if (body && (method === POST || method === PUT || method === DELETE)) {
      payload["body"] = JSON.stringify(body);
    }

    console.log("Requested: ", url, payload);
    return fetch(url, payload)
      .then(this.handleApiErrors)
      .then((res) => {
        return res;
      })
      .catch(({ status, statusText }) => {
        console.log("API Error:", status, statusText, "API URL:", url);
        throw status;
      });
  }

  handleApiErrors(response) {
    if (!response.ok) {
      throw { status: response.status, statusText: response.statusText };
    }
    return response;
  }
})();
