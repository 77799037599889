import {handleActions} from "redux-actions"
import {SET_LANGUAGE} from "../actionTypes/languageActions"
var update = require('immutability-helper')

const initialState={
  "currentLanguage":"en"
}

const reducer=handleActions({
  [SET_LANGUAGE]:(state,action)=>update(state,{currentLanguage:{$set:action.payload}})
},initialState)

export default reducer
