import React from 'react';
import ReactDOM from  'reactDOM';
import { connect } from 'react-redux'
import * as timerActions from "../../redux/actionCreators/timerActionCreators"

const Timer = class extends React.Component {
  constructor(props){
    super(props)
    this.tick=this.tick.bind(this)
    this.execTimers=this.execTimers.bind(this)
  }
  componentDidMount(){
    // Initialize timers (15 secs)
    if(!window.timersOf15){
      window.timersOf15=[]
      setInterval(this.execTimers,15000)
    }

    // Add new timer - unique name
    window.timersOf15.push({name:this.props.name, behavior:this.props.behavior})

    // Exec timers
    this.execTimers()
  }
  execTimers(){
    window.timersOf15.forEach(t=>this.tick(t))
  }
  tick({name,behavior}){
    this.props.timerTick(name)
    behavior()
  }
  componentWillUnmount(){
    const previousTimers = window.timersOf15.filter(t=>t.name!==this.props.name)
    window.timersOf15=previousTimers
  }
  render() {
    return null
  }
}


const mapStateToProps = function(state) {
  return {
  }
}

const mapDispatchToProps = function(dispatch)  {
  return {
    timerTick:name=>dispatch(timerActions.timerTick(name))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Timer)
