import React, { Component } from 'react'
import Translate from '../shared/i18n/Translate'
import Breadcrumb from '../common/breadcrumb';
import Title from '../common/title';

class Edit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        code: this.props.isEditing ? this.props.task.code : '',
        descr: this.props.isEditing ? this.props.task.descr : '',
        has_trigger: this.props.isEditing ? this.props.task.has_trigger : false,
        trigger_url: this.props.isEditing ? this.props.task.trigger_url : null
      }
    };
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }
  componentDidMount() {
    if (this.props.isEditing) {
      this.setState({
        data: {
          ...this.state.data,
          id: this.props.task.id
        }
      })
    }
  }
  getErrorMessage() {
  }
  onChange(event) {
    const target = event.target;
    //const value = target.value;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.state.data[target.name] = value;
    this.setState({data: this.state.data});
  }
  onSave(e) {
    e.preventDefault();
    this.setState({
      data: {
        ...this.state.data,
        client_id: this.props.loggedUser.currentAccount
      }
    }, () => {
      this.props.saveTasks(this.state.data);
    });

  }
  render() {
    const { goToHome, goToList, isEditing, strings} = this.props;
    const title = isEditing ? `${strings.editTask}` : `${strings.newTask}`;
    let breadcrumb = [
      {text: strings.home, toUrl: goToHome},
      {text: strings.taskTemplates, toUrl: goToList},
      {text: title}
    ];
    return (
      <React.Fragment>
        <Title title={title} showBtn={false}>
          <Breadcrumb list={breadcrumb} />
        </Title>
        <div className="col-md-12">
          <div className="box box-info">
            <form autoComplete="off" className="form-horizontal">
              <div className="box-body">
                {this.props.isEditing && <div className="form-group">
                  <label className="col-sm-3 control-label">{strings.id}</label>
                  <div className="col-sm-6">
                    <input type="text" value={this.state.data.id} className="form-control" placeholder={strings.id} disabled/>
                  </div>
                </div>}

                <div className="form-group">
                  <label className="col-sm-3 control-label">{strings.code}</label>
                  <div className="col-sm-6">
                    <input type="text" value={this.state.data.code} name="code" className="form-control" required onChange={this.onChange}/>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">{strings.descr}</label>
                  <div className="col-sm-6">
                    <input type="text" value={this.state.data.descr} name="descr" className="form-control" required onChange={this.onChange}/>
                  </div>
                </div>
                <div className="form-group">


                  <label className="col-sm-3 control-label">{strings.trigger}</label>
                  <div className="col-sm-6">
                    <input name="has_trigger" id="has_trigger" checked={this.state.data.has_trigger}  type="checkbox" onChange={this.onChange} />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-3 control-label">{strings.trigger_url}</label>
                  <div className="col-sm-6">
                    <input type="text"
                      value={this.state.data.trigger_url}
                      name="trigger_url"
                      className="form-control"
                      onChange={this.onChange}
                      disabled={!this.state.data.has_trigger}
                    />
                  </div>
                </div>
              </div>
              <div className="box-footer zd-box-footer">
                <button type="submit" className="btn btn-info pull-left" onClick={(e) => { this.onSave(e) }}>{strings.save}</button>
                <button className="btn btn-default pull-right" onClick={(e) => { e.preventDefault();goToList() }} >
                  {strings.cancel}
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
      )
    }
  }

  export default Translate(Edit, "TasksTemplates");
