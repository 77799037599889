import React from 'react';
import ReactDOM from  'reactDOM';
import $ from 'jquery';
import jQuery from 'jquery';
import Translate from '../shared/i18n/Translate'

window.$ = $;
window.jQuery = jQuery;

var Todo = ({strings}) => (
  <div>
 

    
    <section className="content" style={{'minHeight': '650px'}}>
      <div className="error-page">
        <h2 className="headline text-yellow"> Support Center</h2>
        <div className="error-content">
          <h3><i className="fa fa-warning text-yellow"></i> do you want to access the support center? </h3>
          <div>
            if you are new in the platform, check the Getting Started Guide.
            Meanwhile, you may <a href="https://blinkiotplatformhelp.zendesk.com" target="_blank">begin here</a> 
          </div>
        </div>
      </div>
    </section>
  
 

  </div>
)

export default Translate(Todo,"NotFoundPage")
