import { handleActions } from "redux-actions"
import { SET_ASSETTYPES, SET_ASSET_CLASSES, SET_MANUFACTURES } from "../actionTypes/assetTypesListActions"
var update = require('immutability-helper')

const initialState = {
  listAssets: [],
  listAssetClasses: [],
  listManufactures: []
}

const reducer = handleActions({
  [SET_ASSETTYPES]: (state, action) => update(state, {
    listAssets: {$set: action.payload}
  }),
  [SET_ASSET_CLASSES]: (state, action) => update(state, {
    listAssetClasses: {$set: action.payload}
  })
  ,
  [SET_MANUFACTURES]: (state, action) => update(state, {
    listManufactures: {$set: action.payload}
  }),
}, initialState)

export default reducer