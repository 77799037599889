

import React from 'react';
import PropTypes from 'prop-types';

class Controls extends React.Component {
    //const { title, newActionLabel, children, showBreadcrumb } = props;
    constructor(props) {
        super(props);
        this.state = {
            paging: {
                prev: false,
                next: false
            }
        };
        this.onPrev = this.onPrev.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.paging !== prevProps.paging) {
            this.setState({
                paging: {
                    ...this.state.paging,
                    next: this.props.paging.total == this.props.paging.limit,
                    prev: this.props.paging.page > 1
                }
            })
        }
    }
    onPrev() {
        console.log('previo controls');
        const toPage = this.props.paging.page - 1; // 2
        const offset = this.props.paging.limit * (toPage - 1);
        this.props.onPrev(offset);
    }

    onNext() {
        const toPage = this.props.paging.page + 1;
        const offset = this.props.paging.limit * (toPage - 1);
        this.props.onNext(offset);
    }
    onRefresh() {
        this.props.onRefresh();
    }

  render() {
    const { onRefresh } = this.props;
    return <div className="mailbox-controls iot-controls">
            <div className="pull-left">
            <button type="button" className="btn btn-default btn-sm" onClick={this.onRefresh}>
                <i className="fa fa-refresh"></i>
            </button>
            </div>
            <div className="pull-right">
                <div className="btn-group pull-right">
                    <button type="button" className="btn btn-default btn-sm" disabled={!this.state.paging.prev} onClick={this.onPrev}><i className="fa fa-chevron-left"></i></button>
                    <button type="button" className="btn btn-default btn-sm" disabled={!this.state.paging.next} onClick={this.onNext}><i className="fa fa-chevron-right"></i></button>
                </div>
                {/*<div className="page pull-right">1/2</div> */}
            </div>
        </div>
    }
}

Controls.propTypes = {
    onRefresh: PropTypes.func,
    onNext: PropTypes.func,
    onPrevious: PropTypes.func
};

Controls.defaultProps = {
    onRefresh: () => {},
    onNext: () => {},
    onPrevious: () => {}
};

module.exports = Controls;